import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../hooks';
import { closeRequestReviewModal, FlagCallModalState } from '../../../redux/reducers';
import { useGetCallQuery, useGetUsersForSelectQuery } from '../../../services';
import { ComponentSize, FlaggedUser, RoleFilterType, Roles, StatusColor, TextColor } from '../../../types';
import { parseUserToOption } from '../../../utils';
import { Dialog, Icon, Icons, MultiSelect, Spinner, Typography, TypographySize } from '../../shared';
import RequestReviewModalActions from './RequestReviewModalActions';

const MAX_OPTIONS_HEIGHT = 212;

const toggleNotify = (user: FlaggedUser) => ({ ...user, notify: !user.notify });

const RequestReviewModal = ({ callSid: callSidProp, call: callProp }: FlagCallModalState) => {
  // State
  const [searchValue, setSearchValue] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<FlaggedUser[] | undefined>();

  // Redux
  const dispatch = useAppDispatch();

  // Queries
  // If the call is passed in as a prop, we skip the call query.
  const { data: fetchedCall, isLoading: isLoadingCall } = useGetCallQuery(callSidProp || '', { skip: !!callProp });
  const { data: userData, isLoading: isLoadingUsers } = useGetUsersForSelectQuery({
    roleFilterType: RoleFilterType.SPECIFY_ROLES,
    roles: [Roles.MANAGER, Roles.ADMIN],
  });
  const users = userData?.users || [];

  const call = callProp || fetchedCall;
  const isLoading = isLoadingCall || isLoadingUsers;

  // Updates the selectedUsers with the flagged users after the call is loaded.
  useEffect(() => {
    if (call) {
      setSelectedUsers(call.flaggedUserIds?.map((userId) => ({ userId, notify: false })));
    }
  }, [call]);

  // Checks if the user is flagged.
  const isFlagged = useCallback((id: string) => call?.flaggedUserIds?.includes(id), [call?.flaggedUserIds]);

  const onMultiSelectChange = useCallback(
    (newSelected?: string[]) => {
      const newSelectedWithNotify = newSelected?.map((userId) => ({ userId, notify: !isFlagged(userId) }));
      setSelectedUsers(newSelectedWithNotify);
    },
    [isFlagged, setSelectedUsers]
  );

  const options = useMemo(() => {
    // Parse the user list to options.
    const managerUserOptions = users.map(parseUserToOption);
    // Map over the options to add the notify again button if applicable.
    return managerUserOptions.map((option) => {
      // If the user is flagged and is currently selected, then they can be notified again.
      const canNotifyAgain = isFlagged(option.value) && selectedUsers?.find((user) => user.userId === option.value);
      // Whether the user is set to be notified.
      const isNotifying = selectedUsers?.find((user) => user.userId === option.value)?.notify;
      // Toggles the notification status for the user.
      const onNotifyAgain = () =>
        setSelectedUsers(
          selectedUsers?.map((user) => {
            const isUser = user.userId === option.value;
            return isUser ? toggleNotify(user) : user;
          })
        );

      return {
        ...option,
        endElement: canNotifyAgain ? (
          <Typography
            size={TypographySize.CAPTION}
            color={isNotifying ? TextColor.PRIMARY : TextColor.TERTIARY}
            onClick={onNotifyAgain}
          >
            Notify again
          </Typography>
        ) : undefined,
      };
    });
  }, [call?.flaggedUserIds, users, isFlagged, selectedUsers, setSelectedUsers]);

  const selectedOptions = users
    .filter((user) => selectedUsers?.find((u) => u.userId === user.id))
    .map(parseUserToOption);

  return (
    <Dialog
      isOpen
      onClose={() => dispatch(closeRequestReviewModal())}
      title="Request review"
      icon={<Icons icon={Icon.FLAG} color={StatusColor.WARNING} fill />}
    >
      <div className="flex h-full flex-col">
        {isLoading && <Spinner className="h-full self-center" size={ComponentSize.MEDIUM} />}
        {!isLoading && (
          <div className="flex flex-col gap-4">
            <MultiSelect
              inline
              options={options}
              selected={selectedOptions}
              onChange={onMultiSelectChange}
              searchProps={{
                searchValue,
                setSearchValue,
              }}
              maxOptionsHeight={MAX_OPTIONS_HEIGHT}
            />
            <RequestReviewModalActions
              callSid={call?.callSid}
              flaggedUserIds={call?.flaggedUserIds}
              selectedUsers={selectedUsers}
            />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default RequestReviewModal;
