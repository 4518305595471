import { useMemo } from 'react';
import { ButtonColor, Icon, Select, TextButton, TextInput } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { randomizeScene, setBackgroundAudioPrompt } from '../../../../redux/reducers';
import { Permissions } from '../../../../types';
import ProspectFieldGroup from '../ProspectFieldGroup';
import ProspectPageField from '../ProspectPageField';
import TabSection from '../TabSection';

const AUDIO_SELECTION_SELECT_WIDTH = 300;

const Scene = () => {
  const dispatch = useAppDispatch();
  const backgroundAudioPrompt = useAppSelector((state) => state.prospect.fields.backgroundAudioPrompt);
  const user = useAppSelector((state) => state.auth.user);
  const isSuperAdmin = (user?.permissions || []).includes(Permissions.ADMIN_ACCESS);

  const handleBgPromptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newBackgroundAudioPrompt = e.target.value;
    dispatch(setBackgroundAudioPrompt(newBackgroundAudioPrompt));
  };

  const audioFields = useMemo(
    () => [
      {
        label: 'Audio selection',
        width: AUDIO_SELECTION_SELECT_WIDTH,
        content: <Select disabled={!backgroundAudioPrompt.value} options={[]} onChange={() => {}} />,
      },
      {
        label: 'Preview audio',
        content: <TextButton color={ButtonColor.SECONDARY} disabled text="0:00/0:00" startIcon={Icon.PLAY} />,
      },
    ],
    []
  );

  return (
    <TabSection title="Scene" onRandomize={() => dispatch(randomizeScene())}>
      <ProspectPageField
        label="Background prompt"
        content={<TextInput value={backgroundAudioPrompt.value} onChange={handleBgPromptChange} />}
      />
      {/* TODO: Add audio selection and preview audio and enable for non-super-admins. */}
      {isSuperAdmin && <ProspectFieldGroup fields={audioFields} />}
    </TabSection>
  );
};

export default Scene;
