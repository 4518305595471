import { useMemo } from 'react';
import { STATS_PANEL_WIDTH } from '../../../../constants';
import { useGetOrganizationSettingsQuery } from '../../../../services';
import { Benchmarks, StatusColor } from '../../../../types';
import { formatDurationVerbose } from '../../../../utils';
import CallStatsCard from './CallStatsCard';

const SUCCESS_TALK_SPEED_AND_RATIO_MARGIN_PERCENTAGE = 5;
const WARNING_TALK_SPEED_AND_RATIO_MARGIN_PERCENTAGE = 20;

const WARNING_FILLER_AND_MONOLOGUE_MARGIN_PERCENTAGE = 20;

const roundToTwoDecimals = (value: number): number => Math.round(value * 100) / 100;

const calculateTalkRatio = (talkTime: number, callDuration: number): number =>
  callDuration ? roundToTwoDecimals((talkTime / callDuration) * 100) : 0;

const calculateTalkSpeed = (wordsCount: number, talkTime: number): number =>
  talkTime ? Math.round((wordsCount / talkTime) * 60) : 0;

// Get the color of the indicator based on the value and the benchmark set in the org settings.
const getStatIndicatorColor = (value: number, type: Benchmarks, benchmark?: number) => {
  if (!benchmark) return undefined;

  switch (type) {
    case Benchmarks.LONGEST_MONOLOGUE:
    case Benchmarks.FILLER_WORDS:
      // Value at benchmark or under is successful
      if (value <= benchmark) return StatusColor.SUCCESS_CONTENT;
      // Value up to 20% above benchmark is warning
      if (value >= benchmark * (1 + WARNING_FILLER_AND_MONOLOGUE_MARGIN_PERCENTAGE / 100)) return StatusColor.WARNING;
      // Value more than 20% above benchmark is critical
      return StatusColor.ERROR_CONTENT;

    case Benchmarks.TALK_RATIO:
    case Benchmarks.TALK_SPEED: {
      const deviation = (Math.abs(value - benchmark) / benchmark) * 100;
      // Value up to 5% below or above benchmark is successful
      if (deviation <= SUCCESS_TALK_SPEED_AND_RATIO_MARGIN_PERCENTAGE) return StatusColor.SUCCESS_CONTENT;
      // Value up to 20% below or above benchmark is warning
      if (deviation <= WARNING_TALK_SPEED_AND_RATIO_MARGIN_PERCENTAGE) return StatusColor.WARNING;
      // Value more than 20% below or above benchmark is critical
      return StatusColor.ERROR_CONTENT;
    }

    default:
      return undefined;
  }
};

interface CallStatsPanelProps {
  longestMonologueDuration?: number;
  fillerWordsCount?: number;
  wordsCount?: number;
  talkTime?: number;
  callDuration?: number;
}

const CallStatsPanel = ({
  longestMonologueDuration = 0,
  fillerWordsCount = 0,
  wordsCount = 0,
  talkTime = 0,
  callDuration = 0,
}: CallStatsPanelProps) => {
  const { data: orgConfigs } = useGetOrganizationSettingsQuery();
  const { longestMonologue, fillerWordsPerMinute, wordsPerMinute, talkRatio } = orgConfigs || {};

  const calculatedTalkRatio = useMemo(() => calculateTalkRatio(talkTime, callDuration), [talkTime, callDuration]);
  const calculatedTalkSpeed = useMemo(() => calculateTalkSpeed(wordsCount, talkTime), [wordsCount, talkTime]);

  const monologueIndicatorColor = getStatIndicatorColor(
    longestMonologueDuration,
    Benchmarks.LONGEST_MONOLOGUE,
    longestMonologue ?? undefined
  );
  const talkRatioIndicatorColor = getStatIndicatorColor(
    calculatedTalkRatio,
    Benchmarks.TALK_RATIO,
    talkRatio ?? undefined
  );
  const talkSpeedIndicatorColor = getStatIndicatorColor(
    calculatedTalkSpeed,
    Benchmarks.TALK_SPEED,
    wordsPerMinute ?? undefined
  );
  const fillerWordsIndicatorColor = getStatIndicatorColor(
    fillerWordsCount,
    Benchmarks.FILLER_WORDS,
    fillerWordsPerMinute ?? undefined
  );

  return (
    <div className="flex flex-col gap-4" style={{ minWidth: STATS_PANEL_WIDTH }}>
      {/* Longest time representative spoke without letting the prospect speak. */}
      <CallStatsCard
        title="Longest Monologue"
        value={formatDurationVerbose(longestMonologueDuration)}
        subtitle="by representative"
        indicatorColor={monologueIndicatorColor}
      />
      {/* The % of the call the representative was speaking. */}
      <CallStatsCard
        title="Talk Ratio"
        value={`${calculatedTalkRatio}%`}
        subtitle="representative speaking"
        indicatorColor={talkRatioIndicatorColor}
      />
      {/* How many words per minute the representative spoke */}
      <CallStatsCard
        title="Talk Speed"
        value={calculatedTalkSpeed}
        subtitle="words per minute"
        indicatorColor={talkSpeedIndicatorColor}
      />
      {/* How many filler words the representative spoke per minute */}
      <CallStatsCard
        title="Filler Words"
        value={fillerWordsCount}
        subtitle="filler words per minute"
        indicatorColor={fillerWordsIndicatorColor}
      />
    </div>
  );
};

export default CallStatsPanel;
