import { Slider, SliderProps, Typography, TypographyComponent } from '../../../components';

interface ProspectSliderProps extends SliderProps {
  leftLabel: TypographyComponent | string | number;
  rightLabel: TypographyComponent | string | number;
}

const ProspectSlider = ({ leftLabel, rightLabel, ...props }: ProspectSliderProps) => {
  return (
    <div className="flex w-full items-center gap-4">
      <Typography className="select-none">{leftLabel}</Typography>
      <Slider hideStep {...props} />
      <Typography className="select-none">{rightLabel}</Typography>
    </div>
  );
};

export default ProspectSlider;
