import * as RadixTabs from '@radix-ui/react-tabs';
import clsx from 'clsx';
import { Orientation } from '../../../types';
import { TabsProps, TabsType } from './Tabs.types';
import TabsContent from './TabsContent';
import TabsList from './TabsList';

const Tabs = ({
  activeTab,
  tabs,
  setActiveTab,
  className,
  orientation = Orientation.HORIZONTAL,
  type = TabsType.BOXED,
}: TabsProps) => {
  return (
    <RadixTabs.Root
      value={activeTab}
      className={clsx(
        'flex h-full',
        orientation === Orientation.VERTICAL ? 'flex-row' : 'flex-col',
        type !== TabsType.LIFTED && 'gap-4',
        className
      )}
    >
      <TabsList activeTab={activeTab} orientation={orientation} tabs={tabs} type={type} setActiveTab={setActiveTab} />
      <TabsContent tabs={tabs} type={type} orientation={orientation} />
    </RadixTabs.Root>
  );
};

export default Tabs;
