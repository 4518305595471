import { useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeWebCall, WebCallData } from '../../../redux/reducers';
import { Orientation } from '../../../types';
import { Divider, MAX_TAGS_AND_NOTES_HEIGHT, ProspectDetails, TagsAndNotesCell } from '../../shared';
import WebCallActions from './WebCallActions';
import WebCallStatus, { CallStatus } from './WebCallStatus';
import WebCallUpNext from './WebCallUpNext';

// Use your own Twilio number for testing in development by passing it to startCall.
// const TWILIO_NUMBER = '+18589464419';

interface WebCallModalContentProps {
  callSid: string;
  currWebCall: WebCallData;
  isCalling: boolean;
  isConnected: boolean;
  isError: boolean;
  isHangingUp: boolean;
  isMuted: boolean;
  mute: () => void;
  unmute: () => void;
  startCall: (number: string, userId: string) => void;
  stopCall: () => void;
  startTime?: number;
}

const WebCallModalContent = ({
  callSid,
  currWebCall,
  isCalling,
  isConnected,
  isError,
  isHangingUp,
  isMuted,
  mute,
  unmute,
  startCall,
  stopCall,
  startTime,
}: WebCallModalContentProps) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.auth.user?.id);

  const { currProspect, nextProspect } = currWebCall;
  const { personaId, associatedPhoneNumber, tags, notes } = currProspect;

  // Determine the current status of the call.
  const status = useMemo(() => {
    // If the call is connected or there is an error, set the status to CONNECTED.
    // This is to simulate a connection while the error audio plays.
    if (isConnected || isError) return CallStatus.CONNECTED;

    // If the call is in progress, set the status to CALLING.
    if (isCalling) return CallStatus.CALLING;

    // If none of the above conditions are met, set the status to IDLE.
    return CallStatus.IDLE;
  }, [isError, isConnected, isCalling]);

  // Handles calling the given number.
  const callNumber = useCallback(
    (number: string) => {
      if (userId) {
        startCall(number, userId);
      }
    },
    [userId, startCall]
  );

  // Handles closing the web call modal.
  const onCloseWebCallModal = useCallback(() => {
    dispatch(closeWebCall());
  }, [dispatch]);

  // Start a new call whenever the prospect changes.
  useEffect(() => {
    const timeoutId = setTimeout(() => callNumber(associatedPhoneNumber), 0);
    return () => clearTimeout(timeoutId);
  }, [associatedPhoneNumber, callNumber]);

  // End the call when the user unloads the page.
  useEffect(() => {
    const handleBeforeUnload = () => {
      stopCall();
      onCloseWebCallModal();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [stopCall, onCloseWebCallModal]);

  return (
    <div className="flex items-center gap-8" style={{ height: MAX_TAGS_AND_NOTES_HEIGHT }}>
      <div className="flex-1">
        <ProspectDetails {...currProspect} />
      </div>
      <Divider type={Orientation.VERTICAL} />
      <div className="flex-[1.5]">
        <TagsAndNotesCell prospectId={personaId} prospectTags={tags} notes={notes} />
      </div>
      <Divider type={Orientation.VERTICAL} />
      <div className="flex-1">
        <WebCallStatus
          callSid={callSid}
          isConnected={isConnected}
          isHangingUp={isHangingUp}
          startTime={startTime}
          status={status}
          handleRedial={() => callNumber(associatedPhoneNumber)}
        />
      </div>
      <Divider type={Orientation.VERTICAL} />
      <div className="flex-[1.25]">
        {status !== CallStatus.IDLE && (
          <WebCallActions endCall={stopCall} isHangingUp={isHangingUp} isMuted={isMuted} mute={mute} unmute={unmute} />
        )}
        {status === CallStatus.IDLE && <WebCallUpNext currPersonaId={personaId} nextProspect={nextProspect} />}
      </div>
    </div>
  );
};

export default WebCallModalContent;
