import { useEffect, useState } from 'react';
import { NUM_LOADING_SKELETONS } from '../../../../../constants';
import { useGetInvitesQuery, useGetUsersQuery } from '../../../../../services';
import { AppUser, ComponentSize } from '../../../../../types';
import { sortUsers } from '../../../../../utils';
import { Divider, Skeleton } from '../../../../shared';
import UserRow from './UserRow';

const UserTable = () => {
  const { data: users = [], isLoading: isOrgUsersLoading } = useGetUsersQuery({});
  const { data: invites = [], isLoading: isInvitesLoading } = useGetInvitesQuery();
  const isLoading = isOrgUsersLoading || isInvitesLoading;

  // State to manage the most recent user data,
  // providing immediate feedback on the UI after successful API responses,
  // ensuring a responsive user experience.
  const [updatedUsers, setUpdatedUsers] = useState<AppUser[]>([]);

  // Transform invitees data for consistent structure with users.
  const invitees = invites.map(({ id, email, role }) => ({
    id,
    email,
    name: email,
    role,
    isInvitee: true,
  }));

  // Combine and sort users and invitees.
  const sortedUsers = [...updatedUsers, ...invitees].sort(sortUsers);

  // Update local state when users data changes from API response
  useEffect(() => {
    if (!isOrgUsersLoading) {
      setUpdatedUsers(users);
    }
  }, [users, isOrgUsersLoading]);

  return (
    <>
      {isLoading &&
        Array.from({ length: NUM_LOADING_SKELETONS }, (_, index) => (
          <div key={index}>
            <div className="flex justify-between py-4">
              <Skeleton size={ComponentSize.MEDIUM} />
            </div>
            {index < NUM_LOADING_SKELETONS - 1 && <Divider />}
          </div>
        ))}
      {!isLoading && (
        <div className="display-scrollbar-lg max-h-full overflow-auto">
          {sortedUsers.map((user, index) => {
            const isLastUser = index === sortedUsers.length - 1;
            return (
              <div key={user.id}>
                <UserRow rowUser={user} setUsers={setUpdatedUsers} />
                {!isLastUser && <Divider />}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default UserTable;
