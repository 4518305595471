import { useCallback } from 'react';
import { updateOrganization } from '../redux/reducers';
import { Roles } from '../types';
import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';

const useUpdateOrgSeats = () => {
  const dispatch = useAppDispatch();
  const currUserSeats = useAppSelector((state) => state.auth.organization?.userSeats);

  const updateOrgSeats = useCallback(
    (oldRole?: Roles, newRole?: Roles) => {
      const updatedSeats = { ...currUserSeats } as Record<Roles, number>;

      // Decrement the old role count
      if (oldRole) updatedSeats[oldRole] = Math.max(0, (updatedSeats[oldRole] || 0) - 1);

      // Increment the new role count
      if (newRole) updatedSeats[newRole] = (updatedSeats[newRole] || 0) + 1;

      dispatch(updateOrganization({ userSeats: updatedSeats }));
    },
    [dispatch, currUserSeats]
  );

  return updateOrgSeats;
};

export default useUpdateOrgSeats;
