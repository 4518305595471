import { ProspectType } from './prospect.types';

export enum PromptTemplateStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export type PromptTemplateForSelect = {
  id: string;
  name: string;
  purpose?: ProspectType;
};

export type PromptTemplate = PromptTemplateForSelect & {
  createdAt?: string;
  default?: boolean;
  isUsed?: boolean;
  lastEditor?: {
    id: string;
    name: string;
    picture: string;
  };
  prompt?: string;
  status?: PromptTemplateStatus;
  updatedAt?: string;
};

export type PromptTemplatesResponse = {
  message: string;
  data: {
    promptTemplates: PromptTemplate[];
  };
};

export enum PromptTemplatesSelectMode {
  SELECT = 'select',
  ALL = 'all',
}

export type PromptTemplatesParams = {
  selectMode: PromptTemplatesSelectMode;
  purpose?: ProspectType;
  status?: PromptTemplateStatus;
};

export type CreatePromptTemplatePayload = {
  name: string;
  prompt: string;
  default?: boolean;
};

export type UpdatePromptTemplatePayload = {
  id: string;
  default?: boolean;
  name?: string;
  prompt?: string;
  purpose?: ProspectType;
};
