import { useCallback, useState, useRef } from 'react';
import { useGenerateUploadUrlMutation, useUploadFileMutation } from '../services';
import { FileStatus, FileDataType } from '../types';
import { generateFileId } from '../utils';
import { MAX_UPLOAD_FILE_SIZE } from '../constants';
import { useToast } from '../hooks';
import { AlertType } from '../components';
import { ShowToastProps } from '../context';

const UPLOAD_FILE_ERROR_MSG = 'Failed to upload file';

const FILE_UPLOAD_INTERVAL = 800; // 800ms
const FILE_UPLOAD_PROGRESS_MAX_INCREMENT = 20; // 20%
const FILE_UPLOAD_PROGRESS_MAX = 90; // 90%

interface UseUploadFilesProps {
  successToastData: ShowToastProps;
  toCreateProspect?: boolean;
}

/* Custom hook to handle multiple files upload */
const useUploadFiles = ({ successToastData, toCreateProspect }: UseUploadFilesProps) => {
  // State to store the files and their upload status
  const [files, setFiles] = useState<FileDataType[]>([]);
  // Add a ref to store abort controllers for each file
  const abortControllersRef = useRef<Map<string, AbortController>>(new Map());

  // Toast hook
  const { showToast } = useToast();

  // Mutations
  const [generateUploadUrl] = useGenerateUploadUrlMutation();
  const [uploadFile] = useUploadFileMutation();

  // Error handler
  const onError = useCallback(
    (selectedFiles: FileDataType[]) => {
      showToast({
        message: UPLOAD_FILE_ERROR_MSG,
        type: AlertType.ERROR,
      });
      // Update files attempting to upload status to error
      setFiles((prevFiles) =>
        prevFiles.map((file) => ({
          ...file,
          status: selectedFiles.some((selectedFile) => selectedFile.id === file.id) ? FileStatus.ERROR : file.status,
        }))
      );
    },
    [showToast]
  );

  // Success handler
  const onSuccess = useCallback(() => {
    showToast({ ...successToastData, type: AlertType.SUCCESS });
  }, [showToast]);

  // Simulate progress for each file
  // Update progress every 800ms with a random number between 0-20 based on file size
  // Stop when progress is >= 90 and file is uploading till the actual upload is complete
  const simulateProgress = useCallback(() => {
    return setInterval(() => {
      setFiles((prev) =>
        prev.map((f) => {
          if (f.status !== FileStatus.UPLOADING || f.progress >= FILE_UPLOAD_PROGRESS_MAX) return f;

          // Calculate progress increment based on file size
          // For files close to MAX_FILE_SIZE, increment will be smaller
          const sizeRatio = 1 - f.file.size / MAX_UPLOAD_FILE_SIZE;
          // Larger files will have smaller increments
          const maxIncrement = FILE_UPLOAD_PROGRESS_MAX_INCREMENT * sizeRatio;

          return {
            ...f,
            progress: Math.min(Math.floor(f.progress + Math.random() * maxIncrement), FILE_UPLOAD_PROGRESS_MAX),
          };
        })
      );
    }, FILE_UPLOAD_INTERVAL);
  }, []);

  const handleUploadFiles = useCallback(
    async (selectedFiles: FileDataType[]) => {
      // Get the files object to upload
      const filesToUpload = selectedFiles.map((file) => file.file);

      if (!filesToUpload.length) return onError(selectedFiles);

      // Simulate progress for each file
      const progressInterval = simulateProgress();

      try {
        const generateUploadUrlPayload = {
          files: filesToUpload.map(({ type, name, size }) => ({
            type,
            name,
            size,
            isForPPCreation: toCreateProspect,
          })),
        };

        // Generate upload URLs for the files
        const { data: generatedUploadUrlData, error: generateUploadUrlError } =
          await generateUploadUrl(generateUploadUrlPayload);

        // Check if the upload URL generation was not successful
        if (generateUploadUrlError || !generatedUploadUrlData?.length) {
          onError(selectedFiles);
          return;
        }

        // Upload files and track individual results
        const uploadPromises = filesToUpload.map(async (file, index) => {
          const uploadUrl = generatedUploadUrlData[index].presignedUrl;
          const fileId = generateFileId(file);

          // Create new AbortController for this file
          const abortController = new AbortController();
          abortControllersRef.current.set(fileId, abortController);

          return uploadFile({
            file,
            uploadUrl,
            signal: abortController.signal,
          }).then((result) => {
            // Clean up abort controller after upload completes
            abortControllersRef.current.delete(fileId);

            setFiles((prev) =>
              prev.map((f) =>
                f.id === fileId
                  ? {
                      ...f,
                      status: result.error ? FileStatus.ERROR : FileStatus.SUCCESS,
                    }
                  : f
              )
            );
            return { file, result };
          });
        });

        // Wait for all uploads to complete
        const uploadResults = await Promise.all(uploadPromises);
        // Only call onSuccess if at least one file was uploaded successfully
        if (uploadResults.some((result) => !result.result.error)) {
          onSuccess();
        }
      } catch (error) {
        console.error(error, UPLOAD_FILE_ERROR_MSG);
        onError(selectedFiles);
      } finally {
        // Clear progress interval
        clearInterval(progressInterval);
      }
    },
    [generateUploadUrl, uploadFile, simulateProgress, onError, onSuccess]
  );

  // Upload abort by fileId
  const abortFileUpload = useCallback((fileId: string) => {
    const controller = abortControllersRef.current.get(fileId);
    if (controller) {
      controller.abort();
      abortControllersRef.current.delete(fileId);
    }
  }, []);

  // Abort all ongoing uploads
  const abortAllFileUploads = useCallback(() => {
    abortControllersRef.current.forEach((controller) => {
      controller.abort();
    });
    // Clear the controllers map
    abortControllersRef.current.clear();
  }, []);

  return {
    uploadFiles: handleUploadFiles,
    files,
    setFiles,
    abortFileUpload,
    abortAllFileUploads,
  };
};

export default useUploadFiles;
