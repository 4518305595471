import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { PaginationState } from '@tanstack/react-table';
import { ApiPostError, CustomSortingState } from '../types';

export const getUrlWithPaginationAndSorting = (
  url: string,
  pagination?: PaginationState,
  sorting?: CustomSortingState
) => {
  const params = new URLSearchParams();

  // let paginationQuery: string | undefined = undefined;
  if (pagination) {
    params.set('page', pagination.pageIndex.toString());
    params.set('perPage', pagination.pageSize.toString());
  }

  // let sortingQuery: string | undefined = undefined;
  if (sorting) {
    params.set('sortBy', sorting.sortBy);
    params.set('sortOrder', sorting.sortOrder);
  }

  return `${url}?${params.toString()}`;
};

/**
 * Checks if the error is a SerializedError.
 */
export const isSerializedError = (error: FetchBaseQueryError | ApiPostError): error is SerializedError => {
  return !('status' in error);
};

/**
 * Creates a URL with query parameters, parsing arrays into key=value&key=value format.
 */
export const getUrlWithFlatQueryParams = (url: string, params: Record<string, boolean | string | string[]>) => {
  const paramsString = Object.entries(params)
    .flatMap(([key, value]) =>
      Array.isArray(value)
        ? value.map((item) => `${key}=${encodeURIComponent(item)}`)
        : `${key}=${encodeURIComponent(value)}`
    )
    .join('&');
  return `${url}?${paramsString}`;
};
