import { ButtonColor, Icon, Select, TextButton, TextInput } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { randomizeScene, setBackgroundAudioPrompt } from '../../../../redux/reducers';
import ProspectPageField from '../ProspectPageField';
import TabSection from '../TabSection';

const AUDIO_SELECTION_SELECT_WIDTH = 300;

const Scene = () => {
  const dispatch = useAppDispatch();
  const backgroundAudioPrompt = useAppSelector((state) => state.prospect.fields.backgroundAudioPrompt);

  const handleBgPromptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newBackgroundAudioPrompt = e.target.value;
    dispatch(setBackgroundAudioPrompt(newBackgroundAudioPrompt));
  };

  return (
    <TabSection title="Scene" onRandomize={() => dispatch(randomizeScene())}>
      <ProspectPageField
        label="Background prompt"
        content={<TextInput value={backgroundAudioPrompt.value} onChange={handleBgPromptChange} />}
      />
      <div className="flex w-full gap-4">
        <ProspectPageField
          label="Audio selection"
          width={AUDIO_SELECTION_SELECT_WIDTH}
          content={<Select disabled={!backgroundAudioPrompt.value} options={[]} onChange={() => {}} />}
        />
        <ProspectPageField
          label="Preview audio"
          content={<TextButton color={ButtonColor.SECONDARY} disabled text="0:00/0:00" startIcon={Icon.PLAY} />}
        />
      </div>
    </TabSection>
  );
};

export default Scene;
