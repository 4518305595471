import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProspectDesignerModal, Spinner } from '../../components';
import { useAppDispatch, useAppSelector, useWarnBeforeUnload } from '../../hooks';
import { clearProspectForm, setProspectForm } from '../../redux/reducers';
import { useGetPracticeProspectByIdQuery } from '../../services/api.services';
import { ProspectPageMode, ProspectPageTabs } from '../../types';
import ProspectPageFooter from './ProspectPageFooter';
import ProspectPageHeader from './ProspectPageHeader';
import { AccountTab, ConfigurationTab, LeadTab, PersonalTab, PromptTab, ScenarioTab } from './ProspectPageTabs';

const DEFAULT_ACTIVE_TAB = ProspectPageTabs.PERSONAL;

const ProspectPage = () => {
  const { personaId } = useParams();

  const dispatch = useAppDispatch();
  const { mode, orgId, prospectType } = useAppSelector((state) => state.prospect);

  const [activeTab, setActiveTab] = useState(DEFAULT_ACTIVE_TAB);
  // In non-create mode, all tabs are visited.
  const [visitedTabs, setVisitedTabs] = useState(new Set([DEFAULT_ACTIVE_TAB]));

  const { data: prospect, isLoading: isLoadingProspect } = useGetPracticeProspectByIdQuery(personaId as string, {
    skip: !personaId,
  });

  // Warn the user if they try to leave the page without saving.
  // We don't need to warn the user if they are not editing the form.
  useWarnBeforeUnload(mode === ProspectPageMode.VIEW);

  // Loads the prospect data into the form.
  const resetForm = useCallback(() => {
    if (prospect) dispatch(setProspectForm(prospect));
  }, [dispatch, prospect]);

  // Load prospect data into form when personaId and prospect are available
  useEffect(() => {
    resetForm();
  }, [resetForm]);

  // If the mode is view, set the visited tabs to all tabs.
  useEffect(() => {
    if (mode === ProspectPageMode.VIEW && visitedTabs.size !== Object.values(ProspectPageTabs).length) {
      setVisitedTabs(new Set(Object.values(ProspectPageTabs)));
    }
    // Only listen to mode changes.
  }, [mode]);

  // Resets the prospect form when the page is unmounted.
  useEffect(() => {
    return () => {
      dispatch(clearProspectForm());
    };
  }, []);

  const renderTabContent = () => {
    switch (activeTab) {
      case ProspectPageTabs.PERSONAL:
        return <PersonalTab />;
      case ProspectPageTabs.LEAD:
        return <LeadTab />;
      case ProspectPageTabs.ACCOUNT:
        return <AccountTab />;
      case ProspectPageTabs.SCENARIO:
        return <ScenarioTab />;
      case ProspectPageTabs.CONFIGURATION:
        return <ConfigurationTab />;
      case ProspectPageTabs.PROMPT:
      default:
        return <PromptTab />;
    }
  };

  if (isLoadingProspect) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col">
      <ProspectPageHeader
        activeTab={activeTab}
        visitedTabs={visitedTabs}
        setActiveTab={setActiveTab}
        setVisitedTabs={setVisitedTabs}
      />
      <div className="flex flex-grow justify-center overflow-y-auto py-8">
        <div className="h-fit w-full max-w-[700px]">{renderTabContent()}</div>
      </div>
      <ProspectPageFooter
        activeTab={activeTab}
        visitedTabs={visitedTabs}
        resetForm={resetForm}
        setActiveTab={setActiveTab}
        setVisitedTabs={setVisitedTabs}
      />
      {/* Show the prospect designer modal if the org id or prospect type is not set,
      meaning the user has just started creating a prospect. */}
      {(!orgId || !prospectType) && <ProspectDesignerModal />}
    </div>
  );
};

export default ProspectPage;
