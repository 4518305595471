import { StatusColor } from '../../../types';
import { capitalizeFirstLetter, conditionalObject } from '../../../utils';
import { RadioColor } from '../Radio';
import { YesNoAnswer } from './RadioGroup.types';

/** Utility function to get yes/no options with or without colors. */
export const getYesNoOptions = (includeColors: boolean = false) => 
    Object.values(YesNoAnswer).map((type) => ({
    label: capitalizeFirstLetter(type),
    value: type,
    ...conditionalObject(includeColors, {
      color: type === YesNoAnswer.YES ? StatusColor.SUCCESS : StatusColor.ERROR as RadioColor,
    }),
  }));
