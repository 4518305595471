import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Spinner } from '../../components';
import { AppRoutes } from '../../constants';
import { useAppSelector, useFeatureFlag } from '../../hooks';
import { AccessDeniedPage, NotFoundPage } from '../../pages';
import { ComponentSize, LD_FeatureFlags } from '../../types';

interface ProtectedRouteProps {
  children: React.JSX.Element;
}

/**
 * A higher-order component that ensures a route can only be accessed by authenticated users.
 * It leverages both local Redux state and Auth0 state to manage authentication flow.
 * - Initiates a login redirect if unauthenticated.
 * - Displays a spinner only during initial route access when user authentication state is being confirmed.
 * - Renders the specified component post-authentication.
 * - Displays an "Access Denied" page if there's an authentication error.
 */
const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const location = useLocation();

  const { error, isLoading: auth0IsLoading, loginWithRedirect } = useAuth0();
  const {
    isAuthenticated: reduxIsAuthenticated,
    isLoading: reduxIsLoading,
    isOnboarded,
  } = useAppSelector((state) => state.auth);

  const quizFF = useFeatureFlag(LD_FeatureFlags.RELEASE_QUIZ);
  const selfServeFF = useFeatureFlag(LD_FeatureFlags.RELEASE_SELF_SERVE);
  const areFFsLoaded = quizFF !== undefined && selfServeFF !== undefined;

  useEffect(() => {
    // If already authenticated or loading, no need to proceed with redirect logic.
    if (reduxIsAuthenticated || reduxIsLoading) return;

    if (error) {
      console.error(error);
    } else if (!auth0IsLoading) {
      const params = new URLSearchParams(location.search);
      const invitation = params.get('invitation') || undefined;
      // Redirect only when the user is not authenticated and no error is present.
      if (invitation) {
        // if invitation is present, go to invitation flow
        loginWithRedirect({ authorizationParams: { invitation } });
      } else {
        loginWithRedirect();
      }
    }
  }, [reduxIsAuthenticated, error, loginWithRedirect, auth0IsLoading, reduxIsLoading]);

  if (error) {
    return <AccessDeniedPage />;
  }

  // We use reduxIsAuthenticated rather than Auth0's isAuthenticated to avoid showing a spinner
  // during internal application loading processes, which offers a smoother user experience
  // and reduces visual clutter.
  if (reduxIsAuthenticated && areFFsLoaded) {
    // Check if the current path is the finish setup page.
    const isFinishSetupPage = location.pathname.includes(AppRoutes.FINISH_SETUP);

    // If the user is not onboarded and is not on the finish setup page,
    // redirect to finish setup.
    if (!isOnboarded && !isFinishSetupPage) {
      return <Navigate to={AppRoutes.FINISH_SETUP} replace />;
    }

    // If the user is onboarded and is on the finish setup page,
    // redirect to practice.
    if (isOnboarded && isFinishSetupPage) {
      return <Navigate to={AppRoutes.PRACTICE} replace />;
    }

    const isQuizPage = location.pathname.includes(AppRoutes.QUIZ);
    const isProspectPage = location.pathname.includes(AppRoutes.PROSPECT);

    // If the FF is disabled for the current page, show 404.
    if ((isQuizPage && !quizFF) || (isProspectPage && !selfServeFF)) {
      return <NotFoundPage />;
    }

    // Otherwise, render the children components.
    return children;
  }

  // Show spinner while the authentication status is being confirmed initially.
  return (
    <div className="flex h-screen items-center justify-center">
      <Spinner size={ComponentSize.LARGE} />
    </div>
  );
};

export default ProtectedRoute;
