import { Getter, PaginationState, Row } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DataTable,
  DataTableColumnDef,
  MAX_TAGS_AND_NOTES_HEIGHT,
  SortableHeader,
  TagsAndNotesCell,
  Typography,
  TypographySize,
  UserBadge,
} from '../../components';
import { DATE_COLUMN_WIDTH, PAGINATION_PAGE_SIZE } from '../../constants';
import { useAppDispatch, useAppSelector, useGetFiltersFromParams } from '../../hooks';
import { setPracticeProspectsList, startWebCall, updateLastLoginTime } from '../../redux/reducers';
import { useGetPracticeProspectsMutation } from '../../services';
import {
  CustomSortingState,
  DateFormat,
  PracticeFilters,
  PracticeProspect,
  ProspectSortingFilters,
  SortingOrder,
  TextColor,
} from '../../types';
import PracticePageActionsCell from './PracticePageActionsCell';
import PracticePageCallCell from './PracticePageCallCell';
import PracticePageFooter from './PracticePageFooter';

const NOTES_COLUMN_WIDTH = '40%';
const CALL_BUTTON_COLUMN_WIDTH = 98;
const ELLIPSIS_COLUMN_WIDTH = 56;

const PracticePage = () => {
  // State for the row index of the prospect that has actions open.
  const [activeRowIndex, setActiveRowIndex] = useState<number>();
  // State for pagination settings.
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: PAGINATION_PAGE_SIZE,
  });
  // State for sorting settings.
  const [sorting, setSorting] = useState<CustomSortingState>({
    sortBy: ProspectSortingFilters.UPDATED_AT,
    sortOrder: SortingOrder.DESC,
  });

  // Redux
  const dispatch = useAppDispatch();
  const { organization, lastLoginTime } = useAppSelector((state) => state.auth);
  const isUploadModalEnabled = organization?.isUploadModalEnabled;
  const prospects = useAppSelector((state) => state.practice.prospects);

  // Mutations and queries
  const [getPracticeProspects, { data, isLoading }] = useGetPracticeProspectsMutation();
  const totalPages = data?.pagination.totalPages || 0;

  // Custom hooks
  const filters: PracticeFilters = useGetFiltersFromParams();

  // Reset to page 1 when filters change.
  useEffect(() => {
    if (pagination.pageIndex !== 1) {
      setPagination((prev) => ({ ...prev, pageIndex: 1 }));
    }
  }, [filters, setPagination]);

  // Fetch prospects whenever filters, pagination or sorting settings change.
  useEffect(() => {
    getPracticeProspects({
      accountName: filters.prospectAccount,
      createdDate: filters.dateCreated ? [filters.dateCreated] : undefined,
      personaId: filters.prospect,
      search: filters.search,
      tags: filters.tags,
      pagination,
      sorting,
    });
  }, [filters, pagination, sorting]);

  // This effect sets the list of prospects for the Practice Page in state.
  // It allows for optimistic updates to the state instead of refetching from the server.
  useEffect(() => {
    if (isLoading) return;
    dispatch(setPracticeProspectsList(data?.prospects || []));
    return () => {
      dispatch(setPracticeProspectsList([]));
    };
  }, [data?.prospects, isLoading]);

  const isNewSinceLastLogin = useCallback(
    (date: Date): boolean => {
      if (lastLoginTime) {
        // If the last login time is set,
        // check if the date is after the last login time.
        return dayjs(date).isAfter(dayjs(lastLoginTime));
      } else {
        // If the last login time is not set, update it with the current time
        // and return false.
        dispatch(updateLastLoginTime());
        return false;
      }
    },
    [dispatch, lastLoginTime]
  );

  const renderSortableHeader = useCallback(
    (sortingId: ProspectSortingFilters, title: string) => (
      <SortableHeader title={title} sorting={sorting} setSorting={setSorting} sortingId={sortingId} />
    ),
    [sorting, setSorting]
  );

  // Define columns for the data table.
  const columns: DataTableColumnDef<PracticeProspect>[] = useMemo(
    () => [
      {
        accessorKey: 'call',
        header: '',
        width: CALL_BUTTON_COLUMN_WIDTH,
        cell: ({ row }) => <PracticePageCallCell prospect={row.original} />,
      },
      {
        accessorKey: 'prospect',
        header: () => renderSortableHeader(ProspectSortingFilters.FIRST_NAME, 'Prospect'),
        cell: ({ row }) => {
          const { firstName, lastName, jobTitle, accountName } = row.original;
          const title = `${firstName} ${lastName}`;
          const subtitle = `${accountName} | ${jobTitle}`;
          return <UserBadge title={title} subtitle={subtitle} />;
        },
      },
      {
        // Column for any additional notes.
        accessorKey: 'tagsAndNotes',
        header: 'Tags & Notes',
        width: NOTES_COLUMN_WIDTH,
        cell: ({ row }) => {
          const { personaId, tags, notes } = row.original;
          return <TagsAndNotesCell notes={notes} prospectId={personaId} prospectTags={tags} hideEmptyState />;
        },
      },
      {
        // Column for displaying the date created,
        // highlighting if it was within the last two days.
        accessorKey: 'updatedAt',
        header: () => renderSortableHeader(ProspectSortingFilters.UPDATED_AT, 'Last updated'),
        width: DATE_COLUMN_WIDTH,
        cell: ({ row, getValue }: { row: Row<PracticeProspect>; getValue: Getter<Date> }) => {
          const updatedDate = getValue();
          const label = dayjs(updatedDate).format(DateFormat.MONTH_DAY);

          // Check if the prospect was created after the user's last login.
          const createdDate = row.original.createdAt;
          const isNew = isNewSinceLastLogin(createdDate);
          return (
            <div className="flex flex-col gap-1">
              {isNew && (
                <Typography color={TextColor.DESTRUCTIVE} size={TypographySize.CAPTION}>
                  NEW
                </Typography>
              )}
              <Typography size={TypographySize.CAPTION}>{label}</Typography>
            </div>
          );
        },
      },
      {
        accessorKey: 'ellipsis',
        header: '',
        width: ELLIPSIS_COLUMN_WIDTH,
        cell: ({ row }) => (
          <PracticePageActionsCell
            prospect={row.original}
            setIsActive={(open) => setActiveRowIndex(open ? row.index : undefined)}
          />
        ),
      },
    ],
    [isNewSinceLastLogin, renderSortableHeader]
  );

  return (
    <div className="flex flex-col items-center gap-8">
      <DataTable
        activeRowIndices={[activeRowIndex].filter((index): index is number => index !== undefined)}
        columns={columns}
        isLoading={isLoading}
        data={prospects}
        paginationControls={{ pagination, totalPages, setPagination }}
        contentHeight={MAX_TAGS_AND_NOTES_HEIGHT}
        onRowClick={(_, index) => dispatch(startWebCall({ prospect: prospects[index] }))}
      />
      {isUploadModalEnabled && <PracticePageFooter />}
    </div>
  );
};

export default PracticePage;
