import { useEffect } from 'react';
import { ButtonColor, Icon, TextButton } from '../../../../components';
import { MIN_REQUIRED_SCENARIO_ITEMS } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { addObjection, deleteObjection, randomizeObjections, updateObjection } from '../../../../redux/reducers';
import { ProspectPageMode } from '../../../../types';
import TabSection from '../TabSection';
import ScenarioListItem from './ScenarioListItem';

const Objections = () => {
  const dispatch = useAppDispatch();
  const { mode, fields } = useAppSelector((state) => state.prospect);

  const objections = fields.objections.map((o) => o.value);
  const canDelete = objections.length > MIN_REQUIRED_SCENARIO_ITEMS;

  const isViewMode = mode === ProspectPageMode.VIEW;

  // Delete empty objections on unmount.
  useEffect(() => {
    return () => {
      if (objections.length > MIN_REQUIRED_SCENARIO_ITEMS) {
        objections.forEach((objection, index) => {
          if (!objection) {
            dispatch(deleteObjection(index));
          }
        });
      }
    };
  }, []);

  return (
    <TabSection title="Objections" onRandomize={() => dispatch(randomizeObjections())}>
      {objections.map((objection, index) => (
        <ScenarioListItem
          key={index}
          index={index}
          canDelete={canDelete}
          isViewMode={isViewMode}
          value={objection}
          deleteItem={() => dispatch(deleteObjection(index))}
          updateItem={(value) => dispatch(updateObjection({ index, value }))}
        />
      ))}
      {!isViewMode && (
        <TextButton
          text="Add objection"
          startIcon={Icon.PLUS}
          color={ButtonColor.SECONDARY}
          onClick={() => {
            dispatch(addObjection());
          }}
        />
      )}
    </TabSection>
  );
};

export default Objections;
