import clsx from 'clsx';
import { useCallback } from 'react';
import { ComponentSize, TextColor } from '../../../types';
import { Typography, TypographySize } from '../Typography';
import { AVATAR_SIZE_TO_WIDTH, AVATAR_TO_TYPOGRAPHY_SIZE } from './Avatar.constants';
import { AvatarProps } from './Avatar.types';
import { stringToColor } from './Avatar.utils';

const Avatar = ({ imgSrc, label, ring, size = ComponentSize.SMALL, className, disabled }: AvatarProps) => {
  const avatarWidth = typeof size === 'number' ? size : AVATAR_SIZE_TO_WIDTH[size];

  // Get background color based on the text input.
  const bgColor = stringToColor(label);

  // Get typography size based on the size input.
  const getTypographySize = useCallback(() => {
    if (typeof size === 'number') {
      if (size <= 24) return TypographySize.CAPTION;
    } else {
      return AVATAR_TO_TYPOGRAPHY_SIZE[size];
    }
  }, [size]);

  // Get label for the avatar.
  const getLabel = useCallback(() => {
    if (!label) return '';
    // If the label starts with a plus sign, return the label as is.
    if (label?.startsWith('+')) return label;
    // Otherwise, return the first character of the label.
    return label?.charAt(0).toUpperCase();
  }, [label]);

  return (
    <div className={clsx('avatar placeholder rounded-full', ring && 'border-2 border-base-0')}>
      <div className={clsx('rounded-full', bgColor, className)} style={{ width: avatarWidth }}>
        {imgSrc && <img src={imgSrc} />}
        {!imgSrc && (
          <Typography size={getTypographySize()} color={TextColor.WHITE}>
            {getLabel()}
          </Typography>
        )}
      </div>
      {!!disabled && <div className="absolute inset-0 rounded-full bg-gray-800 opacity-50" />}
    </div>
  );
};

export default Avatar;
