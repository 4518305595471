import { DatePickerRange, SelectOption } from '../components';
import { AppRoutes } from '../constants';
import { AnalyticsFilterKeys, AppUser, FilterKeys, PracticeFilterKeys, ReviewFilterKeys } from '../types';
import { getCurrentAppRoute } from './app.utils';
import { formatDisplayedPhoneNumber, snakeCaseToLabel } from './string.utils';

/**
 * Parses a required value into a SelectOption shape (label and value).
 */
export const parseRequiredSelectOption = (value: string): SelectOption => ({ label: snakeCaseToLabel(value), value });

/**
 * Converts an optional array of strings into an array of SelectOption objects.
 * Each string is transformed into a SelectOption with a label (converted from snake case)
 * and a value. If the input is undefined, the function returns undefined.
 */
export const parseOptionalSelectOption = (value?: string[]): SelectOption[] | undefined => {
  if (!value) return undefined;
  return value.map((v) => ({ label: snakeCaseToLabel(v), value: v }));
};

/**
 * Creates user options from user data.
 */
export const getUserOptions = (userData?: { users: AppUser[]; phoneNumbers?: string[] }) => {
  const mappedUsers = userData?.users.map(({ id, name = '' }) => ({ value: id, label: name })) || [];
  const mappedPhoneNumbers = (userData?.phoneNumbers || []).map((phoneNumber) => ({
    value: phoneNumber,
    label: formatDisplayedPhoneNumber(phoneNumber),
  }));
  return mappedUsers.concat(mappedPhoneNumbers);
};

/**
 * Gets the valid filter keys for the current app route.
 */
export const getValidKeys = (): FilterKeys[] => {
  switch (getCurrentAppRoute()) {
    case AppRoutes.REVIEW:
      return Object.values(ReviewFilterKeys);
    case AppRoutes.ANALYTICS:
      return Object.values(AnalyticsFilterKeys);
    case AppRoutes.PRACTICE:
    default:
      return Object.values(PracticeFilterKeys);
  }
};

/**
 * Gets the date range parameter from the start and end dates.
 */
export const getDateRangeParam = (range: DatePickerRange): string => `${range.start.getTime()}-${range.end.getTime()}`;
