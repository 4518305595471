import { useMergeRefs } from '@floating-ui/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { TooltipTriggerProps } from './Tooltip.types';
import { useTooltipContext } from './TooltipContext';

/**
 * TooltipTrigger component that serves as the element which triggers the tooltip.
 * It supports being wrapped around a child element or as a standalone span.
 */
const TooltipTrigger = forwardRef<HTMLElement, React.HTMLProps<HTMLElement> & TooltipTriggerProps>(
  function TooltipTrigger({ children, fullWidth, ...props }, propRef) {
    const context = useTooltipContext();
    const childrenRef = (children as React.HTMLProps<HTMLElement>).ref as React.RefObject<HTMLElement>;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    // Render a span element as the default trigger.
    return (
      <span
        ref={ref}
        data-state={context.open ? 'open' : 'closed'}
        {...context.getReferenceProps(props)}
        className={clsx('flex items-center', fullWidth ? 'w-full' : 'w-fit')}
      >
        {children}
      </span>
    );
  }
);

export default TooltipTrigger;
