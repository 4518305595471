import { useCallback, useMemo } from 'react';
import { TextInput } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setAccountName, setIndustry } from '../../../../redux/reducers';
import ProspectFieldGroup from '../ProspectFieldGroup';

const CompanyFields = () => {
  const dispatch = useAppDispatch();
  const { accountName, industry } = useAppSelector((state) => state.prospect.fields);

  const handleCompanyNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newAccountName = e.target.value;
      dispatch(setAccountName(newAccountName));
    },
    [dispatch]
  );

  const handleIndustryChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newIndustry = e.target.value;
      dispatch(setIndustry(newIndustry));
    },
    [dispatch]
  );

  const fields = useMemo(
    () => [
      {
        label: 'Company name',
        required: true,
        content: <TextInput value={accountName.value} onChange={handleCompanyNameChange} />,
      },
      {
        label: 'Industry',
        required: true,
        content: <TextInput value={industry.value} onChange={handleIndustryChange} />,
      },
    ],
    [accountName, industry, handleCompanyNameChange, handleIndustryChange]
  );

  return <ProspectFieldGroup fields={fields} />;
};

export default CompanyFields;
