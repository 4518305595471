import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tabs, TabsType } from '../../components';
import { ActivityTab } from './ActivityTab';
import { useFeatureFlag, useUpdateFilterParams } from '../../hooks';
import { AnalyticsTab, AnalyticsFilterKeys, LD_FeatureFlags } from '../../types';
import { ProgressReportTab } from './ProgressReportTab';
import { ANALYTICS_ACTIVE_TAB_PARAM } from '../../constants';
import { conditionalArray } from '../../utils';

const DEFAULT_ACTIVE_TAB = AnalyticsTab.ACTIVITY;

const AnalyticsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const updateFilterParams = useUpdateFilterParams();

  const currentTabFromParams = searchParams.get(ANALYTICS_ACTIVE_TAB_PARAM);
  const activeTab = currentTabFromParams || DEFAULT_ACTIVE_TAB;

  const analyticsV2FF = useFeatureFlag(LD_FeatureFlags.RELEASE_ANALYTICS_V2);

  const tabs = useMemo(
    () => [
      {
        id: AnalyticsTab.ACTIVITY,
        title: 'Activity',
        content: <ActivityTab />,
      },
      ...conditionalArray(analyticsV2FF, {
        id: AnalyticsTab.PROGRESS_REPORT,
        title: 'Progress report',
        content: <ProgressReportTab />,
      }),
    ],
    [analyticsV2FF]
  );

  const handleTabChange = useCallback(
    (newActiveTab: string) => {
      // Reset all filters except date range when changing tabs
      Object.values(AnalyticsFilterKeys).forEach((key) => {
        if (key !== AnalyticsFilterKeys.DATE_RANGE) {
          updateFilterParams(key, undefined);
        }
      });
      setSearchParams({ [ANALYTICS_ACTIVE_TAB_PARAM]: newActiveTab });
    },
    [updateFilterParams]
  );

  // Set default active tab if not set in URL
  useEffect(() => {
    if (!currentTabFromParams) {
      handleTabChange(DEFAULT_ACTIVE_TAB);
    }
  }, [currentTabFromParams, handleTabChange]);

  return (
    <div className="p4 h-full w-full">
      <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={handleTabChange} type={TabsType.BORDERED} />
    </div>
  );
};

export default AnalyticsPage;
