import { SelectOption } from '../components/shared';
import { Roles } from '../types';

export const GOOGLE_USER_ID_PREFIX = 'google-oauth2';

/**
 * The full claim identifier for app metadata within the JWT.
 * This claim contains user-specific data like disabled and onboarded.
 */
export const JWT_NAMESPACE_APP_METADATA_CLAIM = 'https://api.fullyramped.com/api/app_metadata';

/**
 * The full claim identifier for organization information within the JWT.
 * This claim contains organization-specific data like display name and organization ID.
 */
export const JWT_NAMESPACE_ORG_CLAIM = 'https://api.fullyramped.com/api/organization';

/**
 * The full claim identifier for role information within the JWT.
 * This claim is used to store the roles associated with the user,
 * which dictate access levels and permissions within the application.
 */
export const JWT_NAMESPACE_ROLES_CLAIM = 'https://api.fullyramped.com/api/roles';

/**
 * A mapping of organization roles with their labels.
 */
export const ROLE_OPTIONS: SelectOption[] = [
  { label: 'Super admin', value: Roles.SUPER_ADMIN },
  { label: 'Admin', value: Roles.ADMIN },
  { label: 'Manager', value: Roles.MANAGER },
  { label: 'Representative', value: Roles.SALES_REP },
];

/**
 * The header name for skipping authentication.
 */
export const SKIP_AUTH_HEADER = 'Skip-Auth';
