import { DatePickerRange } from '../components';
import { AverageByPeriod } from './analytics.types';

/**
 * Enum to define the keys used for practice filters in the URL and state.
 */
export enum PracticeFilterKeys {
  DATE_CREATED = 'dateCreated',
  PROSPECT = 'prospect',
  PROSPECT_ACCOUNT = 'prospectAccount',
  SEARCH = 'search',
  TAGS = 'tags',
}

/**
 * Enum to define the keys used for call filters in the URL and state.
 */
export enum ReviewFilterKeys {
  CALL_DATE = 'callDate',
  USER = 'user',
  FLAGGED = 'flagged',
  PROSPECT = 'prospect',
  TAGS = 'tags',
}

export enum AnalyticsFilterKeys {
  DATE_RANGE = 'dateRange',
  USER = 'user',
  PROSPECT = 'prospect',
  TAGS = 'tags',
  AVERAGE_BY = 'averageBy',
}

export type FilterKeys = ReviewFilterKeys | PracticeFilterKeys | AnalyticsFilterKeys;

/**
 * Type definition for the filters used in the practice page.
 */
export type PracticeFilters = {
  [PracticeFilterKeys.DATE_CREATED]?: DatePickerRange;
  [PracticeFilterKeys.PROSPECT]?: string[];
  [PracticeFilterKeys.PROSPECT_ACCOUNT]?: string[];
  [PracticeFilterKeys.SEARCH]?: string;
  [PracticeFilterKeys.TAGS]?: string[];
};

/**
 * Type definition for the filters used in the call page.
 */
export type ReviewFilters = {
  [ReviewFilterKeys.CALL_DATE]?: DatePickerRange;
  [ReviewFilterKeys.USER]?: string[];
  [ReviewFilterKeys.FLAGGED]?: boolean;
  [ReviewFilterKeys.PROSPECT]?: string[];
  [ReviewFilterKeys.TAGS]?: string[];
};

/**
 * Type definition for the filters used in the analytics page.
 */
export type AnalyticsFilters = {
  [AnalyticsFilterKeys.DATE_RANGE]?: DatePickerRange;
  [AnalyticsFilterKeys.USER]?: string[];
  [AnalyticsFilterKeys.PROSPECT]?: string[];
  [AnalyticsFilterKeys.TAGS]?: string[];
  [AnalyticsFilterKeys.AVERAGE_BY]?: AverageByPeriod;
};

export type Filters = PracticeFilters | ReviewFilters | AnalyticsFilters;

export type FilterValues = boolean | string | string[] | DatePickerRange;
