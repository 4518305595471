import { useCallback, useRef, useState, useEffect } from 'react';
import { ComponentSize, FileStatus, FileDataType } from '../../../types';
import { AlertType, Icon, Icons, Typography, TypographySize } from '../../shared';
import clsx from 'clsx';
import { useToast, useUploadFiles } from '../../../hooks';
import FileContent from './FileContent';
import { generateFileId } from '../../../utils';
import { MAX_UPLOAD_FILE_SIZE } from '../../../constants';
import { ShowToastProps } from '../../../context';

interface SubmitFileProps {
  successToastData: ShowToastProps;
  toCreateProspect?: boolean;
}

const SubmitFile = ({ successToastData, toCreateProspect = false }: SubmitFileProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { uploadFiles, files, setFiles, abortFileUpload, abortAllFileUploads } = useUploadFiles({
    successToastData,
    toCreateProspect,
  });
  const { showToast } = useToast();

  // To abort all ongoing uploads when tab or modal is closed
  useEffect(() => {
    return () => {
      abortAllFileUploads();
    };
  }, [abortAllFileUploads]);

  // Handle drag over
  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  // Handle drag leave
  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const isValidFileSize = useCallback(
    (file: File) => {
      if (file.size < MAX_UPLOAD_FILE_SIZE) return true;
      showToast({
        message: `File size exceeds the maximum limit of ${MAX_UPLOAD_FILE_SIZE / 1024 / 1024}MB`,
        type: AlertType.ERROR,
      });
      return false;
    },
    [showToast]
  );

  // Handle processing, validating and submitting new selected or dropped files
  const processNewFiles = useCallback(
    (selectedFiles: File[]) => {
      if (!selectedFiles.length) return;

      // Filter out files that are already in the list and add new files
      const uniqueFiles = selectedFiles
        .filter((file) => !files.some((f) => f.id === generateFileId(file)))
        .map((file) => ({
          file,
          id: generateFileId(file),
          status: isValidFileSize(file) ? FileStatus.UPLOADING : FileStatus.ERROR,
          progress: 0,
        }));

      // If no new files uploaded, show an error toast to give user feedback
      if (!uniqueFiles.length) {
        showToast({
          message: `File${selectedFiles.length - uniqueFiles.length > 1 ? 's' : ''} already uploaded`,
          type: AlertType.ERROR,
        });
        return;
      }

      // Add new files including files with error status because we want to show them with the error status
      setFiles((prev) => [...uniqueFiles, ...prev]);

      // Filter out files with error status due to invalid file size to not submit them
      const validFiles = uniqueFiles.filter((file) => file.status !== FileStatus.ERROR);

      // If there are no valid files, return
      if (!validFiles.length) return;

      // Submit files
      uploadFiles(validFiles);
    },
    [uploadFiles, isValidFileSize, files, showToast]
  );

  // Handle files drop
  const handleFilesDrop = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);

      const droppedFiles = Array.from(e.dataTransfer.files);
      processNewFiles(droppedFiles);
      // Reset data so that if the same file is selected again, it will be processed and show an error
      e.dataTransfer.clearData();
    },
    [processNewFiles]
  );

  // Handle files selection
  const handleFilesSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFiles = e.target.files ? Array.from(e.target.files) : [];
      processNewFiles(selectedFiles);
      // Reset the input value so that if the same file is selected again, it will be processed and show an error
      e.target.value = '';
    },
    [processNewFiles]
  );

  // Handle delete file
  const handleDeleteFile = useCallback(
    (fileData: FileDataType) => {
      // If file is uploading, abort the upload
      if (fileData.status === FileStatus.UPLOADING) {
        abortFileUpload(fileData.id);
      }
      setFiles(files.filter(({ id }) => id !== fileData.id));
    },
    [abortFileUpload, files]
  );

  // Triggers opening file explorer
  const handleClick = () => {
    fileInputRef.current?.click();
  };

  // Handle try upload again
  const handleTryUploadAgain = useCallback(
    (fileData: FileDataType) => {
      // Validate file size
      const isValidFile = isValidFileSize(fileData.file);
      if (!isValidFile) return;

      // Upload file
      uploadFiles([fileData]);
    },
    [isValidFileSize, uploadFiles]
  );

  return (
    <div className="flex flex-col gap-2">
      <div
        className={clsx(
          'flex cursor-pointer flex-col items-center justify-center gap-4 rounded-lg border border-neutral-content p-8',
          isDragging && '!border-primary'
        )}
        onClick={handleClick}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleFilesDrop}
      >
        {/* Hidden file input to trigger file selection */}
        <input ref={fileInputRef} type="file" multiple className="hidden" onChange={handleFilesSelect} />
        <Icons icon={Icon.UPLOAD} size={ComponentSize.LARGE} className={clsx(isDragging && 'stroke-primary')} />
        <Typography size={TypographySize.H5} className={clsx(isDragging && 'text-primary')}>
          <span className="font-bold">Click to upload </span>or drag and drop
        </Typography>
      </div>
      {files?.map((fileData) => (
        <FileContent
          key={fileData.id}
          fileData={fileData}
          handleDeleteFile={handleDeleteFile}
          handleTryUploadAgain={handleTryUploadAgain}
        />
      ))}
    </div>
  );
};

export default SubmitFile;
