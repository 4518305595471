import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonColor, EmptyState, Icon, Spinner } from '../../../components';
import { AppRoutes, DEFAULT_ANALYTICS_DATE_RANGE } from '../../../constants';
import { useGetFiltersFromParams } from '../../../hooks';
import { useGetCallsMutation, useGetCallsActivityAnalyticsMutation } from '../../../services';
import { AnalyticsFilters, ComponentSize } from '../../../types';
import { formatMinutesToDuration } from '../../../utils';
import { ActivityCard } from './ActivityCard';

const ActivityTab = () => {
  const navigate = useNavigate();
  const [getCallsActivityAnalytics, { data: analyticsData, isLoading: isLoadingAnalytics }] =
    useGetCallsActivityAnalyticsMutation();
  const [getCalls, { data: callsData, isLoading: isLoadingCalls }] = useGetCallsMutation();

  const filters: AnalyticsFilters = useGetFiltersFromParams();

  const fetchAnalyticsData = useCallback(() => {
    const dateRange = [filters.dateRange ?? DEFAULT_ANALYTICS_DATE_RANGE];
    getCallsActivityAnalytics({
      dateRange,
      tags: filters.tags,
      callers: filters.user,
      prospectIds: filters.prospect,
    });
  }, [getCallsActivityAnalytics, filters]);

  // Fetch calls data
  // TODO: replace api call with redux once we have them stored there
  useEffect(() => {
    getCalls({});
  }, [getCalls]);

  // Fetch analytics data
  useEffect(() => {
    fetchAnalyticsData();
  }, [fetchAnalyticsData]);

  // Sort data by call count in descending order
  const callsChartData = analyticsData?.callers
    .map((caller) => ({
      label: caller.name,
      value: caller.callCount,
    }))
    .sort((a, b) => b.value - a.value);

  // Backend returns data sorted descendingly by talk time, so no need to sort it.
  const talkTimeChartData = analyticsData?.callers.map((caller) => ({
    label: caller.name,
    value: caller.talkTime,
  }));

  if (isLoadingAnalytics || isLoadingCalls) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner size={ComponentSize.SMALL} />
      </div>
    );
  }

  // If there are calls but no analytics data, show the no calls for the selected filters message
  if (callsData?.calls.length && !analyticsData?.callers.length) {
    return <EmptyState description="No calls found for the selected filters" icon={Icon.BAR_CHART} />;
  }

  // If there are no calls, show the make calls message
  if (!callsData?.calls.length) {
    return (
      <EmptyState
        ctaButtonProps={{
          color: ButtonColor.PRIMARY,
          text: 'Call now',
          onClick: () => navigate(AppRoutes.PRACTICE),
        }}
        description="Make calls to start seeing insights"
        icon={Icon.BAR_CHART}
      />
    );
  }

  // Determine the unit for the total talk time title based on the total talk time value
  const totalTalkTimeTitleUnit = analyticsData?.totalTalkTime && analyticsData?.totalTalkTime > 59 ? 'hrs' : 'mins';

  return (
    <div className="flex flex-col gap-4 p-4">
      <ActivityCard
        title="Practice calls"
        value={analyticsData?.totalCalls || 0}
        chartTitle="Count of practice calls by caller"
        data={callsChartData || []}
      />
      <ActivityCard
        title={`Total talk time (${totalTalkTimeTitleUnit})`}
        value={analyticsData?.totalTalkTime || 0}
        chartTitle="Total talk time by caller"
        data={talkTimeChartData || []}
        valueFormatter={formatMinutesToDuration}
      />
    </div>
  );
};

export default ActivityTab;
