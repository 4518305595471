import { ComponentSize } from '../../../../types';

export const TEXT_AREA_DEFAULT_ROWS = 3;

export const TEXT_AREA_SIZE: Record<ComponentSize, string> = {
  [ComponentSize.X_SMALL]: 'text-xs',
  [ComponentSize.SMALL]: 'text-sm',
  [ComponentSize.MEDIUM]: 'text-base',
  [ComponentSize.LARGE]: 'text-lg',
};
