import clsx from 'clsx';
import React from 'react';
import { Icon, Icons, Typography, TypographySize } from '../../../components';
import { useAppSelector } from '../../../hooks';
import { ProspectPageMode, TextColor } from '../../../types';

type BaseProspectPageFieldProps = {
  content: React.ReactElement<{ disabled?: boolean }>;
  label: string;
  required?: boolean;
  tooltip?: string;
};

export type ProspectPageFieldProps =
  | (BaseProspectPageFieldProps & { fullWidth?: boolean; width?: never })
  | (BaseProspectPageFieldProps & { fullWidth?: never; width?: number });

const ProspectPageField = ({ content, label, required, tooltip, fullWidth, width }: ProspectPageFieldProps) => {
  // In view mode, the field is not editable, and accordingly,
  // the required asterisk is hidden, the randomize button is hidden and the field is disabled.
  const isViewMode = useAppSelector((state) => state.prospect.mode === ProspectPageMode.VIEW);

  return (
    <div className={clsx('flex flex-col gap-2', fullWidth ? 'flex-1' : 'flex-0', width && 'w-fit')}>
      <div className="flex items-center gap-2">
        <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY} className="select-none">
          {label}
          {!isViewMode && required && '*'}
        </Typography>
        {tooltip && <Icons icon={Icon.INFO} color={TextColor.SECONDARY} tooltip={tooltip} />}
      </div>
      <div className="flex h-8 items-center" style={{ width }}>
        {React.cloneElement(content, {
          disabled: content.props.disabled || isViewMode,
        })}
      </div>
    </div>
  );
};

export default ProspectPageField;
