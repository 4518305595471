import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { api } from '../../services';
import {
  analyticsReducer,
  authReducer,
  connectionReducer,
  gongReducer,
  modalReducer,
  practiceReducer,
  prospectReducer,
  reviewReducer,
  scorecardTemplateReducer,
} from '../reducers';
import { PERSIST_CONFIG } from './store.constants';

const rootReducer = combineReducers({
  auth: authReducer,
  connection: connectionReducer,
  gong: gongReducer,
  modal: modalReducer,
  practice: practiceReducer,
  prospect: prospectReducer,
  review: reviewReducer,
  scorecardTemplate: scorecardTemplateReducer,
  analytics: analyticsReducer,
  [api.reducerPath]: api.reducer,
});

const persistedReducer = persistReducer(PERSIST_CONFIG, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(api.middleware),
});

export const persistor = persistStore(store);

// Enable automated refetching of queries on certain actions, like focus or network reconnection.
setupListeners(store.dispatch);
