import clsx from 'clsx';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { ROLE_OPTIONS, SUPER_ADMIN_EMAIL_DOMAIN } from '../../../../../constants';
import { useAppSelector, useHandleApiResponse, useToast, useUpdateOrgSeats } from '../../../../../hooks';
import { useEditUserRoleMutation } from '../../../../../services';
import { AppUser, ComponentSize, Permissions, Roles } from '../../../../../types';
import { conditionalArray, isHigherRole } from '../../../../../utils';
import { AlertType, Select, UserBadge } from '../../../../shared';

import DisableConfirmModal from './DisableConfirmModal';
import EnableConfirmModal from './EnableConfirmModal';
import RevokeConfirmModal from './RevokeConfirmModal';

const ROLE_EDIT_ERROR_MSG = 'Failed to edit user role';

enum UserActions {
  DISABLE = 'disable',
  ENABLE = 'enable',
  REVOKE_INVITE = 'revoke-invite',
}

interface UserRowProps {
  rowUser: AppUser & { isInvitee?: boolean };
  setUsers: Dispatch<SetStateAction<AppUser[]>>;
}

const UserRow = ({ rowUser, setUsers }: UserRowProps) => {
  // State
  const [isDisableConfirmOpen, setIsDisableConfirmOpen] = useState(false);
  const [isEnableConfirmOpen, setIsEnableConfirmOpen] = useState(false);
  const [isRevokeConfirmOpen, setIsRevokeConfirmOpen] = useState(false);

  // Custom hooks
  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse();
  const updateOrgSeats = useUpdateOrgSeats();

  // Mutations
  const [editUserRole, { isLoading: isEditLoading }] = useEditUserRoleMutation();

  const {
    id: rowUserId,
    disabled: rowUserDisabled = false,
    email: rowUserEmail,
    isInvitee: rowUserIsInvitee = false,
    role: rowUserRole,
  } = rowUser;

  const currUser = useAppSelector((state) => state.auth.user);
  const { id: currUserId, role: currUserRole, permissions: currUserPermissions } = currUser || {};

  // Check if the current user is the same as the row user.
  const isCurrUser = currUserId === rowUserId;

  const getSelectOptions = useCallback(() => {
    // If the current user is the same as the row user,
    // no options are shown.
    if (isCurrUser) return [];

    // If the user is an invitee, show the revoke invitation option
    // if the current user has sufficient permissions.
    if (rowUserIsInvitee) {
      const canRevokeInvite = !!currUserPermissions?.includes(Permissions.INVITE_USER);
      return canRevokeInvite
        ? [{ label: 'Revoke invitation', value: UserActions.REVOKE_INVITE, destructive: true }]
        : [];
    }

    // Check if the current user is a super admin.
    const isSuperAdmin = !!currUserPermissions?.includes(Permissions.ADMIN_ACCESS);

    // If the user is disabled, show the enable user option
    // if the current user is a super admin.
    if (rowUserDisabled) {
      return isSuperAdmin ? [{ label: 'Enable user', value: UserActions.ENABLE }] : [];
    }

    // Check if the current user can edit the user's role.
    // Super admins can edit any role.
    // Admins can edit any role except super admins.
    // Managers can edit any role lower than their own.
    // Sales reps cannot edit any role.
    const canManageSameRole = isSuperAdmin || currUserRole === Roles.ADMIN;
    if (!currUserRole || !isHigherRole(currUserRole, rowUserRole, canManageSameRole)) return [];

    // If the user is not an invitee and is not disabled, show the changeable role options,
    // depending on the current user's role.
    // Only show the super admin option for users with the SUPER_ADMIN_EMAIL_DOMAIN.
    const canBeSuperAdmin = rowUserRole === Roles.SUPER_ADMIN || rowUserEmail?.endsWith(SUPER_ADMIN_EMAIL_DOMAIN);
    const allowedRoleOptions = ROLE_OPTIONS.filter(
      (option) =>
        isHigherRole(currUserRole, option.value as Roles, canManageSameRole) &&
        (option.value !== Roles.SUPER_ADMIN || canBeSuperAdmin)
    );

    // Check if the current user can disable the user
    // and add the disable user option to the list of options.
    const canDisableUser = !!currUserPermissions?.includes(Permissions.DISABLE_USER);
    return [
      ...allowedRoleOptions,
      ...conditionalArray(canDisableUser, {
        label: 'Disable user',
        value: UserActions.DISABLE,
        destructive: true,
        divider: true,
      }),
    ];
  }, [currUserRole, currUserPermissions, rowUserRole, rowUserDisabled, rowUserEmail, rowUserIsInvitee, isCurrUser]);

  // Handles editing a user's role.
  const handleEditUserRole = useCallback(
    async (newRole: Roles) => {
      try {
        const response = await editUserRole({ id: rowUserId, role: newRole });

        const onSuccess = () => {
          // Optimistically update user state.
          setUsers((prev) => prev.map((user) => (user.id === rowUserId ? { ...user, role: newRole } : user)));
          // Update organization seat counts
          updateOrgSeats(rowUserRole, newRole);
        };

        handleApiResponse({ response, errorMsg: ROLE_EDIT_ERROR_MSG, onSuccess });
      } catch (error) {
        console.error(`${ROLE_EDIT_ERROR_MSG}: `, error);
        showToast({ message: ROLE_EDIT_ERROR_MSG, type: AlertType.ERROR });
      }
    },
    [rowUserId, rowUserRole, handleApiResponse, showToast, updateOrgSeats]
  );

  // Handle selection change in role select dropdown.
  const onSelectChange = useCallback(
    (value?: string) => {
      switch (value) {
        case UserActions.DISABLE:
          // On selecting 'Disable user', open the Disable confirm modal.
          setIsDisableConfirmOpen(true);
          break;
        case UserActions.REVOKE_INVITE:
          // On selecting 'Revoke invitation', open the Revoke invitation confirm modal.
          setIsRevokeConfirmOpen(true);
          break;
        case UserActions.ENABLE:
          // On selecting 'Enable user', open the Enable confirm modal.
          setIsEnableConfirmOpen(true);
          break;
        default:
          // Otherwise, handle editing the user's role.
          handleEditUserRole(value as Roles);
      }
    },
    [handleEditUserRole]
  );

  // Get selected role for the dropdown.
  const getSelectedRole = useCallback(() => {
    if (rowUserDisabled) return { label: 'Disabled', value: UserActions.DISABLE };
    if (rowUserRole) return ROLE_OPTIONS.find((option) => option.value === rowUserRole);
    return undefined;
  }, [rowUserDisabled, rowUserRole]);

  return (
    <>
      <div className={clsx('flex justify-between py-4', rowUser.disabled && 'opacity-50')}>
        {/* User details */}
        <UserBadge
          title={`${rowUser.name} ${isCurrUser ? '(You)' : ''}`}
          subtitle={rowUser.name !== rowUser.email ? rowUser.email : undefined}
          size={ComponentSize.MEDIUM}
          imgSrc={rowUser.picture}
          isInvitee={rowUser.isInvitee}
        />
        {/* End actions */}
        <div className="flex w-44 items-center gap-2">
          <Select
            selected={getSelectedRole()}
            options={getSelectOptions()}
            onChange={onSelectChange}
            loading={isEditLoading}
            tooltip={!getSelectOptions().length ? 'You do not have permission to edit this role' : undefined}
            placeholder="Select role"
          />
        </div>
      </div>
      <DisableConfirmModal
        isOpen={isDisableConfirmOpen}
        setIsOpen={setIsDisableConfirmOpen}
        user={rowUser}
        setUsers={setUsers}
      />
      <EnableConfirmModal
        isOpen={isEnableConfirmOpen}
        setIsOpen={setIsEnableConfirmOpen}
        user={rowUser}
        setUsers={setUsers}
      />
      <RevokeConfirmModal isOpen={isRevokeConfirmOpen} setIsOpen={setIsRevokeConfirmOpen} user={rowUser} />
    </>
  );
};

export default UserRow;
