import { useMemo } from 'react';
import { DateFormat, DateUnit } from '../../../types';
import { DAYS_IN_WEEK } from './DatePicker.constants';
import { DatePickerWeekDaysProps } from './DatePicker.types';

const DatePickerWeekDays = ({ currMonth }: DatePickerWeekDaysProps) => {
  const days = useMemo(() => {
    const startDate = currMonth.startOf(DateUnit.WEEK);
    return Array.from({ length: DAYS_IN_WEEK }, (_, dayIndex) =>
      startDate.add(dayIndex, DateUnit.DAY).format(DateFormat.WEEKDAY)
    );
  }, [currMonth]);

  return (
    <div className="flex gap-1">
      {days.map((day) => (
        <div className="flex-1 text-center" key={day}>
          {day}
        </div>
      ))}
    </div>
  );
};

export default DatePickerWeekDays;
