import clsx from 'clsx';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, ButtonColor, Icon, IconButton, Typography, TypographySize, TypographyWeight } from '../../components';
import { AppRoutes } from '../../constants';
import { useHandleApiResponse } from '../../hooks';
import { useDuplicatePromptTemplateMutation } from '../../services';
import { ComponentSize, DateFormat, PromptTemplate, PromptTemplateStatus, TextColor } from '../../types';
import ManagePromptTemplatePurpose from './ManagePromptTemplatePurpose';

const PROMPT_TEMPLATE_AVATAR_SIZE = 16;
const DUPLICATE_PROMPT_TEMPLATE_ERROR_MSG = 'Failed to duplicate prompt template';

interface PromptTemplateRowProps {
  promptTemplate: PromptTemplate;
  scrollToTop: () => void;
}

const PromptTemplateRow = ({ promptTemplate, scrollToTop }: PromptTemplateRowProps) => {
  const navigate = useNavigate();

  const { id, lastEditor, name: promptTemplateName, purpose, status, updatedAt } = promptTemplate;
  const { name: lastEditorName, picture: lastEditorPicture } = lastEditor || {};

  const isArchived = status === PromptTemplateStatus.ARCHIVED;

  const handleApiResponse = useHandleApiResponse();
  const [duplicatePromptTemplate] = useDuplicatePromptTemplateMutation();

  const handleEditPromptTemplate = useCallback(() => {
    navigate(`${AppRoutes.PROMPT_TEMPLATES}/${id}`);
  }, [id, navigate]);

  const handleDuplicatePromptTemplate = useCallback(async () => {
    try {
      const response = await duplicatePromptTemplate(id);
      handleApiResponse({ response, errorMsg: DUPLICATE_PROMPT_TEMPLATE_ERROR_MSG, onSuccess: scrollToTop });
    } catch (error) {
      console.error(DUPLICATE_PROMPT_TEMPLATE_ERROR_MSG, error);
    }
  }, [id, duplicatePromptTemplate, handleApiResponse, scrollToTop]);

  return (
    <div className="flex items-center justify-between gap-2">
      <div className={clsx('flex flex-col gap-1', isArchived && 'opacity-50')}>
        <div className="flex items-center gap-4">
          <Typography size={TypographySize.H5} weight={TypographyWeight.SEMI_BOLD}>
            {promptTemplateName}
          </Typography>
          <ManagePromptTemplatePurpose
            promptTemplateId={id}
            promptTemplatePurpose={purpose}
            scrollToTop={scrollToTop}
          />
        </div>
        <div className="flex items-center gap-2">
          <Avatar size={PROMPT_TEMPLATE_AVATAR_SIZE} label={lastEditorName} imgSrc={lastEditorPicture} />
          <Typography size={TypographySize.CAPTION}>{lastEditorName}</Typography>
          <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY}>
            {dayjs(updatedAt).format(DateFormat.MONTH_DAY_TIME)}
          </Typography>
        </div>
      </div>
      <div className="flex gap-1">
        <IconButton
          icon={Icon.EDIT}
          color={ButtonColor.SECONDARY}
          size={ComponentSize.MEDIUM}
          tooltip={isArchived ? undefined : 'Edit'}
          onClick={handleEditPromptTemplate}
          disabled={isArchived}
        />
        <IconButton
          icon={Icon.DUPLICATE}
          color={ButtonColor.SECONDARY}
          size={ComponentSize.MEDIUM}
          tooltip="Duplicate"
          onClick={handleDuplicatePromptTemplate}
        />
      </div>
    </div>
  );
};

export default PromptTemplateRow;
