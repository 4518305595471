import { useCallback } from 'react';
import { HOTLINE_DEFAULT_PHONE_NUMBER, HOTLINE_DEFAULT_PROSPECT_NAME } from '../../../../../constants';
import { useAppDispatch, useToast } from '../../../../../hooks';
import { startWebCall } from '../../../../../redux/reducers';
import { useGetHotlinePracticeProspectQuery } from '../../../../../services';
import { TextColor } from '../../../../../types';
import {
  AlertType,
  ButtonColor,
  ButtonVariant,
  Icon,
  IconButton,
  TextButton,
  Typography,
  TypographySize,
} from '../../../../shared';

interface ProspectHotlineItemProps {
  onClose: () => void;
}

const ProspectHotlineItem = ({ onClose }: ProspectHotlineItemProps) => {
  // Redux
  const dispatch = useAppDispatch();

  // Custom hooks
  const { showToast } = useToast();

  // Queries
  const { data: hotlineProspect } = useGetHotlinePracticeProspectQuery();
  const { associatedPhoneNumber: hotlinePhoneNumber } = hotlineProspect ?? {};

  // Copies the hotline phone number to the clipboard.
  const handleCopyPhoneNumber = useCallback(
    (phoneNumber: string) => {
      window.navigator.clipboard.writeText(phoneNumber);
      showToast({
        message: 'Phone number copied to clipboard',
        type: AlertType.SUCCESS,
      });
    },
    [showToast]
  );

  // Web calls the hotline prospect.
  // If the hotline prospect is not found, it directly dials the default hotline phone number.
  const handleCallNow = useCallback(() => {
    if (hotlineProspect) {
      onClose();
      dispatch(startWebCall({ prospect: hotlineProspect, hideUpNext: true }));
    } else {
      window.location.href = `tel:${HOTLINE_DEFAULT_PHONE_NUMBER}`;
    }
  }, [hotlineProspect, dispatch, onClose]);

  return (
    <div className="flex items-center justify-between gap-2">
      <Typography size={TypographySize.H5} color={TextColor.SECONDARY}>
        Call {hotlineProspect?.firstName ?? HOTLINE_DEFAULT_PROSPECT_NAME} 24/7 to create a new prospect. She&apos;ll
        ask you questions about the job title and scenario you want to practice!
      </Typography>
      <div className="flex gap-2">
        {/* Only render the copy button if the hotline prospect has a phone number.
         * If the hotline prospect is not found, we use the default hotline phone number.
         */}
        {(!hotlineProspect || !!hotlinePhoneNumber) && (
          <IconButton
            icon={Icon.COPY}
            onClick={() => handleCopyPhoneNumber(hotlinePhoneNumber ?? HOTLINE_DEFAULT_PHONE_NUMBER)}
            variant={ButtonVariant.GHOST}
            color={ButtonColor.SECONDARY}
            tooltip="Copy phone number"
          />
        )}
        <TextButton text="Call now" onClick={handleCallNow} />
      </div>
    </div>
  );
};

export default ProspectHotlineItem;
