import { TextButton, ButtonColor, TypographySize, Icon, Icons, ProgressBar, IconButton, ButtonVariant } from '..';
import { ComponentSize, TextColor, FileStatus, FileDataType } from '../../../types';
import { Typography, TypographyWeight } from '../Typography';
import { formatFileSize } from '../../../utils';
import clsx from 'clsx';

interface FileContentProps {
  fileData: FileDataType;
  handleDeleteFile: (file: FileDataType) => void;
  handleTryUploadAgain: (file: FileDataType) => void;
}

const FileContent = ({ fileData, handleDeleteFile, handleTryUploadAgain }: FileContentProps) => {
  const { file, status, progress } = fileData;

  const isError = status === FileStatus.ERROR;

  // Render file info
  const renderFileInfo = () => (
    <div className="flex items-center gap-2">
      <Typography size={TypographySize.H5} color={isError ? TextColor.DESTRUCTIVE : undefined}>
        {file.name}
      </Typography>
      <Typography color={isError ? TextColor.DESTRUCTIVE : TextColor.SECONDARY}>{formatFileSize(file.size)}</Typography>
    </div>
  );

  // Render trash icon
  const renderTrashIcon = () => (
    <IconButton
      icon={Icon.TRASH}
      size={ComponentSize.MEDIUM}
      variant={ButtonVariant.GHOST}
      color={isError ? ButtonColor.DESTRUCTIVE : ButtonColor.SECONDARY}
      onClick={() => handleDeleteFile(fileData)}
    />
  );

  return (
    <div
      className={clsx(
        'flex rounded-lg border p-8',
        isError ? 'border-error-content bg-error' : 'border-base-300 bg-base-0'
      )}
    >
      {status === FileStatus.SUCCESS && (
        <div className="flex w-full justify-between">
          {renderFileInfo()}
          <Icons icon={Icon.CHECK} size={ComponentSize.LARGE} color={TextColor.SUCCESS} />
        </div>
      )}

      {status === FileStatus.UPLOADING && (
        <div className="flex w-full flex-col gap-4">
          <div className="flex items-center justify-between gap-4">
            {renderFileInfo()}
            {renderTrashIcon()}
          </div>
          <ProgressBar value={progress} />
        </div>
      )}

      {status === FileStatus.ERROR && (
        <div className="flex w-full flex-col gap-4">
          <div className="flex flex-col gap-2">
            <div className="flex w-full justify-between gap-2">
              <div className="flex gap-4">
                <Icons icon={Icon.CIRCLE_X} size={ComponentSize.LARGE} color={TextColor.DESTRUCTIVE} />
                <Typography size={TypographySize.H5} weight={TypographyWeight.SEMI_BOLD} color={TextColor.DESTRUCTIVE}>
                  Upload failed
                </Typography>
              </div>
              {renderTrashIcon()}
            </div>
            {renderFileInfo()}
          </div>
          <TextButton
            text="Try again"
            size={ComponentSize.X_SMALL}
            onClick={() => handleTryUploadAgain(fileData)}
            color={ButtonColor.INVERTED}
          />
        </div>
      )}
    </div>
  );
};

export default FileContent;
