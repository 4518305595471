import { DraggableProvidedDraggableProps, DraggableProvidedDragHandleProps } from '@hello-pangea/dnd';
import { ScorecardQuestionType, QuestionType, TextColor } from '../../../types';
import {
  ButtonColor,
  ButtonVariant,
  Icon,
  IconButton,
  Select,
  TextArea,
  TextInput,
  TextInputType,
  Typography,
} from '../../../components';

const SELECT_INPUT_WIDTH = 150;
const POINTS_INPUT_WIDTH = 100;

const QUESTION_TYPE_OPTIONS = [
  { value: QuestionType.YES_NO, label: 'Yes / No' },
  { value: QuestionType.RANGE, label: 'Range (1-5)' },
  { value: QuestionType.TEXT, label: 'Free response' },
];

interface ScorecardQuestionProps {
  question: ScorecardQuestionType;
  isAIScorecard: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  draggableProps?: DraggableProvidedDraggableProps;
  innerRef?: React.Ref<HTMLDivElement>;
  onUpdate?: (updatedQuestion: ScorecardQuestionType) => void;
  onRemove?: () => void;
}

const ScorecardQuestion = ({
  question,
  isAIScorecard,
  onUpdate,
  onRemove,
  dragHandleProps,
  draggableProps,
  innerRef,
}: ScorecardQuestionProps) => {
  return (
    <div ref={innerRef} {...draggableProps}>
      <div className="flex items-start gap-2 rounded-lg bg-gray-100 p-2">
        <div {...dragHandleProps}>
          <IconButton icon={Icon.GRIP_VERTICAL} color={ButtonColor.SECONDARY} variant={ButtonVariant.GHOST} />
        </div>
        <div className="flex w-full flex-col gap-2">
          <div className="flex justify-between gap-2">
            <Select
              options={QUESTION_TYPE_OPTIONS}
              selected={QUESTION_TYPE_OPTIONS.find((option) => option.value === question.type)}
              onChange={(selected) => onUpdate?.({ ...question, type: selected as QuestionType })}
              placeholder="Question type*"
              width={SELECT_INPUT_WIDTH}
              disabled={isAIScorecard}
            />
            {isAIScorecard && (
              <div className="flex gap-2">
                <Typography color={TextColor.SECONDARY}>Points</Typography>
                <TextInput
                  type={TextInputType.NUMBER}
                  onChange={(e) => onUpdate?.({ ...question, points: Number(e.target.value) })}
                  value={question.points?.toString()}
                  showNumberControls
                  width={POINTS_INPUT_WIDTH}
                />
              </div>
            )}
          </div>
          <TextArea
            placeholder="Question text*"
            onChange={(e) => onUpdate?.({ ...question, text: e.target.value })}
            value={question.text}
            rows={1}
            autoSize
          />
        </div>
        <IconButton
          icon={Icon.TRASH}
          onClick={onRemove}
          color={ButtonColor.DESTRUCTIVE}
          variant={ButtonVariant.GHOST}
        />
      </div>
    </div>
  );
};

export default ScorecardQuestion;
