import * as RadixSlider from '@radix-ui/react-slider';
import clsx from 'clsx';

interface SliderProgressProps {
  max: number;
  min: number;
  value: number;
}

const SliderProgress = ({ max, min, value }: SliderProgressProps) => {
  // We calculate the range of the slider to determine if the progress is less than half.
  // Used to determine the border radius of the progress.
  const range = max - min;
  const isProgressLessThanHalf = value <= range / 2;
  const borderRadiusClassName = isProgressLessThanHalf ? 'rounded-l-full' : 'rounded-full';

  return <RadixSlider.Range className={clsx('absolute h-full bg-gray-900', borderRadiusClassName)} />;
};

export default SliderProgress;
