import { useCallback, useState } from 'react';
import { AlertType, Checkbox, Typography, TypographySize } from '../../../../shared';
import OrgTabSectionTitle from './OrgTabSectionTitle';
import { useUpdateOrganizationSettingsMutation } from '../../../../../services';
import { useToast } from '../../../../../hooks';

interface AccessProps {
  repCanScoreCalls: boolean;
}

const Access = ({ repCanScoreCalls }: AccessProps) => {
  const [checked, setChecked] = useState(repCanScoreCalls);
  const [error, setError] = useState(false);

  // API Mutation
  const [updateOrganizationSettings] = useUpdateOrganizationSettingsMutation();

  // Custom Hooks
  const { showToast } = useToast();

  // Handle error
  const onError = useCallback(() => {
    showToast({ message: 'Failed to update organization settings', type: AlertType.ERROR });
    setError(true);
  }, [showToast]);

  // Handle update allow rep score
  const handleUpdateAllowRepScore = useCallback(
    async (value: boolean) => {
      try {
        const response = await updateOrganizationSettings({
          repCanScoreCalls: value,
        });
        if (response.error) {
          onError();
        }
      } catch (error) {
        console.error(error);
        onError();
      }
    },
    [updateOrganizationSettings, onError]
  );

  // Handle checkbox change
  const handleOnChange = useCallback(
    (newChecked: boolean) => {
      setError(false);
      setChecked(newChecked);
      handleUpdateAllowRepScore(newChecked);
    },
    [handleUpdateAllowRepScore]
  );

  return (
    <div className="flex flex-col gap-4">
      <OrgTabSectionTitle title="Access" iconTooltip="Allow representatives to score their own calls." />
      <div className="flex justify-between">
        <Typography size={TypographySize.H5}>Representatives can score calls</Typography>
        <Checkbox checked={checked} setChecked={handleOnChange} error={error} />
      </div>
    </div>
  );
};

export default Access;
