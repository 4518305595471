import { Select, TextInput, TextInputType } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { randomizeDemographics, setAge, setFirstName, setLastName, setPronouns } from '../../../../redux/reducers';
import { Pronouns } from '../../../../types';
import { snakeCaseToLabel } from '../../../../utils';
import ProspectFieldGroup from '../ProspectFieldGroup';
import TabSection from '../TabSection';

const PRONOUNS_SELECT_WIDTH = 210;
const PRONOUNS_OPTIONS = Object.values(Pronouns).map((value) => ({
  label: snakeCaseToLabel(value, false, ' / '),
  value,
}));

const Demographics = () => {
  const dispatch = useAppDispatch();
  const { firstName, lastName, age, pronouns } = useAppSelector((state) => state.prospect.fields);

  const selectedPronounOption = PRONOUNS_OPTIONS.find((option) => option.value === pronouns?.value);

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFirstName = e.target.value;
    dispatch(setFirstName(newFirstName));
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newLastName = e.target.value;
    dispatch(setLastName(newLastName));
  };

  const handleAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const newAge = newValue ? parseInt(newValue) : undefined;
    dispatch(setAge(newAge));
  };

  const handlePronounsChange = (value?: string) => {
    const newPronouns = value as Pronouns;
    dispatch(setPronouns(newPronouns));
  };

  const fields = [
    {
      label: 'First name',
      required: true,
      content: <TextInput value={firstName.value} onChange={handleFirstNameChange} />,
    },
    {
      label: 'Last name',
      required: true,
      content: <TextInput value={lastName.value} onChange={handleLastNameChange} />,
    },
    {
      label: 'Age',
      content: <TextInput value={age.value?.toString()} onChange={handleAgeChange} type={TextInputType.NUMBER} />,
    },
    {
      label: 'Pronouns',
      width: PRONOUNS_SELECT_WIDTH,
      content: (
        <Select clearable options={PRONOUNS_OPTIONS} selected={selectedPronounOption} onChange={handlePronounsChange} />
      ),
    },
  ];

  return (
    <TabSection title="Demographics" onRandomize={() => dispatch(randomizeDemographics())}>
      <ProspectFieldGroup fields={fields} />
    </TabSection>
  );
};

export default Demographics;
