import { EmptyState, Icon } from '../../components';

interface QuizPageProps {
  children: JSX.Element;
  isEmpty: boolean;
}

const QuizPage = ({ children, isEmpty }: QuizPageProps) => {
  return isEmpty ? <EmptyState description="No quizzes created yet" icon={Icon.QUIZ} /> : children;
};

export default QuizPage;
