import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ANNUAL_REVENUE_RANGES,
  BACKGROUND_PROMPTS,
  CITIES,
  COMPANY_NAMES,
  COMPANY_SIZE_RANGES,
  DEFAULT_HANGUP_PROMPT,
  DEPARTMENTS,
  FIRST_MESSAGES,
  INDUSTRIES,
  JOB_TITLES,
  LAST_NAMES,
  LLM_MAX_TOKENS_SLIDER_LIMITS,
  LLM_TEMPERATURE_SLIDER_LIMITS,
  MIN_REQUIRED_SCENARIO_ITEMS,
  OBJECTIONS,
  PRIORITIES,
  PROSPECT_SLIDER_LIMITS,
  QUESTIONS,
  STT_MODEL,
  VOICE_OPTIONS,
} from '../../constants';
import {
  FundingRaised,
  LLMProviderID,
  PracticeProspectDetails,
  Pronouns,
  ProspectPageMode,
  ProspectRoleType,
  ProspectSuccessResult,
  ProspectType,
  RangeType,
  RemoteType,
  SeverityLevel,
  Tag,
} from '../../types';
import {
  canRandomizeItem,
  getRandomAge,
  getRandomElement,
  getRandomFirstName,
  getRandomNumOfDirectReports,
  getRandomScenarioItems,
  getRandomSliderValue,
  getRandomTenure,
  isValueFilled,
} from '../../utils';

const parseNullToUndefined = <T>(value: T | null): T | undefined => (value === null ? undefined : value);

interface ProspectState {
  mode: ProspectPageMode;
  orgId: string;
  prospectType?: ProspectType;
  personaId?: string;
  personaPrompt: { value?: string; isUserModified?: boolean };
  isPromptEdited: boolean;
  tags: Tag[];
  notes?: string;
  fields: {
    // -- PERSONAL --
    // Demographics
    firstName: { value?: string; isUserModified?: boolean };
    lastName: { value?: string; isUserModified?: boolean };
    age: { value?: number; isUserModified?: boolean };
    pronouns: { value?: Pronouns; isUserModified?: boolean };

    // Personality
    talkativenessLevel: { value: number; isUserModified?: boolean };
    figurativeThinkingLevel: { value: number; isUserModified?: boolean };
    empathyLevel: { value: number; isUserModified?: boolean };
    improvisationLevel: { value: number; isUserModified?: boolean };

    // Voice
    personaVoiceId: { value?: string; isUserModified?: boolean };
    firstMessage: { value?: string; isUserModified?: boolean };

    // -- LEAD --
    jobTitle: { value?: string; isUserModified?: boolean };
    tenure: { value?: number; isUserModified?: boolean };
    roleType: { value?: ProspectRoleType; isUserModified?: boolean };
    department: { value?: string; isUserModified?: boolean };
    numOfDirectReports: { value?: number; isUserModified?: boolean };

    // -- ACCOUNT --
    accountName: { value?: string; isUserModified?: boolean };
    industry: { value?: string; isUserModified?: boolean };
    hqLocation: { value?: string; isUserModified?: boolean };
    remoteType: { value?: RemoteType; isUserModified?: boolean };
    isMultiSite: { value?: boolean; isUserModified?: boolean };
    employeesFrom: { value?: number; isUserModified?: boolean };
    employeesTo?: number; // Upper bound of employee range, shares isUserModified state with employeesFrom.
    annualRevenueFrom: { value?: number; isUserModified?: boolean };
    annualRevenueTo?: number; // Upper bound of revenue range, shares isUserModified state with annualRevenueFrom.
    fundingRaisedType: { value?: FundingRaised; isUserModified?: boolean };

    priorities: { value: string; isUserModified?: boolean }[];
    objections: { value: string; isUserModified?: boolean }[];
    questions: { value: string; isUserModified?: boolean }[];

    // Outcome
    successResult: { value?: ProspectSuccessResult; isUserModified?: boolean };
    successDifficultyLevel: { value: number; isUserModified?: boolean };

    // Scene
    backgroundAudioPrompt: { value?: string; isUserModified?: boolean };
    backgroundAudioName: { value?: string; isUserModified?: boolean };

    // -- CONFIGURATION --
    // Call type
    isHidden: boolean;
    isRestricted: boolean;

    // Manager details
    scorecardTemplateId?: string;
    managerNotes?: string;

    // Conversation tuning
    numOfInitialShortResponses?: number;
    objectionRate?: SeverityLevel;
    questionRate?: SeverityLevel;
    numOfRequiredUncoveredPriorities?: number;

    // Ending the call
    isHangupFeatureEnabled: boolean;
    hangupPrompt?: string;

    // Technical configuration
    ttsModelId?: string;
    sttModel: string;
    sttModelId?: string;
    llmModel?: LLMProviderID;
    llmModelId?: string;
    llmTemperature: number;
    llmMaxTokens: number;
    promptTemplateId?: string;
  };
}

const INITIAL_PROSPECT_STATE: ProspectState = {
  mode: ProspectPageMode.CREATE,
  orgId: '',
  prospectType: ProspectType.REPS,
  personaPrompt: {},
  isPromptEdited: false,
  tags: [],
  fields: {
    // Demographics
    firstName: {},
    lastName: {},
    age: {},
    pronouns: {},

    // Personality
    talkativenessLevel: { value: PROSPECT_SLIDER_LIMITS.default },
    figurativeThinkingLevel: { value: PROSPECT_SLIDER_LIMITS.default },
    empathyLevel: { value: PROSPECT_SLIDER_LIMITS.default },
    improvisationLevel: { value: PROSPECT_SLIDER_LIMITS.default },

    // Voice
    personaVoiceId: {},
    firstMessage: { value: FIRST_MESSAGES[0] },

    jobTitle: {},
    tenure: {},
    roleType: {},
    department: {},
    numOfDirectReports: {},

    accountName: {},
    industry: {},
    hqLocation: {},
    remoteType: {},
    isMultiSite: {},
    employeesFrom: {},
    annualRevenueFrom: {},
    fundingRaisedType: {},

    priorities: Array.from({ length: MIN_REQUIRED_SCENARIO_ITEMS }, () => ({ value: '' })),
    objections: Array.from({ length: MIN_REQUIRED_SCENARIO_ITEMS }, () => ({ value: '' })),
    questions: Array.from({ length: MIN_REQUIRED_SCENARIO_ITEMS }, () => ({ value: '' })),
    successResult: {},
    successDifficultyLevel: { value: PROSPECT_SLIDER_LIMITS.default },
    backgroundAudioPrompt: {},
    backgroundAudioName: {},

    isHidden: false,
    isRestricted: true,
    isHangupFeatureEnabled: true,
    hangupPrompt: DEFAULT_HANGUP_PROMPT,
    sttModel: STT_MODEL,
    llmTemperature: LLM_TEMPERATURE_SLIDER_LIMITS.default,
    llmMaxTokens: LLM_MAX_TOKENS_SLIDER_LIMITS.default,
  },
};

// Create a slice for managing the prospect form state.
const prospectReducer = createSlice({
  name: 'prospect',
  initialState: INITIAL_PROSPECT_STATE,
  reducers: {
    // Resets the prospect form to its initial state.
    clearProspectForm: () => INITIAL_PROSPECT_STATE,
    // Sets the prospect form to the given prospect.
    setProspectForm: (state, action: PayloadAction<PracticeProspectDetails>) => {
      state.mode = ProspectPageMode.VIEW;

      const prospect = action.payload;

      state.orgId = prospect.orgId;
      state.prospectType = prospect.type;
      state.personaId = prospect.personaId;
      state.personaPrompt.value = prospect.personaPrompt;
      state.isPromptEdited = prospect.isPromptEdited;
      state.notes = prospect.notes;
      state.tags = prospect.tags;

      const fields = state.fields;

      // -- PERSONAL --
      // Demographics
      fields.firstName.value = parseNullToUndefined(prospect.firstName);
      fields.lastName.value = parseNullToUndefined(prospect.lastName);
      fields.age.value = parseNullToUndefined(prospect.age);
      fields.pronouns.value = parseNullToUndefined(prospect.pronouns);

      // Personality
      fields.talkativenessLevel.value = prospect.talkativenessLevel;
      fields.figurativeThinkingLevel.value = prospect.figurativeThinkingLevel;
      fields.empathyLevel.value = prospect.empathyLevel;
      fields.improvisationLevel.value = prospect.improvisationLevel;

      // Voice
      fields.personaVoiceId.value = parseNullToUndefined(prospect.personaVoiceId);
      fields.firstMessage.value = parseNullToUndefined(prospect.firstMessage);

      // -- LEAD --
      fields.jobTitle.value = parseNullToUndefined(prospect.jobTitle);
      fields.tenure.value = parseNullToUndefined(prospect.tenure);
      fields.roleType.value = parseNullToUndefined(prospect.roleType);
      fields.department.value = parseNullToUndefined(prospect.department);
      fields.numOfDirectReports.value = parseNullToUndefined(prospect.numOfDirectReports);

      // -- ACCOUNT --
      fields.accountName.value = parseNullToUndefined(prospect.accountName);
      fields.industry.value = parseNullToUndefined(prospect.industry);
      fields.hqLocation.value = parseNullToUndefined(prospect.hqLocation);
      fields.remoteType.value = parseNullToUndefined(prospect.remoteType);
      fields.isMultiSite.value = parseNullToUndefined(prospect.isMultiSite);
      fields.employeesFrom.value = parseNullToUndefined(prospect.employeesFrom);
      fields.employeesTo = parseNullToUndefined(prospect.employeesTo);
      fields.annualRevenueFrom.value = parseNullToUndefined(prospect.annualRevenueFrom);
      fields.annualRevenueTo = parseNullToUndefined(prospect.annualRevenueTo);
      fields.fundingRaisedType.value = parseNullToUndefined(prospect.fundingRaisedType);

      // -- SCENARIO --
      fields.priorities = [
        ...prospect.priorities.map((priority) => ({ value: priority })),
        ...Array(Math.max(0, MIN_REQUIRED_SCENARIO_ITEMS - prospect.priorities.length)).fill({ value: '' }),
      ];
      fields.objections = [
        ...prospect.objections.map((objection) => ({ value: objection })),
        ...Array(Math.max(0, MIN_REQUIRED_SCENARIO_ITEMS - prospect.objections.length)).fill({ value: '' }),
      ];
      fields.questions = [
        ...prospect.questions.map((question) => ({ value: question })),
        ...Array(Math.max(0, MIN_REQUIRED_SCENARIO_ITEMS - prospect.questions.length)).fill({ value: '' }),
      ];

      // Outcome
      fields.successResult.value = parseNullToUndefined(prospect.successResult);
      fields.successDifficultyLevel.value =
        parseNullToUndefined(prospect.successDifficultyLevel) ?? PROSPECT_SLIDER_LIMITS.min;

      // Scene
      fields.backgroundAudioPrompt.value = parseNullToUndefined(prospect.backgroundAudioPrompt);
      fields.backgroundAudioName.value = parseNullToUndefined(prospect.backgroundAudioName);

      // -- CONFIGURATION --
      // Call type
      fields.isHidden = prospect.isHidden;
      fields.isRestricted = prospect.isRestricted;

      // Manager details
      fields.managerNotes = parseNullToUndefined(prospect.managerNotes);
      fields.scorecardTemplateId = parseNullToUndefined(prospect.scorecardTemplateId);

      // Conversation tuning
      fields.numOfInitialShortResponses = parseNullToUndefined(prospect.numOfInitialShortResponses);
      fields.objectionRate = parseNullToUndefined(prospect.objectionRate);
      fields.questionRate = parseNullToUndefined(prospect.questionRate);
      fields.numOfRequiredUncoveredPriorities = parseNullToUndefined(prospect.numOfRequiredUncoveredPriorities);

      // Ending of the call
      fields.isHangupFeatureEnabled = !!parseNullToUndefined(prospect.isHangupFeatureEnabled);
      fields.hangupPrompt = parseNullToUndefined(prospect.hangupPrompt);

      // Technical configuration
      fields.ttsModelId = parseNullToUndefined(prospect.ttsModelId);
      fields.sttModelId = parseNullToUndefined(prospect.sttModelId);
      fields.llmModel = parseNullToUndefined(prospect.llmModel as LLMProviderID);
      fields.llmModelId = parseNullToUndefined(prospect.llmModelId);
      fields.llmTemperature = parseNullToUndefined(prospect.llmTemperature) ?? LLM_TEMPERATURE_SLIDER_LIMITS.min;
      fields.llmMaxTokens = parseNullToUndefined(prospect.llmMaxTokens) ?? LLM_MAX_TOKENS_SLIDER_LIMITS.min;
      fields.promptTemplateId = parseNullToUndefined(prospect.promptTemplateId);
    },
    // -- General -- //
    setMode: (state, action: PayloadAction<ProspectPageMode>) => {
      state.mode = action.payload;
    },
    setOrgId: (state, action: PayloadAction<string>) => {
      state.orgId = action.payload;
    },
    // -- Personal -- //
    setFirstName: (state, action: PayloadAction<string>) => {
      state.fields.firstName.value = action.payload;
      state.fields.firstName.isUserModified = isValueFilled(action.payload);
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.fields.lastName.value = action.payload;
      state.fields.lastName.isUserModified = isValueFilled(action.payload);
    },
    setAge: (state, action: PayloadAction<number | undefined>) => {
      state.fields.age.value = action.payload;
      state.fields.age.isUserModified = isValueFilled(action.payload);
    },
    setPronouns: (state, action: PayloadAction<Pronouns | undefined>) => {
      state.fields.pronouns.value = action.payload;
      state.fields.pronouns.isUserModified = isValueFilled(action.payload);
    },
    setTalkativenessLevel: (state, action: PayloadAction<number>) => {
      state.fields.talkativenessLevel.value = action.payload;
      // We always set this to true because the field cannot be cleared,
      // so the user is always modifying the field.
      state.fields.talkativenessLevel.isUserModified = true;
    },
    setFigurativeThinkingLevel: (state, action: PayloadAction<number>) => {
      state.fields.figurativeThinkingLevel.value = action.payload;
      // We always set this to true because the field cannot be cleared,
      // so the user is always modifying the field.
      state.fields.figurativeThinkingLevel.isUserModified = true;
    },
    setEmpathyLevel: (state, action: PayloadAction<number>) => {
      state.fields.empathyLevel.value = action.payload;
      // We always set this to true because the field cannot be cleared,
      // so the user is always modifying the field.
      state.fields.empathyLevel.isUserModified = true;
    },
    setImprovisationLevel: (state, action: PayloadAction<number>) => {
      state.fields.improvisationLevel.value = action.payload;
      // We always set this to true because the field cannot be cleared,
      // so the user is always modifying the field.
      state.fields.improvisationLevel.isUserModified = true;
    },
    setPersonaVoiceId: (state, action: PayloadAction<string | undefined>) => {
      state.fields.personaVoiceId.value = action.payload;
      state.fields.personaVoiceId.isUserModified = isValueFilled(action.payload);
    },
    setFirstMessage: (state, action: PayloadAction<string>) => {
      state.fields.firstMessage.value = action.payload;
      state.fields.firstMessage.isUserModified = isValueFilled(action.payload);
    },
    // Randomizes demographics.
    // Only randomizes fields that the user hasn't previously modified themselves.
    randomizeDemographics: (state) => {
      const fields = state.fields;

      let newPronouns: Pronouns | undefined;
      if (canRandomizeItem(state.mode, fields.pronouns)) {
        newPronouns = getRandomElement(Object.values(Pronouns));
        state.fields.pronouns.value = newPronouns;
      }

      if (canRandomizeItem(state.mode, fields.firstName)) {
        const currentPronouns = newPronouns ?? state.fields.pronouns?.value;
        const randomFirstName = getRandomFirstName(currentPronouns);
        state.fields.firstName.value = randomFirstName;
      }

      if (canRandomizeItem(state.mode, fields.lastName)) {
        const randomLastName = getRandomElement(LAST_NAMES);
        state.fields.lastName.value = randomLastName;
      }

      if (canRandomizeItem(state.mode, fields.age)) {
        const randomAge = getRandomAge();
        state.fields.age.value = randomAge;
      }
    },
    // Randomizes personality traits.
    // Only randomizes fields that the user hasn't previously modified themselves.
    randomizePersonality: (state) => {
      const fields = state.fields;

      if (canRandomizeItem(state.mode, fields.talkativenessLevel)) {
        const randomValue = getRandomSliderValue();
        state.fields.talkativenessLevel.value = randomValue;
      }

      if (canRandomizeItem(state.mode, fields.figurativeThinkingLevel)) {
        const randomValue = getRandomSliderValue();
        state.fields.figurativeThinkingLevel.value = randomValue;
      }

      if (canRandomizeItem(state.mode, fields.empathyLevel)) {
        const randomValue = getRandomSliderValue();
        state.fields.empathyLevel.value = randomValue;
      }

      if (canRandomizeItem(state.mode, fields.improvisationLevel)) {
        const randomValue = getRandomSliderValue();
        state.fields.improvisationLevel.value = randomValue;
      }
    },
    // Randomizes voice.
    // Only randomizes fields that the user hasn't previously modified themselves.
    randomizeVoice: (state) => {
      if (canRandomizeItem(state.mode, state.fields.personaVoiceId)) {
        const randomVoiceId = getRandomElement(VOICE_OPTIONS).value;
        state.fields.personaVoiceId.value = randomVoiceId;
      }

      if (canRandomizeItem(state.mode, state.fields.firstMessage)) {
        const randomFirstMessage = getRandomElement(FIRST_MESSAGES);
        state.fields.firstMessage.value = randomFirstMessage;
      }
    },
    // -- Lead -- //
    setJobTitle: (state, action: PayloadAction<string>) => {
      state.fields.jobTitle.value = action.payload;
      state.fields.jobTitle.isUserModified = !!action.payload;
    },
    setTenure: (state, action: PayloadAction<number | undefined>) => {
      state.fields.tenure.value = action.payload;
      // We check for undefined because 0 is a valid value.
      state.fields.tenure.isUserModified = action.payload !== undefined;
    },
    setRoleType: (state, action: PayloadAction<ProspectRoleType | undefined>) => {
      state.fields.roleType.value = action.payload;
      state.fields.roleType.isUserModified = !!action.payload;
    },
    setDepartment: (state, action: PayloadAction<string>) => {
      state.fields.department.value = action.payload;
      state.fields.department.isUserModified = !!action.payload;
    },
    setNumOfDirectReports: (state, action: PayloadAction<number | undefined>) => {
      state.fields.numOfDirectReports.value = action.payload;
      // We check for undefined because 0 is a valid value.
      state.fields.numOfDirectReports.isUserModified = action.payload !== undefined;
    },
    randomizeLead: (state) => {
      const fields = state.fields;

      if (canRandomizeItem(state.mode, fields.jobTitle)) {
        const randomJobTitle = getRandomElement(JOB_TITLES);
        state.fields.jobTitle.value = randomJobTitle;
      }

      if (canRandomizeItem(state.mode, fields.tenure)) {
        const randomTenure = getRandomTenure();
        state.fields.tenure.value = randomTenure;
      }

      if (canRandomizeItem(state.mode, fields.roleType)) {
        const randomRoleType = getRandomElement(Object.values(ProspectRoleType));
        state.fields.roleType.value = randomRoleType;
      }

      if (canRandomizeItem(state.mode, fields.department)) {
        const randomDepartment = getRandomElement(DEPARTMENTS);
        state.fields.department.value = randomDepartment;
      }

      if (canRandomizeItem(state.mode, fields.numOfDirectReports)) {
        const randomNumOfDirectReports = getRandomNumOfDirectReports();
        state.fields.numOfDirectReports.value = randomNumOfDirectReports;
      }
    },
    // -- Account -- //
    setAccountName: (state, action: PayloadAction<string>) => {
      state.fields.accountName.value = action.payload;
      state.fields.accountName.isUserModified = !!action.payload;
    },
    setIndustry: (state, action: PayloadAction<string>) => {
      state.fields.industry.value = action.payload;
      state.fields.industry.isUserModified = !!action.payload;
    },
    setHQLocation: (state, action: PayloadAction<string>) => {
      state.fields.hqLocation.value = action.payload;
      state.fields.hqLocation.isUserModified = !!action.payload;
    },
    setRemoteType: (state, action: PayloadAction<RemoteType | undefined>) => {
      state.fields.remoteType.value = action.payload;
      state.fields.remoteType.isUserModified = !!action.payload;
    },
    setIsMultiSite: (state, action: PayloadAction<boolean | undefined>) => {
      state.fields.isMultiSite.value = action.payload;
      // We check for undefined because false is a valid value.
      state.fields.isMultiSite.isUserModified = action.payload !== undefined;
    },
    setNumOfEmployees: (state, action: PayloadAction<RangeType | undefined>) => {
      const [employeesFrom, employeesTo] = action.payload ?? [];
      if (employeesFrom === undefined) {
        // If employeesFrom is undefined, we clear the range.
        state.fields.employeesFrom.value = undefined;
        state.fields.employeesTo = undefined;
        state.fields.employeesFrom.isUserModified = false;
      } else {
        // Otherwise, we set the range.
        state.fields.employeesFrom.value = employeesFrom;
        state.fields.employeesTo = employeesTo;
        state.fields.employeesFrom.isUserModified = true;
      }
    },
    setAnnualRevenue: (state, action: PayloadAction<RangeType | undefined>) => {
      const [revenueFrom, revenueTo] = action.payload ?? [];
      if (revenueFrom === undefined) {
        // If revenueFrom is undefined, we clear the range.
        state.fields.annualRevenueFrom.value = undefined;
        state.fields.annualRevenueTo = undefined;
        state.fields.annualRevenueFrom.isUserModified = false;
      } else {
        // Otherwise, we set the range.
        state.fields.annualRevenueFrom.value = revenueFrom;
        state.fields.annualRevenueTo = revenueTo;
        state.fields.annualRevenueFrom.isUserModified = true;
      }
    },
    setFundingRaisedType: (state, action: PayloadAction<FundingRaised | undefined>) => {
      state.fields.fundingRaisedType.value = action.payload;
      state.fields.fundingRaisedType.isUserModified = !!action.payload;
    },
    randomizeAccount: (state) => {
      const fields = state.fields;

      if (canRandomizeItem(state.mode, fields.accountName)) {
        const randomCompanyName = getRandomElement(COMPANY_NAMES);
        state.fields.accountName.value = randomCompanyName;
      }

      if (canRandomizeItem(state.mode, fields.industry)) {
        const randomIndustry = getRandomElement(INDUSTRIES);
        state.fields.industry.value = randomIndustry;
      }

      if (canRandomizeItem(state.mode, fields.hqLocation)) {
        const randomHQLocation = getRandomElement(CITIES);
        state.fields.hqLocation.value = randomHQLocation;
      }

      if (canRandomizeItem(state.mode, fields.remoteType)) {
        const randomRemoteType = getRandomElement(Object.values(RemoteType));
        state.fields.remoteType.value = randomRemoteType;
      }

      if (canRandomizeItem(state.mode, fields.isMultiSite)) {
        const randomIsMultiSite = getRandomElement([true, false]);
        state.fields.isMultiSite.value = randomIsMultiSite;
      }

      if (canRandomizeItem(state.mode, fields.employeesFrom)) {
        const [randomEmployeesFrom, randomEmployeesTo] = getRandomElement(COMPANY_SIZE_RANGES).value;
        state.fields.employeesFrom.value = randomEmployeesFrom;
        state.fields.employeesTo = randomEmployeesTo;
      }

      if (canRandomizeItem(state.mode, fields.annualRevenueFrom)) {
        const [randomRevenueFrom, randomRevenueTo] = getRandomElement(ANNUAL_REVENUE_RANGES).value;
        state.fields.annualRevenueFrom.value = randomRevenueFrom;
        state.fields.annualRevenueTo = randomRevenueTo;
      }

      if (canRandomizeItem(state.mode, fields.fundingRaisedType)) {
        const randomFundingRaisedType = getRandomElement(Object.values(FundingRaised));
        state.fields.fundingRaisedType.value = randomFundingRaisedType;
      }
    },
    // -- Scenario -- //
    // Adds an empty item.
    addPriority: (state) => {
      state.fields.priorities.push({ value: '' });
    },
    addObjection: (state) => {
      state.fields.objections.push({ value: '' });
    },
    addQuestion: (state) => {
      state.fields.questions.push({ value: '' });
    },
    // Deletes an item at the given index.
    deletePriority: (state, action: PayloadAction<number>) => {
      state.fields.priorities = state.fields.priorities.filter((_, i) => i !== action.payload);
    },
    deleteObjection: (state, action: PayloadAction<number>) => {
      state.fields.objections = state.fields.objections.filter((_, i) => i !== action.payload);
    },
    deleteQuestion: (state, action: PayloadAction<number>) => {
      state.fields.questions = state.fields.questions.filter((_, i) => i !== action.payload);
    },
    // Updates an item at the given index.
    updatePriority: (state, action: PayloadAction<{ index: number; value: string }>) => {
      const { index, value } = action.payload;
      if (state.fields.priorities[index]) {
        state.fields.priorities[index].value = value;
        state.fields.priorities[index].isUserModified = !!value;
      }
    },
    updateObjection: (state, action: PayloadAction<{ index: number; value: string }>) => {
      const { index, value } = action.payload;
      if (state.fields.objections[index]) {
        state.fields.objections[index].value = value;
        state.fields.objections[index].isUserModified = !!value;
      }
    },
    updateQuestion: (state, action: PayloadAction<{ index: number; value: string }>) => {
      const { index, value } = action.payload;
      if (state.fields.questions[index]) {
        state.fields.questions[index].value = value;
        state.fields.questions[index].isUserModified = !!value;
      }
    },
    setSuccessResult: (state, action: PayloadAction<ProspectSuccessResult | undefined>) => {
      state.fields.successResult.value = action.payload;
      state.fields.successResult.isUserModified = !!action.payload;
    },
    setSuccessDifficultyLevel: (state, action: PayloadAction<number>) => {
      state.fields.successDifficultyLevel.value = action.payload;
      // We check for undefined because 0 is a valid value.
      state.fields.successDifficultyLevel.isUserModified = action.payload !== undefined;
    },
    setBackgroundAudioPrompt: (state, action: PayloadAction<string | undefined>) => {
      state.fields.backgroundAudioPrompt.value = action.payload;
      state.fields.backgroundAudioPrompt.isUserModified = !!action.payload;
    },
    randomizePriorities: (state) => {
      state.fields.priorities = getRandomScenarioItems(PRIORITIES, state.fields.priorities);
    },
    randomizeObjections: (state) => {
      state.fields.objections = getRandomScenarioItems(OBJECTIONS, state.fields.objections);
    },
    randomizeQuestions: (state) => {
      state.fields.questions = getRandomScenarioItems(QUESTIONS, state.fields.questions);
    },
    randomizeOutcome: (state) => {
      const fields = state.fields;

      if (canRandomizeItem(state.mode, fields.successResult)) {
        const randomSuccessResult = getRandomElement(Object.values(ProspectSuccessResult));
        state.fields.successResult.value = randomSuccessResult;
      }

      if (canRandomizeItem(state.mode, fields.successDifficultyLevel)) {
        const randomSuccessDifficulty = getRandomSliderValue();
        state.fields.successDifficultyLevel.value = randomSuccessDifficulty;
      }
    },
    randomizeScene: (state) => {
      const { backgroundAudioPrompt } = state.fields;

      if (canRandomizeItem(state.mode, backgroundAudioPrompt)) {
        const randomBackgroundAudioPrompt = getRandomElement(BACKGROUND_PROMPTS);
        state.fields.backgroundAudioPrompt.value = randomBackgroundAudioPrompt;
      }

      // TODO: Randomize audio selection.
    },
    // -- Configuration -- //
    setProspectType: (state, action: PayloadAction<ProspectType>) => {
      state.prospectType = action.payload;
    },
    setIsHidden: (state, action: PayloadAction<boolean>) => {
      state.fields.isHidden = action.payload;
    },
    setIsRestricted: (state, action: PayloadAction<boolean>) => {
      state.fields.isRestricted = action.payload;
    },
    setScorecardTemplateId: (state, action: PayloadAction<string | undefined>) => {
      state.fields.scorecardTemplateId = action.payload;
    },
    setManagerNotes: (state, action: PayloadAction<string>) => {
      state.fields.managerNotes = action.payload;
    },
    setNumOfInitialShortResponses: (state, action: PayloadAction<number | undefined>) => {
      state.fields.numOfInitialShortResponses = action.payload;
    },
    setObjectionRate: (state, action: PayloadAction<SeverityLevel | undefined>) => {
      state.fields.objectionRate = action.payload;
    },
    setQuestionRate: (state, action: PayloadAction<SeverityLevel | undefined>) => {
      state.fields.questionRate = action.payload;
    },
    setNumOfRequiredUncoveredPriorities: (state, action: PayloadAction<number | undefined>) => {
      state.fields.numOfRequiredUncoveredPriorities = action.payload;
    },
    setIsHangupFeatureEnabled: (state, action: PayloadAction<boolean>) => {
      state.fields.isHangupFeatureEnabled = action.payload;
    },
    setHangupPrompt: (state, action: PayloadAction<string | undefined>) => {
      state.fields.hangupPrompt = action.payload;
    },
    setTtsModelId: (state, action: PayloadAction<string>) => {
      state.fields.ttsModelId = action.payload;
    },
    setSttModelId: (state, action: PayloadAction<string>) => {
      state.fields.sttModelId = action.payload;
    },
    setLlmModel: (state, action: PayloadAction<LLMProviderID>) => {
      state.fields.llmModel = action.payload;
    },
    setLlmModelId: (state, action: PayloadAction<string | undefined>) => {
      state.fields.llmModelId = action.payload;
    },
    setLlmTemperature: (state, action: PayloadAction<number>) => {
      state.fields.llmTemperature = action.payload;
    },
    setLlmMaxTokens: (state, action: PayloadAction<number>) => {
      state.fields.llmMaxTokens = action.payload;
    },
    setPromptTemplateId: (state, action: PayloadAction<string>) => {
      state.fields.promptTemplateId = action.payload;
    },
    setPersonaPrompt: (state, action: PayloadAction<{ value?: string; isUserModified?: boolean }>) => {
      state.personaPrompt.value = action.payload.value;
      state.personaPrompt.isUserModified = !!action.payload.isUserModified;
    },
    setIsPromptEdited: (state, action: PayloadAction<boolean>) => {
      state.isPromptEdited = action.payload;
    },
  },
});
export const {
  clearProspectForm,
  setProspectForm,
  setMode,
  setOrgId,
  setFirstName,
  setLastName,
  setAge,
  setPronouns,
  setTalkativenessLevel,
  setFigurativeThinkingLevel,
  setEmpathyLevel,
  setImprovisationLevel,
  setPersonaVoiceId,
  randomizeDemographics,
  randomizePersonality,
  randomizeVoice,
  setJobTitle,
  setTenure,
  setRoleType,
  setDepartment,
  setNumOfDirectReports,
  randomizeLead,
  setAccountName,
  setIndustry,
  setHQLocation,
  setRemoteType,
  setIsMultiSite,
  setNumOfEmployees,
  setAnnualRevenue,
  setFundingRaisedType,
  randomizeAccount,
  addPriority,
  addObjection,
  addQuestion,
  deletePriority,
  deleteObjection,
  deleteQuestion,
  updatePriority,
  updateObjection,
  updateQuestion,
  setSuccessResult,
  setSuccessDifficultyLevel,
  setBackgroundAudioPrompt,
  randomizePriorities,
  randomizeObjections,
  randomizeQuestions,
  randomizeOutcome,
  randomizeScene,
  setProspectType,
  setIsHidden,
  setIsRestricted,
  setScorecardTemplateId,
  setManagerNotes,
  setFirstMessage,
  setNumOfInitialShortResponses,
  setObjectionRate,
  setQuestionRate,
  setNumOfRequiredUncoveredPriorities,
  setIsHangupFeatureEnabled,
  setHangupPrompt,
  setTtsModelId,
  setSttModelId,
  setLlmModel,
  setLlmModelId,
  setLlmTemperature,
  setLlmMaxTokens,
  setPromptTemplateId,
  setPersonaPrompt,
  setIsPromptEdited,
} = prospectReducer.actions;

export default prospectReducer.reducer;
