import { DatePickerRange } from '../components';

export enum AnalyticsTab {
  ACTIVITY = 'ACTIVITY',
  PROGRESS_REPORT = 'PROGRESS_REPORT',
}

export enum AverageByPeriod {
  DAY = 'day',
  WEEK = 'week',
}

export type BaseAnalyticsPayload = {
  dateRange: DatePickerRange[];
  prospectIds?: string[];
  tags?: string[];
};

export type CallsActivityPayload = BaseAnalyticsPayload & {
  callers?: string[];
};

export type BaseProgressReportsPayload = BaseAnalyticsPayload & {
  groupBy: AverageByPeriod;
  userId: string;
};

export type ProgressReportCallScorePayload = BaseProgressReportsPayload & {
  scorecardTemplateId: string;
};

export type CallsActivityData = {
  totalCalls: number;
  totalTalkTime: number;
  callers: {
    id: string;
    name: string;
    callCount: number;
    talkTime: number;
  }[];
};

export type ProgressReportCallScoreData = {
  date: string;
  avg_score: number | null;
}[];

export type ProgressReportTalkTimeData = {
  date: string;
  _avg: {
    wordsPerMinute: number;
  };
}[];
