import clsx from 'clsx';
import React, { forwardRef, MouseEvent, useCallback, useEffect, useState } from 'react';
import { ComponentSize, DropdownPlacement, TextColor } from '../../../types';
import { Dropdown, DropdownContent, DropdownTrigger } from '../Dropdown';
import { Icon, IconComponent, Icons } from '../Icons';
import { Typography, TypographySize } from '../Typography';
import { DropdownItemProps } from './DropdownItem.types';

const DropdownItem = forwardRef<HTMLDivElement, DropdownItemProps>(
  (
    {
      label,
      active,
      destructive,
      disabled,
      endElement,
      hovered,
      icon: iconProp,
      startElement,
      submenu,
      onClick,
      onSubmenuClose,
    },
    ref
  ) => {
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

    const clickable = !!onClick || !!submenu;
    const shouldDisable = disabled || !clickable;

    const handleClick = useCallback(
      (e: MouseEvent<HTMLDivElement> | KeyboardEvent) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        } else if (submenu) {
          e.stopPropagation();
          setIsSubmenuOpen(true);
        }
      },
      [onClick, submenu]
    );

    const getTextColor = useCallback(() => {
      if (shouldDisable) return TextColor.TERTIARY;
      if (destructive) return TextColor.DESTRUCTIVE;
      return TextColor.SECONDARY;
    }, [destructive, shouldDisable]);

    const renderIcon = useCallback(
      (icon: Icon | IconComponent) => {
        const iconSize = ComponentSize.X_SMALL;
        return typeof icon === 'string' ? (
          <Icons icon={icon} color={getTextColor()} size={iconSize} />
        ) : (
          React.cloneElement(icon, {
            color: icon.props.color ?? getTextColor(),
            size: icon.props.size ?? iconSize,
          })
        );
      },
      [getTextColor]
    );

    const renderLabel = useCallback(() => {
      const textSize = TypographySize.CAPTION;
      return typeof label === 'string' ? (
        <Typography color={getTextColor()} size={textSize}>
          {label}
        </Typography>
      ) : (
        React.cloneElement(label, {
          color: label.props.color ?? getTextColor(),
          size: label.props.size ?? textSize,
        })
      );
    }, [getTextColor, label]);

    // Handles triggering the onClick event when the Enter key is pressed and the item is hovered.
    useEffect(() => {
      if (!hovered || !clickable) return;

      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          handleClick(e);
        }
      };

      document.addEventListener('keydown', handleKeyDown);
      return () => document.removeEventListener('keydown', handleKeyDown);
    }, [clickable, hovered, handleClick]);

    const renderItem = () => (
      <div
        className={clsx(
          'flex w-full cursor-pointer justify-between gap-2 rounded-lg p-2',
          (isSubmenuOpen || hovered) && (destructive ? 'bg-error' : 'bg-base-100'), // For controlled hover state.
          destructive ? 'hover:bg-error' : 'hover:bg-base-100', // For uncontrolled hover state.
          shouldDisable && 'pointer-events-none'
        )}
        onClick={handleClick}
        ref={ref}
      >
        <div className="flex items-center gap-2">
          {startElement && startElement}
          {iconProp && renderIcon(iconProp)}
          {renderLabel()}
        </div>
        <div className="flex items-center justify-between gap-2">
          {endElement && endElement}
          {active && renderIcon(Icon.CHECK)}
          {submenu && renderIcon(Icon.CHEVRON_RIGHT)}
        </div>
      </div>
    );

    if (submenu) {
      return (
        <Dropdown
          open={isSubmenuOpen}
          onOpenChange={(open) => {
            setIsSubmenuOpen(open);
            if (!open && onSubmenuClose) onSubmenuClose();
          }}
          placement={DropdownPlacement.RIGHT_START}
        >
          <DropdownTrigger>{renderItem()}</DropdownTrigger>
          <DropdownContent>{submenu}</DropdownContent>
        </Dropdown>
      );
    }

    return renderItem();
  }
);

DropdownItem.displayName = 'DropdownItem';

export default DropdownItem;
