import clsx from 'clsx';
import * as React from 'react';

interface TableProps extends React.HTMLAttributes<HTMLTableElement> {
  className?: string;
}

const Table = React.forwardRef<HTMLTableElement, TableProps>(({ className, ...props }, ref) => (
  <span className={clsx('display-scrollbar-lg w-full overflow-x-auto rounded-md border', className)} {...props}>
    <table ref={ref} className="table" {...props} />
  </span>
));

Table.displayName = 'Table';

export default Table;
