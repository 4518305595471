import { PracticeProspectsFilters } from './prospect.types';

// TODO: Add more saved view pages
export enum Page {
  PRACTICE_PROSPECTS = 'PRACTICE_PROSPECTS',
}

export interface SavedView {
  id: string;
  orgMemberId: string;
  page: Page.PRACTICE_PROSPECTS;
  filters: PracticeProspectsFilters;
  createdAt: Date;
  updatedAt: Date;
}

export interface UpdateSavedViewPayload {
  page: Page.PRACTICE_PROSPECTS;
  filters: PracticeProspectsFilters;
}
