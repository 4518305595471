import {
  ButtonColor,
  ButtonVariant,
  Icon,
  IconButton,
  TextInput,
  Typography,
  TypographySize,
} from '../../../../components';
import { MIN_REQUIRED_SCENARIO_ITEMS } from '../../../../constants';
import { TextColor } from '../../../../types';
interface ScenarioListItemProps {
  index: number;
  canDelete: boolean;
  isViewMode: boolean;
  value: string;
  deleteItem: () => void;
  updateItem: (value: string) => void;
}

const ScenarioListItem = ({ index, canDelete, isViewMode, value, deleteItem, updateItem }: ScenarioListItemProps) => {
  return (
    <div key={index} className="flex items-center gap-2">
      <Typography mono color={TextColor.SECONDARY} size={TypographySize.CAPTION} className="select-none">
        {index + 1}
        {index < MIN_REQUIRED_SCENARIO_ITEMS && !isViewMode ? '*' : '\u00A0'}
      </Typography>
      <TextInput value={value} onChange={(e) => updateItem(e.target.value)} disabled={isViewMode} />
      {!isViewMode && (
        <IconButton
          color={ButtonColor.DESTRUCTIVE}
          disabled={!canDelete}
          icon={Icon.TRASH}
          variant={ButtonVariant.GHOST}
          onClick={deleteItem}
        />
      )}
    </div>
  );
};

export default ScenarioListItem;
