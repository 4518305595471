import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useHandleApiResponse, useToast } from '../../../hooks';
import { updateProspect } from '../../../redux/reducers';
import { useEditPracticeProspectMutation, useGetScorecardTemplatesForSelectQuery } from '../../../services';
import { ComponentSize, PracticeProspect, TextColor } from '../../../types';
import {
  AlertType,
  ButtonColor,
  ButtonGroup,
  ButtonVariant,
  Dialog,
  findOptionByValue,
  Select,
  TextArea,
  TextButton,
  Typography,
  TypographySize,
} from '../../shared';
import { getAIScorecardTemplateOptions } from '../../../utils';

const ERROR_MSG = 'Failed to update prospect';

interface ManagerNotesModalProps {
  isOpen: boolean;
  prospect: PracticeProspect;
  onClose: () => void;
}

const ManagerNotesModal = ({ isOpen, prospect, onClose }: ManagerNotesModalProps) => {
  const dispatch = useAppDispatch();

  const handleApiResponse = useHandleApiResponse();
  const { showToast } = useToast();

  const [editPracticeProspect, { isLoading: isSaving }] = useEditPracticeProspectMutation();

  const [notes, setNotes] = useState(prospect.managerNotes || '');
  const [selectedScorecardTemplate, setSelectedScorecardTemplate] = useState(prospect.scorecardTemplateId);

  const { data: scorecardTemplates = [], isLoading: isLoadingScorecardTemplates } =
    useGetScorecardTemplatesForSelectQuery();

  const scorecardTemplateOptions = getAIScorecardTemplateOptions(scorecardTemplates);

  const selectedScorecardTemplateOption = findOptionByValue(scorecardTemplateOptions, selectedScorecardTemplate);

  const handleSave = useCallback(async () => {
    try {
      const response = await editPracticeProspect({
        id: prospect.personaId,
        managerNotes: notes,
        scorecardTemplateId: selectedScorecardTemplate ?? null,
      });
      handleApiResponse({
        response,
        errorMsg: ERROR_MSG,
        onSuccess: () => {
          dispatch(
            updateProspect({
              personaId: prospect.personaId,
              managerNotes: notes,
              scorecardTemplateId: selectedScorecardTemplate,
            })
          );
          onClose();
        },
      });
    } catch (error) {
      console.error(error);
      showToast({ type: AlertType.ERROR, message: ERROR_MSG });
    }
  }, [prospect.personaId, notes, selectedScorecardTemplate, editPracticeProspect, handleApiResponse, showToast]);

  // Reset the notes and scorecard template when the modal is opened.
  useEffect(() => {
    if (isOpen) {
      setNotes(prospect.managerNotes || '');
      setSelectedScorecardTemplate(prospect.scorecardTemplateId);
    }
  }, [isOpen]);

  return (
    <Dialog isOpen={isOpen} onClose={onClose} title={`${prospect.firstName} ${prospect.lastName}`}>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <Typography>AI scorecard</Typography>
            <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY}>
              Automatically scores this call
            </Typography>
          </div>
          <Select
            loading={isLoadingScorecardTemplates}
            placeholder="Select scorecard"
            options={scorecardTemplateOptions}
            selected={selectedScorecardTemplateOption}
            onChange={(newValue) => setSelectedScorecardTemplate(newValue)}
            clearable
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <Typography>Manager notes</Typography>
            <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY}>
              Only visible to managers and administrators
            </Typography>
          </div>
          <TextArea
            value={notes}
            rows={6}
            onChange={(e) => setNotes(e.target.value)}
            placeholder={`Example:\n\u0020\u0020• Use for Q2 onboarding class\n\u0020\u0020• Tests product knowledge\n\u0020\u0020• Tests competitor objections`}
          />
        </div>
        <ButtonGroup size={ComponentSize.MEDIUM} loading={isSaving}>
          <TextButton
            text="Save changes"
            variant={ButtonVariant.FILLED}
            color={ButtonColor.PRIMARY}
            onClick={handleSave}
          />
          <TextButton text="Cancel" onClick={onClose} />
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export default ManagerNotesModal;
