import { useCallback, useState } from 'react';

import { ScorecardTemplate } from '../../types';
import { ScorecardForm } from './ScorecardForm';
import ScorecardsTable from './ScorecardsTable';
import { ButtonColor, Icon, TextButton, Typography, TypographySize, TypographyWeight } from '../../components';
import { useNavigate } from 'react-router-dom';

const ScorecardTemplatesPage = () => {
  // State to store the scorecard ID being edited
  const [editingScorecardId, setEditingScorecardId] = useState<string | undefined>(undefined);
  const [isScorecardFormOpen, setIsScorecardFormOpen] = useState(false);

  const navigate = useNavigate();

  // Handler on editing a scorecard
  const handleEditScorecard = useCallback((scorecard: ScorecardTemplate) => {
    setEditingScorecardId(scorecard.id);
    setIsScorecardFormOpen(true);
  }, []);

  // Handler to reset the editing scorecard state
  const handleResetEditingScorecard = useCallback(() => {
    setEditingScorecardId(undefined);
    setIsScorecardFormOpen(false);
  }, []);

  // Handler on create new scorecard
  const handleCreateNewScorecard = useCallback(() => {
    setEditingScorecardId(undefined);
    setIsScorecardFormOpen(true);
  }, []);

  // Handler to go back to the previous page
  const handleBack = () => {
    navigate(-1);
  };

  // Render the scorecard form
  if (isScorecardFormOpen) {
    return <ScorecardForm onClose={handleResetEditingScorecard} scorecardId={editingScorecardId} />;
  }

  // Render the scorecards table
  return (
    <div className="flex h-full w-full flex-col">
      <div className="flex gap-4 px-8 py-4">
        <TextButton onClick={handleBack} startIcon={Icon.CHEVRON_LEFT} text="Back" color={ButtonColor.SECONDARY} />
        <Typography size={TypographySize.H3} weight={TypographyWeight.SEMI_BOLD}>
          Scorecard templates
        </Typography>
      </div>
      <ScorecardsTable onEditScorecard={handleEditScorecard} handleCreateNewScorecard={handleCreateNewScorecard} />
    </div>
  );
};

export default ScorecardTemplatesPage;
