import { useMemo } from 'react';
import { DateUnit } from '../../../types';
import { DAYS_IN_WEEK, ROWS_IN_MONTH } from './DatePicker.constants';
import { DatePickerBodyProps } from './DatePicker.types';
import DatePickerCell from './DatePickerCell';

/**
 * DatePickerBody component that renders the cells of the date picker.
 */
const DatePickerBody = ({ currMonth, ...props }: DatePickerBodyProps) => {
  const rows = useMemo(() => {
    // Start of the current month.
    const monthStart = currMonth.startOf(DateUnit.MONTH);
    // Start of the week containing the start of the month
    const startDate = monthStart.startOf(DateUnit.WEEK);
    // Generate rows and columns for the date picker.
    return Array.from({ length: ROWS_IN_MONTH }, (_, rowIndex) =>
      Array.from({ length: DAYS_IN_WEEK }, (_, dayIndex) =>
        startDate.add(rowIndex * DAYS_IN_WEEK + dayIndex, DateUnit.DAY)
      )
    );
  }, [currMonth]);

  return (
    <div className="flex flex-col gap-1">
      {rows.map((row, weekIndex) => (
        <div className="flex gap-1" key={`week-${weekIndex}`}>
          {row.map((date, dayIndex) => (
            <DatePickerCell
              key={date.toString()}
              currMonth={currMonth}
              currDate={date}
              isStartOfWeek={dayIndex === 0}
              isEndOfWeek={dayIndex === DAYS_IN_WEEK - 1}
              {...props}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default DatePickerBody;
