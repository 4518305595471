type BasePagination = {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  itemsPerPage: number;
};

export type PagePagination = BasePagination;

export type CursorPagination = BasePagination & {
  cursor: string;
};

export enum PaginationType {
  PAGE = 'page',
  CURSOR = 'cursor',
}