import { useMemo } from 'react';
import { getYesNoOptions, RadioGroup, TextInput, YesNoAnswer } from '../../../../components';
import { DEFAULT_HANGUP_PROMPT } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setHangupPrompt, setIsHangupFeatureEnabled } from '../../../../redux/reducers';
import ProspectPageField from '../ProspectPageField';
import TabSection from '../TabSection';

const EndingTheCall = () => {
  const dispatch = useAppDispatch();
  const { isHangupFeatureEnabled, hangupPrompt } = useAppSelector((state) => state.prospect.fields);

  const fields = useMemo(
    () => [
      {
        label: 'Can hangup?',
        content: (
          <RadioGroup
            options={getYesNoOptions()}
            value={isHangupFeatureEnabled ? YesNoAnswer.YES : YesNoAnswer.NO}
            onChange={(e) => {
              const newIsEnabled = e.target.value === YesNoAnswer.YES;
              dispatch(setIsHangupFeatureEnabled(newIsEnabled));
              if (newIsEnabled) {
                // On enabling the hangup feature, set the default hangup prompt.
                dispatch(setHangupPrompt(DEFAULT_HANGUP_PROMPT));
              } else {
                // On disabling the hangup feature, reset the hangup prompt.
                dispatch(setHangupPrompt(undefined));
              }
            }}
          />
        ),
      },
      {
        label: 'Hangup prompt',
        required: isHangupFeatureEnabled,
        content: (
          <TextInput
            disabled={!isHangupFeatureEnabled} // Changing the prompt is only allowed if hangup feature is enabled.
            value={hangupPrompt}
            onChange={(e) => dispatch(setHangupPrompt(e.target.value))}
          />
        ),
      },
    ],
    [isHangupFeatureEnabled, hangupPrompt, dispatch]
  );

  return (
    <TabSection title="Ending the call">
      {fields.map((field) => (
        <ProspectPageField key={field.label} {...field} />
      ))}
    </TabSection>
  );
};

export default EndingTheCall;
