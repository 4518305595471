import { useMemo } from 'react';
import {
  FilterOptionsAndSelections,
  useAppSelector,
  useGetFiltersFromParams,
  useUpdateFilterParams,
} from '../../hooks';
import { useGetUsersForSelectQuery } from '../../services';
import { ComponentSize, ReviewFilterKeys, ReviewFilters as ReviewFiltersType, Roles } from '../../types';
import { getUserOptions } from '../../utils';
import { findOptionsByValues, SidebarDateFilter, SidebarSelectFilter, Toggle } from '../shared';

interface ReviewFiltersProps {
  prospectOptionsAndSelections: FilterOptionsAndSelections;
  tagOptionsAndSelections: FilterOptionsAndSelections;
}

const ReviewFilters = ({ prospectOptionsAndSelections, tagOptionsAndSelections }: ReviewFiltersProps) => {
  const { user } = useAppSelector((state) => state.auth);
  const isSalesRep = user?.role === Roles.SALES_REP;

  // Retrieve filters from URL parameters.
  const filters: ReviewFiltersType = useGetFiltersFromParams();

  // Hook to update filter parameters in the URL.
  const updateFilterParams = useUpdateFilterParams();

  // Fetch users and phone numbers for the user options.
  const { data: userData, isLoading: isLoadingUsers } = useGetUsersForSelectQuery({});

  // Memoize user and prospect options to avoid unnecessary recalculations.
  const userOptions = useMemo(() => getUserOptions(userData), [userData]);

  // Find the selected user option based on the current filters.
  const selectedUsers = useMemo(() => findOptionsByValues(userOptions, filters.user), [userOptions, filters.user]);

  return (
    <>
      <Toggle
        label="Flagged calls only"
        checked={filters.flagged}
        onChange={(newValue) => updateFilterParams(ReviewFilterKeys.FLAGGED, newValue)}
        size={ComponentSize.MEDIUM}
      />
      {/* Only show the User   filter if the user is not a Sales Representative */}
      {!isSalesRep && (
        <SidebarSelectFilter
          options={userOptions}
          placeholder="Select user"
          selected={selectedUsers}
          title="User"
          onChange={(newValue) => updateFilterParams(ReviewFilterKeys.USER, newValue)}
          loading={isLoadingUsers}
        />
      )}
      {/* Prospect filter */}
      <SidebarSelectFilter
        placeholder="Select prospect"
        title="Prospect"
        onChange={(newValue) => updateFilterParams(ReviewFilterKeys.PROSPECT, newValue)}
        {...prospectOptionsAndSelections}
      />
      {/* Tags filter */}
      <SidebarSelectFilter
        title="Tags"
        placeholder="Select tag"
        onChange={(newValue) => updateFilterParams(ReviewFilterKeys.TAGS, newValue)}
        {...tagOptionsAndSelections}
      />
      {/* Call Date filter */}
      <SidebarDateFilter
        title="Call date"
        onChange={(newValue) => updateFilterParams(ReviewFilterKeys.CALL_DATE, newValue)}
        selected={filters.callDate}
      />
    </>
  );
};

export default ReviewFilters;
