import { useState } from 'react';
import { SELECT_SEARCH_THRESHOLD } from '../../../../constants';
import { MultiSelect, MultiSelectProps, Select, SelectProps, Typography } from '../../../shared';
import { SidebarSelectFilterProps } from './SidebarSelectFilter.types';

const SidebarSelectFilter = ({
  title,
  clearable = true,
  multiSelect = true,
  ...selectProps
}: SidebarSelectFilterProps) => {
  const [searchValue, setSearchValue] = useState('');

  // Enable search if the number of options is greater than the threshold.
  const enableSearch = selectProps.options.length > SELECT_SEARCH_THRESHOLD;
  const searchProps = enableSearch ? { searchValue, setSearchValue } : undefined;

  const sharedProps = {
    clearable,
    searchProps,
  };

  return (
    <div className="flex flex-col gap-2">
      <Typography>{title}</Typography>
      {multiSelect ? (
        <MultiSelect {...(selectProps as MultiSelectProps)} {...sharedProps} />
      ) : (
        <Select {...(selectProps as SelectProps)} {...sharedProps} />
      )}
    </div>
  );
};

export default SidebarSelectFilter;
