import dayjs from 'dayjs';
import { useCallback } from 'react';
import { DateUnit } from '../../../types';
import { getStartAndEnd } from '../../../utils';
import { DatePickerRange } from '../DatePicker';
import { DropdownItem } from '../DropdownItem';

/** Pre-defined date range options. */
export enum DateOptions {
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  THIS_WEEK = 'This week',
  LAST_WEEK = 'Last week',
  THIS_MONTH = 'This month',
  LAST_MONTH = 'Last month',
}

const DATE_OPTION_TO_DATE_RANGE = {
  [DateOptions.TODAY]: getStartAndEnd(dayjs(), DateUnit.DAY),
  [DateOptions.YESTERDAY]: getStartAndEnd(dayjs().subtract(1, DateUnit.DAY), DateUnit.DAY),
  [DateOptions.THIS_WEEK]: getStartAndEnd(dayjs(), DateUnit.WEEK),
  [DateOptions.LAST_WEEK]: getStartAndEnd(dayjs().subtract(1, DateUnit.WEEK), DateUnit.WEEK),
  [DateOptions.THIS_MONTH]: getStartAndEnd(dayjs(), DateUnit.MONTH),
  [DateOptions.LAST_MONTH]: getStartAndEnd(dayjs().subtract(1, DateUnit.MONTH), DateUnit.MONTH),
};

interface DateOptionDropdownItemProps {
  option: DateOptions;
  onChange: (newValue?: DatePickerRange) => void;
  onClose: () => void;
  selected?: DatePickerRange;
}

const DateOptionDropdownItem = ({ option, onChange, onClose, selected }: DateOptionDropdownItemProps) => {
  const range = DATE_OPTION_TO_DATE_RANGE[option];

  const isActive =
    range &&
    selected &&
    dayjs(range.start).isSame(selected.start, DateUnit.DAY) &&
    dayjs(range.end).isSame(selected.end, DateUnit.DAY);

  const handleOptionClick = useCallback(() => {
    onChange(range);
    onClose();
  }, [range, onChange, onClose]);

  return <DropdownItem label={option} active={isActive} onClick={handleOptionClick} />;
};

export default DateOptionDropdownItem;
