import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setOrgId, setProspectForm, setProspectType } from '../../../redux/reducers';
import { useGetPracticeProspectByIdMutation } from '../../../services';
import { Permissions, ProspectPageTabs, ProspectType } from '../../../types';
import { DesignerModal, DesignerModalCardProps, OrgSelector, Typography, TypographySize } from '../../shared';
import ProspectPurposeSelector from './ProspectPurposeSelector';
import ProspectTemplateSelector from './ProspectTemplateSelector';

enum ProspectDesignerModalOption {
  TEMPLATE = 'TEMPLATE',
  CUSTOM = 'CUSTOM',
}

interface ProspectDesignerModalProps {
  setVisitedTabs: Dispatch<SetStateAction<Set<ProspectPageTabs>>>;
}

const ProspectDesignerModal = ({ setVisitedTabs }: ProspectDesignerModalProps) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { user, organization } = useAppSelector((state) => state.auth);
  const permissions = user?.permissions || [];
  const isSuperAdmin = permissions.includes(Permissions.ADMIN_ACCESS);

  const [templateOrgId, setTemplateOrgId] = useState<string>();
  const [templateProspectId, setTemplateProspectId] = useState<string>();
  const [newProspectPurpose, setNewProspectPurpose] = useState<ProspectType>();
  const [targetOrgId, setTargetOrgId] = useState(isSuperAdmin ? undefined : organization?.id);
  const [selectedDesignerOption, setSelectedDesignerOption] = useState(ProspectDesignerModalOption.TEMPLATE);

  const [getPracticeProspectById, { isLoading: isProspectLoading }] = useGetPracticeProspectByIdMutation();

  // Disable the Next button if the user hasn't selected the required fields.
  const disabled =
    !targetOrgId ||
    (selectedDesignerOption === ProspectDesignerModalOption.CUSTOM && !newProspectPurpose) ||
    (selectedDesignerOption === ProspectDesignerModalOption.TEMPLATE && !templateProspectId);

  const options: DesignerModalCardProps[] = useMemo(
    () => [
      {
        id: ProspectDesignerModalOption.TEMPLATE,
        isSelected: selectedDesignerOption === ProspectDesignerModalOption.TEMPLATE,
        title: 'Based on existing prospect',
        description: 'Which Practice Prospect do you want to duplicate?',
        children: (
          <ProspectTemplateSelector
            isSuperAdmin={isSuperAdmin}
            templateOrgId={templateOrgId}
            setTemplateOrgId={setTemplateOrgId}
            templateProspectId={templateProspectId}
            setTemplateProspectId={setTemplateProspectId}
          />
        ),
        onSelect: () => setSelectedDesignerOption(ProspectDesignerModalOption.TEMPLATE),
      },
      {
        id: ProspectDesignerModalOption.CUSTOM,
        isSelected: selectedDesignerOption === ProspectDesignerModalOption.CUSTOM,
        title: 'Create new prospect',
        description: 'What is the purpose of this prospect?',
        children: (
          <ProspectPurposeSelector
            newProspectPurpose={newProspectPurpose}
            setNewProspectPurpose={setNewProspectPurpose}
          />
        ),
        onSelect: () => setSelectedDesignerOption(ProspectDesignerModalOption.CUSTOM),
      },
    ],
    [templateOrgId, templateProspectId, newProspectPurpose, selectedDesignerOption, isSuperAdmin]
  );

  // Exits designer mode by navigating to the practice page,
  // since the designer modal only opens in the /prospect page.
  const onCancel = useCallback(() => {
    navigate(AppRoutes.PRACTICE);
  }, [navigate]);

  // Sets the org id and prospect type in the redux store.
  // This automatically closes the designer modal since it only opens if orgId or prospectType are not set.
  const handleNext = useCallback(async () => {
    // If the user has not selected an org, return.
    if (!targetOrgId) return;

    if (selectedDesignerOption === ProspectDesignerModalOption.CUSTOM && newProspectPurpose) {
      // If the user has selected a custom prospect type, set the prospect type in the redux store.
      dispatch(setOrgId(targetOrgId));
      dispatch(setProspectType(newProspectPurpose));
    } else if (selectedDesignerOption === ProspectDesignerModalOption.TEMPLATE && templateProspectId) {
      // If the user has selected a template prospect, fetch the prospect data and set the form state.
      const { data: prospect } = await getPracticeProspectById(templateProspectId);
      if (prospect) {
        dispatch(setProspectForm({ prospect: { ...prospect, orgId: targetOrgId }, isTemplate: true }));
        // Mark all tabs as visited except for the "Prompt" tab since we are creating a new prospect, but with preset data.
        setVisitedTabs(new Set(Object.values(ProspectPageTabs).slice(0, -1)));
      }
    }
  }, [
    dispatch,
    targetOrgId,
    newProspectPurpose,
    selectedDesignerOption,
    templateProspectId,
    getPracticeProspectById,
    setVisitedTabs,
  ]);

  return (
    <DesignerModal
      loading={isProspectLoading}
      disabled={disabled}
      isOpen
      options={options}
      title="Prospect designer"
      onCancel={onCancel}
      onNext={handleNext}
      footer="Note: these cannot be changed. You must create a new prospect to change these properties."
    >
      {isSuperAdmin && (
        <div className="flex flex-col gap-4">
          <Typography size={TypographySize.H5}>What organization is this prospect for?</Typography>
          <OrgSelector selectFirstAsDefault selectedOrgId={targetOrgId} onChange={setTargetOrgId} />
        </div>
      )}
    </DesignerModal>
  );
};

export default ProspectDesignerModal;
