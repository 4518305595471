import { AppRoutes } from '../constants';

export const getCurrentAppRoute = () => {
  const pathname = location.pathname;
  // Exclude HOME because it matches all paths.
  const routes = Object.values(AppRoutes).filter((route) => route !== AppRoutes.HOME);
  for (const route of routes) {
    if (pathname.includes(route)) {
      return route;
    }
  }
  // Default to HOME if no match is found.
  return AppRoutes.HOME;
};
