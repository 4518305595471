import { ButtonColor, TextButton, Typography, TypographySize } from '../../components';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { openUploadModal } from '../../redux/reducers';

interface PracticePageProps {
  children: JSX.Element;
}

const PracticePage = ({ children }: PracticePageProps) => {
  const dispatch = useAppDispatch();
  const organization = useAppSelector((state) => state.auth.organization);
  const isUploadModalEnabled = organization?.isUploadModalEnabled;

  const handleEnhanceClick = () => {
    dispatch(openUploadModal());
  };

  return (
    <div className="flex flex-col gap-8">
      {children}
      {isUploadModalEnabled && (
        <div className="flex flex-col items-center gap-4">
          <Typography size={TypographySize.H5}>Want to improve your prospects?</Typography>
          <TextButton text="Enhance" onClick={handleEnhanceClick} color={ButtonColor.SECONDARY} />
        </div>
      )}
    </div>
  );
};

export default PracticePage;
