import { DatePickerRange } from '../components';
import { CursorPagination } from './pagination.types';

// TODO: Restrict fields according to auth method once done on backend
// Gong Auth type in Org
export type GongPublicAuth = {
  expiresAt: Date | null;
  authMethod: GongAuthMethod;
  accessKey: string | null;
  secretKeyLast4: string | null;
  apiBaseUrl: string;
};

export interface GenerateGongOAuthStateResponse {
  data: {
    state: string;
  };
}

export interface GongAPIKeyPayload {
  accessKey: string;
  secretKey: string;
  apiBaseUrl: string;
}

export interface GongUser {
  id: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  title: string;
  phoneNumber: string;
  active: boolean;
}

export interface GongUsersData {
  users: GongUser[];
}

export interface GongUsersResponse {
  data: GongUsersData;
  message: string;
}

export interface GongCallsPayload {
  startTime?: DatePickerRange;
  userIds?: string[];
  cursor?: string;
}

interface GongCallParty {
  id: string;
  name: string;
  speakerId: string;
}

export interface GongCall {
  id: string;
  title: string;
  duration: number;
  callDate: string;
  parties: GongCallParty[];
}

export interface GongCallsData {
  calls: GongCall[];
  pagination: CursorPagination;
}

export interface GongCallsResponse {
  data: GongCallsData;
  message: string;
}

export interface GongCreateProspectPayload {
  gongCallIds: string[];
}

export enum GongAuthMethod {
  OAUTH = 'OAUTH',
  API_KEY = 'API_KEY',
}
