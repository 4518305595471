import { TextColor } from '../../../types';
import { Typography } from '../Typography';
import { ProgressBarProps } from './ProgressBar.types';

const ProgressBar = ({ value, max = 100 }: ProgressBarProps) => {
  return (
    <div className="flex w-full items-center justify-between gap-4">
      <progress className="progress" value={value} max={max} />
      <Typography color={TextColor.SECONDARY}>{value}%</Typography>
    </div>
  );
};
export default ProgressBar;
