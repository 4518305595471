import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes, PROSPECT_TYPE_OPTIONS } from '../../../constants';
import { useAppDispatch } from '../../../hooks';
import { setOrgId, setPromptTemplateId, setProspectType } from '../../../redux/reducers';
import { useGetOrganizationsQuery, useGetPromptTemplatesQuery } from '../../../services';
import { PromptTemplatesSelectMode, ProspectType } from '../../../types';
import { parseOrgToOption } from '../../../utils';
import { DesignerModal, DesignerModalCardProps, Select, Typography, TypographySize } from '../../shared';

enum ProspectDesignerModalOption {
  TEMPLATE = 'TEMPLATE',
  CUSTOM = 'CUSTOM',
}

const ProspectDesignerModal = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [selectedPromptTemplateId, setSelectedPromptTemplateId] = useState<string>();
  const [selectedProspectType, setSelectedProspectType] = useState<ProspectType>();
  const [selectedOrgId, setSelectedOrgId] = useState<string>();
  const [selectedDesignerOption, setSelectedDesignerOption] = useState(ProspectDesignerModalOption.TEMPLATE);

  const { data: orgs = [], isLoading: isOrganizationsLoading } = useGetOrganizationsQuery();
  const { data: promptTemplates = [], isLoading: isPromptTemplatesLoading } = useGetPromptTemplatesQuery({
    selectMode: PromptTemplatesSelectMode.SELECT,
  });

  const orgOptions = useMemo(() => orgs.map(parseOrgToOption), [orgs]);

  // Only keep templates with currently supported prospect purposes.
  const filteredPromptTemplates = useMemo(
    () =>
      promptTemplates.filter((template) => PROSPECT_TYPE_OPTIONS.find((purpose) => template.purpose === purpose.value)),
    [promptTemplates]
  );
  const promptTemplateOptions = useMemo(
    () => filteredPromptTemplates.map(({ name, id }) => ({ label: name, value: id })),
    [filteredPromptTemplates]
  );

  // Disable the Next button if the user hasn't selected the required fields.
  const disabled =
    !selectedOrgId ||
    (selectedDesignerOption === ProspectDesignerModalOption.CUSTOM && !selectedProspectType) ||
    (selectedDesignerOption === ProspectDesignerModalOption.TEMPLATE && !selectedPromptTemplateId);

  const options: DesignerModalCardProps[] = useMemo(
    () => [
      {
        id: ProspectDesignerModalOption.TEMPLATE,
        isSelected: selectedDesignerOption === ProspectDesignerModalOption.TEMPLATE,
        title: 'Based on existing prospect',
        description: 'Which Practice Prospect do you want to duplicate?',
        children: (
          <Select
            loading={isPromptTemplatesLoading}
            placeholder="Select Practice Prospect"
            options={promptTemplateOptions}
            selected={promptTemplateOptions.find(({ value }) => value === selectedPromptTemplateId)}
            onChange={(newValue) => setSelectedPromptTemplateId(newValue as string)}
          />
        ),
        onSelect: () => setSelectedDesignerOption(ProspectDesignerModalOption.TEMPLATE),
      },
      {
        id: ProspectDesignerModalOption.CUSTOM,
        isSelected: selectedDesignerOption === ProspectDesignerModalOption.CUSTOM,
        title: 'Create new prospect',
        description: 'What is the purpose of this prospect?',
        children: (
          <Select
            placeholder="Select purpose"
            options={PROSPECT_TYPE_OPTIONS}
            selected={PROSPECT_TYPE_OPTIONS.find((option) => option.value === selectedProspectType)}
            onChange={(newValue) => setSelectedProspectType(newValue as ProspectType)}
          />
        ),
        onSelect: () => setSelectedDesignerOption(ProspectDesignerModalOption.CUSTOM),
      },
    ],
    [
      isPromptTemplatesLoading,
      promptTemplateOptions,
      selectedPromptTemplateId,
      selectedProspectType,
      selectedDesignerOption,
    ]
  );

  // Exits designer mode by navigating to the practice page,
  // since the designer modal only opens in the /prospect page.
  const onCancel = useCallback(() => {
    navigate(AppRoutes.PRACTICE);
  }, [navigate]);

  // Sets the org id and prospect type in the redux store.
  // This automatically closes the designer modal since it only opens if orgId or prospectType are not set.
  const handleNext = useCallback(() => {
    // If the user has not selected an org, return.
    if (!selectedOrgId) return;

    if (selectedDesignerOption === ProspectDesignerModalOption.CUSTOM && selectedProspectType) {
      // If the user has selected a custom prospect type, set the prospect type in the redux store.
      dispatch(setOrgId(selectedOrgId));
      dispatch(setProspectType(selectedProspectType));
    } else if (selectedDesignerOption === ProspectDesignerModalOption.TEMPLATE && selectedPromptTemplateId) {
      // If the user has selected a template prospect, set the prompt template id and prospect type in the redux store.
      const selectedPromptTemplate = filteredPromptTemplates.find(({ id }) => id === selectedPromptTemplateId);
      if (selectedPromptTemplate?.purpose) {
        dispatch(setOrgId(selectedOrgId));
        dispatch(setPromptTemplateId(selectedPromptTemplate.id));
        dispatch(setProspectType(selectedPromptTemplate.purpose));
      }
    }
  }, [
    dispatch,
    filteredPromptTemplates,
    selectedOrgId,
    selectedProspectType,
    selectedPromptTemplateId,
    selectedDesignerOption,
  ]);

  // Set the first org as the default selected org.
  useEffect(() => {
    if (orgs.length && !selectedOrgId) {
      setSelectedOrgId(orgs[0].id);
    }
  }, [orgs]);

  return (
    <DesignerModal
      disabled={disabled}
      isOpen
      options={options}
      title="Prospect designer"
      onCancel={onCancel}
      onNext={handleNext}
      footer="Note: these cannot be changed. You must create a new prospect to change these properties."
    >
      <div className="flex flex-col gap-4">
        <Typography size={TypographySize.H5}>What organization is this prospect for?</Typography>
        <Select
          options={orgOptions}
          selected={orgOptions.find((option) => option.value === selectedOrgId)}
          onChange={(newValue) => setSelectedOrgId(newValue as string)}
          loading={isOrganizationsLoading}
        />
      </div>
    </DesignerModal>
  );
};

export default ProspectDesignerModal;
