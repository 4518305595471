import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { PaginationType } from '../../../types';
import { convertToPx } from '../../../utils';
import { Skeleton } from '../Skeleton';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../Table';
import { DATA_TABLE_DEFAULT_CONTENT_HEIGHT, DATA_TABLE_Y_PADDING } from './DataTable.constants';
import { DataTableColumnDef, DataTableProps } from './DataTable.types';
import PaginationControls from './PaginationControls';

/** Generic DataTable component designed to handle different data types with configurable columns. */
function DataTable<TData>({
  activeRowIndices,
  columns,
  data,
  tableClassName,
  contentHeight: contentHeightProp,
  isLoading,
  paginationControls,
  onRowClick,
  onRowHover,
  onRowRightClick,
}: DataTableProps<TData>) {
  const [localPagination, setLocalPagination] = useState(paginationControls);

  const contentHeight = contentHeightProp ?? DATA_TABLE_DEFAULT_CONTENT_HEIGHT;
  // + 1 for the bottom border.
  const rowHeight = contentHeight + DATA_TABLE_Y_PADDING * 2 + 1;

  useEffect(() => {
    if (!isLoading && paginationControls) {
      setLocalPagination(paginationControls);
    }
  }, [isLoading, paginationControls]);

  // Initialize the table with configuration for columns and data.
  const table = useReactTable({
    data,
    columns,
    manualPagination: true, // Manual "server-side" pagination
    getCoreRowModel: getCoreRowModel(), // Function to generate a row model from provided data.
    onPaginationChange: localPagination?.setPagination,
    state: {
      pagination: localPagination?.pagination,
    },
  });

  const handleRowClick = (e: React.MouseEvent<Element, MouseEvent>, index: number) => {
    if (onRowClick) {
      onRowClick(e, index);
      if (onRowHover) {
        onRowHover(undefined);
      }
    }
  };

  const handleRowHover = (hover: boolean, index: number) => {
    if (onRowHover) {
      onRowHover(hover ? index : undefined);
    }
  };

  const handleRowRightClick = (e: React.MouseEvent<Element, MouseEvent>, index: number) => {
    if (onRowRightClick) {
      onRowRightClick(e, index);
      if (onRowHover) {
        onRowHover(undefined);
      }
    }
  };

  // Renders table rows based on loading state and data availability.
  const renderRows = () => {
    if (isLoading) {
      return Array.from({ length: 3 }).map((_, index) => (
        <TableRow key={index}>
          <TableCell colSpan={columns.length}>
            <Skeleton size={contentHeight} />
          </TableCell>
        </TableRow>
      ));
    }

    const rows = table.getRowModel().rows;

    if (!rows.length) {
      return (
        <TableRow style={{ height: rowHeight }}>
          <TableCell colSpan={columns.length}>No results.</TableCell>
        </TableRow>
      );
    }

    return rows.map((row, index) => (
      <TableRow
        key={row.id}
        data-state={row.getIsSelected() && 'selected'}
        active={activeRowIndices?.includes(index)}
        onClick={(e) => handleRowClick(e, index)}
        onHover={(hover) => handleRowHover(hover, index)}
        onRightClick={(e) => handleRowRightClick(e, index)}
        style={{ height: rowHeight }}
      >
        {row.getVisibleCells().map((cell) => {
          const columnDef: DataTableColumnDef<TData> = cell.column.columnDef;
          return (
            <TableCell key={cell.id} style={{ width: convertToPx(columnDef.width) }}>
              <div>{flexRender(columnDef.cell, cell.getContext())}</div>
            </TableCell>
          );
        })}
      </TableRow>
    ));
  };

  // If both onNextClick and onPrevClick are provided, then it is cursor-based.
  // @tanstack/react-table doesn't have built-in support for cursor-based pagination.
  // We need to handle it manually in case the pagination is cursor-based.
  const isCursorPagination = !!(paginationControls?.onNextClick && paginationControls?.onPrevClick);
  const paginationType = isCursorPagination ? PaginationType.CURSOR : PaginationType.PAGE;

  // Intionally not ternary condition to not evaluate the expression based on the return value of the function itself.
  const handlePrevClick = () => {
    if (paginationControls?.onPrevClick) {
      return paginationControls.onPrevClick();
    } else {
      return table.previousPage();
    }
  };

  // Intionally not ternary condition to not evaluate the expression based on the return value of the function itself.
  const handleNextClick = () => {
    if (paginationControls?.onNextClick) {
      return paginationControls.onNextClick();
    } else {
      return table.nextPage();
    }
  };

  return (
    <div className="flex flex-col items-center gap-2">
      <Table className={tableClassName}>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const columnDef: DataTableColumnDef<TData> = header.column.columnDef;
                return (
                  <TableHead key={header.id} style={{ width: convertToPx(columnDef.width) }}>
                    {header.isPlaceholder ? null : flexRender(columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>{renderRows()}</TableBody>
      </Table>
      {!!localPagination && localPagination.totalPages > 1 && (
        <PaginationControls
          onPrevClick={handlePrevClick}
          onNextClick={handleNextClick}
          pagination={localPagination.pagination}
          setPagination={localPagination.setPagination}
          totalPages={localPagination.totalPages}
          paginationType={paginationType}
        />
      )}
    </div>
  );
}

export default DataTable;
