import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppRoutes, SIDEBAR_WIDTH } from '../../constants';
import { useFilterOptionsAndSelections } from '../../hooks';
import { CreateModal } from '../modals';
import { ButtonVariant, Divider, Icon, TextButton, Typography, TypographySize, TypographyWeight } from '../shared';
import AnalyticsFilters from './AnalyticsFilters';
import PracticeFilters from './PracticeFilters';
import ReviewFilters from './ReviewFilters';
import SidebarFooterActions from './SidebarFooterActions';

const SidebarContent = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const location = useLocation();
  const isPracticePage = location.pathname.includes(AppRoutes.PRACTICE);
  const isReviewPage = location.pathname.includes(AppRoutes.REVIEW);
  const isAnalyticsPage = location.pathname.includes(AppRoutes.ANALYTICS);

  const { accounts, prospects, tags } = useFilterOptionsAndSelections();

  return (
    <div
      className="flex h-full w-full flex-col gap-4 overflow-hidden border-r border-base-100 p-4"
      style={{ width: SIDEBAR_WIDTH }}
    >
      <div className="flex flex-col gap-4">
        <TextButton
          text="Create"
          onClick={() => setIsCreateModalOpen(true)}
          fullWidth
          startIcon={Icon.WAND}
          variant={ButtonVariant.OUTLINE}
        />
        <Divider />
      </div>
      <Typography size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD} className="sticky top-0">
        Filters
      </Typography>
      <div className="flex flex-1 flex-col gap-6 overflow-y-auto pb-6">
        {isPracticePage && (
          <PracticeFilters
            accountOptionsAndSelections={accounts}
            prospectOptionsAndSelections={prospects}
            tagOptionsAndSelections={tags}
          />
        )}
        {isReviewPage && <ReviewFilters prospectOptionsAndSelections={prospects} tagOptionsAndSelections={tags} />}
        {isAnalyticsPage && (
          <AnalyticsFilters prospectOptionsAndSelections={prospects} tagOptionsAndSelections={tags} />
        )}
      </div>
      <SidebarFooterActions />
      <CreateModal isOpen={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)} />
    </div>
  );
};

export default SidebarContent;
