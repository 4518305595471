import { useCallback, useMemo } from 'react';
import { updateProspect } from '../redux/reducers';
import { useApplyTagsMutation } from '../services';
import { Tag } from '../types';
import useAppDispatch from './useAppDispatch';
import useHandleApiResponse from './useHandleApiResponse';

const TAGS_UPDATE_ERROR_MSG = 'Failed to update prospect tags';

const useUpdateProspectTags = ({
  personaId,
  selectedTags,
  tags,
  setSelectedTags,
}: {
  personaId: string;
  selectedTags: Tag[];
  tags: Tag[];
  setSelectedTags: (tags: Tag[]) => void;
}) => {
  const dispatch = useAppDispatch();

  const [applyTags, { isLoading }] = useApplyTagsMutation();

  const handleApiResponse = useHandleApiResponse();

  const tagIdsToApply = useMemo(
    () => selectedTags.filter((newTag) => !tags.find((oldTag) => oldTag.id === newTag.id)).map((tag) => tag.id),
    [selectedTags, tags]
  );

  const tagIdsToRemove = useMemo(
    () => tags.filter((oldTag) => !selectedTags.find((newTag) => newTag.id === oldTag.id)).map((tag) => tag.id),
    [tags, selectedTags]
  );

  const runApplyTags = useCallback(async () => {
    // If there are no tags to apply or remove, return.
    if (!tagIdsToApply.length && !tagIdsToRemove.length) return;
    try {
      const response = await applyTags({
        prospectId: personaId,
        tagsToApply: tagIdsToApply,
        tagsToRemove: tagIdsToRemove,
      });

      handleApiResponse({
        response,
        errorMsg: TAGS_UPDATE_ERROR_MSG,
        onError: () => {
          // Reset the selected tags to the original tags.
          setSelectedTags(tags);
        },
        onSuccess: () => {
          // Update the prospect tags in the redux store.
          dispatch(updateProspect({ personaId, tags: selectedTags }));
        },
      });
    } catch (error) {
      console.error(TAGS_UPDATE_ERROR_MSG, error);
    }
  }, [personaId, tagIdsToApply, tagIdsToRemove, selectedTags, tags, dispatch, applyTags, handleApiResponse]);

  return { runApplyTags, isLoading };
};

export default useUpdateProspectTags;
