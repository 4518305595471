import { useMergeRefs } from '@floating-ui/react';
import clsx from 'clsx';
import { cloneElement, forwardRef, isValidElement } from 'react';
import { useDropdownContext } from './DropdownContext';

interface DropdownTriggerProps {
  children: React.ReactNode;
  // Whether to treat the child element as the trigger.
  asChild?: boolean;
  fullWidth?: boolean;
}

/**
 * DropdownTrigger component that serves as the element which triggers the dropdown.
 */
const DropdownTrigger = forwardRef<HTMLElement, React.HTMLProps<HTMLElement> & DropdownTriggerProps>(
  function DropdownTrigger({ children, asChild = false, fullWidth, ...props }, propRef) {
    const context = useDropdownContext();
    const childrenRef = (children as React.HTMLProps<HTMLElement>).ref as React.RefObject<HTMLElement>;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    // `asChild` allows the user to pass any element as the anchor
    if (asChild && isValidElement(children)) {
      return cloneElement(
        children,
        context.getReferenceProps({
          ref,
          ...props,
          ...children.props,
          'data-state': context.open ? 'open' : 'closed',
        })
      );
    }

    return (
      <span
        ref={ref}
        data-state={context.open ? 'open' : 'closed'} // The user can style the trigger based on the state.
        {...context.getReferenceProps({
          ...props,
          className: clsx(
            props.className,
            'flex items-center',
            fullWidth && 'w-full',
            context.disabled ? 'pointer-events-none' : 'cursor-pointer'
          ),
        })}
      >
        {children}
      </span>
    );
  }
);

export default DropdownTrigger;
