import { ComponentSize, TextColor } from '../../../types';
import { BadgeColor } from './Badge.types';

// Maps badge size to DaisyUI classes.
export const BADGE_SIZE: Record<ComponentSize, string> = {
  [ComponentSize.X_SMALL]: 'badge-xs',
  [ComponentSize.SMALL]: 'badge-sm',
  [ComponentSize.MEDIUM]: 'badge-md',
  [ComponentSize.LARGE]: 'badge-lg',
};

export const BADGE_ICON_SIZE: Record<ComponentSize, ComponentSize> = {
  [ComponentSize.X_SMALL]: ComponentSize.X_SMALL,
  [ComponentSize.SMALL]: ComponentSize.X_SMALL,
  [ComponentSize.MEDIUM]: ComponentSize.SMALL,
  [ComponentSize.LARGE]: ComponentSize.SMALL,
};

export const OUTLINE_BADGE_COLORS = {
  bgColor: 'badge-outline',
  textColor: TextColor.PRIMARY,
  hoverBgColor: 'badge-neutral',
  hoverTextColor: TextColor.WHITE,
};

export const GHOST_BADGE_COLORS = {
  [BadgeColor.DEFAULT]: {
    bgColor: 'badge-ghost',
    textColor: TextColor.PRIMARY,
    hoverBgColor: 'bg-base-300',
    hoverTextColor: TextColor.PRIMARY,
  },
  [BadgeColor.AI]: {
    bgColor: 'badge-ai',
    textColor: TextColor.AI,
    hoverBgColor: 'bg-ai',
    hoverTextColor: TextColor.WHITE,
  },
  [BadgeColor.DESTRUCTIVE]: {
    bgColor: 'badge-error',
    textColor: TextColor.DESTRUCTIVE,
    hoverBgColor: 'bg-error-content',
    hoverTextColor: TextColor.WHITE,
  },
};
