import { YAxis, LineChart as RechartsLineChart, Line, XAxis, ResponsiveContainer, ReferenceLine } from 'recharts';
import { LineChartKeys, LineChartProps } from './LineChart.types';
import { TYPOGRAPHY_SIZE } from '../Typography';

const DOT_RADIUS = 5;
const CHART_HEIGHT = 340;
const CHART_Y_MARGIN = 20;
const CHART_X_MARGIN = 30;

const AXIS_LINE_STROKE = 'stroke-base-200';

const LineChart = ({ data, xAxis, yAxis, benchmark }: LineChartProps) => {
  // Calculate the domain to include both the data range and benchmark
  const maxDataValue = Math.max(...data.map((d) => d[LineChartKeys.AVG]));
  const minDataValue = Math.min(...data.map((d) => d[LineChartKeys.AVG]));
  const yDomain = [Math.min(minDataValue, benchmark?.value ?? 0), Math.max(maxDataValue, benchmark?.value ?? 0)];

  return (
    <div className="w-full">
      <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
        <RechartsLineChart
          data={data}
          margin={{ left: CHART_X_MARGIN, right: CHART_X_MARGIN, bottom: CHART_Y_MARGIN, top: CHART_Y_MARGIN }}
        >
          <XAxis
            axisLine={{ className: AXIS_LINE_STROKE }}
            className={TYPOGRAPHY_SIZE.caption}
            dataKey={LineChartKeys.DATE}
            label={{
              offset: -CHART_Y_MARGIN,
              position: 'insideBottom',
              value: xAxis.label,
              className: TYPOGRAPHY_SIZE.paragraph,
            }}
            tickLine={false}
            tickFormatter={xAxis.formatter}
          />
          <YAxis
            axisLine={{ className: AXIS_LINE_STROKE }}
            className={TYPOGRAPHY_SIZE.caption}
            label={{
              angle: -90,
              position: 'insideLeft',
              value: yAxis.label,
              className: TYPOGRAPHY_SIZE.paragraph,
            }}
            tickLine={false}
            tickFormatter={yAxis.formatter}
            domain={yDomain}
          />
          {benchmark && (
            <ReferenceLine
              y={benchmark.value}
              label={{
                value: benchmark.label,
                position: 'insideTopLeft',
                className: TYPOGRAPHY_SIZE.caption,
                dy: 4,
              }}
              stroke="oklch(var(--nc))"
            />
          )}
          <Line
            dataKey={LineChartKeys.AVG}
            dot={{ className: 'fill-primary stroke-primary', r: DOT_RADIUS }}
            stroke="oklch(var(--p))"
            type="linear"
          />
        </RechartsLineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChart;
