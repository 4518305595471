import { PaginationState } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { AppRoutes, PAGINATION_PAGE_SIZE } from '../../constants';
import { useAppDispatch, useGetFiltersFromParams } from '../../hooks';
import { setProspects } from '../../redux/reducers';
import { useGetPracticeProspectsMutation } from '../../services';
import { CustomSortingState, PracticeFilters, ProspectSortingFilters, ProspectType, SortingOrder } from '../../types';
import { getCurrentAppRoute } from '../../utils';
import PracticePage from './PracticePage';
import ProspectTable from './ProspectTable';
import QuizPage from './QuizPage';

const ActivityPage = () => {
  const dispatch = useAppDispatch();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: PAGINATION_PAGE_SIZE,
  });

  const [sorting, setSorting] = useState<CustomSortingState>({
    sortBy: ProspectSortingFilters.UPDATED_AT,
    sortOrder: SortingOrder.DESC,
  });

  const [getPracticeProspects, { data, isLoading }] = useGetPracticeProspectsMutation();
  const totalPages = data?.pagination.totalPages;

  const filters: PracticeFilters = useGetFiltersFromParams();

  const currRoute = getCurrentAppRoute();
  const isQuiz = currRoute === AppRoutes.QUIZ;

  // Fetch prospects whenever filters change.
  useEffect(() => {
    getPracticeProspects({
      accountName: filters.prospectAccount,
      createdDate: filters.dateCreated ? [filters.dateCreated] : undefined,
      personaId: filters.prospect,
      search: filters.search,
      tags: filters.tags,
      pagination,
      sorting,
      types: [ProspectType.QUIZ],
      excludeTypes: !isQuiz, // Only show Quiz prospects in the Quiz page.
    });
  }, [filters, isQuiz, pagination, sorting]);

  // Reset to page 1 when filters change.
  useEffect(() => {
    if (pagination.pageIndex !== 1) {
      setPagination((prev) => ({ ...prev, pageIndex: 1 }));
    }
  }, [filters]);

  // This effect sets the list of prospects for activity pages.
  // It allows for optimistic updates to the state instead of refetching from the server.
  useEffect(() => {
    if (isLoading) return;
    dispatch(setProspects(data?.prospects || []));
    return () => {
      dispatch(setProspects([]));
    };
  }, [data?.prospects, isLoading, dispatch]);

  const renderTable = () => (
    <ProspectTable
      isLoading={isLoading}
      pagination={pagination}
      sorting={sorting}
      totalPages={totalPages}
      setPagination={setPagination}
      setSorting={setSorting}
    />
  );

  if (isQuiz) {
    const isEmpty = !isLoading && !data?.prospects.length;
    return <QuizPage isEmpty={isEmpty}>{renderTable()}</QuizPage>;
  }

  return <PracticePage>{renderTable()}</PracticePage>;
};

export default ActivityPage;
