import { useCallback } from 'react';
import { AlertType } from '../components';
import { ApiPostError, ApiSuccess } from '../types';
import { isSerializedError } from '../utils';
import useToast from './useToast';

const UNKNOWN_ERROR = {
  message: 'An unknown error occurred',
  status: 'UNKNOWN_ERROR',
};

type ApiPostResponse<T> = { data: ApiSuccess<T>; error?: undefined } | { data?: undefined; error: ApiPostError };

const useHandleApiResponse = (hideToast = false) => {
  const { showToast } = useToast();

  const handleApiResponse = useCallback(
    async <T>({
      response,
      errorMsg,
      successMsg,
      onError,
      onSuccess,
    }: {
      response: ApiPostResponse<T>;
      errorMsg: string;
      successMsg?: string;
      onError?: (error: { message: string; status?: number | string }) => void;
      onSuccess?: (data: T) => Promise<void> | void;
    }) => {
      const error = response.error;
      if (error) {
        const message = isSerializedError(error) ? error.message || UNKNOWN_ERROR.message : error.data.message;
        console.error(`${errorMsg}: `, message);
        if (onError) {
          const status = isSerializedError(error) ? error.code || UNKNOWN_ERROR.status : error.status;
          onError({ message, status });
        }
        if (!hideToast) {
          showToast({ message: errorMsg, type: AlertType.ERROR });
        }
        return;
      }

      if (response.data) {
        const { data, message } = response.data;
        if (onSuccess) await onSuccess(data);
        if (!hideToast) showToast({ message: successMsg ?? message, type: AlertType.SUCCESS });
      }
    },
    [hideToast, showToast]
  );

  return handleApiResponse;
};

export default useHandleApiResponse;
