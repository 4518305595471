import clsx from 'clsx';
import React from 'react';

// Interface for TableRow props extending HTML attributes for a table row.
interface TableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  /** Optional prop to indicate if the row is active. */
  active?: boolean;
  /** Optional click handler. */
  onClick?: (e: React.MouseEvent) => void;
  /** Optional hover handler. */
  onHover?: (hover: boolean) => void;
  // Optional right click handler.
  onRightClick?: (e: React.MouseEvent) => void;
}

const TableRow = React.forwardRef<HTMLTableRowElement, TableRowProps>(
  ({ active, onClick, onHover, onRightClick, ...props }, ref) => (
    <tr
      ref={ref}
      className={clsx(active && 'active !bg-base-100', (onClick || onHover) && 'hover cursor-pointer')}
      onClick={onClick}
      onContextMenu={(e) => {
        if (onRightClick) {
          e.preventDefault();
          onRightClick(e);
        }
      }}
      onMouseEnter={onHover ? () => onHover(true) : undefined}
      onMouseLeave={onHover ? () => onHover(false) : undefined}
      {...props}
    />
  )
);

TableRow.displayName = 'TableRow';

export default TableRow;
