import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FilterKeys, FilterValues } from '../types';
import { getDateRangeParam } from '../utils';

/**
 * Custom hook that returns a function to update filter parameters in the URL.
 */
const useUpdateFilterParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Memoize the URLSearchParams object to avoid unnecessary recalculations.
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  // Updates the filter parameters in the URL, given the filter key and its new value.
  // If the new value is undefined, the filter will be removed.
  const updateFilterParams = useCallback(
    (key: FilterKeys, value?: FilterValues, customPathname?: string) => {
      if (typeof value === 'boolean') {
        // If the value is a boolean, we need to explicitly set or delete the key based on the value.
        if (value) params.set(key, value.toString());
        else params.delete(key);
      } else if (typeof value === 'object' && value && 'start' in value && 'end' in value) {
        // If the value is an object with start and end dates.
        // If the dates are coming from the saved default view in Redux,
        // they are converted to strings, so we need to convert them back to dates here.
        const start = value.start instanceof Date ? value.start : new Date(value.start);
        const end = value.end instanceof Date ? value.end : new Date(value.end);
        params.set(key, getDateRangeParam({ start, end }));
      } else if (!Array.isArray(value) && value) {
        params.set(key, value);
      } else if (Array.isArray(value) && value?.length) {
        params.set(key, value.join(','));
      } else {
        params.delete(key);
      }

      navigate({ pathname: customPathname ?? location.pathname, search: params.toString() }, { replace: true });
    },
    [location.pathname, navigate, params]
  );

  return updateFilterParams;
};

export default useUpdateFilterParams;
