import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../constants';
import { useAppDispatch, useAppSelector, useHandleApiResponse } from '../../../hooks';
import { closeZeusModal, setIsImpersonating, setOrganization, setUser } from '../../../redux/reducers';
import { api, useGetImpersonatedUserMutation } from '../../../services';
import { ApiTagTypes, ComponentSize, ImpersonatedUserData } from '../../../types';
import { ButtonColor, ButtonGroup, TextButton } from '../../shared';

const IMPERSONATE_USER_ERROR_MSG = 'Failed to impersonate user';

interface ZeusModalActionsProps {
  selectedOrgId?: string;
  selectedUserId?: string;
}

const ZeusModalActions = ({ selectedOrgId, selectedUserId }: ZeusModalActionsProps) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { user, organization, isImpersonating } = useAppSelector((state) => state.auth);

  // Check if the selected user is the same as the current user - used to disable the impersonate button.
  // We check both the orgId and userId in case the user is a part of multiple organizations.
  const isSameUser = selectedUserId === user?.id && selectedOrgId === organization?.id;
  const isImpersonateDisabled = isSameUser || !selectedOrgId || !selectedUserId;

  const handleApiResponse = useHandleApiResponse();
  const [getImpersonatedUser, { isLoading: isLoadingImpersonatedUser }] = useGetImpersonatedUserMutation();

  const onSuccess = useCallback(
    (data: ImpersonatedUserData) => {
      // Set the isImpersonating flag to true.
      dispatch(setIsImpersonating(true));
      // Invalidate all cache tags.
      dispatch(api.util.invalidateTags(Object.values(ApiTagTypes)));
      // Set the organization and user in Redux.
      dispatch(setOrganization(data.organization));
      dispatch(setUser(data));
      // Close the Zeus modal and navigate to the practice page.
      dispatch(closeZeusModal());
      navigate(AppRoutes.PRACTICE);
    },
    [dispatch, navigate]
  );

  // Starts impersonation.
  const handleImpersonateUser = useCallback(async () => {
    if (isImpersonateDisabled) return;
    try {
      // Fetch the impersonated user data.
      const response = await getImpersonatedUser({ orgId: selectedOrgId, impersonateUserId: selectedUserId });
      handleApiResponse({
        response,
        errorMsg: IMPERSONATE_USER_ERROR_MSG,
        onSuccess,
      });
    } catch (error) {
      console.error(IMPERSONATE_USER_ERROR_MSG, error);
    }
  }, [isImpersonateDisabled, selectedOrgId, selectedUserId, handleApiResponse, onSuccess]);

  // Closes the modal and exits impersonation if impersonation is active.
  const handleCancel = async () => {
    dispatch(closeZeusModal());
    if (isImpersonating) {
      // To exit impersonation, set the isImpersonating flag to false.
      dispatch(setIsImpersonating(false));
      // Invalidate all cache tags.
      dispatch(api.util.invalidateTags(Object.values(ApiTagTypes)));
    }
  };

  return (
    <ButtonGroup size={ComponentSize.MEDIUM}>
      <TextButton
        text="Impersonate user"
        onClick={handleImpersonateUser}
        color={ButtonColor.PRIMARY}
        disabled={isImpersonateDisabled}
        loading={isLoadingImpersonatedUser}
      />
      <TextButton text={isImpersonating ? 'Exit Zeus mode' : 'Cancel'} onClick={handleCancel} />
    </ButtonGroup>
  );
};

export default ZeusModalActions;
