import { useCallback } from 'react';
import {
  ButtonColor,
  Icon,
  ManageScorecardTemplate,
  TextButton,
  Typography,
  TypographySize,
  TypographyWeight,
} from '../../components';
import { useNavigate, useParams } from 'react-router-dom';
import { ScorecardTemplatesTable } from './ScorecardTemplatesTable';

const ScorecardTemplatesPage = () => {
  const { scorecardTemplateId } = useParams();
  const navigate = useNavigate();

  // Navigate to the previous page
  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  // Render the scorecard form
  if (scorecardTemplateId) {
    return <ManageScorecardTemplate />;
  }

  // Render the scorecards table
  return (
    <div className="flex h-full w-full flex-col">
      <div className="flex gap-4 px-8 py-4">
        <TextButton onClick={handleBack} startIcon={Icon.CHEVRON_LEFT} text="Back" color={ButtonColor.SECONDARY} />
        <Typography size={TypographySize.H3} weight={TypographyWeight.SEMI_BOLD}>
          Scorecard templates
        </Typography>
      </div>
      <ScorecardTemplatesTable />
    </div>
  );
};

export default ScorecardTemplatesPage;
