import { useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { AppRoutes, DATE_FILTER_KEYS } from '../constants';
import { FilterKeys, Filters, FilterValues, PracticeFilterKeys } from '../types';
import { getValidKeys } from '../utils';

const useGetFiltersFromParams = () => {
  const location = useLocation();
  const isReviewPage = location.pathname.includes(AppRoutes.REVIEW);
  const isAnalyticsPage = location.pathname.includes(AppRoutes.ANALYTICS);
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const previousFilters = useRef<Filters>({});

  // Extracts filter key-value pairs from the given URLSearchParams object.
  // Only includes keys that are valid filter keys for Practice or Review.
  // Splits comma-separated values into arrays for each parameter,
  // except for the search parameter which remains a string.
  const filters: Filters = useMemo(() => {
    const entries: Record<string, FilterValues> = {};
    const validKeys: FilterKeys[] = getValidKeys();
    params.forEach((value, key) => {
      if (validKeys.includes(key as FilterKeys)) {
        if (key === PracticeFilterKeys.SEARCH) {
          entries[key] = value;
        } else if (DATE_FILTER_KEYS.includes(key)) {
          // Convert the hyphen-separated timestamp string into start and end dates.
          const [start, end] = value.split('-');
          const startDate = new Date(parseInt(start));
          const endDate = new Date(parseInt(end));
          entries[key] = { start: startDate, end: endDate };
        } else if (value === 'true' || value === 'false') {
          entries[key] = value === 'true';
        } else {
          entries[key] = value.split(',');
        }
      }
    });

    return entries;
  }, [params, isReviewPage, isAnalyticsPage]);

  const shouldUpdate = JSON.stringify(previousFilters.current) !== JSON.stringify(filters);
  if (shouldUpdate) {
    previousFilters.current = filters;
  }

  // Return the filters if they have changed.
  // Prevents unnecessary re-renders.
  return shouldUpdate ? filters : previousFilters.current;
};

export default useGetFiltersFromParams;
