import { useAppSelector } from '../../../../../hooks';
import { TextColor, Permissions, Roles } from '../../../../../types';
import { Typography, TypographySize, TypographyWeight } from '../../../../shared';
import TabHeader from '../TabHeader';

import AddUser from './AddUser';
import UserTable from './UserTable';
import { SeatCards } from './SeatCards';

const INTERCOM_MESSAGE = 'Hi, I need more seats for my organization.';

const PeopleTab = () => {
  const organization = useAppSelector((state) => state.auth.organization);

  const handleNeedMoreSeatsClick = () => {
    if (!window.Intercom) return;
    window.Intercom('showNewMessage', INTERCOM_MESSAGE);
  };
  const user = useAppSelector((state) => state.auth.user);
  const permissions = user?.permissions || [];

  const isSuperAdmin = permissions.includes(Permissions.ADMIN_ACCESS);
  const isAdmin = user?.role === Roles.ADMIN;

  const canViewSeatsCards = isSuperAdmin || isAdmin;

  if (!organization) return null;

  return (
    <div className="flex h-full w-full flex-col">
      <TabHeader
        title={
          <div className="flex w-full items-center justify-between gap-2">
            <Typography size={TypographySize.H3} weight={TypographyWeight.SEMI_BOLD}>
              {organization.name}
            </Typography>
            {canViewSeatsCards && (
              <Typography
                size={TypographySize.CAPTION}
                onClick={() => handleNeedMoreSeatsClick()}
                color={TextColor.LINK}
                underline
              >
                Need more seats?
              </Typography>
            )}
          </div>
        }
      >
        {canViewSeatsCards && <SeatCards />}
        <AddUser />
      </TabHeader>
      <UserTable />
    </div>
  );
};

export default PeopleTab;
