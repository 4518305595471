import storage from 'redux-persist/lib/storage';

/** The key for the persisted redux store. */
export const PERSIST_KEY = 'root';

/**
 * The persist config for the redux store.
 * Specify which reducers should be persisted in the whitelist.
 */
export const PERSIST_CONFIG = {
  key: PERSIST_KEY,
  storage,
  whitelist: ['auth', 'defaultView', 'zeus'],
};
