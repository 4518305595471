import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AlertType,
  ButtonColor,
  ButtonVariant,
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownItemProps,
  DropdownTrigger,
  Icon,
  IconButton,
  Icons,
  ManageTags,
  Typography,
} from '../../../components';
import { AppRoutes } from '../../../constants';
import { useAppSelector, useFeatureFlag, useToast, useUpdateProspectTags } from '../../../hooks';
import {
  DropdownPlacement,
  LD_FeatureFlags,
  Permissions,
  PracticeFilterKeys,
  PracticeProspect,
  TextColor,
} from '../../../types';
import { conditionalArray } from '../../../utils';

interface ProspectActionsCellProps {
  prospect: PracticeProspect;
  /** Sets row active state when actions dropdown is open. */
  setIsActive: (isActive: boolean) => void;
  /** Opens manager notes modal for prospect. */
  setManagerNotesProspect: (prospect: PracticeProspect) => void;
}

const ProspectActionsCell = ({ prospect, setIsActive, setManagerNotesProspect }: ProspectActionsCellProps) => {
  const { associatedPhoneNumber, personaId, tags } = prospect;

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState(tags);

  const permissions = useAppSelector((state) => state.auth.user?.permissions || []);
  const canManageProspect = permissions.includes(Permissions.MANAGE_PROSPECT);

  const selfServeFF = useFeatureFlag(LD_FeatureFlags.RELEASE_SELF_SERVE);
  const { showToast } = useToast();
  const { runApplyTags, isLoading: isApplyingTags } = useUpdateProspectTags({
    personaId,
    selectedTags,
    tags,
    setSelectedTags,
  });

  // Handles the open/close state of the actions dropdown and the active row index.
  const onOpenChange = useCallback((open: boolean) => {
    setIsOpen(open);
    setIsActive(open);
  }, []);

  // Opens the manager notes modal.
  const onOpenDetails = useCallback(() => {
    setManagerNotesProspect(prospect);
    onOpenChange(false);
  }, [prospect, onOpenChange]);

  // Navigates to the prospect page.
  const onEditProspect = useCallback(() => {
    navigate(`${AppRoutes.PROSPECT}/${personaId}`);
  }, [personaId, navigate]);

  // Copies the prospect's phone number to the clipboard.
  const onCopyPhoneNumber = useCallback(() => {
    if (!associatedPhoneNumber) return;

    navigator.clipboard.writeText(associatedPhoneNumber);
    showToast({ message: 'Phone number copied to clipboard', type: AlertType.SUCCESS });
    onOpenChange(false);
  }, [associatedPhoneNumber, showToast, onOpenChange]);

  // Copies the prospect's link to the clipboard.
  const onShare = useCallback(() => {
    // Generate the URL based on the current window location.
    const url = new URL(window.location.href);
    // Clear all existing search parameters.
    url.search = '';
    // Set the new query parameter.
    url.searchParams.set(PracticeFilterKeys.PROSPECT, personaId);
    // Copy the URL to the clipboard.
    navigator.clipboard.writeText(url.toString());

    showToast({ message: 'Link to prospect copied to clipboard', type: AlertType.SUCCESS });
    onOpenChange(false);
  }, [personaId, showToast, onOpenChange]);

  const actions: (DropdownItemProps & { label: string; icon: Icon })[] = useMemo(
    () => [
      ...conditionalArray(selfServeFF && canManageProspect, {
        label: 'Edit prospect',
        icon: Icon.EDIT,
        onClick: onEditProspect,
      }),
      ...conditionalArray(canManageProspect, {
        label: 'Open details',
        icon: Icon.USER_SEARCH,
        onClick: onOpenDetails,
      }),
      ...conditionalArray(!!associatedPhoneNumber, {
        label: 'Copy phone number',
        icon: Icon.COPY,
        onClick: onCopyPhoneNumber,
      }),
      {
        label: 'Add or remove tags',
        icon: Icon.TAG,
        submenu: (
          <ManageTags inline isLoading={isApplyingTags} selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
        ),
        onSubmenuClose: runApplyTags,
      },
      {
        label: 'Share',
        icon: Icon.SHARE,
        onClick: onShare,
      },
    ],
    [
      selfServeFF,
      canManageProspect,
      isApplyingTags,
      selectedTags,
      onOpenDetails,
      onEditProspect,
      onCopyPhoneNumber,
      onShare,
      runApplyTags,
    ]
  );

  return (
    <Dropdown open={isOpen} onOpenChange={onOpenChange} placement={DropdownPlacement.BOTTOM_END}>
      <DropdownTrigger>
        <IconButton
          active={isOpen}
          color={ButtonColor.SECONDARY}
          icon={Icon.ELLIPSIS_VERTICAL}
          variant={ButtonVariant.OUTLINE}
          onClick={() => onOpenChange(true)}
        />
      </DropdownTrigger>
      <DropdownContent>
        {actions.map(({ icon, label, ...props }) => (
          <DropdownItem
            key={label}
            icon={<Icons icon={icon} color={TextColor.PRIMARY} />}
            label={<Typography color={TextColor.PRIMARY}>{label}</Typography>}
            {...props}
          />
        ))}
      </DropdownContent>
    </Dropdown>
  );
};

export default ProspectActionsCell;
