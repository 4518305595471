import { useMemo } from 'react';
import { PROSPECT_TYPE_TO_COLOR, PROSPECT_TYPE_TO_LABEL, PUBLIC_PROSPECT_TYPES } from '../../constants';
import {
  FilterOptionsAndSelections,
  useAppSelector,
  useFeatureFlag,
  useGetFiltersFromParams,
  useUpdateFilterParams,
} from '../../hooks';
import { useGetUsersForSelectQuery } from '../../services';
import {
  ComponentSize,
  LD_FeatureFlags,
  Permissions,
  ProspectType,
  ReviewFilterKeys,
  ReviewFilters as ReviewFiltersType,
  Roles,
} from '../../types';
import { getUserOptions } from '../../utils';
import { findOptionsByValues, SidebarDateFilter, SidebarSelectFilter, Toggle } from '../shared';

const CATEGORY_OPTIONS = Object.values(ProspectType)
  .filter((type) => PUBLIC_PROSPECT_TYPES.includes(type) || type === ProspectType.QUIZ)
  .map((type) => ({
    value: type,
    label: PROSPECT_TYPE_TO_LABEL[type],
    color: PROSPECT_TYPE_TO_COLOR[type],
  }));

interface ReviewFiltersProps {
  prospectOptionsAndSelections: FilterOptionsAndSelections;
  tagOptionsAndSelections: FilterOptionsAndSelections;
}

const ReviewFilters = ({ prospectOptionsAndSelections, tagOptionsAndSelections }: ReviewFiltersProps) => {
  const user = useAppSelector((state) => state.auth.user);
  const isSalesRep = user?.role === Roles.SALES_REP;
  const permissions = user?.permissions || [];
  const canViewHiring = permissions.includes(Permissions.VIEW_CANDIDATE);

  const quizFF = useFeatureFlag(LD_FeatureFlags.RELEASE_QUIZ);
  // Retrieve filters from URL parameters.
  const filters: ReviewFiltersType = useGetFiltersFromParams();

  // Hook to update filter parameters in the URL.
  const updateFilterParams = useUpdateFilterParams();

  // Fetch users and phone numbers for the user options.
  const { data: userData, isLoading: isLoadingUsers } = useGetUsersForSelectQuery({});

  // Memoize user and prospect options to avoid unnecessary recalculations.
  const userOptions = useMemo(() => getUserOptions(userData), [userData]);

  // Find the selected user option based on the current filters.
  const selectedUsers = useMemo(() => findOptionsByValues(userOptions, filters.user), [userOptions, filters.user]);

  const selectedProspectTypes = useMemo(
    () => findOptionsByValues(CATEGORY_OPTIONS, filters.category),
    [filters.category]
  );
  const prospectTypeOptions = useMemo(() => {
    // Filter out candidate options if user doesn't have hiring permissions.
    return CATEGORY_OPTIONS.filter((option) => canViewHiring || option.value !== ProspectType.CANDIDATES);
  }, [canViewHiring]);

  return (
    <>
      <Toggle
        label="Flagged calls only"
        checked={filters.flagged}
        onChange={(newValue) => updateFilterParams(ReviewFilterKeys.FLAGGED, newValue)}
        size={ComponentSize.MEDIUM}
      />
      {/* Only show the User filter if the user is not a Sales Representative */}
      {!isSalesRep && (
        <SidebarSelectFilter
          options={userOptions}
          placeholder="Select user"
          selected={selectedUsers}
          title="User"
          onChange={(newValue) => updateFilterParams(ReviewFilterKeys.USER, newValue)}
          loading={isLoadingUsers}
        />
      )}
      <SidebarSelectFilter
        placeholder="Select prospect"
        title="Prospect"
        onChange={(newValue) => updateFilterParams(ReviewFilterKeys.PROSPECT, newValue)}
        {...prospectOptionsAndSelections}
      />
      <SidebarSelectFilter
        title="Tags"
        placeholder="Select tag"
        onChange={(newValue) => updateFilterParams(ReviewFilterKeys.TAGS, newValue)}
        {...tagOptionsAndSelections}
      />
      {quizFF && (
        <SidebarSelectFilter
          title="Category"
          placeholder="Select category"
          selected={selectedProspectTypes}
          onChange={(newValue) => updateFilterParams(ReviewFilterKeys.CATEGORY, newValue)}
          options={prospectTypeOptions}
        />
      )}
      <SidebarDateFilter
        title="Call date"
        onChange={(newValue) => updateFilterParams(ReviewFilterKeys.CALL_DATE, newValue)}
        selected={filters.callDate}
      />
    </>
  );
};

export default ReviewFilters;
