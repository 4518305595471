import { useCallback } from 'react';
import { useAppDispatch, useAppSelector, useHandleApiResponse } from '../../../../../hooks';
import { updateOrganization } from '../../../../../redux/reducers';
import { useDisconnectGongMutation } from '../../../../../services';
import { GongAuthMethod, TextColor } from '../../../../../types';
import { ButtonColor, Icon, Icons, TextButton, Typography } from '../../../../shared';

const DISCONNECT_ERROR_MSG = 'Failed to disconnect from Gong';

interface ConnectionButtonProps {
  isConnectingGong: boolean;
  isFailedConnection: boolean;
  gongAuthMethod: GongAuthMethod;
  onConnect: () => Promise<void>;
  onDisconnect?: () => void;
}

const ConnectionButton = ({
  onConnect,
  onDisconnect,
  isConnectingGong,
  isFailedConnection,
  gongAuthMethod,
}: ConnectionButtonProps) => {
  const dispatch = useAppDispatch();
  // The method the user is connected to Gong with.
  const connectionMethod = useAppSelector((state) => state.auth.organization?.gongAuth?.authMethod);

  const showConnectionFailed = isFailedConnection && !connectionMethod;
  const showConnectionSuccess = !isFailedConnection && connectionMethod === gongAuthMethod;

  const [disconnectGong, { isLoading: isDisconnectingGong }] = useDisconnectGongMutation();
  const handleApiResponse = useHandleApiResponse();

  const onDisconnectSuccess = useCallback(() => {
    dispatch(updateOrganization({ gongAuth: undefined }));
    onDisconnect?.();
  }, [dispatch, onDisconnect]);

  const handleDisconnect = useCallback(async () => {
    try {
      const response = await disconnectGong();
      handleApiResponse({
        response,
        errorMsg: DISCONNECT_ERROR_MSG,
        successMsg: 'Gong disconnected successfully',
        onSuccess: onDisconnectSuccess,
      });
    } catch (error) {
      console.error(DISCONNECT_ERROR_MSG, error);
    }
  }, [disconnectGong, handleApiResponse, onDisconnectSuccess]);

  return (
    <div className="flex items-center gap-4">
      {connectionMethod && (
        <TextButton
          text="Disconnect"
          color={ButtonColor.DESTRUCTIVE}
          onClick={handleDisconnect}
          loading={isDisconnectingGong}
          disabled={connectionMethod !== gongAuthMethod}
        />
      )}
      {!connectionMethod && <TextButton text="Connect" onClick={onConnect} loading={isConnectingGong} />}
      <div className="flex items-center gap-2">
        {/* Gong is already connected */}
        {showConnectionSuccess && (
          <>
            <Icons icon={Icon.CHECK} color={TextColor.SUCCESS} />
            <Typography color={TextColor.SUCCESS}>Connected</Typography>
          </>
        )}
        {/* Connection failed */}
        {showConnectionFailed && (
          <>
            <Icons icon={Icon.CLOSE} color={TextColor.DESTRUCTIVE} />
            <Typography color={TextColor.DESTRUCTIVE}>Connection failed</Typography>
          </>
        )}
      </div>
    </div>
  );
};

export default ConnectionButton;
