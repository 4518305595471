import { useMemo } from 'react';
import { findOptionByValue, getYesNoOptions, RadioGroup, TextInput, YesNoAnswer } from '../../../../components';
import { ALL_PROSPECT_TYPE_OPTIONS } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setIsHidden, setIsRestricted } from '../../../../redux/reducers';
import { conditionalArray } from '../../../../utils';
import ProspectFieldGroup from '../ProspectFieldGroup';
import TabSection from '../TabSection';

interface CallTypeProps {
  isSuperAdmin: boolean;
}
const CallType = ({ isSuperAdmin }: CallTypeProps) => {
  const dispatch = useAppDispatch();
  const {
    prospectType,
    fields: { isHidden, isRestricted },
  } = useAppSelector((state) => state.prospect);

  const prospectPurpose = findOptionByValue(ALL_PROSPECT_TYPE_OPTIONS, prospectType)?.label;

  const fields = useMemo(
    () => [
      {
        // Show the prospect purpose selected at the very beginning of prospect creation.
        label: 'Prospect purpose',
        content: <TextInput disabled value={prospectPurpose} />,
      },
      {
        // We store internally as "isHidden" but display as "Public?".
        // So we invert the yes/no value when setting isHidden.
        label: 'Public?',
        tooltip: 'Non-public prospects are hidden from representatives.',
        content: (
          <RadioGroup
            options={getYesNoOptions()}
            value={isHidden ? YesNoAnswer.NO : YesNoAnswer.YES}
            onChange={(e) => dispatch(setIsHidden(e.target.value === YesNoAnswer.NO))}
          />
        ),
      },
      ...conditionalArray(isSuperAdmin, {
        // We store internally as "isRestricted" and display as "Is restricted?".
        // So yes/no maps directly to the boolean.
        label: 'Is restricted?',
        content: (
          <RadioGroup
            options={getYesNoOptions()}
            value={isRestricted ? YesNoAnswer.YES : YesNoAnswer.NO}
            onChange={(e) => dispatch(setIsRestricted(e.target.value === YesNoAnswer.YES))}
          />
        ),
      }),
    ],
    [prospectPurpose, isHidden, isRestricted, isSuperAdmin]
  );

  return (
    <TabSection title="Call type">
      <ProspectFieldGroup fields={fields} spaced />
    </TabSection>
  );
};

export default CallType;
