import { useEffect } from 'react';
import { Banner, BannerType, Icon } from '../../components';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { BannerTypes, closeBanner, openBanner, openZeusModal } from '../../redux/reducers';

const Banners = () => {
  const dispatch = useAppDispatch();
  const banners = useAppSelector((state) => state.modal.banners);
  const { user, organization, isImpersonating } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (isImpersonating && !banners.includes(BannerTypes.ZEUS)) {
      // Open banner when impersonating a user.
      dispatch(openBanner(BannerTypes.ZEUS));
    } else if (!isImpersonating && banners.includes(BannerTypes.ZEUS)) {
      // Close banner on ending user impersonation.
      dispatch(closeBanner(BannerTypes.ZEUS));
    }
  }, [banners, isImpersonating, dispatch]);

  if (!banners.length) return null;

  return (
    <div>
      {banners.includes(BannerTypes.ZEUS) && (
        <Banner
          text={`Zeus mode: Impersonating ${user?.name} at ${organization?.name}`}
          icon={Icon.TRAFFIC_CONE}
          type={BannerType.WARNING}
          endAction={{
            title: 'Change user',
            onClick: () => {
              dispatch(openZeusModal());
            },
          }}
        />
      )}
    </div>
  );
};

export default Banners;
