import dayjs from 'dayjs';
import { useState } from 'react';
import { ComponentSize, DateFormat, DateUnit, DropdownPlacement } from '../../../types';
import {
  ButtonColor,
  ButtonVariant,
  DatePicker,
  DatePickerRange,
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
  Icon,
  IconButton,
  TextInput,
  Tooltip,
  Typography,
} from '../../shared';
import DateOptionDropdownItem, { DateOptions } from './DateOptionDropdownItem';

interface SidebarDateFilterProps {
  title: string;
  onChange: (newValue?: DatePickerRange) => void;
  selected?: DatePickerRange;
  disableClear?: boolean;
}

const SidebarDateFilter = ({ title, selected, disableClear, onChange }: SidebarDateFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { start: startInput, end: endInput } = selected ?? {};

  const getFormattedInput = () => {
    // If the start date is not selected, return undefined.
    if (!startInput) return undefined;

    // If the end date is not selected or the start and end dates are the same,
    // only return the formatted start date.
    const formattedStartInput = dayjs(startInput).format(DateFormat.MONTH_DAY);
    const isSingleDay = !endInput || dayjs(startInput).isSame(endInput, DateUnit.DAY);
    if (isSingleDay) return formattedStartInput;

    // If the end date is selected and it is different from the start date,
    // return the formatted start and end dates.
    const formattedEndInput = dayjs(endInput).format(DateFormat.MONTH_DAY);
    return `${formattedStartInput} - ${formattedEndInput}`;
  };

  const renderEndElement = () => (
    <div className="flex items-center">
      {!disableClear && startInput && endInput && (
        <Tooltip label="Clear">
          <IconButton
            icon={Icon.CLOSE}
            variant={ButtonVariant.GHOST}
            color={ButtonColor.SECONDARY}
            size={ComponentSize.X_SMALL}
            onClick={() => onChange(undefined)}
          />
        </Tooltip>
      )}
      <IconButton
        color={ButtonColor.SECONDARY}
        icon={isOpen ? Icon.CHEVRON_UP : Icon.CHEVRON_DOWN}
        size={ComponentSize.X_SMALL}
        variant={ButtonVariant.GHOST}
        onClick={() => setIsOpen((prev) => !prev)}
      />
    </div>
  );

  return (
    <div className="flex flex-col gap-2">
      <Typography>{title}</Typography>
      <Dropdown fullWidth open={isOpen} onOpenChange={setIsOpen} placement={DropdownPlacement.BOTTOM_START}>
        <DropdownTrigger>
          <TextInput
            placeholder="Select date range"
            value={getFormattedInput()}
            endElement={renderEndElement()}
            onClick={() => setIsOpen((prev) => !prev)}
          />
        </DropdownTrigger>
        <DropdownContent>
          {Object.values(DateOptions).map((option) => (
            <DateOptionDropdownItem
              key={option}
              selected={selected}
              option={option}
              onChange={onChange}
              onClose={() => setIsOpen(false)}
            />
          ))}
          <DropdownItem
            label="Custom"
            submenu={<DatePicker disableClear={disableClear} disableFutureDates range={selected} onChange={onChange} />}
          />
        </DropdownContent>
      </Dropdown>
    </div>
  );
};

export default SidebarDateFilter;
