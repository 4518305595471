import clsx from 'clsx';
import { forwardRef, memo, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { closeMediaPlayer } from '../../redux/reducers';
import ControlButtons from './ControlButtons';
import MediaPlayerContent from './MediaPlayerContent';

const MEDIA_PLAYER_ANIMATION_DURATION = 500;

const MediaPlayer = memo(
  forwardRef<HTMLDivElement>((_, ref) => {
    const isCallDetailsDrawerOpen = useParams().callSid !== undefined;

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const dispatch = useAppDispatch();
    const { autoPlay, call } = useAppSelector((state) => state.review.currentCallDetails) || {};

    // Opens the drawer when the call is fetched,
    // so we would have the content visible during the opening animation.
    useEffect(() => {
      if (call) {
        setIsDrawerOpen(true);
      }
    }, [call]);

    // Closes the drawer and clears the call from Redux after the animation finishes,
    // keeping the content visible during the closing animation
    const closeDrawer = useCallback(() => {
      setIsDrawerOpen(false);
      setTimeout(() => {
        dispatch(closeMediaPlayer());
      }, MEDIA_PLAYER_ANIMATION_DURATION);
    }, [dispatch]);

    return (
      <div
        className={clsx(
          'fixed bottom-0 left-0 right-0 transition-all ease-in-out',
          isDrawerOpen ? 'translate-y-0' : 'translate-y-full'
        )}
        ref={ref}
        style={{ transitionDuration: `${MEDIA_PLAYER_ANIMATION_DURATION}ms` }}
      >
        {call && !isCallDetailsDrawerOpen && (
          <div className="flex justify-end bg-transparent">
            <ControlButtons callSid={call.callSid} processingStatus={call?.processingStatus} onClose={closeDrawer} />
          </div>
        )}
        {call && (
          <div className="relative flex items-center gap-8 border-t border-gray-200 bg-base-0 p-8">
            <MediaPlayerContent autoPlay={autoPlay} call={call} />
          </div>
        )}
      </div>
    );
  })
);

MediaPlayer.displayName = 'MediaPlayer';
export default MediaPlayer;
