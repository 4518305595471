import { useCallback, useMemo } from 'react';
import { Select } from '../../../../components';
import { PROSPECT_SLIDER_LIMITS } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { randomizeOutcome, setSuccessDifficultyLevel, setSuccessResult } from '../../../../redux/reducers';
import { ProspectSuccessResult } from '../../../../types';
import { snakeCaseToLabel } from '../../../../utils';
import ProspectFieldGroup from '../ProspectFieldGroup';
import ProspectSlider from '../ProspectSlider';
import TabSection from '../TabSection';

const OUTCOME_SELECT_WIDTH = 200;
const SUCCESS_RESULT_OPTIONS = Object.values(ProspectSuccessResult).map((value) => ({
  label: snakeCaseToLabel(value),
  value,
}));

const Outcome = () => {
  const dispatch = useAppDispatch();
  const { successResult, successDifficultyLevel } = useAppSelector((state) => state.prospect.fields);

  const selectedSuccessResultOption = SUCCESS_RESULT_OPTIONS.find((option) => option.value === successResult.value);

  const handleSuccessResultChange = useCallback(
    (value?: string) => {
      const newSuccessResult = value as ProspectSuccessResult;
      dispatch(setSuccessResult(newSuccessResult));
    },
    [dispatch]
  );

  const handleDifficultyChange = useCallback(
    (value: number) => {
      dispatch(setSuccessDifficultyLevel(value));
    },
    [dispatch]
  );

  const fields = useMemo(
    () => [
      {
        label: 'Successful result',
        required: true,
        width: OUTCOME_SELECT_WIDTH,
        content: (
          <Select
            clearable
            selected={selectedSuccessResultOption}
            options={SUCCESS_RESULT_OPTIONS}
            onChange={handleSuccessResultChange}
          />
        ),
      },
      {
        label: 'Difficulty',
        content: (
          <ProspectSlider
            hideProgress
            leftLabel="Easy"
            rightLabel="Difficult"
            max={PROSPECT_SLIDER_LIMITS.max}
            min={PROSPECT_SLIDER_LIMITS.min}
            step={PROSPECT_SLIDER_LIMITS.step}
            value={successDifficultyLevel.value}
            onChange={handleDifficultyChange}
          />
        ),
      },
    ],
    [selectedSuccessResultOption, handleSuccessResultChange, handleDifficultyChange]
  );

  return (
    <TabSection title="Outcome" onRandomize={() => dispatch(randomizeOutcome())}>
      <ProspectFieldGroup fields={fields} />
    </TabSection>
  );
};

export default Outcome;
