// -- NOTES --
// Maximum number of rows to display before notes overflow.
export const MAX_NOTES_ROWS = 2;
// Notes height.
export const NOTES_HEIGHT = 16;

// -- TAGS --
// Maximum tag width before overflow.
export const MAX_TAG_WIDTH = 180;
// Tag height.
export const TAG_HEIGHT = 16;
// Gap between tags.
export const TAG_GAP = 4;
// Add tags icon button width.
export const MANAGE_TAGS_BUTTON_WIDTH = 16;

// -- MAX HEIGHTS --
// Maximum tags height.
export const MAX_TAGS_HEIGHT = TAG_HEIGHT * MAX_NOTES_ROWS + TAG_GAP * (MAX_NOTES_ROWS - 1);
// Maximum tags and notes height.
export const MAX_TAGS_AND_NOTES_HEIGHT =
  MAX_TAGS_HEIGHT + NOTES_HEIGHT * MAX_NOTES_ROWS + TAG_GAP * (MAX_NOTES_ROWS - 1);
