import { useCallback, useEffect, useRef, useState } from 'react';
import { ButtonColor, Icon, Spinner, TextButton, TextInput } from '../../../components';
import { ComponentSize, ScorecardSectionType, ScorecardTemplateType } from '../../../types';
import { getNewScorecardQuestion } from '../../../utils';
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import { useGetScorecardTemplateQuery } from '../../../services';
import { useNavigate, useParams } from 'react-router-dom';
import ScorecardTemplateSection from './ScorecardTemplateSection';
import ScorecardTemplateActionButtons from './ScorecardTemplateActionButtons';
import ScorecardTemplateDesignerModal from './ScorecardTemplateDesignerModal';

const ManageScorecardTemplate = () => {
  const { scorecardTemplateId } = useParams();
  const navigate = useNavigate();

  // API Hooks
  const { data: scorecard, isLoading: isScorecardLoading } = useGetScorecardTemplateQuery(scorecardTemplateId || '', {
    skip: !scorecardTemplateId,
  });

  // State
  const [sections, setSections] = useState<ScorecardSectionType[]>([]);
  const [scorecardName, setScorecardName] = useState('');
  const [isDesignerModalOpen, setIsDesignerModalOpen] = useState(!scorecardTemplateId);

  // Default to AI scorecard if new scorecard
  const [scorecardType, setScorecardType] = useState(ScorecardTemplateType.AI);

  // Ref
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const isAIScorecard = scorecardType === ScorecardTemplateType.AI;

  // Update scorecard name, type and sections when scorecard data is fetched
  useEffect(() => {
    if (scorecard) {
      setScorecardName(scorecard.name);
      if (scorecard.type) setScorecardType(scorecard.type);
      if (scorecard.sections) setSections(scorecard.sections);
    }
  }, [scorecard]);

  // Add a new section to scorecard state
  const handleAddSection = () => {
    const newQuestion = getNewScorecardQuestion(isAIScorecard);
    // On adding new section, add one question by default
    const newSection: ScorecardSectionType = {
      title: '',
      id: uuidv4(),
      questions: [newQuestion],
    };

    setSections([...sections, newSection]);

    // Scroll to the added section
    setTimeout(() => {
      scrollContainerRef.current?.scrollTo({
        top: scrollContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  };

  // If there are no sections (for new scorecard), add one by default on initial render
  useEffect(() => {
    if (!scorecardTemplateId && !sections.length && !isDesignerModalOpen) {
      handleAddSection();
    }
  }, [isDesignerModalOpen, scorecardTemplateId]);

  // Update a section in scorecard state
  const handleUpdateSectionState = (index: number, updatedSection: ScorecardSectionType) => {
    const newSections = [...sections];
    newSections[index] = updatedSection;
    setSections(newSections);
  };

  // Remove a section from scorecard state
  const handleRemoveSection = (index: number) => setSections(sections.filter((_, i) => i !== index));

  // Handle scorecard name change
  const handleScorecardNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setScorecardName(e.target.value);
  };

  // Handle section drag end event
  const handleSectionDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedSections = Array.from(sections);
    const [movedSection] = reorderedSections.splice(result.source.index, 1);
    reorderedSections.splice(result.destination.index, 0, movedSection);

    setSections(reorderedSections);
  };

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  // If scorecard is loading and there is a scorecard ID, show the loading spinner
  if (isScorecardLoading && scorecardTemplateId) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner size={ComponentSize.MEDIUM} />
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col overflow-hidden">
      <DragDropContext onDragEnd={handleSectionDragEnd}>
        <div className="flex justify-center gap-4 px-8 py-4">
          <TextButton text="Back" startIcon={Icon.CHEVRON_LEFT} onClick={handleBack} color={ButtonColor.SECONDARY} />
          <TextInput placeholder="Scorecard name*" onChange={handleScorecardNameChange} value={scorecardName} />
        </div>
        <div className="display-scrollbar-lg flex-1 overflow-auto pb-4" ref={scrollContainerRef}>
          <Droppable
            droppableId="sections"
            renderClone={(provided, _snapshot, rubric) => (
              <ScorecardTemplateSection
                section={sections[rubric.source.index]}
                isAIScorecard={isAIScorecard}
                dragHandleProps={provided.dragHandleProps || undefined}
                draggableProps={provided.draggableProps || undefined}
                innerRef={provided.innerRef}
              />
            )}
          >
            {(droppableProvided) => (
              <div
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
                className="mx-auto flex max-w-[700px] flex-col gap-4"
              >
                {sections.map((section, index) => (
                  <Draggable draggableId={section.id} index={index} key={section.id}>
                    {(draggableProvided) => (
                      <ScorecardTemplateSection
                        section={section}
                        isAIScorecard={isAIScorecard}
                        onUpdate={(updatedSection) => handleUpdateSectionState(index, updatedSection)}
                        onRemove={() => handleRemoveSection(index)}
                        dragHandleProps={draggableProvided.dragHandleProps || undefined}
                        draggableProps={draggableProvided.draggableProps || undefined}
                        innerRef={draggableProvided.innerRef}
                      />
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
      <ScorecardTemplateActionButtons
        scorecard={scorecard}
        scorecardType={scorecardType}
        scorecardName={scorecardName}
        sections={sections}
        handleCloseScorecardForm={handleBack}
        handleAddSection={handleAddSection}
      />
      <ScorecardTemplateDesignerModal
        scorecardType={scorecardType}
        setScorecardType={setScorecardType}
        isModalOpen={isDesignerModalOpen}
        setIsModalOpen={setIsDesignerModalOpen}
        onCancel={handleBack}
      />
    </div>
  );
};

export default ManageScorecardTemplate;
