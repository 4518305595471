import { ComponentSize, TagColor } from '../../../types';
import { Icon } from '../Icons';

// Maps to DaisyUI Badge classes.
export enum BadgeColor {
  DEFAULT = 'badge-ghost',
  AI = 'badge-ai',
  DESTRUCTIVE = 'badge-error',
}

interface BaseBadgeProps {
  /** The label to display in the badge. */
  label: string;
  /**
   * Whether the badge is active.
   * If true, the badge will be displayed in an active state.
   */
  active?: boolean;
  /** The color of the dot in the badge. */
  dotColor?: TagColor | string;
  /** The start icon to display in the badge. */
  startIcon?: Icon;
  /** The end icon to display in the badge. */
  endIcon?: Icon;
  /** The maximum width of the badge. */
  maxWidth?: string | number;
  /** Whether the badge should display a dot. */
  showDot?: boolean;
  /** The size of the badge. */
  size?: ComponentSize;
  /** The click handler for the badge. */
  onClick?: () => Promise<void> | void;
}

// This is a union type that allows for either a ghost badge with a defined color or an outline badge.
export type BadgeProps =
  | (BaseBadgeProps & {
      color?: BadgeColor;
      outline?: false;
    })
  | (BaseBadgeProps & {
      color?: never;
      outline: true;
    });
