import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppRoutes, DEFAULT_ANALYTICS_AVERAGE_BY, DEFAULT_ANALYTICS_DATE_RANGE } from '../../constants';
import { useAppSelector, useGetFiltersFromParams, useHandleApiResponse } from '../../hooks';
import { useUpdateSavedViewMutation } from '../../services';
import { AnalyticsFilterKeys, AnalyticsFilters as AnalyticsFiltersType, AnalyticsTab, Page } from '../../types';
import { conditionalArray } from '../../utils';
import { ButtonColor, Icon, Icons, TextButton } from '../shared';
import ClearFiltersDialog from './ClearFiltersDialog';

const SAVE_VIEW_ERROR_MSG = 'Failed to save default view';
const SAVE_VIEW_SUCCESS_MSG = 'Default view saved successfully';

const SidebarFooterActions = () => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const location = useLocation();
  const isPracticePage = location.pathname.includes(AppRoutes.PRACTICE);
  const isAnalyticsPage = location.pathname.includes(AppRoutes.ANALYTICS);

  const handleApiResponse = useHandleApiResponse();
  const filters = useGetFiltersFromParams();
  const hasFilters = Object.keys(filters).length > 0;

  const { analyticsActiveTab } = useAppSelector((state) => state.analytics);

  const [updateSavedView, { isLoading: isUpdatingSavedView }] = useUpdateSavedViewMutation();

  const handleSaveAsDefaultView = useCallback(async () => {
    try {
      const response = await updateSavedView({ page: Page.PRACTICE_PROSPECTS, filters });
      handleApiResponse({ response, errorMsg: SAVE_VIEW_ERROR_MSG, successMsg: SAVE_VIEW_SUCCESS_MSG });
    } catch (error) {
      console.error(SAVE_VIEW_ERROR_MSG, error);
    }
  }, [filters, updateSavedView, handleApiResponse]);

  const actions = useMemo(() => {
    let showClearFilters = hasFilters;

    // If we are on the Analytics page, check if the filters only contain the default date range.
    // If so, we do not allow the user to clear it since this is the minimum required filter.
    if (isAnalyticsPage) {
      const analyticsFilters = filters as AnalyticsFiltersType;
      const dateRangeFilter = analyticsFilters[AnalyticsFilterKeys.DATE_RANGE];
      const averageByFilter = analyticsFilters[AnalyticsFilterKeys.AVERAGE_BY];

      const isProgressReportTab = analyticsActiveTab === AnalyticsTab.PROGRESS_REPORT;

      // Check if the average by and date range filters are the default values.
      const isDefaultAverageByFilter = averageByFilter?.[0] === DEFAULT_ANALYTICS_AVERAGE_BY;
      const isDefaultDateRangeFilter =
        dateRangeFilter &&
        dateRangeFilter.start.getTime() === DEFAULT_ANALYTICS_DATE_RANGE.start.getTime() &&
        dateRangeFilter.end.getTime() === DEFAULT_ANALYTICS_DATE_RANGE.end.getTime();

      // Check if the filters only contain the default filters.
      const isOnlyDefaultDateRange = isProgressReportTab
        ? Object.keys(analyticsFilters).length === 2 && isDefaultDateRangeFilter && isDefaultAverageByFilter
        : Object.keys(analyticsFilters).length === 1 && isDefaultDateRangeFilter;

      showClearFilters = hasFilters && !isOnlyDefaultDateRange;
    }

    return [
      ...conditionalArray(showClearFilters, {
        text: 'Clear filters',
        onClick: () => setIsConfirmModalOpen(true),
      }),
      ...conditionalArray(isPracticePage, {
        text: 'Save as default view',
        endIcon: <Icons icon={Icon.INFO} tooltip="Save this set of filters to be your default view" />,
        loading: isUpdatingSavedView,
        onClick: handleSaveAsDefaultView,
      }),
    ];
  }, [
    isPracticePage,
    isAnalyticsPage,
    hasFilters,
    filters,
    handleSaveAsDefaultView,
    isUpdatingSavedView,
    analyticsActiveTab,
  ]);

  if (!actions.length) return null;

  return (
    <div className="flex flex-col gap-4">
      {actions.map((action) => (
        <TextButton fullWidth key={action.text} color={ButtonColor.SECONDARY} {...action} />
      ))}
      <ClearFiltersDialog isOpen={isConfirmModalOpen} setIsOpen={setIsConfirmModalOpen} />
    </div>
  );
};

export default SidebarFooterActions;
