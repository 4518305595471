import * as React from 'react';
import { ContextType } from './Tooltip.types';

/** Creates a React context for the tooltip with a default value of null. */
const TooltipContext = React.createContext<ContextType>(null);

/**
 * Custom hook to use the TooltipContext.
 * Throws an error if the context is not found, ensuring that
 * the component is used within a Tooltip provider.
 */
export const useTooltipContext = () => {
  const context = React.useContext(TooltipContext);

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <TooltipRoot />');
  }

  return context;
};

export default TooltipContext;
