import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsTab } from '../../types';

interface AnalyticsState {
  analyticsActiveTab: AnalyticsTab;
}

const initialState: AnalyticsState = {
  analyticsActiveTab: AnalyticsTab.ACTIVITY,
};

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setAnalyticsActiveTab: (state, action: PayloadAction<AnalyticsTab>) => {
      state.analyticsActiveTab = action.payload;
    },
  },
});

export const { setAnalyticsActiveTab } = analyticsSlice.actions;
export default analyticsSlice.reducer;
