import { AppUser, Call, CallerInfo, Tag } from '../types';
import { formatDisplayedPhoneNumber } from './string.utils';

/**
 * Retrieves the caller information for a given call and current user.
 */
export const getCallerInfo = (call: Call, currUser: AppUser): CallerInfo => {
  let formattedPhoneNumber = '';
  if (call.incomingPhoneNumber) {
    // Format the incoming phone number for display.
    formattedPhoneNumber = formatDisplayedPhoneNumber(call.incomingPhoneNumber);
  }

  // If the call has no associated userId or candidateId, meaning the caller is a non-FullyRamped user,
  // return the incoming phone number as the caller's unique ID and name.
  if (!call.userId && !call.candidateId) {
    return { name: formattedPhoneNumber };
  }

  // If the caller's userId matches the current user's ID,
  // return the current user's details.
  if (call.userId === currUser.id) {
    return { name: currUser.name || '', picture: currUser.picture };
  }

  // If the call has an associated user object, return the user's details.
  if (call.user) {
    return { name: call.user.name || '', picture: call.user.picture };
  }

  // If the call has an associated candidate object, return the candidate's details.
  if (call.candidate) {
    return {
      name: `${call.candidate.firstName} ${call.candidate.lastName}`.trim(),
    };
  }

  // If none of the above conditions are met, return the incoming phone number as the caller's unique ID and name.
  return { name: formattedPhoneNumber };
};

/**
 * Converts an API prospect response to the app's prospect format.
 * Transforms the nested tags array structure from the API ({ tag: Tag }[])
 * into a flat array of Tag objects (Tag[]) used throughout the app.
 */
export const parseProspect = <T extends { tags: { tag: Tag }[] }>(prospect: T): T & { tags: Tag[] } => ({
  ...prospect,
  tags: prospect.tags.map((tag) => tag.tag),
});
