import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes, DEFAULT_ANALYTICS_AVERAGE_BY, DEFAULT_ANALYTICS_DATE_RANGE } from '../../constants';
import { AnalyticsFilterKeys, AnalyticsTab, FilterKeys, PracticeFilterKeys, ReviewFilterKeys } from '../../types';
import { getDateRangeParam } from '../../utils';
import { ConfirmModal } from '../modals';
import { useAppSelector } from '../../hooks';

interface ClearFiltersDialogProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const ClearFiltersDialog = ({ isOpen, setIsOpen }: ClearFiltersDialogProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isPracticePage = location.pathname.includes(AppRoutes.PRACTICE);
  const isReviewPage = location.pathname.includes(AppRoutes.REVIEW);
  const isAnalyticsPage = location.pathname.includes(AppRoutes.ANALYTICS);

  const { analyticsActiveTab } = useAppSelector((state) => state.analytics);
  const isProgressReportTab = analyticsActiveTab === AnalyticsTab.PROGRESS_REPORT;

  // Memoize the URLSearchParams object to avoid unnecessary recalculations.
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  // Clears the filter parameters in the URL, given the filter keys.
  const clearParams = (keys: FilterKeys[]) => keys.forEach((key) => params.delete(key));

  // Clears the filter parameters in the URL, given the current page.
  const clearFilterParams = useCallback(() => {
    if (isPracticePage) {
      clearParams(Object.values(PracticeFilterKeys));
    } else if (isReviewPage) {
      clearParams(Object.values(ReviewFilterKeys));
    } else if (isAnalyticsPage) {
      // Clear all analytics filters and reset the date range to its default value.
      clearParams(Object.values(AnalyticsFilterKeys));
      params.set(AnalyticsFilterKeys.DATE_RANGE, getDateRangeParam(DEFAULT_ANALYTICS_DATE_RANGE));
      // Set default average by period for progress report tab
      isProgressReportTab && params.set(AnalyticsFilterKeys.AVERAGE_BY, DEFAULT_ANALYTICS_AVERAGE_BY);
    }

    navigate({ pathname: location.pathname, search: params.toString() }, { replace: true });
  }, [isPracticePage, isReviewPage, isAnalyticsPage, isProgressReportTab, params, navigate, location.pathname]);

  // Clears the filter parameters in the URL and closes the dialog.
  const onConfirm = () => {
    clearFilterParams();
    setIsOpen(false);
  };

  return (
    <ConfirmModal
      buttonText="Clear filters"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onConfirm={onConfirm}
      title="Confirm clear filters"
      confirmText="Are you sure you want to clear all filters?"
    />
  );
};

export default ClearFiltersDialog;
