import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import {
  Badge,
  ButtonColor,
  ButtonGroup,
  Dialog,
  DialogType,
  Divider,
  Icon,
  MAX_TAGS_AND_NOTES_HEIGHT,
  ProspectDetails,
  TagsAndNotesCell,
  TextButton,
  Typography,
  TypographySize,
} from '../../../components';
import { useAppDispatch, useAppSelector, useHandleApiResponse } from '../../../hooks';
import { setIsPromptEdited, setPersonaPrompt } from '../../../redux/reducers';
import { useRegeneratePromptMutation } from '../../../services';
import { ComponentSize, Orientation, ProspectPageMode, ProspectPageTabs } from '../../../types';
import CreateEndActions from './CreateEndActions';
import EditEndActions from './EditEndActions';
import ViewEndActions from './ViewEndActions';

const REGENERATE_PROMPT_ERROR_MSG = 'Failed to regenerate prompt';

interface ProspectPageFooterProps {
  activeTab: ProspectPageTabs;
  visitedTabs: Set<ProspectPageTabs>;
  resetForm: () => void;
  setActiveTab: Dispatch<SetStateAction<ProspectPageTabs>>;
  setVisitedTabs: Dispatch<SetStateAction<Set<ProspectPageTabs>>>;
}

const ProspectPageFooter = ({
  activeTab,
  visitedTabs,
  resetForm,
  setActiveTab,
  setVisitedTabs,
}: ProspectPageFooterProps) => {
  const [isRegeneratePromptOpen, setIsRegeneratePromptOpen] = useState(false);

  const dispatch = useAppDispatch();
  const {
    personaId,
    mode,
    tags,
    notes,
    fields: { firstName, lastName, accountName, jobTitle },
  } = useAppSelector((state) => state.prospect);

  const handleApiResponse = useHandleApiResponse();

  const [regeneratePrompt, { isLoading: isRegeneratingPrompt }] = useRegeneratePromptMutation();

  const activeTabIndex = Object.values(ProspectPageTabs).indexOf(activeTab);

  const showProspectDetails =
    mode !== ProspectPageMode.CREATE ||
    activeTabIndex > Object.values(ProspectPageTabs).indexOf(ProspectPageTabs.PERSONAL);

  const showJobTitle =
    mode !== ProspectPageMode.CREATE || activeTabIndex > Object.values(ProspectPageTabs).indexOf(ProspectPageTabs.LEAD);

  const showAccountName =
    mode !== ProspectPageMode.CREATE ||
    activeTabIndex > Object.values(ProspectPageTabs).indexOf(ProspectPageTabs.ACCOUNT);

  const showTagsAndNotes =
    mode !== ProspectPageMode.CREATE ||
    activeTabIndex >= Object.values(ProspectPageTabs).indexOf(ProspectPageTabs.SCENARIO);

  // Handles regenerating the prompt.
  // If regenerating the prompt is successful, we go to the prompt tab.
  const handleRegenerate = useCallback(async () => {
    if (!personaId) return;
    try {
      const response = await regeneratePrompt(personaId);
      handleApiResponse({
        response,
        errorMsg: REGENERATE_PROMPT_ERROR_MSG,
        onSuccess: (newData) => {
          dispatch(setPersonaPrompt({ value: newData.prospect.personaPrompt }));
          dispatch(setIsPromptEdited(newData.prospect.isPromptEdited));
          setIsRegeneratePromptOpen(false);
          setActiveTab(ProspectPageTabs.PROMPT);
        },
      });
    } catch (error) {
      console.error(REGENERATE_PROMPT_ERROR_MSG, error);
    }
  }, [personaId, dispatch, handleApiResponse, regeneratePrompt]);

  return (
    <>
      <div className="flex w-full border-t border-base-100 p-8">
        <div className="flex w-full items-center justify-end" style={{ height: MAX_TAGS_AND_NOTES_HEIGHT }}>
          <div className="flex h-full w-full items-center justify-between gap-8">
            {showProspectDetails && (
              <ProspectDetails
                firstName={firstName.value}
                lastName={lastName.value}
                accountName={showAccountName ? accountName.value : undefined}
                jobTitle={showJobTitle ? jobTitle.value : undefined}
              />
            )}
            {showTagsAndNotes && (
              <div className="flex h-full flex-1 gap-8">
                <Divider type={Orientation.VERTICAL} />
                {personaId && <TagsAndNotesCell prospectId={personaId} prospectTags={tags} notes={notes} />}
                {/* TODO: Add tags and notes. */}
                {!personaId && <Badge label="Add tags and notes" outline startIcon={Icon.PLUS} />}
              </div>
            )}
            {/* Takes up space when both prospect details and tags and notes are hidden. */}
            {!showProspectDetails && !showTagsAndNotes && <div className="flex-grow" />}
            <div className="flex h-full gap-8">
              {showTagsAndNotes && <Divider type={Orientation.VERTICAL} />}
              {mode === ProspectPageMode.CREATE && (
                <CreateEndActions
                  activeTab={activeTab}
                  visitedTabs={visitedTabs}
                  setActiveTab={setActiveTab}
                  setVisitedTabs={setVisitedTabs}
                />
              )}
              {mode === ProspectPageMode.EDIT && (
                <EditEndActions resetForm={resetForm} setIsRegeneratePromptOpen={setIsRegeneratePromptOpen} />
              )}
              {mode === ProspectPageMode.VIEW && <ViewEndActions />}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        isOpen={isRegeneratePromptOpen}
        onClose={() => setIsRegeneratePromptOpen(false)}
        title="Regenerate prompt"
        type={DialogType.CONFIRM}
      >
        <div className="flex flex-col gap-4">
          <Typography size={TypographySize.H5}>Changes are saved. Do you want the prompt to be regenerated?</Typography>
          <ButtonGroup size={ComponentSize.MEDIUM} loading={isRegeneratingPrompt}>
            <TextButton text="Regenerate prompt" onClick={handleRegenerate} color={ButtonColor.PRIMARY} />
            <TextButton text="Save without regenerating" onClick={() => setIsRegeneratePromptOpen(false)} />
          </ButtonGroup>
        </div>
      </Dialog>
    </>
  );
};

export default ProspectPageFooter;
