import { useAppDispatch } from '../../../../hooks';
import { randomizeAccount } from '../../../../redux/reducers';
import TabSection from '../TabSection';
import CompanyFields from './CompanyFields';
import LocationFields from './LocationFields';
import ResourceFields from './ResourceFields';

const AccountTab = () => {
  const dispatch = useAppDispatch();

  return (
    <TabSection title="Account" onRandomize={() => dispatch(randomizeAccount())}>
      <CompanyFields />
      <LocationFields />
      <ResourceFields />
    </TabSection>
  );
};

export default AccountTab;
