import { User as Auth0User } from '@auth0/auth0-react';
import { ApiSuccess } from './api.types';
import { Permissions } from './auth0.types';
import { AppOrganization } from './organization.types';
import { TeamData } from './team.types';

export enum Roles {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  SALES_REP = 'SALES_REP',
}

/**
 * Represents a user within the application,
 * with properties adapted to the application's naming conventions and usage.
 */
export type AppUser = {
  id: string;
  disabled?: boolean;
  createdAt?: string;
  email?: string;
  name?: string;
  permissions?: Permissions[];
  picture?: string;
  role?: Roles;
  intercomHmac?: string;
  teamMembers?: TeamData[];
};

export type DBUser = {
  id: string;
  email: string;
  name: string;
  picture: string;
  createdAt: string;
  updatedAt: string;
};

export type OrgMember = DBUser & {
  role: Roles;
  disabled: boolean;
  onboarded: boolean;
};

export type UsersParams = {
  orgId?: string;
  disableImpersonation?: boolean;
};

/** Represents the data returned from the API for users after transformation. */
export type UsersForSelectResponseData = {
  users: AppUser[];
  phoneNumbers?: string[];
};

export enum RoleFilterType {
  LOWER_HIERARCHY_ROLES = 'LOWER_HIERARCHY_ROLES',
  SPECIFY_ROLES = 'SPECIFY_ROLES',
}

/**
 * Parameters for fetching users for select.
 * If roleFilterType is SPECIFY_ROLES, roles must be provided.
 */
export type UsersForSelectParams = {
  roleFilterType?: RoleFilterType;
  roles?: Roles[];
};

/** Represents the data returned from the API for users before transformation. */
export type UsersForSelectResponse = {
  data: {
    phoneNumbers?: string[];
    users: DBUser[];
  };
};

export type ImpersonateUserParams = {
  orgId: string;
  impersonateUserId: string;
};

export type ImpersonatedUserData = AppUser & {
  organization: AppOrganization;
};

export type ImpersonatedUserResponse = ApiSuccess<Auth0User & { organization: AppOrganization }>;
