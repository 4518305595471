import clsx from 'clsx';
import { Typography } from '../Typography';
import { RadioProps } from './Radio.types';
import { STATUS_COLOR_TO_RADIO_COLOR } from './Radio.constants';

const Radio = ({ value, label, color, checked, disabled, onChange }: RadioProps) => {
  return (
    <div className="flex items-center gap-2">
      <input
        type="radio"
        value={value}
        checked={checked}
        onChange={onChange}
        className={clsx('radio', color && STATUS_COLOR_TO_RADIO_COLOR[color])}
        disabled={disabled}
      />
      <Typography>{label}</Typography>
    </div>
  );
};

export default Radio;
