import { BasePracticeProspect } from '../types';

/** Generates the email content for sending an invitation to a hiring candidate. */
export function generateCandidateEmailContent(
  candidateName: string,
  candidatePhoneNumber: string,
  orgName: string,
  prospect: BasePracticeProspect
): string {
  return `Hi ${candidateName},

During this hiring screen, you will call an AI Practice Prospect and pitch ${orgName}.

What you need to know:
1. You can make up to three calls to AI Practice Prospect
2. You must call from your own phone (phone number ${candidatePhoneNumber})
3. Your practice prospect is named ${prospect.firstName} ${prospect.lastName}. They work at ${prospect.accountName} as a ${prospect.jobTitle}. Your goal is to book a meeting.
`;
}

/** Replaces <br /> with new lines for display in the text area. */
export const getDisplayedContent = (message: string) => {
  return message.replace(/<br \/>/g, '\n');
};

/** Replaces new lines with <br /> for sending email content in HTML format. */
export const getFormattedContent = (message: string) => {
  return message.replace(/\n/g, '<br />');
};
