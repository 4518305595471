export const getInputClasses = (disabled?: boolean, error?: boolean | string) => {
  const classes = 'flex items-center gap-2 !outline-none';

  if (disabled) {
    return classes + ' cursor-auto text-neutral';
  } else if (error) {
    return classes + ' !border-error-content bg-error';
  } else {
    return classes + ' border-base-300 bg-base-0';
  }
};
