import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, useHandleApiResponse, useToast } from '../../../../../hooks';
import { updateOrganization } from '../../../../../redux/reducers';
import { useSubmitGongAPIKeyMutation } from '../../../../../services';
import { GongAuthMethod, TextColor } from '../../../../../types';
import { isValidURL } from '../../../../../utils';
import { AlertType, TextInput, Typography } from '../../../../shared';
import ConnectionButton from './ConnectionButton';
import IntegrationSectionHeader from './IntegrationSectionHeader';

const ERROR_MSG = 'Connection to Gong failed';
const SECRET_KEY_HIDDEN_CHARS_NUMBER = 42;

const ConnectGongAPIKey = () => {
  const [accessKeyInput, setAccessKeyInput] = useState('');
  const [secretKeyInput, setSecretKeyInput] = useState('');
  const [apiBaseUrlInput, setApiBaseUrlInput] = useState('');

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isFailedConnection, setIsFailedConnection] = useState(false);

  const gongAuth = useAppSelector((state) => state.auth.organization?.gongAuth);
  const isGongConnected = !!gongAuth;
  const { secretKeyLast4, accessKey, apiBaseUrl } = gongAuth || {};

  const dispatch = useAppDispatch();

  const [submitGongAPIKey, { isLoading: isSubmitting }] = useSubmitGongAPIKeyMutation();

  const handleApiResponse = useHandleApiResponse();
  const { showToast } = useToast();

  useEffect(() => {
    if (secretKeyLast4 && accessKey && apiBaseUrl) {
      setSecretKeyInput('*'.repeat(SECRET_KEY_HIDDEN_CHARS_NUMBER) + secretKeyLast4);
      setAccessKeyInput(accessKey);
      setApiBaseUrlInput(apiBaseUrl);
    }
  }, [secretKeyLast4, accessKey, apiBaseUrl]);

  // Reset the inputs
  const resetInputs = useCallback(() => {
    setAccessKeyInput('');
    setSecretKeyInput('');
    setApiBaseUrlInput('');
  }, []);

  // Handle the error response
  const onError = useCallback(() => {
    setIsFailedConnection(true);
    showToast({ message: ERROR_MSG, type: AlertType.ERROR });
  }, [showToast]);

  // Handle the success response
  const onSuccess = useCallback(() => {
    setIsFailedConnection(false);
    showToast({ message: 'Gong connected successfully', type: AlertType.SUCCESS });
    dispatch(
      updateOrganization({
        gongAuth: {
          expiresAt: null,
          authMethod: GongAuthMethod.API_KEY,
          accessKey: accessKeyInput,
          secretKeyLast4: secretKeyInput.slice(-4),
          apiBaseUrl: apiBaseUrlInput,
        },
      })
    );
  }, [dispatch, showToast, secretKeyInput, accessKeyInput, apiBaseUrlInput]);

  // Validate the inputs
  const validateInputs = useCallback(() => {
    const newErrors: Record<string, string> = {
      accessKey: !accessKeyInput ? 'Please enter an access key' : '',
      secretKey: !secretKeyInput ? 'Please enter a secret key' : '',
      apiBaseUrl: !apiBaseUrlInput
        ? 'Please enter a URL'
        : !isValidURL(apiBaseUrlInput)
          ? 'Please enter a valid URL'
          : '',
    };

    // Remove empty error messages
    const filteredErrors = Object.fromEntries(Object.entries(newErrors).filter(([, value]) => value !== ''));

    setErrors(filteredErrors);
    return Object.keys(filteredErrors).length === 0;
  }, [accessKeyInput, apiBaseUrlInput, secretKeyInput]);

  // Handle the API key submission
  const handleSubmitGongAPIKey = useCallback(async () => {
    // Validate the inputs
    if (!validateInputs()) return;

    try {
      const response = await submitGongAPIKey({
        accessKey: accessKeyInput,
        secretKey: secretKeyInput,
        apiBaseUrl: apiBaseUrlInput,
      });
      handleApiResponse({ response, errorMsg: ERROR_MSG, onError, onSuccess });
    } catch (error) {
      onError();
    }
  }, [submitGongAPIKey, handleApiResponse, onError, onSuccess, validateInputs]);

  // Handle the access key change
  const handleOnAccessKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Reset the error
    setErrors({ ...errors, accessKey: '' });
    setAccessKeyInput(e.target.value);
  };

  // Handle the secret key change
  const handleOnSecretKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Reset the error
    setErrors({ ...errors, secretKey: '' });
    setSecretKeyInput(e.target.value);
  };

  // Handle the API base URL change
  const handleOnApiBaseUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Reset the error
    setErrors({ ...errors, apiBaseUrl: '' });
    setApiBaseUrlInput(e.target.value);
  };

  return (
    <div className="flex flex-col gap-4">
      <IntegrationSectionHeader
        title="Gong API Key"
        description={
          <>
            If OAuth is not available, you can provide an API access key instead. See Gong instructions&nbsp;
            <a
              className="underline"
              href="https://help.gong.io/docs/receive-access-to-the-api"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </>
        }
      />
      <div className="flex flex-col gap-1">
        <div className="flex flex-col gap-2">
          <Typography color={TextColor.SECONDARY}>Username / Access Key</Typography>
          <TextInput
            value={accessKeyInput}
            onChange={handleOnAccessKeyChange}
            error={errors.accessKey ?? ''}
            disabled={isGongConnected}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Typography color={TextColor.SECONDARY}>Password / Access Key Secret</Typography>
          <TextInput
            value={secretKeyInput}
            onChange={handleOnSecretKeyChange}
            error={errors.secretKey ?? ''}
            disabled={isGongConnected}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Typography color={TextColor.SECONDARY}>API Base URL</Typography>
          <TextInput
            value={apiBaseUrlInput}
            onChange={handleOnApiBaseUrlChange}
            error={errors.apiBaseUrl ?? ''}
            disabled={isGongConnected}
          />
        </div>
      </div>
      <div className="-mt-2">
        <ConnectionButton
          onConnect={handleSubmitGongAPIKey}
          onDisconnect={resetInputs}
          isConnectingGong={isSubmitting}
          isFailedConnection={isFailedConnection}
          gongAuthMethod={GongAuthMethod.API_KEY}
        />
      </div>
    </div>
  );
};

export default ConnectGongAPIKey;
