import dayjs from 'dayjs';
import { useState } from 'react';
import { DateFormat, DateUnit } from '../../../types';
import { ButtonColor, ButtonVariant, IconButton } from '../Button';
import { Icon } from '../Icons';
import { Typography, TypographyWeight } from '../Typography';
import { DatePickerProps } from './DatePicker.types';
import DatePickerBody from './DatePickerBody';
import DatePickerWeekDays from './DatePickerWeekDays';

const DatePicker = ({ disableFutureDates, range, ...props }: DatePickerProps) => {
  // Initialize the currentMonth state based on range.start if provided.
  const [currentMonth, setCurrentMonth] = useState(dayjs(range?.start) || dayjs());

  // Disable the next month button if disableFutureDates is true and the current month is the same as today.
  const disableNextMonth = disableFutureDates && currentMonth.isSame(dayjs(), DateUnit.MONTH);

  return (
    <div className="flex flex-col gap-2">
      {/* Header */}
      <div className="flex items-center justify-between">
        <IconButton
          onClick={() => setCurrentMonth(currentMonth.subtract(1, DateUnit.MONTH))}
          icon={Icon.CHEVRON_LEFT}
          variant={ButtonVariant.GHOST}
          color={ButtonColor.SECONDARY}
        />
        <Typography weight={TypographyWeight.BOLD}>{currentMonth.format(DateFormat.MONTH_YEAR)}</Typography>
        <IconButton
          onClick={() => setCurrentMonth(currentMonth.add(1, DateUnit.MONTH))}
          icon={Icon.CHEVRON_RIGHT}
          variant={ButtonVariant.GHOST}
          color={ButtonColor.SECONDARY}
          disabled={disableNextMonth}
        />
      </div>
      {/* Week days */}
      <DatePickerWeekDays currMonth={currentMonth} />
      {/* Day cells */}
      {<DatePickerBody currMonth={currentMonth} disableFutureDates={disableFutureDates} range={range} {...props} />}
    </div>
  );
};

export default DatePicker;
