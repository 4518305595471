import { useCallback, useMemo } from 'react';
import { Select, TextInput } from '../../../../components';
import { MULTI_SITE_OPTIONS, REMOTE_OPTIONS } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setHQLocation, setIsMultiSite, setRemoteType } from '../../../../redux/reducers';
import { RemoteType } from '../../../../types';
import ProspectFieldGroup from '../ProspectFieldGroup';

const LOCATION_SELECT_WIDTH = 200;

const LocationFields = () => {
  const dispatch = useAppDispatch();
  const { hqLocation, remoteType, isMultiSite } = useAppSelector((state) => state.prospect.fields);

  const selectedRemoteOption = REMOTE_OPTIONS.find((option) => option.value === remoteType.value);

  const selectedMultiSiteOption = MULTI_SITE_OPTIONS.find((option) => {
    if (isMultiSite.value === undefined) return undefined;
    return option.value === (isMultiSite.value ? 'true' : 'false');
  });

  const handleHQLocationChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newHQLocation = e.target.value;
      dispatch(setHQLocation(newHQLocation));
    },
    [dispatch]
  );

  const handleRemoteChange = useCallback(
    (value?: string) => {
      const newRemote = value as RemoteType;
      dispatch(setRemoteType(newRemote));
    },
    [dispatch]
  );

  const handleMultiSiteChange = useCallback(
    (value?: string) => {
      const newIsMultiSite = value === undefined ? undefined : value === 'true';
      dispatch(setIsMultiSite(newIsMultiSite));
    },
    [dispatch]
  );

  const fields = useMemo(
    () => [
      {
        label: 'HQ location',
        content: <TextInput value={hqLocation.value} onChange={handleHQLocationChange} />,
      },
      {
        label: 'Remote?',
        width: LOCATION_SELECT_WIDTH,
        content: (
          <Select clearable options={REMOTE_OPTIONS} selected={selectedRemoteOption} onChange={handleRemoteChange} />
        ),
      },
      {
        label: 'Multi-site?',
        width: LOCATION_SELECT_WIDTH,
        content: (
          <Select
            clearable
            options={MULTI_SITE_OPTIONS}
            selected={selectedMultiSiteOption}
            onChange={handleMultiSiteChange}
          />
        ),
      },
    ],
    [
      hqLocation,
      handleHQLocationChange,
      handleMultiSiteChange,
      handleRemoteChange,
      selectedMultiSiteOption,
      selectedRemoteOption,
    ]
  );

  return <ProspectFieldGroup fields={fields} />;
};

export default LocationFields;
