import clsx from 'clsx';
import { Radio, Typography, TypographySize, TypographyWeight } from '../../shared';
import { DesignerModalCardProps } from './DesignerModal.types';

const DesignerModalCard = ({ id, title, description, isSelected, onSelect, children }: DesignerModalCardProps) => {
  return (
    <div
      className={clsx(
        'flex cursor-pointer items-center gap-4 rounded-lg border p-4',
        isSelected ? 'border-primary bg-green-50' : 'border-transparent'
      )}
      onClick={onSelect}
    >
      <Radio checked={isSelected} value={id} onChange={onSelect} />
      <div className="flex w-full flex-col gap-4">
        <Typography size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD}>
          {title}
        </Typography>
        <Typography size={TypographySize.H5}>{description}</Typography>
        {children}
      </div>
    </div>
  );
};

export default DesignerModalCard;
