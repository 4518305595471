import KrispSDK, { IAudioFilterNode } from '@krispai/javascript-sdk';
import { useCallback, useRef, useState } from 'react';
import { INBOUND_CHUNK_SIZE, MIC_INPUT_SAMPLE_RATE } from '../constants';
import { TwilioMediaMessage } from '../types';
import { downsampleBuffer, floatTo16BitPCM, linearToMuLaw, uint8ArrayToBase64 } from '../utils/audio.utils';

const useAudioInput = (startTimeRef: React.MutableRefObject<number>) => {
  // Mute state
  const [isMuted, setIsMuted] = useState(false);
  const [isConnectingToMic, setIsConnectingToMic] = useState(false);
  const [isConnectedToMic, setIsConnectedToMic] = useState(false);

  const mutedRef = useRef(false);

  const inputRef = useRef<MediaStreamAudioSourceNode | null>(null);
  const processorNodeRef = useRef<AudioWorkletNode | null>(null);

  // For inbound (microphone) audio
  const micAudioContextRef = useRef<AudioContext | null>(null);

  // For outbound (playback) audio
  const playbackAudioContextRef = useRef<AudioContext | null>(null);

  // Krisp SDK references
  const krispSdkRef = useRef<KrispSDK | null>(null);
  const krispFilterNodeRef = useRef<IAudioFilterNode | null>(null);

  // References to store accumulated data and counters
  const downsampledBufferRef = useRef(new Float32Array());
  const chunkCounterRef = useRef(0);
  const sequenceNumberRef = useRef(0);

  const mute = useCallback((giveFeedback = true) => {
    if (giveFeedback) {
      setIsMuted(true);
    }
    mutedRef.current = true;
  }, []);

  const unmute = useCallback(() => {
    setIsMuted(false);
    mutedRef.current = false;
  }, []);

  const startAudioInput = useCallback(
    (sendMessageFunctions: ((message: TwilioMediaMessage) => void)[], streamSidRef: React.MutableRefObject<string>) => {
      return new Promise<void>((resolve, reject) => {
        let resolvePromise: (() => void) | null = resolve;

        const initializeAudio = async () => {
          let stream;
          try {
            setIsConnectingToMic(true);
            stream = await navigator.mediaDevices.getUserMedia({
              audio: {
                noiseSuppression: true, // Disable browser's built-in noise suppression
                echoCancellation: true, // Enable echo cancellation
                autoGainControl: true,
              },
            });

            const micInputSampleRate = stream.getAudioTracks()[0].getSettings().sampleRate || MIC_INPUT_SAMPLE_RATE;

            // Initialize AudioContext
            const AudioContextConstructor = window.AudioContext || window.webkitAudioContext;
            if (!AudioContextConstructor) {
              throw new Error('Web Audio API is not supported in this browser');
            }

            micAudioContextRef.current = new AudioContextConstructor({
              sampleRate: micInputSampleRate,
            });

            playbackAudioContextRef.current = new AudioContextConstructor();

            const audioContext = micAudioContextRef.current;

            if (!audioContext) {
              throw new Error('AudioContext is not initialized');
            }

            // Resume AudioContext before initializing Krisp SDK
            if (audioContext.state === 'suspended') {
              await audioContext.resume();
            }

            // Load the AudioWorklet module
            await audioContext.audioWorklet.addModule('/krisp-processor.js');

            // Create the AudioWorkletNode
            processorNodeRef.current = new AudioWorkletNode(audioContext, 'krisp-processor');

            // Initialize Krisp SDK
            console.log('Initializing Krisp SDK...');
            krispSdkRef.current = new KrispSDK({
              params: {
                logProcessStats: false,
                debugLogs: false,
                models: {
                  model8: `${process.env.REACT_APP_PUBLIC_BUCKET_BASE_URL}/krisp-models/model_8.kw`,
                  model16: `${process.env.REACT_APP_PUBLIC_BUCKET_BASE_URL}/krisp-models/model_16.kw`,
                  model32: `${process.env.REACT_APP_PUBLIC_BUCKET_BASE_URL}/krisp-models/model_32.kw`,
                },
                useSharedArrayBuffer: false,
              },
            });

            // Initialize SDK (do not await)
            krispSdkRef.current.init();
            console.log('Krisp SDK initialized.');

            // Create Krisp noise filter node
            const krispReadyPromise = new Promise<void>((resolve) => {
              krispSdkRef.current!.createNoiseFilter(audioContext, (event: Event) => {
                const filterNode = event.target as IAudioFilterNode;
                krispFilterNodeRef.current = filterNode;
                console.log('Krisp SDK is ready');
                resolve();
              });
            });

            // Wait for the Krisp SDK to be ready before proceeding
            await krispReadyPromise;

            // Create a MediaStreamSource from the microphone input
            inputRef.current = audioContext.createMediaStreamSource(stream);

            // Connect the nodes: Microphone -> Krisp Filter -> Processor Node
            inputRef.current.connect(krispFilterNodeRef.current!);
            krispFilterNodeRef.current!.connect(processorNodeRef.current!);

            processorNodeRef.current.port.onmessage = (event) => {
              const message = event.data;
              if (message.type === 'processor-initialized') {
                console.log('AudioWorkletProcessor initialized');
              } else {
                // Resolve the promise after the first packet is sent
                if (resolvePromise) {
                  setIsConnectingToMic(false);
                  setIsConnectedToMic(true);
                  resolvePromise();
                  resolvePromise = null;
                  return;
                }

                if (startTimeRef.current === 0) {
                  return;
                }
                const inputData = message.data as Float32Array;

                // Apply noise gate if needed (optional)
                // const processedData = applyNoiseGate(inputData);

                // Downsample to 8000Hz
                const downsampledData = downsampleBuffer(inputData, audioContext.sampleRate, 8000);

                // Accumulate downsampled data
                const combinedDownsampledData = new Float32Array(
                  downsampledBufferRef.current.length + downsampledData.length
                );
                combinedDownsampledData.set(downsampledBufferRef.current);
                combinedDownsampledData.set(downsampledData, downsampledBufferRef.current.length);
                downsampledBufferRef.current = combinedDownsampledData;

                // Process in chunks of 160 samples (20ms at 8000Hz)
                while (downsampledBufferRef.current.length >= INBOUND_CHUNK_SIZE) {
                  // Extract 160 samples
                  const chunkData = downsampledBufferRef.current.slice(0, INBOUND_CHUNK_SIZE);

                  // Remove processed samples
                  downsampledBufferRef.current = downsampledBufferRef.current.slice(INBOUND_CHUNK_SIZE);

                  let muLawData: Uint8Array;

                  if (mutedRef.current) {
                    // Generate silent mu-law data when muted
                    muLawData = new Uint8Array(160); // 160 bytes for 20ms at 8000Hz
                    muLawData.fill(0xff); // Mu-law silence
                  } else {
                    // Convert to 16-bit PCM
                    const pcm16Data = floatTo16BitPCM(chunkData);

                    // Convert to μ-Law (8-bit)
                    muLawData = linearToMuLaw(pcm16Data);

                    // Ensure the muLawData is 160 bytes
                    if (muLawData.length !== 160) {
                      console.warn(`Unexpected muLawData length: ${muLawData.length}`);
                    }
                  }

                  // Prepare and send data
                  const base64Data = uint8ArrayToBase64(muLawData);
                  const timestamp = Date.now() - startTimeRef.current;
                  const message: TwilioMediaMessage = {
                    event: 'media',
                    streamSid: streamSidRef.current,
                    sequenceNumber: sequenceNumberRef.current.toString(),
                    media: {
                      payload: base64Data,
                      track: 'inbound',
                      timestamp: timestamp.toFixed(0),
                      chunk: chunkCounterRef.current.toString(),
                    },
                  };

                  // Send message to all provided sendMessage functions
                  sendMessageFunctions.forEach((sendMessage) => {
                    sendMessage(message);
                  });
                  chunkCounterRef.current++;
                  sequenceNumberRef.current++;
                }
              }
            };
          } catch (error) {
            await resetAudioInput();
            console.error('Error accessing microphone or initializing Krisp SDK:', error);
            reject(error);
          }
        };

        initializeAudio();
      });
    },
    [downsampleBuffer, floatTo16BitPCM, linearToMuLaw, uint8ArrayToBase64]
  );

  const resetAudioInput = useCallback(async () => {
    // Close mic audio connection
    if (inputRef.current) {
      inputRef.current.disconnect();
      const tracks = inputRef.current.mediaStream?.getTracks();
      tracks?.forEach((track) => track.stop());
      inputRef.current = null;
    }

    // Disconnect and dispose of the AudioWorkletNode
    if (processorNodeRef.current) {
      processorNodeRef.current.port.postMessage({ command: 'stop' });
      processorNodeRef.current.disconnect();
      processorNodeRef.current = null;
    }

    // Disconnect and close other audio components

    // Close the AudioContext if it's not already closed
    if (micAudioContextRef.current && micAudioContextRef.current.state !== 'closed') {
      await micAudioContextRef.current.close();
    }
    micAudioContextRef.current = null;

    // Dispose Krisp SDK
    if (krispFilterNodeRef.current) {
      krispFilterNodeRef.current.disconnect();
      krispFilterNodeRef.current.dispose?.();
      krispFilterNodeRef.current = null;
    }

    if (krispSdkRef.current) {
      krispSdkRef.current.dispose();
      krispSdkRef.current = null;
    }

    // Reset counters and buffers
    chunkCounterRef.current = 0;
    sequenceNumberRef.current = 0;
    downsampledBufferRef.current = new Float32Array();

    // Reset state
    setIsConnectingToMic(false);
    setIsConnectedToMic(false);
    setIsMuted(false);
    mutedRef.current = false;
  }, []);

  return {
    isConnectingToMic,
    isConnectedToMic,
    startAudioInput,
    resetAudioInput,
    playbackAudioContextRef,
    isMuted,
    mute,
    unmute,
  };
};

export default useAudioInput;
