import { AlertType, ButtonColor, ButtonVariant, Icon, IconButton, TextButton } from '../../../components';
import { useToast } from '../../../hooks';

const ViewEndActions = () => {
  const { showToast } = useToast();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    showToast({ message: 'Link to prospect copied to clipboard', type: AlertType.SUCCESS });
  };

  // TODO: Start web call.
  const handleCall = () => {};

  return (
    <div className="flex gap-2">
      <IconButton
        color={ButtonColor.SECONDARY}
        icon={Icon.COPY}
        variant={ButtonVariant.GHOST}
        onClick={handleCopyLink}
      />
      <TextButton
        text="Call now"
        onClick={handleCall}
        disabled // TODO: Enable calling.
      />
    </div>
  );
};

export default ViewEndActions;
