import { useEffect } from 'react';
import { FilterOptionsAndSelections, useGetFiltersFromParams, useUpdateFilterParams } from '../../hooks';
import { useGetSavedViewsQuery } from '../../services';
import { Page, PracticeFilterKeys, PracticeFilters as PracticeFiltersType } from '../../types';
import { SidebarDateFilter, SidebarSearchFilter, SidebarSelectFilter } from '../shared';

interface PracticeFiltersProps {
  accountOptionsAndSelections: FilterOptionsAndSelections;
  prospectOptionsAndSelections: FilterOptionsAndSelections;
  tagOptionsAndSelections: FilterOptionsAndSelections;
}

const PracticeFilters = ({
  accountOptionsAndSelections,
  prospectOptionsAndSelections,
  tagOptionsAndSelections,
}: PracticeFiltersProps) => {
  // Custom hooks to retrieve and update filters.
  const filters: PracticeFiltersType = useGetFiltersFromParams();
  const updateFilterParams = useUpdateFilterParams();

  const { data: savedViews = [], isLoading: isSavedViewsLoading } = useGetSavedViewsQuery();
  const savedView = savedViews.find((view) => view.page === Page.PRACTICE_PROSPECTS);

  // Apply default filters when the page loads
  // only if there are no filters already applied.
  useEffect(() => {
    const hasFilters = Object.keys(filters).length > 0;
    if (isSavedViewsLoading || hasFilters || !savedView) return;

    Object.entries(savedView.filters).forEach(([key, value]) => updateFilterParams(key as PracticeFilterKeys, value));
  }, [isSavedViewsLoading]);

  return (
    <>
      {/* Search filter for prospects */}
      <SidebarSearchFilter
        onSearchChange={(newValue) => updateFilterParams(PracticeFilterKeys.SEARCH, newValue)}
        placeholder="Search keyword"
        value={filters.search}
      />
      {/* Prospect filter */}
      <SidebarSelectFilter
        title="Prospect"
        placeholder="Select prospect"
        onChange={(newValue) => updateFilterParams(PracticeFilterKeys.PROSPECT, newValue)}
        {...prospectOptionsAndSelections}
      />
      {/* Prospect Account filter */}
      <SidebarSelectFilter
        title="Prospect account"
        placeholder="Select prospect account"
        onChange={(newValue) => updateFilterParams(PracticeFilterKeys.PROSPECT_ACCOUNT, newValue)}
        {...accountOptionsAndSelections}
      />
      {/* Tags filter */}
      <SidebarSelectFilter
        title="Tags"
        placeholder="Select tag"
        onChange={(newValue) => updateFilterParams(PracticeFilterKeys.TAGS, newValue)}
        {...tagOptionsAndSelections}
      />
      {/* Date Created filter */}
      <SidebarDateFilter
        title="Date created"
        onChange={(newValue) => updateFilterParams(PracticeFilterKeys.DATE_CREATED, newValue)}
        selected={filters.dateCreated}
      />
    </>
  );
};

export default PracticeFilters;
