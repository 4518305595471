import { useCallback, useEffect, useState } from 'react';
import { useGetCallScoreAnalyticsMutation, useGetScorecardTemplatesForSelectQuery } from '../../../services';
import { ProgressReportCard } from './ProgressReportCard';
import { AnalyticsFilters, AverageByPeriod, DateFormat } from '../../../types';
import { findOptionByValue, LineChartKeys } from '../../../components';
import { getAIScorecardTemplateOptions } from '../../../utils';
import dayjs from 'dayjs';

const SCORE_BENCHMARK = {
  value: 100,
  label: 'Goal: 100%',
};

interface CallScoreChartProps {
  filters: AnalyticsFilters;
}

const CallScoreChart = ({ filters }: CallScoreChartProps) => {
  // State for selected scorecard template
  const [selectedScorecardTemplate, setSelectedScorecardTemplate] = useState<string | undefined>(undefined);

  // Fetch scorecard templates
  const { data: scorecardTemplates = [], isLoading: isLoadingScorecardTemplates } =
    useGetScorecardTemplatesForSelectQuery();

  // Get AI scorecard template options
  const scorecardTemplateOptions = getAIScorecardTemplateOptions(scorecardTemplates);

  // Fetch call score analytics
  const [getCallScoreAnalytics, { data: callScoreAnalytics = [], isLoading: isLoadingCallScoreAnalytics }] =
    useGetCallScoreAnalyticsMutation();

  // Set first AI scorecard template as default
  useEffect(() => {
    if (scorecardTemplateOptions.length && !selectedScorecardTemplate) {
      setSelectedScorecardTemplate(scorecardTemplateOptions[0]?.value);
    }
  }, [scorecardTemplateOptions]);

  // Fetch call score analytics
  const fetchCallScoreAnalytics = useCallback(() => {
    // If any of the required fields are missing, return
    if (!filters.user || !filters.averageBy || !filters.dateRange || !selectedScorecardTemplate) return;

    getCallScoreAnalytics({
      dateRange: [filters.dateRange],
      tags: filters.tags,
      prospectIds: filters.prospect,
      groupBy: filters.averageBy?.[0] as AverageByPeriod,
      userId: filters.user?.[0],
      scorecardTemplateId: selectedScorecardTemplate,
    });
  }, [getCallScoreAnalytics, filters, selectedScorecardTemplate]);

  // Fetch call score analytics
  useEffect(() => {
    fetchCallScoreAnalytics();
  }, [fetchCallScoreAnalytics]);

  // Transform call score analytics data
  const callScoreData = callScoreAnalytics.map((item) => ({
    [LineChartKeys.DATE]: item.date,
    [LineChartKeys.AVG]: (item.avg_score ?? 0) * 100,
  }));

  // Calculate total average call score
  const totalAverageCallScore = callScoreData.length
    ? Math.round(callScoreData.reduce((acc, item) => acc + item.avg, 0) / callScoreData.length)
    : undefined;

  // Find selected scorecard template
  const selectedScorecardTemplateOption = findOptionByValue(scorecardTemplateOptions, selectedScorecardTemplate);

  return (
    <ProgressReportCard
      benchmark={SCORE_BENCHMARK}
      title="Average call score"
      value={totalAverageCallScore ? `${totalAverageCallScore}%` : undefined}
      data={callScoreData}
      xAxis={{ formatter: (value) => dayjs(value).format(DateFormat.MONTH_DAY) }}
      yAxis={{ label: 'Score(%)', formatter: (value: string) => `${value}%` }}
      selectOptions={scorecardTemplateOptions}
      selectedOption={selectedScorecardTemplateOption}
      onSelectOption={setSelectedScorecardTemplate}
      isLoading={isLoadingCallScoreAnalytics || isLoadingScorecardTemplates}
    />
  );
};

export default CallScoreChart;
