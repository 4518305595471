import { ChangeEvent, useCallback } from 'react';
import { TextArea, Typography, TypographySize } from '../../../components';
import { useAppDispatch, useAppSelector, useHandleApiResponse } from '../../../hooks';
import { setIsPromptEdited, setPersonaPrompt } from '../../../redux/reducers';
import { useRegeneratePromptMutation } from '../../../services';
import { ComponentSize, ProspectPageMode } from '../../../types';
import TabSection from './TabSection';

const PROMPT_MIN_ROWS = 20;

const PromptTab = () => {
  const dispatch = useAppDispatch();
  const {
    mode,
    personaId,
    personaPrompt,
    fields: { promptTemplateId },
  } = useAppSelector((state) => state.prospect);
  const promptValue = personaPrompt?.value;

  const handleApiResponse = useHandleApiResponse();
  const [regeneratePrompt, { isLoading: isRegeneratingPrompt }] = useRegeneratePromptMutation();

  // We can only regenerate the prompt if there is a prompt template.
  // Handles the case where an old practice prospect that does not have a prompt template set is being viewed.
  const canRegeneratePrompt = !!promptTemplateId;

  const handleRegenerate = useCallback(async () => {
    if (!personaId) return;

    const response = await regeneratePrompt(personaId);
    handleApiResponse({
      response,
      errorMsg: 'Failed to regenerate prompt',
      onSuccess: (newData) => {
        dispatch(setPersonaPrompt({ value: newData.prospect.personaPrompt }));
        dispatch(setIsPromptEdited(newData.prospect.isPromptEdited));
      },
    });
  }, [personaId, regeneratePrompt, dispatch, handleApiResponse]);

  // Handles updating the prompt value and sets the isUserModified flag to true.
  const handlePromptChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setPersonaPrompt({ value: e.target.value, isUserModified: true }));
  };

  return (
    <TabSection
      title="Prompt"
      isRegenerating={isRegeneratingPrompt}
      onRegenerate={canRegeneratePrompt ? handleRegenerate : undefined}
    >
      {mode === ProspectPageMode.VIEW && <Typography size={TypographySize.H5}>{promptValue}</Typography>}
      {mode === ProspectPageMode.EDIT && (
        <TextArea
          autoSize
          disabled={isRegeneratingPrompt}
          size={ComponentSize.MEDIUM}
          value={promptValue}
          onChange={handlePromptChange}
          rows={PROMPT_MIN_ROWS}
        />
      )}
    </TabSection>
  );
};

export default PromptTab;
