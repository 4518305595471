import { useCallback, useEffect, useRef, useState } from 'react';
import { EmptyState, Footer, Icon, Spinner, TextButton } from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setScorecardTemplates } from '../../../redux/reducers';
import { useGetScorecardTemplatesQuery } from '../../../services';
import { ComponentSize, ScorecardTemplate, SortingOrder } from '../../../types';
import ScorecardRow from './ScorecardRow';

interface ScorecardsTableProps {
  onEditScorecard: (scorecard: ScorecardTemplate) => void;
  handleCreateNewScorecard: () => void;
}

const ScorecardsTable = ({ onEditScorecard, handleCreateNewScorecard }: ScorecardsTableProps) => {
  const dispatch = useAppDispatch();
  const scorecardTemplatesRedux = useAppSelector((state) => state.scorecardTemplate.templates);
  const tableRef = useRef<HTMLDivElement>(null);

  // State to scroll to the top of the table when a new scorecard is duplicated
  const [shouldScrollToTop, setShouldScrollToTop] = useState(false);

  // APIs
  const { data: scorecardTemplates, isLoading } = useGetScorecardTemplatesQuery({
    sorting: {
      sortBy: 'updatedAt',
      sortOrder: SortingOrder.DESC,
    },
  });

  const onDuplicateScorecard = useCallback(() => {
    setShouldScrollToTop(true);
  }, []);

  // Initialize Redux state with backend data
  useEffect(() => {
    if (scorecardTemplates && scorecardTemplatesRedux.length === 0) {
      dispatch(setScorecardTemplates(scorecardTemplates));
    }
  }, [scorecardTemplates, dispatch, scorecardTemplatesRedux.length]);

  // Scroll to the top of the table when a new scorecard is duplicated
  useEffect(() => {
    if (shouldScrollToTop && tableRef.current) {
      tableRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      setShouldScrollToTop(false);
    }
  }, [shouldScrollToTop]);

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner size={ComponentSize.MEDIUM} />
      </div>
    );
  }

  return (
    <>
      <div
        className="display-scrollbar-lg mx-auto flex h-full w-[700px] flex-col gap-4 overflow-y-auto pb-6"
        ref={tableRef}
      >
        {!scorecardTemplatesRedux?.length && <EmptyState description="No scorecard templates" icon={Icon.TALLY} />}
        {scorecardTemplatesRedux?.map((scorecard) => (
          <ScorecardRow
            key={scorecard.id}
            scorecard={scorecard}
            onEditScorecard={onEditScorecard}
            onDuplicateScorecard={onDuplicateScorecard}
          />
        ))}
      </div>
      <Footer className="justify-end">
        <TextButton text="Create new" onClick={handleCreateNewScorecard} />
      </Footer>
    </>
  );
};

export default ScorecardsTable;
