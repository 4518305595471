import clsx from 'clsx';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { DateFormat, DateUnit, TextColor } from '../../../types';
import { getStartAndEnd } from '../../../utils';
import { Typography, TypographyWeight } from '../Typography';
import { DatePickerCellProps } from './DatePicker.types';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

/**
 * DatePickerCell component that renders a single date cell.
 */
const DatePickerCell = ({
  currMonth,
  currDate,
  disableClear,
  disableFutureDates,
  isEndOfWeek,
  isStartOfWeek,
  range,
  onChange,
}: DatePickerCellProps) => {
  const { start, end } = range || {};

  // Get the start and end of the current date.
  const { start: startOfCurrDate, end: endOfCurrDate } = getStartAndEnd(currDate, DateUnit.DAY);

  // Check if the current date is within the range of the selected start and end dates.
  const isWithinRange = start && end && currDate.isSameOrAfter(start) && currDate.isSameOrBefore(end);

  // Check if the range is a single day.
  const isSingleDay = start && end && dayjs(start).isSame(end, DateUnit.DAY);

  // Check if the current date is one of the selected dates.
  const isRangeStartDate = start && currDate.isSame(start, DateUnit.DAY);
  const isRangeEndDate = end && currDate.isSame(end, DateUnit.DAY);
  const isSelectedDate = isRangeStartDate || isRangeEndDate;

  // Check if the current date is today.
  const isToday = currDate.isSame(dayjs(), DateUnit.DAY);

  // Check if the current date is in a different month than the current month.
  const isDiffMonth = !currDate.isSame(currMonth, DateUnit.MONTH);

  // Check if the current date is in the future.
  const isFutureDate = currDate.isAfter(dayjs(), DateUnit.DAY);

  // Disable the current date if the disableFutureDates prop is true and the date is in the future.
  const isDisabled = disableFutureDates && isFutureDate;

  // Get the text color based on the selected/disabled states, month difference, and whether the date is today.
  const getTextColor = () => {
    if (isSelectedDate) return TextColor.WHITE;
    if (isDiffMonth || isDisabled) return TextColor.TERTIARY;
    if (isToday) return 'text-primary';
    return undefined;
  };

  // Get the text weight based on the selected state.
  const getTextWeight = () => {
    if (isSelectedDate) return TypographyWeight.BOLD;
    return undefined;
  };

  const formattedDate = currDate.format(DateFormat.DAY);

  const onClickCurrDate = () => {
    // If no date range is selected yet, set the clicked date as both the start and end date,
    // using start of day for start and end of day for end.
    if (!range) {
      onChange({ start: startOfCurrDate, end: endOfCurrDate });
      return;
    }

    // If clicking on the selected start date and clearing is enabled,
    // clear the entire date range.
    if (isRangeStartDate) {
      if (!disableClear) onChange(undefined);
      return;
    }

    // If clicking a date before the selected start date,
    // update the start date to this earlier date and keep the end date unchanged,
    // unless it was a single-day selection in which case make the end date match.
    if (end && currDate.isBefore(start)) {
      const isSingleDay = dayjs(start).isSame(end, DateUnit.DAY);
      onChange({ start: startOfCurrDate, end: isSingleDay ? endOfCurrDate : end });
      return;
    }

    // If clicking on the selected end date,
    // reset the end date to match the start date, making it a single-day selection.
    if (start && isRangeEndDate) {
      const endOfStartDate = dayjs(start).endOf(DateUnit.DAY).toDate();
      onChange({ start, end: endOfStartDate });
      return;
    }

    // If clicking a date that comes after the start date,
    // update the end date to this new clicked date.
    if (start && currDate.isAfter(start)) {
      onChange({ start, end: endOfCurrDate });
    }
  };

  return (
    <div className="relative">
      {/*
        Render green background for dates within selected range.
        Don't render the green background if the range is a single day.
      */}
      {isWithinRange && !isSingleDay && (
        <div
          className={clsx(
            'absolute inset-0 bg-green-50',
            !isSelectedDate && '-mx-0.5',
            isRangeStartDate && '-mr-0.5 rounded-l-md',
            isRangeEndDate && '-ml-0.5 rounded-r-md',
            isStartOfWeek && 'rounded-l-md',
            isEndOfWeek && 'rounded-r-md'
          )}
        />
      )}
      {/* Render the date number with styling for selected/unselected states. */}
      <Typography
        weight={getTextWeight()}
        color={getTextColor()}
        className={clsx(
          'relative flex h-8 w-8 items-center justify-center rounded-md',
          isSelectedDate && 'bg-primary',
          !isSelectedDate && !isWithinRange && 'hover:bg-gray-200',
          isDisabled && 'pointer-events-none'
        )}
        onClick={onClickCurrDate}
      >
        {formattedDate}
      </Typography>
    </div>
  );
};

export default DatePickerCell;
