import { useEffect, useRef, useState } from 'react';
import { Badge, ManageTags, MoreTagsLabel } from '../../../components';
import { useWindowResize } from '../../../hooks';
import { Tag } from '../../../types';
import { TagsRowProps } from './TagsRow.types';

const TAGS_GAP = 8;
// TODO: Dynamically calculate more tags label width
const MORE_TAGS_LABEL_WIDTH = 44;
const ADD_TAGS_BUTTON_WIDTH = 16;

const TagsRow = ({
  enableManageTags,
  tags,
  handleTagClick,
  onApplyTags,
  isApplyingTags = false,
  selectedTags,
  setSelectedTags,
}: TagsRowProps) => {
  const [hiddenTags, setHiddenTags] = useState<Tag[]>([]);
  const [lastVisibleIndex, setLastVisibleIndex] = useState<number>(tags.length - 1);
  const [isManageTagsDropdownOpen, setIsManageTagsDropdownOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const tagsRef = useRef<(HTMLDivElement | null)[]>([]);

  const windowWidth = useWindowResize();

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current && tagsRef.current.length > 0) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const newHiddenTags: Tag[] = [];

        const addTagsButtonWidth = enableManageTags ? ADD_TAGS_BUTTON_WIDTH + TAGS_GAP : 0;
        const moreTagsLabelWidth = MORE_TAGS_LABEL_WIDTH + TAGS_GAP;

        let availableWidth = containerRect.width - (moreTagsLabelWidth + addTagsButtonWidth);
        let newLastVisibleIndex = tags.length - 1;

        for (let i = 0; i < tagsRef.current.length; i++) {
          const tagRef = tagsRef.current[i];

          if (!tagRef) continue;

          const tagRect = tagRef.getBoundingClientRect();

          // Check if the tag fits
          if (tagRect.width > availableWidth) {
            // Save last visible index
            newLastVisibleIndex = i - 1;
            // If it doesn't fit, add this tag and all remaining tags to hidden tags
            newHiddenTags.push(...tags.slice(i));
            break;
          }

          // Reduce available width for the next tag
          availableWidth -= tagRect.width + TAGS_GAP;
        }

        setLastVisibleIndex(newLastVisibleIndex);
        setHiddenTags(newHiddenTags);
      }
    };

    checkOverflow();
  }, [enableManageTags, tags, windowWidth, containerRef.current]);

  const renderManageTagsButton = () =>
    enableManageTags ? (
      <ManageTags
        itemTags={tags}
        isOpen={isManageTagsDropdownOpen}
        setIsOpen={setIsManageTagsDropdownOpen}
        onClose={onApplyTags}
        isLoading={isApplyingTags}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />
    ) : null;

  // If there are no tags, render only the manage tags dropdown if the add tag management is enabled.
  if (!tags.length) return renderManageTagsButton();

  return (
    <div ref={containerRef} className="flex w-full items-center overflow-hidden" style={{ gap: TAGS_GAP }}>
      {tags.map((tag, index) => (
        <div className="flex items-center gap-2" key={tag.id}>
          <div
            ref={(el) => (tagsRef.current[index] = el)}
            // Hide tag by changing opacity to maintain its position in the DOM
            // This allows for accurate overflow detection and smooth transitions
            className={hiddenTags.find((hiddenTag) => hiddenTag.id === tag.id) ? 'opacity-0' : ''}
          >
            <Badge label={tag.name} dotColor={tag.color} onClick={() => handleTagClick?.(tag.id)} showDot />
          </div>
          {/* Only show the "More tags" label if there are hidden tags and it's the last visible tag */}
          {index === lastVisibleIndex && (
            <>
              {hiddenTags.length > 0 && <MoreTagsLabel hiddenTags={hiddenTags} />}
              {renderManageTagsButton()}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default TagsRow;
