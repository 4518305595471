import {
  BarChart,
  BarChartDataPoint,
  Divider,
  Typography,
  TypographySize,
  TypographyWeight,
} from '../../../components';

interface ActivityCardProps {
  title: string;
  value: number;
  chartTitle: string;
  data: BarChartDataPoint[];
  valueFormatter?: (value: number) => string;
}

export const ActivityCard = ({ title, value, chartTitle, data, valueFormatter }: ActivityCardProps) => (
  <div className="flex flex-col rounded-md border">
    <div className="flex flex-col gap-1 p-4">
      <Typography size={TypographySize.H5}>{title}</Typography>
      <Typography weight={TypographyWeight.SEMI_BOLD} size={TypographySize.H1}>
        {valueFormatter ? valueFormatter(value) : value}
      </Typography>
    </div>
    <Divider />
    <div className="p-4">
      <BarChart data={data} title={chartTitle} valueFormatter={valueFormatter} />
    </div>
  </div>
);
