import { useEffect, useMemo } from 'react';
import {
  ANALYTICS_ACTIVE_TAB_PARAM,
  AVERAGE_BY_OPTIONS,
  DEFAULT_ANALYTICS_AVERAGE_BY,
  DEFAULT_ANALYTICS_DATE_RANGE,
} from '../../constants';
import { FilterOptionsAndSelections, useGetFiltersFromParams, useUpdateFilterParams } from '../../hooks';
import { useGetUsersForSelectQuery } from '../../services';
import { AnalyticsFilterKeys, AnalyticsFilters as AnalyticsFiltersType, AnalyticsTab } from '../../types';
import { getUserOptions } from '../../utils';
import { findOptionByValue, findOptionsByValues, SidebarDateFilter, SidebarSelectFilter } from '../shared';

interface AnalyticsFiltersProps {
  tagOptionsAndSelections: FilterOptionsAndSelections;
  prospectOptionsAndSelections: FilterOptionsAndSelections;
}

const AnalyticsFilters = ({ tagOptionsAndSelections, prospectOptionsAndSelections }: AnalyticsFiltersProps) => {
  const searchParams = new URLSearchParams(location.search);

  const analyticsActiveTab = searchParams.get(ANALYTICS_ACTIVE_TAB_PARAM);
  const isProgressReportTab = analyticsActiveTab === AnalyticsTab.PROGRESS_REPORT;

  // Retrieve filters from URL parameters.
  const filters: AnalyticsFiltersType = useGetFiltersFromParams();

  // Hook to update filter parameters in the URL.
  const updateFilterParams = useUpdateFilterParams();

  // Fetch users and phone numbers for the user options.
  const { data: userData, isLoading: isLoadingUsers } = useGetUsersForSelectQuery({});

  // Memoize user and prospect options to avoid unnecessary recalculations.
  const userOptions = useMemo(() => getUserOptions({ users: userData?.users || [] }), [userData]);

  // Find the selected user option based on the current filters.
  const selectedUsers = useMemo(() => findOptionsByValues(userOptions, filters.user), [userOptions, filters.user]);

  // Find the selected average by option based on the current filters.
  const selectedAverageBy = useMemo(
    () => findOptionByValue(AVERAGE_BY_OPTIONS, filters.averageBy?.[0]),
    [filters.averageBy]
  );

  useEffect(() => {
    // Set the default date range if no date range is selected.
    if (!filters.dateRange) {
      updateFilterParams(AnalyticsFilterKeys.DATE_RANGE, DEFAULT_ANALYTICS_DATE_RANGE);
    }
    // Reset average by if not on progress report tab.
    if (filters.averageBy && !isProgressReportTab) {
      updateFilterParams(AnalyticsFilterKeys.AVERAGE_BY, undefined);
    }
    // Set default average by if on progress report tab.
    if (!filters.averageBy && isProgressReportTab) {
      updateFilterParams(AnalyticsFilterKeys.AVERAGE_BY, DEFAULT_ANALYTICS_AVERAGE_BY);
    }
  }, [isProgressReportTab, updateFilterParams]);

  const sharedUserFilterProps = {
    options: userOptions,
    placeholder: 'Select user',
    title: 'User',
    loading: isLoadingUsers,
  };

  return (
    <>
      {/* Call Date filter */}
      <SidebarDateFilter
        title="Date range"
        onChange={(newValue) => updateFilterParams(AnalyticsFilterKeys.DATE_RANGE, newValue)}
        selected={filters.dateRange}
        disableClear
      />
      {/* User filter */}
      {!isProgressReportTab && (
        <SidebarSelectFilter
          selected={selectedUsers}
          onChange={(newValue) => updateFilterParams(AnalyticsFilterKeys.USER, newValue)}
          {...sharedUserFilterProps}
        />
      )}
      {isProgressReportTab && (
        <>
          <SidebarSelectFilter
            selected={selectedUsers?.[0]}
            error={!filters.user}
            onChange={(newValue) => updateFilterParams(AnalyticsFilterKeys.USER, newValue)}
            multiSelect={false}
            clearable={false}
            {...sharedUserFilterProps}
          />
          <SidebarSelectFilter
            options={AVERAGE_BY_OPTIONS}
            selected={selectedAverageBy}
            title="Average by"
            onChange={(newValue) => updateFilterParams(AnalyticsFilterKeys.AVERAGE_BY, newValue)}
            multiSelect={false}
            clearable={false}
          />
        </>
      )}

      {/* Prospect filter */}
      <SidebarSelectFilter
        placeholder="Select prospect"
        title="Prospect"
        onChange={(newValue) => updateFilterParams(AnalyticsFilterKeys.PROSPECT, newValue)}
        {...prospectOptionsAndSelections}
      />
      {/* Tags filter */}
      <SidebarSelectFilter
        title="Tags"
        placeholder="Select tag"
        onChange={(newValue) => updateFilterParams(AnalyticsFilterKeys.TAGS, newValue)}
        {...tagOptionsAndSelections}
      />
    </>
  );
};

export default AnalyticsFilters;
