import clsx from 'clsx';
import React from 'react';
import { MAX_LINES_TO_CLASSNAME } from '../../../constants';
import { Alignment, TextColor } from '../../../types';
import { getTooltipContent, getTooltipPlacement } from '../../../utils';
import { Tooltip } from '../Tooltip';
import { TYPOGRAPHY_ALIGNMENT, TYPOGRAPHY_SIZE, TYPOGRAPHY_WEIGHT } from './Typography.constants';
import { TypographyProps, TypographySize, TypographyWeight } from './Typography.types';

const Typography = React.forwardRef<HTMLSpanElement, TypographyProps>(
  (
    {
      children,
      alignment = Alignment.LEFT,
      className,
      color = TextColor.PRIMARY,
      italic,
      maxLines,
      mono,
      noWrap,
      size = TypographySize.PARAGRAPH,
      tooltip,
      underline,
      weight = TypographyWeight.NORMAL,
      onClick,
    },
    ref
  ) => {
    const isInteractive = onClick || !!tooltip;

    const handleOnClick = (e: React.MouseEvent<HTMLSpanElement>) => {
      if (onClick) {
        e.stopPropagation();
        onClick(e);
      }
    };

    // Return null if there is no children
    // To avoid typography taking up space in the DOM
    if (children === undefined || children === null) return null;

    return (
      <Tooltip label={getTooltipContent(tooltip)} placement={getTooltipPlacement(tooltip)}>
        <span
          ref={ref}
          className={clsx(
            mono && 'font-mono',
            color,
            TYPOGRAPHY_ALIGNMENT[alignment],
            TYPOGRAPHY_SIZE[size],
            TYPOGRAPHY_WEIGHT[weight],
            underline && 'underline',
            italic && 'italic',
            isInteractive && 'cursor-pointer',
            maxLines && MAX_LINES_TO_CLASSNAME[maxLines],
            noWrap ? 'whitespace-nowrap' : 'whitespace-normal',
            onClick && 'select-none',
            className
          )}
          onClick={handleOnClick}
        >
          {children}
        </span>
      </Tooltip>
    );
  }
);

Typography.displayName = 'Typography';

export default Typography;
