import clsx from 'clsx';
import ProspectPageField, { ProspectPageFieldProps } from './ProspectPageField';

interface ProspectFieldGroupProps {
  fields: ProspectPageFieldProps[];
  spaced?: boolean;
}

const ProspectFieldGroup = ({ fields, spaced }: ProspectFieldGroupProps) => {
  const getWidthProps = (field: ProspectPageFieldProps) => {
    // If the fields are spaced, don't apply any fullWidth props.
    if (spaced) return {};

    // If there is a field that is fullWidth or if the current field has a specific width, don't apply fullWidth.
    const hasFullWidthField = fields.find((f) => f.fullWidth);
    if (hasFullWidthField || field.width) return {};

    // Otherwise, apply fullWidth.
    return { fullWidth: true, width: undefined };
  };

  return (
    <div className={clsx('flex gap-4', spaced && 'justify-between')}>
      {fields.map((field) => (
        <ProspectPageField key={field.label} {...field} {...getWidthProps(field)} />
      ))}
    </div>
  );
};

export default ProspectFieldGroup;
