import clsx from 'clsx';
import Radio from '../Radio/Radio';
import { RadioGroupProps, RadioOption } from './RadioGroup.types';

const RadioGroup = ({ options, value, onChange, className, disabled, isViewMode }: RadioGroupProps) => {
  // If viewMode is true, show only the selected option
  const hideOption = (option: RadioOption) => {
    return isViewMode && value !== option.value;
  };

  return (
    <div className={clsx('flex items-center gap-4', className)}>
      {options.map(
        (option) =>
          !hideOption(option) && (
            <Radio
              key={option.value}
              value={option.value}
              label={option.label}
              color={option.color}
              checked={value === option.value}
              onChange={onChange}
              disabled={disabled}
            />
          )
      )}
    </div>
  );
};

export default RadioGroup;
