import { useMemo } from 'react';
import { getYesNoOptions, RadioGroup, TextInput, YesNoAnswer } from '../../../../components';
import { PROSPECT_TYPE_OPTIONS } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setIsHidden, setIsRestricted } from '../../../../redux/reducers';
import ProspectFieldGroup from '../ProspectFieldGroup';
import TabSection from '../TabSection';

const CallType = () => {
  const dispatch = useAppDispatch();
  const {
    prospectType,
    fields: { isHidden, isRestricted },
  } = useAppSelector((state) => state.prospect);

  const prospectPurpose = PROSPECT_TYPE_OPTIONS.find((option) => option.value === prospectType)?.label;

  const fields = useMemo(
    () => [
      {
        // Show the prospect purpose selected at the very beginning of prospect creation.
        label: 'Prospect purpose',
        content: <TextInput disabled value={prospectPurpose} />,
      },
      {
        // We store internally as "isHidden" but display as "Public?".
        // So we invert the yes/no value when setting isHidden.
        label: 'Public?',
        tooltip: 'Non-public prospects are hidden from representatives.',
        content: (
          <RadioGroup
            options={getYesNoOptions()}
            value={isHidden ? YesNoAnswer.NO : YesNoAnswer.YES}
            onChange={(e) => dispatch(setIsHidden(e.target.value === YesNoAnswer.NO))}
          />
        ),
      },
      {
        // We store internally as "isRestricted" and display as "Is restricted?".
        // So yes/no maps directly to the boolean.
        label: 'Is restricted?',
        content: (
          <RadioGroup
            options={getYesNoOptions()}
            value={isRestricted ? YesNoAnswer.YES : YesNoAnswer.NO}
            onChange={(e) => dispatch(setIsRestricted(e.target.value === YesNoAnswer.YES))}
          />
        ),
      },
    ],
    [prospectPurpose, isHidden, isRestricted]
  );

  return (
    <TabSection title="Call type">
      <ProspectFieldGroup fields={fields} spaced />
    </TabSection>
  );
};

export default CallType;
