import { useCallback, useState } from 'react';
import { ButtonColor, ButtonGroup, ButtonVariant, ConfirmModal, Footer, TextButton } from '../../components';
import { useHandleApiResponse } from '../../hooks';
import {
  useArchivePromptTemplateMutation,
  useCreatePromptTemplateMutation,
  useDeletePromptTemplateMutation,
  useUpdatePromptTemplateMutation,
} from '../../services';
import { CreatePromptTemplatePayload, PromptTemplate, UpdatePromptTemplatePayload } from '../../types';
import PromptTemplateLiquidTags from './PromptTemplateLiquidTags';

const PROMPT_TEMPLATE_ARCHIVE_ERROR_MSG = 'Failed to archive prompt template';
const PROMPT_TEMPLATE_CREATE_ERROR_MSG = 'Failed to create prompt template';
const PROMPT_TEMPLATE_UPDATE_ERROR_MSG = 'Failed to update prompt template';
const PROMPT_TEMPLATE_DELETE_ERROR_MSG = 'Failed to delete prompt template';
interface ManagePromptTemplateFooterProps {
  nameInput: string;
  promptInput: string;
  handleBack: () => void;
  onInsertTag: (tag: string) => void;
  promptTemplate?: PromptTemplate;
}

const ManagePromptTemplateFooter = ({
  nameInput,
  promptInput,
  handleBack,
  onInsertTag,
  promptTemplate,
}: ManagePromptTemplateFooterProps) => {
  const [isArchiveConfirmModalOpen, setIsArchiveConfirmModalOpen] = useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);

  const handleApiResponse = useHandleApiResponse();

  const [archivePromptTemplate, { isLoading: isArchiving }] = useArchivePromptTemplateMutation();
  const [createPromptTemplate, { isLoading: isCreating }] = useCreatePromptTemplateMutation();
  const [deletePromptTemplate, { isLoading: isDeleting }] = useDeletePromptTemplateMutation();
  const [updatePromptTemplate, { isLoading: isUpdating }] = useUpdatePromptTemplateMutation();

  // Disable the save button if the name or prompt is unchanged.
  const isNameChanged = nameInput !== promptTemplate?.name;
  const isPromptChanged = promptInput !== promptTemplate?.prompt;
  const isSaveDisabled = !nameInput || !promptInput || (!isNameChanged && !isPromptChanged);

  // Edit the existing prompt template.
  const runUpdate = useCallback(
    async (payload: UpdatePromptTemplatePayload) => {
      try {
        const response = await updatePromptTemplate(payload);
        handleApiResponse({
          response,
          errorMsg: PROMPT_TEMPLATE_UPDATE_ERROR_MSG,
        });
      } catch (error) {
        console.error(PROMPT_TEMPLATE_UPDATE_ERROR_MSG, error);
      }
    },
    [updatePromptTemplate, handleApiResponse]
  );

  // Create a new prompt template.
  const runCreate = useCallback(
    async (payload: CreatePromptTemplatePayload) => {
      try {
        const response = await createPromptTemplate(payload);
        handleApiResponse({
          response,
          errorMsg: PROMPT_TEMPLATE_CREATE_ERROR_MSG,
          onSuccess: handleBack,
        });
      } catch (error) {
        console.error(PROMPT_TEMPLATE_CREATE_ERROR_MSG, error);
      }
    },
    [createPromptTemplate, handleApiResponse, handleBack]
  );

  // Save the prompt template.
  const handleSave = useCallback(async () => {
    // If the name or prompt is empty, do nothing.
    if (!nameInput || !promptInput) return;

    const payload = { name: nameInput, prompt: promptInput };
    if (promptTemplate) {
      // If the prompt template exists, edit it.
      runUpdate({ id: promptTemplate.id, ...payload });
    } else {
      // Otherwise, create a new prompt template.
      runCreate(payload);
    }
  }, [nameInput, promptInput, promptTemplate, runCreate, runUpdate]);

  // Delete the prompt template.
  const runDelete = useCallback(async () => {
    if (!promptTemplate) return;
    try {
      const response = await deletePromptTemplate(promptTemplate.id);
      handleApiResponse({
        response,
        errorMsg: PROMPT_TEMPLATE_DELETE_ERROR_MSG,
        onSuccess: handleBack,
      });
    } catch (error) {
      console.error(PROMPT_TEMPLATE_DELETE_ERROR_MSG, error);
    }
  }, [deletePromptTemplate, handleApiResponse, handleBack, promptTemplate]);

  // Show the delete confirmation modal.
  const handleDelete = useCallback(async () => {
    if (!promptTemplate) {
      // If the prompt template does not exist (ie. we are creating a new prompt template),
      // just navigate back to the prompt templates page.
      handleBack();
    } else {
      // Otherwise, show the delete confirmation modal.
      setIsDeleteConfirmModalOpen(true);
    }
  }, [handleBack, promptTemplate]);

  // Archive the prompt template.
  const runArchive = useCallback(async () => {
    if (!promptTemplate) return;
    try {
      const response = await archivePromptTemplate(promptTemplate.id);
      handleApiResponse({
        response,
        errorMsg: PROMPT_TEMPLATE_ARCHIVE_ERROR_MSG,
        onSuccess: handleBack,
      });
    } catch (error) {
      console.error(PROMPT_TEMPLATE_ARCHIVE_ERROR_MSG, error);
    }
  }, [archivePromptTemplate, handleApiResponse, handleBack, promptTemplate]);

  // Show the archive confirmation modal.
  const handleArchive = useCallback(() => {
    setIsArchiveConfirmModalOpen(true);
  }, []);

  return (
    <>
      <Footer className="flex items-center justify-between gap-4">
        <PromptTemplateLiquidTags onInsertTag={onInsertTag} />
        <ButtonGroup loading={isCreating || isUpdating}>
          <TextButton disabled={isSaveDisabled} text="Save" color={ButtonColor.PRIMARY} onClick={handleSave} />
          <TextButton
            text={promptTemplate?.isUsed ? 'Archive' : 'Delete'}
            color={ButtonColor.DESTRUCTIVE}
            variant={ButtonVariant.OUTLINE}
            onClick={promptTemplate?.isUsed ? handleArchive : handleDelete}
          />
        </ButtonGroup>
      </Footer>
      <ConfirmModal
        destructive
        isOpen={isArchiveConfirmModalOpen}
        setIsOpen={setIsArchiveConfirmModalOpen}
        onConfirm={runArchive}
        title="Archive prompt template"
        confirmText="Are you sure you want to archive this prompt template? This action cannot be undone."
        buttonText="Archive"
        isLoading={isArchiving}
      />
      <ConfirmModal
        destructive
        isOpen={isDeleteConfirmModalOpen}
        setIsOpen={setIsDeleteConfirmModalOpen}
        onConfirm={runDelete}
        title="Delete prompt template"
        confirmText="Are you sure you want to delete this prompt template? This action cannot be undone."
        buttonText="Delete"
        isLoading={isDeleting}
      />
    </>
  );
};

export default ManagePromptTemplateFooter;
