import { User as Auth0User } from '@auth0/auth0-react';
import { SelectOption } from '../components';
import { GOOGLE_USER_ID_PREFIX } from '../constants';
import { AppUser, Auth0Roles, Roles, SortableUser } from '../types';

/**
 * Gets the top role from a list of roles
 */
export function getTopRole(roles: Roles[]): Roles {
  return roles.sort((a, b) => roles.indexOf(a) - roles.indexOf(b))[0];
}

/**
 * Parses Auth0 user data into application format.
 */
export const getParsedAuth0User = (user: Auth0User): AppUser => {
  // Destructure necessary fields.
  const { created_at, disabled, email, user_id, roles, name, permissions, picture, intercom_hmac } = user;

  const getPicture = () => {
    if (!user_id) return;

    const isGoogleUser = user_id.includes(GOOGLE_USER_ID_PREFIX);
    return isGoogleUser ? picture : undefined;
  };

  const getRole = () => {
    if (!roles) return;

    const parsedRoles = roles.map((role: { id: string; name: Roles } | Roles) =>
      typeof role === 'string' ? role : role.name
    );
    const topRole = getTopRole(parsedRoles);
    return topRole;
  };

  // Create a new object with the keys formatted to follow JavaScript naming conventions
  // and ensure 'name' is undefined if it matches the email (to avoid redundant data).
  const parsedUser = {
    createdAt: created_at,
    disabled,
    email,
    id: user_id,
    role: getRole(),
    name,
    permissions,
    picture: getPicture(),
    intercomHmac: intercom_hmac,
  };

  return parsedUser;
};

/**
 * Checks if a role is higher than another role in the hierarchy
 * @param role - The role to check
 * @param comparedRole - The role to compare against
 * @param isEqual - If true, returns true when roles are equal. Defaults to false
 * @returns True if role is higher than comparedRole (or equal if isEqual=true)
 */
export function isHigherRole(role: Roles, comparedRole?: Roles, isEqual = false): boolean {
  // Convert enum values to numbers directly
  const rolesArray = Object.values(Roles).reverse();
  const roleIndex = rolesArray.indexOf(role);
  const comparedRoleIndex = comparedRole ? rolesArray.indexOf(comparedRole) : -1;

  if (isEqual) return roleIndex >= comparedRoleIndex;
  return roleIndex > comparedRoleIndex;
}

export const transformAuth0Role = (role: Auth0Roles): Roles => {
  if (role === Auth0Roles.SUPER_ADMIN) return Roles.SUPER_ADMIN;
  if (role === Auth0Roles.ADMIN) return Roles.ADMIN;
  if (role === Auth0Roles.MANAGER) return Roles.MANAGER;
  return Roles.SALES_REP;
};

export const sortUsers = (a: SortableUser, b: SortableUser) => {
  // Helper function to get the last name from the full name
  const getLastName = (name: string) => {
    const parts = name.trim().split(' ');
    return parts.length > 1 ? parts[parts.length - 1] : parts[0];
  };

  // Disabled users are always at the bottom
  if (a.disabled && !b.disabled) return 1;
  if (!a.disabled && b.disabled) return -1;

  // Get last names, fallback to email if name is not available
  const lastNameA = a.name ? getLastName(a.name).toLowerCase() : a.email?.toLowerCase() || 0;
  const lastNameB = b.name ? getLastName(b.name).toLowerCase() : b.email?.toLowerCase() || 0;

  if (lastNameA < lastNameB) return -1;
  if (lastNameA > lastNameB) return 1;

  // If last names are equal, fallback to full name or email comparison
  const nameA = a.name?.toLowerCase() || a.email?.toLowerCase() || 0;
  const nameB = b.name?.toLowerCase() || b.email?.toLowerCase() || 0;

  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;

  return 0;
};

/**
 * Splits a full name into first and last name.
 * If there are more than two parts, all parts except the last one are joined as the first name.
 */
export const splitName = (fullName?: string) => {
  if (!fullName?.length) return ['', ''];

  const nameParts = fullName.trim().split(' ');

  if (nameParts.length === 1) {
    return [nameParts[0], ''];
  }

  const lastName = nameParts.pop() as string;
  const firstName = nameParts.join(' ');

  return [firstName, lastName];
};

/**
 * Parses a user object to a Select option.
 */
export const parseUserToOption = (user: AppUser): SelectOption => {
  const label = user.name ? `${user.name} | ${user.email}` : user.email || '';
  return { label, value: user.id };
};
