import { useMemo } from 'react';
import { Dot } from '../../components';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { openRequestReviewModal, openUnflagConfirmModal } from '../../redux/reducers';
import { BaseColor, Call, ComponentSize, DropdownPlacement, Permissions, Roles, StatusColor } from '../../types';

interface FlaggedIndicatorProps {
  call: Call;
  isActive: boolean;
}

const FlaggedIndicator = ({ call, isActive }: FlaggedIndicatorProps) => {
  const dispatch = useAppDispatch();
  const { role, permissions } = useAppSelector((state) => state.auth.user) || {};

  // Check if the user can flag/unflag calls.
  // Checking permissions and not just roles is just an extra security check.
  const canFlag = permissions?.includes(Permissions.FLAG_CALL);
  const canUnflag = role !== Roles.SALES_REP && permissions?.includes(Permissions.UNFLAG_CALL);

  const action = useMemo(() => {
    const action: { tooltip?: string; onClick?: () => void } = {};
    if (canFlag) {
      action.tooltip = 'Change notified managers';
      action.onClick = () => dispatch(openRequestReviewModal({ call }));
    } else if (canUnflag) {
      action.tooltip = 'Unflag this call';
      action.onClick = () => dispatch(openUnflagConfirmModal(call.callSid));
    }
    return action;
  }, [canFlag, canUnflag, call, dispatch]);

  return (
    <Dot
      color={isActive ? BaseColor.NEUTRAL_CONTENT : StatusColor.WARNING}
      tooltip={action.tooltip ? { content: action.tooltip, placement: DropdownPlacement.RIGHT } : undefined}
      onClick={action.onClick}
      size={ComponentSize.X_SMALL}
    />
  );
};

export default FlaggedIndicator;
