/* Generate a unique file id */
export const generateFileId = (file: File): string => {
  return `${file.name}-${file.size}-${file.lastModified}`;
};

/* Format file size to MB or KB */
export const formatFileSize = (bytes: number): string => {
  const kb = bytes / 1024;
  // If file size is greater than 1000KB, return the file size in MB
  return kb >= 1000 ? `${Math.round(kb / 1024)}MB` : `${Math.round(kb)}KB`;
};