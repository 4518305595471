import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../constants';
import { CallProcessingStatus, ComponentSize } from '../../types';
import { ButtonColor, ButtonVariant, Icon, IconButton } from '../shared';

interface ControlButtonsProps {
  callSid: string;
  onClose: () => void;
  processingStatus?: CallProcessingStatus;
}

const ControlButtons = ({ callSid, onClose, processingStatus }: ControlButtonsProps) => {
  const navigate = useNavigate();

  // Opens the call details drawer by navigating to the review page with the callSid as a parameter
  // while preserving existing search params.
  const openDetails = useCallback(
    () =>
      navigate({
        pathname: `${AppRoutes.REVIEW}/${callSid}`,
        search: window.location.search,
      }),
    [callSid, navigate]
  );

  return (
    <div className="z-10 -mb-[0.8px] mr-4 flex w-fit justify-end gap-1 rounded-t-lg border border-b-0 border-gray-200 bg-base-0 p-1">
      {/* Only show the open details button if call processing has completed. */}
      {processingStatus === CallProcessingStatus.PROCESSED && (
        <IconButton
          icon={Icon.MAXIMIZE}
          variant={ButtonVariant.GHOST}
          color={ButtonColor.SECONDARY}
          size={ComponentSize.X_SMALL}
          onClick={openDetails}
          tooltip="Open details"
        />
      )}
      <IconButton
        icon={Icon.CLOSE}
        variant={ButtonVariant.GHOST}
        color={ButtonColor.SECONDARY}
        size={ComponentSize.X_SMALL}
        onClick={onClose}
      />
    </div>
  );
};

export default ControlButtons;
