import { useAppSelector } from '../../../../../hooks';
import { useGetPhoneNumbersQuery } from '../../../../../services';
import { ComponentSize, TextColor } from '../../../../../types';
import { splitName } from '../../../../../utils';
import { Avatar, TextInput, Typography } from '../../../../shared';
import TabHeader from '../TabHeader';
import PhoneNumberSection from './PhoneNumberSection';

const AccountTab = () => {
  const { user } = useAppSelector((state) => state.auth);

  // Fetch phone number data.
  const { data: phoneNumberData } = useGetPhoneNumbersQuery();

  // Ensures a user is loaded before rendering.
  if (!user) return null;

  const [firstName, lastName] = splitName(user.name);
  const userWithPhoneNumbers = { id: user.id, phoneNumbers: phoneNumberData || [] };

  return (
    <div className="flex flex-col gap-4">
      <TabHeader title="Your account" />
      <div className="flex items-center gap-4">
        <Avatar label={user.name} size={ComponentSize.LARGE} imgSrc={user.picture} />
        <div className="flex w-full flex-col gap-2">
          <Typography color={TextColor.SECONDARY}>First name</Typography>
          <TextInput value={firstName} disabled />
        </div>
        <div className="flex w-full flex-col gap-2">
          <Typography color={TextColor.SECONDARY}>Last name</Typography>
          <TextInput value={lastName} disabled />
        </div>
      </div>
      <div className="flex w-full flex-col gap-2">
        <Typography color={TextColor.SECONDARY}>Email</Typography>
        <TextInput value={user.email} disabled />
      </div>
      <PhoneNumberSection user={userWithPhoneNumbers} />
    </div>
  );
};

export default AccountTab;
