import {
  YAxis,
  LineChart as RechartsLineChart,
  Line,
  XAxis,
  ResponsiveContainer,
  ReferenceLine,
  Tooltip,
} from 'recharts';
import { LineChartKeys, LineChartProps } from './LineChart.types';
import { Typography, TYPOGRAPHY_SIZE, TypographySize } from '../Typography';
import { Dot } from '../Dot';
import { TextColor, ComponentSize } from '../../../types';

const DOT_RADIUS = 5;
const CHART_HEIGHT = 340;
const CHART_Y_MARGIN = 20;
const CHART_X_MARGIN = 30;

const AXIS_LINE_STROKE = 'stroke-base-200';

const LineChart = ({ data, xAxis, yAxis, benchmark }: LineChartProps) => {
  // Calculate the domain to include both the data range and benchmark
  const maxDataValue = Math.max(...data.map((d) => d[LineChartKeys.AVG]));
  const minDataValue = Math.min(...data.map((d) => d[LineChartKeys.AVG]));

  // If min value is the benchmark value, subtract 10 as buffer to the y domain to avoid overlapping the benchmark line
  const minValue = Math.min(minDataValue, benchmark?.value ? benchmark.value - 10 : 0);
  const maxValue = Math.max(maxDataValue, benchmark?.value ? benchmark.value : 0);

  const yDomain = [minValue, maxValue];

  return (
    <div className="w-full">
      <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
        <RechartsLineChart
          data={data}
          margin={{ left: CHART_X_MARGIN, right: CHART_X_MARGIN, bottom: CHART_Y_MARGIN, top: CHART_Y_MARGIN }}
        >
          <XAxis
            axisLine={{ className: AXIS_LINE_STROKE }}
            className={TYPOGRAPHY_SIZE.caption}
            dataKey={LineChartKeys.DATE}
            label={{
              offset: -CHART_Y_MARGIN,
              position: 'insideBottom',
              value: xAxis.label,
              className: TYPOGRAPHY_SIZE.paragraph,
            }}
            tickLine={false}
            tickFormatter={xAxis.formatter}
          />
          <YAxis
            axisLine={{ className: AXIS_LINE_STROKE }}
            className={TYPOGRAPHY_SIZE.caption}
            label={{
              angle: -90,
              position: 'insideLeft',
              value: yAxis.label,
              className: TYPOGRAPHY_SIZE.paragraph,
            }}
            tickLine={false}
            tickFormatter={yAxis.formatter}
            domain={yDomain}
            scale="linear"
          />
          {benchmark && (
            <ReferenceLine
              y={benchmark.value}
              label={{
                value: benchmark.label,
                position: 'insideTopLeft',
                className: TYPOGRAPHY_SIZE.caption,
                dy: 4,
              }}
              stroke="oklch(var(--nc))"
            />
          )}
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            labelFormatter={xAxis.formatter}
            formatter={(value) => [
              <div key="tooltip" className="flex gap-2">
                <Dot color="#0AAD7C" size={ComponentSize.X_SMALL} />
                <Typography size={TypographySize.CAPTION} color={TextColor.WHITE}>
                  {yAxis.tooltipLabel}: {yAxis.formatter?.(value.toString()) || value}
                </Typography>
              </div>,
              null, // Hide default key display
            ]}
            contentStyle={{
              border: 'none',
              padding: '8px 12px',
              gap: '8px',
              borderRadius: '8px',
              background: 'oklch(var(--n))',
            }}
            labelStyle={{ color: 'oklch(var(--b1))' }}
          />
          <Line
            dataKey={LineChartKeys.AVG}
            name={yAxis.tooltipLabel}
            dot={{
              r: DOT_RADIUS,
              fill: 'oklch(var(--p))',
              stroke: 'oklch(var(--p))',
              strokeWidth: 2,
            }}
            activeDot={{
              r: DOT_RADIUS + 2, // Highlight on hover
              fill: 'oklch(var(--b1))',
              stroke: 'oklch(var(--p))',
              strokeWidth: 2,
            }}
            stroke="oklch(var(--p))"
            type="linear"
          />
        </RechartsLineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChart;
