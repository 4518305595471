import { useCallback, useEffect } from 'react';
import { findOptionsByValues, SelectOption } from '../components';
import { useGetPracticeProspectsForSelectQuery, useGetTagsQuery } from '../services';
import { PracticeFilterKeys, PracticeFilters, ReviewFilterKeys } from '../types';
import { parseRequiredSelectOption } from '../utils';
import useGetFiltersFromParams from './useGetFiltersFromParams';
import useUpdateFilterParams from './useUpdateFilterParams';

export type FilterOptionsAndSelections = {
  /** The options for the filter. */
  options: SelectOption[];
  /** Whether the options are loading. */
  loading: boolean;
  /** The selected options for the filter. */
  selected?: SelectOption[];
};

// Custom hook to manage filter options and selections.
const useFilterOptionsAndSelections = (): Record<string, FilterOptionsAndSelections> => {
  // Fetch all prospects and tags.
  const { data: prospects = [], isLoading: isProspectsLoading } = useGetPracticeProspectsForSelectQuery({});
  const { data: tags = [], isLoading: isTagsLoading } = useGetTagsQuery();

  // Get current filters from URL parameters.
  const filters = useGetFiltersFromParams();
  const updateFilterParams = useUpdateFilterParams();

  // Create unique account options from prospect data.
  // Transform options to SelectOption format.
  const accountOptions = Array.from(new Set(prospects.map((p) => p.accountName))).map(parseRequiredSelectOption);
  const prospectOptions = prospects.map(({ personaId, firstName, lastName, accountName }) => ({
    value: personaId,
    label: `${firstName} ${lastName} | ${accountName}`,
  }));
  const tagOptions = tags.map(({ id, name, color }) => ({ value: id, label: name, color }));

  const accountsFilter = (filters as PracticeFilters).prospectAccount;
  const prospectsFilter = (filters as PracticeFilters).prospect;
  const tagsFilter = filters.tags;

  // Parse selected values into SelectOption format.
  // If the selected value is not in the options list, it is ignored.
  // This means that only valid options are returned.
  const selectedAccounts = findOptionsByValues(accountOptions, accountsFilter);
  const selectedProspects = findOptionsByValues(prospectOptions, prospectsFilter);
  const selectedTags = findOptionsByValues(tagOptions, tagsFilter);

  // Updates the params if current param values and valid param values are not the same.
  const updateParamsWithValidValues = useCallback(
    (filterKey: string, validSelectedOptions: SelectOption[], isLoading: boolean, filterValue?: string[]) => {
      if (isLoading || !filterValue || filterValue.length === validSelectedOptions.length) return;

      const selectedValues = validSelectedOptions.map((option) => option.value);
      updateFilterParams(filterKey as PracticeFilterKeys | ReviewFilterKeys, selectedValues);
    },
    [updateFilterParams]
  );

  useEffect(() => {
    updateParamsWithValidValues('prospectAccount', selectedAccounts, isProspectsLoading, accountsFilter);
    updateParamsWithValidValues('prospect', selectedProspects, isProspectsLoading, prospectsFilter);
    updateParamsWithValidValues('tags', selectedTags, isTagsLoading, tagsFilter);
  }, [
    accountsFilter,
    prospectsFilter,
    tagsFilter,
    isProspectsLoading,
    isTagsLoading,
    selectedAccounts,
    selectedProspects,
    selectedTags,
    updateParamsWithValidValues,
  ]);

  // Return an object with filter options, selections, and loading states for each filter type.
  return {
    accounts: {
      options: accountOptions,
      selected: selectedAccounts,
      loading: isProspectsLoading,
    },
    prospects: {
      options: prospectOptions,
      selected: selectedProspects,
      loading: isProspectsLoading,
    },
    tags: {
      options: tagOptions,
      selected: selectedTags,
      loading: isTagsLoading,
    },
  };
};

export default useFilterOptionsAndSelections;
