import { useMemo } from 'react';
import { useAppSelector, useFeatureFlag } from '../../../../../hooks';
import { LD_FeatureFlags, Permissions, TextColor } from '../../../../../types';
import { conditionalArray } from '../../../../../utils';
import { Accordion, Typography, TypographySize } from '../../../../shared';
import ItemWrapper from '../ItemWrapper';
import PromptTemplatesItem from './PromptTemplatesItem';
import ProspectDesignerItem from './ProspectDesignerItem';
import ProspectHotlineItem from './ProspectHotlineItem';

interface CustomProspectTabProps {
  onClose: () => void;
}

const CustomProspectTab = ({ onClose }: CustomProspectTabProps) => {
  const permissions = useAppSelector((state) => state.auth.user?.permissions) || [];
  const isSuperAdmin = permissions.includes(Permissions.ADMIN_ACCESS);
  const canManageProspect = permissions.includes(Permissions.MANAGE_PROSPECT);

  const selfServeFF = useFeatureFlag(LD_FeatureFlags.RELEASE_SELF_SERVE);

  const items = useMemo(
    () => [
      {
        id: 'prospect-hotline',
        title: 'Prospect hotline',
        content: <ProspectHotlineItem onClose={onClose} />,
      },
      // Only admins and managers have access to the prospect designer.
      ...conditionalArray(canManageProspect, {
        id: 'prospect-designer',
        title: 'Prospect designer',
        content: <ProspectDesignerItem onClose={onClose} />,
        disabled: !selfServeFF,
      }),
      // Only super-admins can manage prompt templates.
      ...conditionalArray(isSuperAdmin, {
        id: 'manage-prompt-templates',
        title: 'Manage prompt templates',
        content: <PromptTemplatesItem onClose={onClose} />,
      }),
    ],
    [canManageProspect, isSuperAdmin, selfServeFF]
  );

  return (
    <div className="flex flex-col gap-4">
      <Typography size={TypographySize.H5} color={TextColor.SECONDARY}>
        Tailor a new Practice Prospect to your ICP and scenario.
      </Typography>
      <Accordion items={items.map((item) => ({ ...item, content: <ItemWrapper>{item.content}</ItemWrapper> }))} />
    </div>
  );
};

export default CustomProspectTab;
