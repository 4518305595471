import clsx from 'clsx';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { Avatar, Icon, Icons, TagsRow, Typography, TypographySize, TypographyWeight } from '../../../components';
import { useAppDispatch, useHandleApiResponse } from '../../../hooks';
import { updateScorecardTemplate as updateScorecardTemplateRedux } from '../../../redux/reducers';
import { useUpdateScorecardTemplateMutation } from '../../../services';
import {
  ComponentSize,
  DateFormat,
  ScorecardTemplate,
  ScorecardTemplateStatus,
  ScorecardTemplateType,
  TextColor,
} from '../../../types';
import ScorecardRowActions from './ScorecardRowActions';

const SCORECARD_UPDATE_ERROR_MSG = 'Failed to update scorecard';

interface ScorecardRowProps {
  scorecard: ScorecardTemplate;
  onEditScorecard: (scorecard: ScorecardTemplate) => void;
  onDuplicateScorecard: () => void;
}

const ScorecardRow = ({ scorecard, onEditScorecard, onDuplicateScorecard }: ScorecardRowProps) => {
  // State to track currently selected tags.
  const [selectedTags, setSelectedTags] = useState(scorecard.tags || []);

  // Redux
  const dispatch = useAppDispatch();

  // Hooks
  const handleApiResponse = useHandleApiResponse();

  // Mutations
  const [updateScorecardTemplate, { isLoading: isApplyingTags }] = useUpdateScorecardTemplateMutation();

  const { id, lastEditor, name, status, tags = [], type, updatedAt } = scorecard;

  // On error, reset the selected tags to the current tags.
  const onApplyTagsError = useCallback(() => {
    setSelectedTags(tags);
  }, [tags]);

  // On success, update the scorecard tags in the redux store.
  const onApplyTagsSuccess = useCallback(() => {
    dispatch(updateScorecardTemplateRedux({ id, tags: selectedTags }));
  }, [id, selectedTags, dispatch]);

  // Handler to apply tags to a scorecard
  const handleApplyTags = useCallback(async () => {
    const selectedTagIds = selectedTags.map((tag) => tag.id);

    // Do nothing if the selected tags are the same as the current tags.
    const shouldApplyTags =
      selectedTagIds.length !== tags.length || !selectedTagIds.every((id) => tags.find((tag) => tag.id === id));
    if (!shouldApplyTags) {
      return;
    }

    try {
      const response = await updateScorecardTemplate({ id, tags: selectedTagIds });

      handleApiResponse({
        response,
        errorMsg: SCORECARD_UPDATE_ERROR_MSG,
        onError: onApplyTagsError,
        onSuccess: onApplyTagsSuccess,
      });
    } catch (error) {
      console.error(SCORECARD_UPDATE_ERROR_MSG, error);
    }
  }, [
    id,
    tags,
    selectedTags,
    dispatch,
    updateScorecardTemplate,
    handleApiResponse,
    onApplyTagsError,
    onApplyTagsSuccess,
  ]);

  // If a scorecard is archived
  const isScorecardArchived = status === ScorecardTemplateStatus.ARCHIVED;

  const archivedTextColor = TextColor.TERTIARY;
  const nameTextColor = isScorecardArchived ? archivedTextColor : undefined;
  const isAiScorecard = type === ScorecardTemplateType.AI;

  return (
    <div className="flex items-center justify-between gap-2">
      <div className="flex w-full flex-col gap-1 overflow-auto">
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <Typography
              size={TypographySize.H5}
              color={nameTextColor}
              weight={TypographyWeight.SEMI_BOLD}
              className={clsx`overflow-hidden truncate ${scorecard?.tags?.length ? 'max-w-[250px]' : 'max-w-[320px]'}`}
              noWrap
            >
              {name}
            </Typography>
            {isAiScorecard && <Icons icon={Icon.WAND} color={TextColor.AI} />}
          </div>
          <TagsRow
            enableManageTags
            tags={tags}
            onApplyTags={handleApplyTags}
            isApplyingTags={isApplyingTags}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />
        </div>

        <div className="flex items-center gap-2">
          <Avatar
            size={ComponentSize.X_SMALL}
            label={lastEditor.name}
            imgSrc={lastEditor.picture}
            disabled={isScorecardArchived}
          />
          <Typography size={TypographySize.CAPTION} color={nameTextColor}>
            {lastEditor.name}
          </Typography>
          <Typography
            size={TypographySize.CAPTION}
            color={isScorecardArchived ? archivedTextColor : TextColor.SECONDARY}
          >
            {dayjs(updatedAt).format(DateFormat.MONTH_DAY_TIME)}
          </Typography>
        </div>
      </div>

      <ScorecardRowActions
        scorecard={scorecard}
        onEditScorecard={onEditScorecard}
        onDuplicateScorecard={onDuplicateScorecard}
      />
    </div>
  );
};

export default ScorecardRow;
