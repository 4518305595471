import { useCallback, useEffect, useState } from 'react';
import { useGetOrganizationSettingsQuery, useGetMetricAnalyticsMutation } from '../../../services';
import { ProgressReportCard } from './ProgressReportCard';
import { AnalyticsFilters, AverageByPeriod, DateFormat, ProgressReportMetric } from '../../../types';
import { findOptionByValue, LineChartKeys } from '../../../components';
import dayjs from 'dayjs';

// TODO: Add more options in Analytics V3
const TALK_SPEED_SELECT_OPTIONS = [{ label: 'Talk speed', value: 'wordsPerMinute' }];

interface TalkSpeedChartProps {
  filters: AnalyticsFilters;
}

const TalkSpeedChart = ({ filters }: TalkSpeedChartProps) => {
  // Set default selected metric to words per minute
  const [selectedMetric, setSelectedMetric] = useState<string | undefined>(TALK_SPEED_SELECT_OPTIONS[0].value);

  const { data: orgConfigs } = useGetOrganizationSettingsQuery();
  const { wordsPerMinute } = orgConfigs || {};

  // Fetch talk time analytics
  const [getMetricAnalytics, { data: metricAnalytics = [], isLoading: isLoadingMetricAnalytics }] =
    useGetMetricAnalyticsMutation();

  // Fetch talk time analytics
  const fetchTalkTimeAnalytics = useCallback(() => {
    if (!filters.user || !filters.averageBy || !filters.dateRange) return;

    getMetricAnalytics({
      dateRange: [filters.dateRange],
      tags: filters.tags,
      prospectIds: filters.prospect,
      groupBy: filters.averageBy?.[0] as AverageByPeriod,
      userId: filters.user?.[0],
      metric: ProgressReportMetric.TALK_SPEED,
    });
  }, [getMetricAnalytics, filters]);

  // Fetch talk time analytics
  useEffect(() => {
    fetchTalkTimeAnalytics();
  }, [fetchTalkTimeAnalytics]);

  // Transform talk time analytics data
  const talkTimeData = metricAnalytics.map((item) => ({
    [LineChartKeys.DATE]: item.date,
    [LineChartKeys.AVG]: Math.round(item.metricValue ?? 0),
  }));

  // Calculate total average talk time
  const totalAvgTalkTime = talkTimeData.length
    ? Math.round(talkTimeData.reduce((acc, item) => acc + item.avg, 0) / talkTimeData.length)
    : undefined;

  // Find selected metric
  const selectedMetricOption = findOptionByValue(TALK_SPEED_SELECT_OPTIONS, selectedMetric);
  const benchmark = wordsPerMinute ? { value: wordsPerMinute, label: `Benchmark: ${wordsPerMinute}` } : undefined;

  return (
    <ProgressReportCard
      title="Average talk speed"
      valueUnit="words per minute"
      value={totalAvgTalkTime ? `${totalAvgTalkTime}` : undefined}
      data={talkTimeData}
      xAxis={{ formatter: (value) => dayjs(value).format(DateFormat.MONTH_DAY) }}
      yAxis={{ label: 'Talk speed', tooltipLabel: 'Talk speed' }}
      selectOptions={TALK_SPEED_SELECT_OPTIONS}
      selectedOption={selectedMetricOption}
      onSelectOption={setSelectedMetric}
      isLoading={isLoadingMetricAnalytics}
      benchmark={benchmark}
    />
  );
};

export default TalkSpeedChart;
