import { useRef } from 'react';
import { useDetectTextOverflow } from '../../../hooks';
import { ComponentSize, TextColor } from '../../../types';
import { Avatar } from '../Avatar';
import { Typography, TypographySize, TypographyWeight } from '../Typography';
import { ProspectDetailsProps } from './ProspectDetails.types';

const SINGLE_LINE = 1;
const DOUBLE_LINE = 2;

const ProspectDetails = ({ firstName, lastName, accountName, jobTitle }: ProspectDetailsProps) => {
  // In the prospect page,
  // all props (firstName, lastName, accountName, jobTitle) are undefined if the user has not filled them out yet,
  // they become empty strings if the user has filled them out and then cleared them.
  // If they are empty strings, we show the placeholder text.
  // We do not need to handle the case where they are undefined because the user will not be able to proceed to the next tab.
  const hasFirstAndLastName = firstName !== undefined && lastName !== undefined;
  const fullName = hasFirstAndLastName ? `${firstName || '[First name]'} ${lastName || '[Last name]'}` : undefined;

  const fullNameRef = useRef<HTMLDivElement>(null);
  const isNameOverflowing = useDetectTextOverflow(fullNameRef, DOUBLE_LINE);

  const accountRef = useRef<HTMLDivElement>(null);
  const isAccountOverflowing = useDetectTextOverflow(accountRef, SINGLE_LINE);

  const jobTitleRef = useRef<HTMLDivElement>(null);
  const isJobTitleOverflowing = useDetectTextOverflow(jobTitleRef, SINGLE_LINE);

  return (
    <div className="flex items-center gap-4">
      <Avatar label={firstName ? fullName : '?'} size={ComponentSize.MEDIUM} />
      <div className="flex flex-col gap-1">
        <Typography
          size={TypographySize.H5}
          weight={TypographyWeight.SEMI_BOLD}
          ref={fullNameRef}
          maxLines={DOUBLE_LINE}
          tooltip={isNameOverflowing ? fullName : undefined}
        >
          {fullName}
        </Typography>
        {accountName !== undefined && (
          <Typography
            size={TypographySize.CAPTION}
            color={TextColor.SECONDARY}
            ref={accountRef}
            maxLines={SINGLE_LINE}
            tooltip={isAccountOverflowing ? accountName : undefined}
          >
            {accountName || '[Account name]'}
          </Typography>
        )}
        {jobTitle !== undefined && (
          <Typography
            size={TypographySize.CAPTION}
            color={TextColor.SECONDARY}
            ref={jobTitleRef}
            maxLines={SINGLE_LINE}
            tooltip={isJobTitleOverflowing ? jobTitle : undefined}
          >
            {jobTitle || '[Job title]'}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default ProspectDetails;
