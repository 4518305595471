import { useCallback, useEffect, useRef, useState } from 'react';
import { EmptyState, Footer, Icon, Spinner, TextButton } from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setScorecardTemplates } from '../../../redux/reducers';
import { useGetScorecardTemplatesQuery } from '../../../services';
import { ComponentSize, SortingOrder } from '../../../types';
import ScorecardTemplateRow from './ScorecardTemplateRow';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../constants';

const ScorecardTemplatesTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const scorecardTemplatesRedux = useAppSelector((state) => state.scorecardTemplate.templates);
  const tableRef = useRef<HTMLDivElement>(null);

  // State to scroll to the top of the table when a new scorecard is duplicated
  const [shouldScrollToTop, setShouldScrollToTop] = useState(false);

  // APIs
  const { data: scorecardTemplates, isLoading } = useGetScorecardTemplatesQuery({
    sorting: {
      sortBy: 'updatedAt',
      sortOrder: SortingOrder.DESC,
    },
  });

  // Navigate to create new scorecard template form
  const handleCreateNewScorecard = useCallback(() => {
    navigate(AppRoutes.SCORECARD_TEMPLATES_CREATE);
  }, [navigate]);

  const onDuplicateScorecard = useCallback(() => {
    setShouldScrollToTop(true);
  }, []);

  // Initialize Redux state with backend data
  useEffect(() => {
    if (scorecardTemplates && scorecardTemplatesRedux.length === 0) {
      dispatch(setScorecardTemplates(scorecardTemplates));
    }
  }, [scorecardTemplates, dispatch, scorecardTemplatesRedux.length]);

  // Scroll to the top of the table when a new scorecard is duplicated
  useEffect(() => {
    if (shouldScrollToTop && tableRef.current) {
      tableRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      setShouldScrollToTop(false);
    }
  }, [shouldScrollToTop]);

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner size={ComponentSize.MEDIUM} />
      </div>
    );
  }

  return (
    <>
      <div className="display-scrollbar-lg flex-1 overflow-y-auto pb-6" ref={tableRef}>
        <div className="mx-auto flex w-full max-w-[700px] flex-col gap-4">
          {!scorecardTemplatesRedux?.length && <EmptyState description="No scorecard templates" icon={Icon.TALLY} />}
          {scorecardTemplatesRedux?.map((scorecardTemplate) => (
            <ScorecardTemplateRow
              key={scorecardTemplate.id}
              scorecardTemplate={scorecardTemplate}
              onDuplicateScorecard={onDuplicateScorecard}
            />
          ))}
        </div>
      </div>
      <Footer className="justify-end">
        <TextButton text="Create new" onClick={handleCreateNewScorecard} />
      </Footer>
    </>
  );
};

export default ScorecardTemplatesTable;
