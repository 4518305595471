import { useMemo } from 'react';
import { useAppSelector, useFeatureFlag } from '../../../../../hooks';
import { LD_FeatureFlags, TextColor, Permissions } from '../../../../../types';
import { conditionalArray } from '../../../../../utils';
import { Accordion, Icon, Icons, SubmitFile, SubmitLink, Typography, TypographySize } from '../../../../shared';
import ItemWrapper from '../ItemWrapper';
import AITwinGong from './AITwinGong';

enum AITwinItem {
  FILE = 'FILE',
  LINK = 'LINK',
  GONG = 'GONG',
}

interface AITwinTabProps {
  onClose: () => void;
}

const AITwinTab = ({ onClose }: AITwinTabProps) => {
  const gongFF = useFeatureFlag(LD_FeatureFlags.RELEASE_GONG);
  const { user, organization } = useAppSelector((state) => state.auth);

  const isUploadModalEnabled = organization?.isUploadModalEnabled;
  const permissions = user?.permissions || [];

  const canViewGong = permissions.includes(Permissions.VIEW_GONG);

  const items = useMemo(
    () => [
      {
        id: AITwinItem.FILE,
        title: 'Upload call',
        disabled: !isUploadModalEnabled,
        content: (
          <SubmitFile
            successToastData={{
              title: 'Practice Prospect is processing',
              message:
                'We’re building your Practice Prospect! We’ll email you when your AI twin is complete, usually within a few hours.',
            }}
            toCreateProspect
          />
        ),
      },
      {
        id: AITwinItem.LINK,
        title: 'Share call link',
        disabled: !isUploadModalEnabled,
        content: <SubmitLink />,
      },
      ...conditionalArray(gongFF && canViewGong, {
        id: AITwinItem.GONG,
        icon: <Icons icon={Icon.GONG} fill preserveSize />,
        title: 'Build from real call',
        className: 'bg-gong-purple-light',
        disabled: !isUploadModalEnabled,
        content: <AITwinGong onClose={onClose} />,
      }),
    ],
    [gongFF, canViewGong, isUploadModalEnabled, onClose]
  );

  return (
    <div className="flex flex-col gap-4">
      <Typography color={TextColor.SECONDARY} size={TypographySize.H5}>
        Practice Prospects based on real calls.
      </Typography>
      <Accordion items={items.map((item) => ({ ...item, content: <ItemWrapper>{item.content}</ItemWrapper> }))} />
    </div>
  );
};

export default AITwinTab;
