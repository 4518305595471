import { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeZeusModal } from '../../../redux/reducers';
import { useGetUsersQuery } from '../../../services';
import { Roles, StatusColor } from '../../../types';
import { parseUserToOption } from '../../../utils';
import { Dialog, Icon, Icons, OrgSelector, Select, Typography, TypographySize } from '../../shared';
import ZeusModalActions from './ZeusModalActions';

const ZEUS_MODAL_WIDTH = 460;
const ZEUS_SELECT_WIDTH = 300;

const ZeusModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.modal.isZeusModalOpen);
  const { user, organization, isImpersonating } = useAppSelector((state) => state.auth);

  // Set selected state with impersonated user on mount.
  const [selectedOrgId, setSelectedOrgId] = useState(isImpersonating ? organization?.id : undefined);
  const [selectedUserId, setSelectedUserId] = useState(isImpersonating ? user?.id : undefined);

  // We disable impersonation on these requests to ensure we get the true lists of organizations and users,
  // rather than those filtered by any currently impersonated user's permissions.
  // This allows us to act as the real super-admin user when selecting items to impersonate.
  const { data: users = [], isLoading: isLoadingUsers } = useGetUsersQuery(
    { orgId: selectedOrgId, disableImpersonation: true },
    { skip: !selectedOrgId }
  );

  // Map organizations and users to Select options.
  const userOptions = useMemo(() => {
    // The useGetUsersQuery returns the current organization's users by default if no orgId is provided,
    // so we check if an orgId is selected and show the list of users if so.
    // Skip only works before any data is fetched.
    if (!selectedOrgId) return [];

    // Filter out super admins and disabled users.
    const filteredUsers = users.filter((user) => user.role !== Roles.SUPER_ADMIN && !user.disabled);
    return filteredUsers.map(parseUserToOption);
  }, [selectedOrgId, users]);

  // Handles changing the selected organization.
  const onChangeOrg = (selected?: string) => {
    // If the selected org is the same as the current selected org, do nothing.
    if (selected === selectedOrgId) return;

    // Update the selected organization.
    setSelectedOrgId(selected);

    // We reset the selected user unless the selected org is the impersonated user's org.
    // In that case, we initialize the selected user as the impersonated user's id.
    const isImpersonatedOrg = isImpersonating && selected === organization?.id;
    setSelectedUserId(isImpersonatedOrg ? user?.id : undefined);
  };

  return (
    <Dialog
      icon={<Icons icon={Icon.ZEUS} color={StatusColor.INFO} />}
      isOpen={isOpen}
      onClose={() => {
        dispatch(closeZeusModal());
      }}
      title="Enter Zeus mode"
      description="Impersonate another user's account."
      width={ZEUS_MODAL_WIDTH}
    >
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between">
          <Typography size={TypographySize.H5}>Organization</Typography>
          <OrgSelector selectedOrgId={selectedOrgId} width={ZEUS_SELECT_WIDTH} onChange={onChangeOrg} />
        </div>
        <div className="flex items-center justify-between">
          <Typography size={TypographySize.H5}>User</Typography>
          <Select
            options={userOptions}
            onChange={setSelectedUserId}
            width={ZEUS_SELECT_WIDTH}
            placeholder="Select user"
            loading={isLoadingUsers}
            selected={userOptions.find((user) => user.value === selectedUserId)}
          />
        </div>
        <ZeusModalActions selectedOrgId={selectedOrgId} selectedUserId={selectedUserId} />
      </div>
    </Dialog>
  );
};

export default ZeusModal;
