import { ScorecardTemplateType } from "./scorecardTemplate.types";

/** Question types */
export enum QuestionType {
  YES_NO = 'YES_OR_NO',
  RANGE = 'RANGE',
  TEXT = 'TEXT',
}

/** Scorecard answer type */
export type ScorecardAnswer = {
  id?: string;
  response?: string;
  questionId: string;
};

/** Question type */
export type ScorecardQuestionType = {
  id: string;
  text: string;
  order?: number;
  type?: QuestionType;
  answers?: ScorecardAnswer[];
  points?: number;
};

/** Section type */
export type ScorecardSectionType = {
  id: string;
  title: string;
  order?: number;
  questions: ScorecardQuestionType[];
};

/** Scorecard type */
export type Scorecard = {
  id: string;
  callSid: string;
  userId: string;
  user: {
    id: string;
    name: string;
    picture: string;
  };
  scorecardTemplateId: string;
  scorecardTemplate: {
    id: string;
    name: string;
    sections: ScorecardSectionType[];
    points?: number;
    type?: ScorecardTemplateType;
  };
  isScoredByAI: boolean;
  createdAt: string;
  updatedAt: string;
  points?: number;
  pointsPercentage?: number;
};

/** Scorecard response type */
export type ScorecardResponse = {
  message: string;
  data: {
    scorecard: Scorecard;
  };
};

/** Create scorecard payload type */
export interface CreateScorecardPayload {
  callSid: string;
  scorecardTemplateId: string;
  answers: ScorecardAnswer[];
  isScoredByAI?: boolean;
}

/** Update scorecard payload type */
export interface UpdateScorecardPayload {
  id: string;
  callSid: string;
  answers: ScorecardAnswer[];
}

export type UpdateScorecardResponse = {
  scorecard: {
    id: string;
    pointsPercentage: number | null;
  };
};
