import clsx from 'clsx';
import { Avatar, Badge, Icon, Typography, TypographySize, TypographyWeight } from '../../../../components';
import {
  Alignment,
  ComponentSize,
  Scorecard,
  ScorecardTemplateType,
  SemanticColor,
  TextColor,
} from '../../../../types';

interface ScorecardHeaderProps {
  existingScorecard: Scorecard;
}

const ScorecardHeader = ({ existingScorecard }: ScorecardHeaderProps) => {
  const { points = 0, pointsPercentage = 0, user, isScoredByAI, scorecardTemplate } = existingScorecard;
  const { points: totalPoints = 0, type } = scorecardTemplate;

  const isAIScorecard = type === ScorecardTemplateType.AI;

  return (
    <div className="flex items-center justify-between gap-2">
      {/* Scored by info */}
      <div className={clsx('flex gap-2', isAIScorecard && !isScoredByAI && 'flex-col')}>
        <Typography color={TextColor.SECONDARY} size={TypographySize.CAPTION} noWrap>
          Scored by:
        </Typography>
        {isScoredByAI && (
          <Badge label="AI" color={SemanticColor.AI} startIcon={Icon.WAND} size={ComponentSize.MEDIUM} />
        )}
        {!isScoredByAI && (
          <div className="flex items-center gap-2">
            <Avatar size={ComponentSize.X_SMALL} label={user.name} imgSrc={user.picture} />
            <Typography color={TextColor.SECONDARY}>{user.name}</Typography>
          </div>
        )}
      </div>
      {/* Scorecard points only for AI scorecards */}
      {isAIScorecard && (
        <div className="flex flex-col items-end gap-1">
          <Typography
            alignment={Alignment.RIGHT}
            color={TextColor.SECONDARY}
            size={TypographySize.H3}
            weight={TypographyWeight.SEMI_BOLD}
          >
            {Math.round(pointsPercentage * 100)}%
          </Typography>
          <Typography alignment={Alignment.RIGHT} color={TextColor.SECONDARY} size={TypographySize.CAPTION} noWrap>
            {points} / {totalPoints} points
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ScorecardHeader;
