import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeUploadModal } from '../../../redux/reducers';
import { TextColor } from '../../../types';
import { Accordion, Dialog, DialogType, SubmitFile, SubmitLink, Typography, TypographySize } from '../../shared';

import { PRIVACY_POLICY_URL } from '../../../constants';
import { useMemo } from 'react';

enum UploadItemType {
  FILE = 'FILE',
  LINK = 'LINK',
}

const UploadModal = () => {
  // Redux
  const isOpen = useAppSelector((state) => state.modal.isUploadModalOpen);
  const dispatch = useAppDispatch();

  const items = useMemo(
    () => [
      {
        id: UploadItemType.FILE,
        title: 'Submit a file',
        content: (
          <SubmitFile
            successToastData={{
              title: 'Processing',
              message:
                'Enhancing your prospects now! We’ll email you when this is complete, usually within a few hours.',
            }}
          />
        ),
      },
      {
        id: UploadItemType.LINK,
        title: 'Submit a link',
        content: <SubmitLink />,
      },
    ],
    []
  );

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        dispatch(closeUploadModal());
      }}
      title="Improve your organization’s Practice Prospects"
      type={DialogType.SETTINGS}
      footer={
        <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY}>
          What you upload and share is private to your workspace. It is used to create realistic practice prospects, we
          do not train AI models using this information.&nbsp;
          <span className="cursor-pointer underline" onClick={() => window.open(PRIVACY_POLICY_URL, '_blank')}>
            Privacy policy
          </span>
        </Typography>
      }
      description={
        <Typography size={TypographySize.H5} color={TextColor.SECONDARY} className="mt-2">
          Make your prospects more realistic by sharing material on your ICP, training material, past calls, or industry
          specific knowledge.
        </Typography>
      }
    >
      <Accordion
        items={items.map((item) => ({ ...item, content: <div className="mt-4 w-[90%]">{item.content}</div> }))}
      />
    </Dialog>
  );
};

export default UploadModal;
