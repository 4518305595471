import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../../../constants';
import { TextColor } from '../../../../../types';
import { TextButton, Typography, TypographySize } from '../../../../shared';

interface PromptTemplatesItemProps {
  onClose: () => void;
}

const PromptTemplatesItem = ({ onClose }: PromptTemplatesItemProps) => {
  const navigate = useNavigate();

  // Close the Create modal and navigate to the Prompt Templates page.
  const handleStart = () => {
    onClose();
    navigate(AppRoutes.PROMPT_TEMPLATES);
  };

  return (
    <div className="flex items-center justify-between">
      <Typography size={TypographySize.H5} color={TextColor.SECONDARY}>
        Create and edit prompt templates for prospects.
      </Typography>
      <TextButton text="Start" onClick={handleStart} />
    </div>
  );
};

export default PromptTemplatesItem;
