import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Call } from '../../types';

type CallDetails = {
  call: Call;
  startTime?: number;
  autoPlay?: boolean;
};

/**
 * Interface for the call page state.
 */
interface ReviewState {
  /**
   * List of calls displayed in the review table.
   * The list is filtered and sorted based on the user's selected filters.
   */
  calls: Call[];
  /**
   * Details about the currently playing call in the media player.
   * Contains the call object and playback settings.
   * Undefined when no call is being played.
   */
  currentCallDetails?: CallDetails;
}

/**
 * Initial state for the call page.
 */
const INITIAL_REVIEW_STATE: ReviewState = {
  calls: [],
};

// Create a slice for managing the call page state.
const reviewReducer = createSlice({
  name: 'review',
  initialState: INITIAL_REVIEW_STATE,
  reducers: {
    // Action to set the list of calls.
    setCalls: (state, action: PayloadAction<Call[]>) => {
      state.calls = action.payload;
    },
    // Action to update a call in the list of calls.
    updateCall: (state, action: PayloadAction<Partial<Call>>) => {
      state.calls = state.calls.map(
        (call) => call.callSid === action.payload.callSid ? { ...call, ...action.payload } : call
      )
    },
    // Action to open the media player and play the specified call.
    openMediaPlayer: (state, action: PayloadAction<CallDetails>) => {
      state.currentCallDetails = action.payload;
    },
    // Action to close the media player.
    closeMediaPlayer: (state) => {
      state.currentCallDetails = undefined;
    },
    // Action to update the currently open media player playback details.
    updatePlaybackDetails: (state, action: PayloadAction<{ startTime: number; autoPlay?: boolean }>) => {
      if (state.currentCallDetails) {
        state.currentCallDetails.startTime = action.payload.startTime;
        state.currentCallDetails.autoPlay = action.payload.autoPlay;
      }
    },
    // Action to reset the playback details to their default values.
    resetPlaybackDetails: (state) => {
      if (state.currentCallDetails) {
        state.currentCallDetails.startTime = undefined;
        state.currentCallDetails.autoPlay = undefined;
      }
    },
  },
});

export const {
  setCalls,
  updateCall,
  openMediaPlayer,
  updatePlaybackDetails,
  resetPlaybackDetails,
  closeMediaPlayer,
} = reviewReducer.actions;
export default reviewReducer.reducer;
