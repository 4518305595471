import { Typography, TypographySize, TypographyWeight, Icons, Icon } from '../../../../shared';

interface OrgTabSectionTitleProps {
  title: string;
  iconTooltip: string;
}

const OrgTabSectionTitle = ({ title, iconTooltip }: OrgTabSectionTitleProps) => {
  return (
    <div className="flex justify-between">
      <Typography size={TypographySize.H5} weight={TypographyWeight.SEMI_BOLD}>
        {title}
      </Typography>
      <Icons icon={Icon.INFO} tooltip={iconTooltip} />
    </div>
  );
};

export default OrgTabSectionTitle;
