import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AlertType,
  ButtonColor,
  EmptyState,
  Footer,
  Icon,
  Spinner,
  TextButton,
  Typography,
  TypographySize,
  TypographyWeight,
} from '../../components';
import { AppRoutes } from '../../constants';
import { useToast } from '../../hooks';
import { useGetPromptTemplatesQuery } from '../../services';
import { PromptTemplatesSelectMode, PromptTemplateStatus } from '../../types';
import ManagePromptTemplatePage from '../ManagePromptTemplatePage';
import PromptTemplateRow from './PromptTemplateRow';

const PromptTemplatesPage = () => {
  const navigate = useNavigate();
  const { promptTemplateId } = useParams();

  const listRef = useRef<HTMLDivElement>(null);

  const { showToast } = useToast();

  const { data: promptTemplates = [], isLoading } = useGetPromptTemplatesQuery({
    selectMode: PromptTemplatesSelectMode.ALL,
  });

  // Sort the prompt templates by archived status and updatedAt date.
  const sortedPromptTemplates = useMemo(
    () =>
      [...promptTemplates].sort((a, b) => {
        // First sort by archived status.
        if (a.status === PromptTemplateStatus.ARCHIVED && b.status !== PromptTemplateStatus.ARCHIVED) return 1;
        if (a.status !== PromptTemplateStatus.ARCHIVED && b.status === PromptTemplateStatus.ARCHIVED) return -1;
        // Then sort by updatedAt date.
        return (b.updatedAt ?? '').localeCompare(a.updatedAt ?? '');
      }),
    [promptTemplates]
  );

  // If the prompt template ID is provided, fetch the prompt template from the list.
  const currPromptTemplate = useMemo(
    () => (promptTemplateId ? promptTemplates.find((template) => template.id === promptTemplateId) : undefined),
    [promptTemplates, promptTemplateId]
  );

  // If the prompt template ID is provided but the prompt template is not found,
  // navigate to the prompt templates page and show an error toast.
  useEffect(() => {
    if (promptTemplateId && !currPromptTemplate && !isLoading) {
      navigate(AppRoutes.PROMPT_TEMPLATES);
      showToast({
        title: 'Prompt template not found',
        message: 'The prompt template you are trying to access does not exist.',
        type: AlertType.ERROR,
      });
    }
  }, [promptTemplateId, currPromptTemplate, isLoading, showToast]);

  // Scrolls to the top of the list.
  const scrollToTop = useCallback(() => {
    listRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  if (isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (promptTemplateId) {
    return <ManagePromptTemplatePage promptTemplate={currPromptTemplate} />;
  }

  // flex-col is needed to make the header sticky
  return (
    <div className="flex h-full flex-col">
      <div className="flex items-center gap-4 px-8 pt-4">
        <TextButton
          onClick={() => navigate(AppRoutes.PRACTICE)}
          text="Back"
          color={ButtonColor.SECONDARY}
          startIcon={Icon.CHEVRON_LEFT}
        />
        <Typography size={TypographySize.H3} weight={TypographyWeight.SEMI_BOLD}>
          Prompt templates
        </Typography>
      </div>
      {!sortedPromptTemplates.length && <EmptyState description="No prompt templates" icon={Icon.SQUARE_TERMINAL} />}
      {sortedPromptTemplates.length && (
        <div ref={listRef} className="flex flex-grow justify-center overflow-y-auto py-8">
          <div className="flex h-fit w-full max-w-[700px] flex-col gap-4">
            {sortedPromptTemplates.map((promptTemplate) => (
              <PromptTemplateRow key={promptTemplate.id} promptTemplate={promptTemplate} scrollToTop={scrollToTop} />
            ))}
          </div>
        </div>
      )}
      <Footer className="justify-end">
        <TextButton text="Create new" onClick={() => navigate(AppRoutes.PROMPT_TEMPLATES_CREATE)} />
      </Footer>
    </div>
  );
};

export default PromptTemplatesPage;
