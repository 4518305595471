import { useCallback, useMemo } from 'react';
import { AlertType } from '../components';
import { AppRoutes, CALL_START_TIME_QUERY_PARAM } from '../constants';
import useToast from './useToast';

/**
 * Custom hook to handle sharing a call.
 */
const useShareCall = (callSid?: string, startTime?: number, onSuccess?: () => void) => {
  const { showToast } = useToast();

  const shareUrl = useMemo(() => {
    // Generate the URL based on the current window location.
    const url = new URL(window.location.href);

    // Append the callSid to the current pathname.
    url.pathname = `${AppRoutes.REVIEW}/${callSid}`;

    // Clear all existing search parameters.
    url.search = '';

    // Append the startTime to the URL.
    if (startTime) {
      url.searchParams.set(CALL_START_TIME_QUERY_PARAM, startTime.toString());
    }

    return url.toString();
  }, [callSid, startTime]);

  // Shares the call by copying the URL to the clipboard.
  const onShare = useCallback(() => {
    // Copy the URL to the clipboard.
    navigator.clipboard.writeText(shareUrl);

    // Show success toast.
    showToast({ message: 'Link to call to clipboard', type: AlertType.SUCCESS });

    // Execute the onSuccess callback if provided.
    if (onSuccess) {
      onSuccess();
    }
  }, [shareUrl, onSuccess]);

  return { shareUrl, onShare };
};

export default useShareCall;
