import clsx from 'clsx';
import { useState } from 'react';
import { Icon, Icons, Typography, TypographySize } from '../../components';
import { PROMPT_TEMPLATE_LIQUID_TAGS } from '../../constants';
import { ComponentSize, TextColor } from '../../types';

const LIQUID_TAGS_ROW_HEIGHT_CLASSNAME = 'h-8';

interface PromptTemplateLiquidTagsProps {
  onInsertTag: (tag: string) => void;
}

const PromptTemplateLiquidTags = ({ onInsertTag }: PromptTemplateLiquidTagsProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div className="flex items-center gap-4">
      <Icons
        icon={isCollapsed ? Icon.CHEVRON_UP : Icon.CHEVRON_DOWN}
        color={TextColor.SECONDARY}
        size={ComponentSize.LARGE}
        onClick={() => setIsCollapsed((prev) => !prev)}
      />
      <div className={clsx('flex flex-wrap gap-2 overflow-hidden', isCollapsed && LIQUID_TAGS_ROW_HEIGHT_CLASSNAME)}>
        {PROMPT_TEMPLATE_LIQUID_TAGS.map((tag) => {
          const tagLabel = `{{${tag}}}`;
          return (
            <div key={tag} className={clsx('flex items-center', LIQUID_TAGS_ROW_HEIGHT_CLASSNAME)}>
              <Typography
                color="text-gray-900"
                size={TypographySize.CAPTION}
                className="cursor-pointer rounded-lg border border-gray-900 px-2 py-1 hover:bg-base-100"
                onClick={() => onInsertTag(tagLabel)}
              >
                {tagLabel}
              </Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PromptTemplateLiquidTags;
