import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector, useHandleApiResponse, useToast } from '../../../hooks';
import { useDeleteCommentMutation, useGetCommentsQuery } from '../../../services';
import { ComponentSize, DropdownPlacement } from '../../../types';
import {
  AlertType,
  ButtonColor,
  ButtonVariant,
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
  Icon,
  IconButton,
} from '../../shared';
import { updateCall } from '../../../redux/reducers';
import { ConfirmModal } from '../../modals';

const ERROR_MSG = 'Failed to delete comment';

interface CommentMoreButtonProps {
  commentId: string;
  callSid: string;
}

const CommentMoreButton = ({ commentId, callSid }: CommentMoreButtonProps) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isDeleteDropdownOpen, setIsDeleteDropdownOpen] = useState(false);

  const dispatch = useAppDispatch();
  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse();

  const [deleteComment, { isLoading }] = useDeleteCommentMutation();

  const currentCall = useAppSelector((state) => state.review.calls.find((call) => call.callSid === callSid));

  const { data: comments = [] } = useGetCommentsQuery(callSid);

  const onDeleteSuccess = useCallback(() => {
    if (!currentCall) return;

    // Find the deleted comment to get the user ID
    const deletedComment = comments.find((comment) => comment.id === commentId);
    if (!deletedComment) return;

    // Check if the user has other comments
    const hasOtherComments = comments.some(
      (comment) => comment.id !== commentId && comment.user.id === deletedComment.user.id
    );

    dispatch(
      updateCall({
        callSid,
        numberOfComments: Math.max(0, (currentCall?.numberOfComments ?? 0) - 1),
        // Only filter out the user if they don't have other comments
        usersCommented: hasOtherComments
          ? currentCall.usersCommented
          : currentCall.usersCommented?.filter((user) => user.id !== deletedComment.user.id) ?? [],
      })
    );
    setIsDeleteDropdownOpen(false);
  }, [dispatch, currentCall, callSid, commentId, comments]);

  const runDeleteComment = useCallback(async () => {
    try {
      const response = await deleteComment(commentId);
      handleApiResponse({ response, errorMsg: ERROR_MSG, onSuccess: onDeleteSuccess });
    } catch (error) {
      console.error(`${ERROR_MSG}: `, error);
      showToast({ message: ERROR_MSG, type: AlertType.ERROR });
    }
  }, [commentId, deleteComment, handleApiResponse, onDeleteSuccess]);

  return (
    <>
      <Dropdown
        open={isDeleteDropdownOpen}
        onOpenChange={setIsDeleteDropdownOpen}
        placement={DropdownPlacement.BOTTOM_START}
      >
        <DropdownTrigger>
          <IconButton
            icon={Icon.ELLIPSIS_VERTICAL}
            onClick={() => setIsDeleteDropdownOpen((prev) => !prev)}
            size={ComponentSize.X_SMALL}
            active={isDeleteDropdownOpen}
            variant={ButtonVariant.GHOST}
            color={ButtonColor.SECONDARY}
          />
        </DropdownTrigger>
        <DropdownContent>
          <DropdownItem
            label="Delete comment"
            icon={Icon.TRASH}
            destructive
            onClick={() => setIsConfirmModalOpen(true)}
          />
        </DropdownContent>
      </Dropdown>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        buttonText="Delete"
        setIsOpen={setIsConfirmModalOpen}
        onConfirm={runDeleteComment}
        isLoading={isLoading}
        title="Delete comment"
        confirmText="Deleting a comment is irreversible. Are you sure you want to delete this comment?"
      />
    </>
  );
};

export default CommentMoreButton;
