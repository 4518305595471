import { findOptionByValue, Select } from '../../../../components';
import {
  LLM_MAX_TOKENS_SLIDER_LIMITS,
  LLM_PROVIDER_ID_MODEL_OPTIONS,
  LLM_PROVIDER_OPTIONS,
  LLM_TEMPERATURE_SLIDER_LIMITS,
  STT_MODEL_ID_OPTIONS,
  TTS_MODEL_OPTIONS,
} from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  setLlmMaxTokens,
  setLlmModel,
  setLlmModelId,
  setLlmTemperature,
  setPromptTemplateId,
  setSttModelId,
  setTtsModelId,
} from '../../../../redux/reducers';
import { useGetPromptTemplatesQuery } from '../../../../services';
import { LLMProviderID, PromptTemplatesSelectMode } from '../../../../types';
import ProspectFieldGroup from '../ProspectFieldGroup';
import ProspectPageField from '../ProspectPageField';
import ProspectSlider from '../ProspectSlider';
import TabSection from '../TabSection';

const TECHNICAL_CONFIG_SELECT_WIDTH = 340;

const TechnicalConfiguration = () => {
  const dispatch = useAppDispatch();
  const {
    prospectType,
    fields: { ttsModelId, sttModelId, llmModel, llmModelId, llmTemperature, llmMaxTokens, promptTemplateId },
  } = useAppSelector((state) => state.prospect);

  const { data: promptTemplates = [], isLoading: isPromptTemplatesLoading } = useGetPromptTemplatesQuery({
    selectMode: PromptTemplatesSelectMode.SELECT,
    purpose: prospectType,
  });

  const promptTemplateOptions = promptTemplates.map(({ name, id }) => ({ label: name, value: id }));
  const selectedPromptTemplate = promptTemplateOptions.find(({ value }) => value === promptTemplateId);

  const llmModelOptions = llmModel ? LLM_PROVIDER_ID_MODEL_OPTIONS[llmModel] : [];

  const speechFields = [
    {
      fullWidth: true,
      label: 'Text to speech model',
      required: true,
      content: (
        <Select
          options={TTS_MODEL_OPTIONS}
          selected={findOptionByValue(TTS_MODEL_OPTIONS, ttsModelId)}
          onChange={(newValue) => dispatch(setTtsModelId(newValue as string))}
        />
      ),
    },
    {
      fullWidth: true,
      label: 'Speech to text model',
      required: true,
      content: (
        <Select
          options={STT_MODEL_ID_OPTIONS}
          selected={findOptionByValue(STT_MODEL_ID_OPTIONS, sttModelId)}
          onChange={(newValue) => dispatch(setSttModelId(newValue as string))}
        />
      ),
    },
  ];

  const llmFields = [
    {
      label: 'LLM provider',
      required: true,
      width: TECHNICAL_CONFIG_SELECT_WIDTH,
      content: (
        <Select
          options={LLM_PROVIDER_OPTIONS}
          selected={findOptionByValue(LLM_PROVIDER_OPTIONS, llmModel)}
          onChange={(newValue) => {
            dispatch(setLlmModel(newValue as LLMProviderID));
            // Reset LLM ID on changing the provider.
            dispatch(setLlmModelId(undefined));
          }}
        />
      ),
    },
    {
      label: 'LLM',
      required: true,
      width: TECHNICAL_CONFIG_SELECT_WIDTH,
      content: (
        <Select
          disabled={!llmModel}
          options={llmModelOptions}
          selected={findOptionByValue(llmModelOptions, llmModelId)}
          onChange={(newValue) => dispatch(setLlmModelId(newValue as string))}
        />
      ),
    },
  ];

  const llmConfigFields = [
    {
      label: 'LLM temperature',
      required: true,
      content: (
        <ProspectSlider
          leftLabel={llmTemperature.toFixed(1)}
          rightLabel={LLM_TEMPERATURE_SLIDER_LIMITS.max.toFixed(1)}
          max={LLM_TEMPERATURE_SLIDER_LIMITS.max}
          min={LLM_TEMPERATURE_SLIDER_LIMITS.min}
          step={LLM_TEMPERATURE_SLIDER_LIMITS.step}
          value={llmTemperature}
          onChange={(newValue) => dispatch(setLlmTemperature(newValue))}
        />
      ),
    },
    {
      label: 'LLM max tokens',
      required: true,
      content: (
        <ProspectSlider
          leftLabel={llmMaxTokens}
          rightLabel={LLM_MAX_TOKENS_SLIDER_LIMITS.max}
          max={LLM_MAX_TOKENS_SLIDER_LIMITS.max}
          min={LLM_MAX_TOKENS_SLIDER_LIMITS.min}
          step={LLM_MAX_TOKENS_SLIDER_LIMITS.step}
          value={llmMaxTokens}
          onChange={(newValue) => dispatch(setLlmMaxTokens(newValue))}
        />
      ),
    },
  ];

  return (
    <TabSection title="Technical configuration">
      <ProspectFieldGroup fields={speechFields} />
      <ProspectFieldGroup fields={llmFields} />
      <ProspectFieldGroup fields={llmConfigFields} />
      <ProspectPageField
        label="Prompt template"
        required
        width={TECHNICAL_CONFIG_SELECT_WIDTH}
        content={
          <Select
            loading={isPromptTemplatesLoading}
            selected={selectedPromptTemplate}
            options={promptTemplateOptions}
            onChange={(newValue) => dispatch(setPromptTemplateId(newValue as string))}
          />
        }
      />
    </TabSection>
  );
};

export default TechnicalConfiguration;
