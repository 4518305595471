import { ComponentSize, TextColor } from '../../../types';
import { ButtonColor, TextButton } from '../Button';
import { ButtonGroup } from '../ButtonGroup';
import { Dialog } from '../Dialog';
import { Divider } from '../Divider';
import { Typography } from '../Typography';
import { DesignerModalProps } from './DesignerModal.types';
import DesignerModalCard from './DesignerModalCard';

const DESIGNER_MODAL_WIDTH = 485;

const DesignerModal = ({
  footer,
  isOpen,
  options,
  title,
  onCancel,
  onNext,
  children,
  disabled,
  loading,
}: DesignerModalProps) => {
  return (
    <Dialog isOpen={isOpen} title={title} width={DESIGNER_MODAL_WIDTH} onClose={onCancel}>
      <div className="flex flex-col gap-4">
        {options.map((option) => (
          <DesignerModalCard key={option.id} {...option} />
        ))}
        {children && <Divider />}
        {children}
        <Typography color={TextColor.SECONDARY}>{footer}</Typography>
        <ButtonGroup size={ComponentSize.MEDIUM} loading={loading}>
          <TextButton onClick={onNext} text="Next" color={ButtonColor.PRIMARY} disabled={disabled} />
          <TextButton onClick={onCancel} text="Cancel" />
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export default DesignerModal;
