import { useCallback } from 'react';
import { useAppDispatch, useAppSelector, useHandleApiResponse } from '../../../hooks';
import { setScorecardTemplates } from '../../../redux/reducers';
import { useDuplicateScorecardTemplateMutation } from '../../../services';
import { ComponentSize, ScorecardTemplate, ScorecardTemplateActionType, ScorecardTemplateStatus } from '../../../types';
import { ButtonColor, Icon, IconButton } from '../../../components';

const ERROR_MSG = 'Failed to duplicate scorecard';
const SUCCESS_MSG = 'Scorecard duplicated successfully!';

interface ScorecardRowActionsProps {
  scorecard: ScorecardTemplate;
  onEditScorecard: (scorecard: ScorecardTemplate) => void;
  onDuplicateScorecard: () => void;
}

const ScorecardRowActions = ({ scorecard, onEditScorecard, onDuplicateScorecard }: ScorecardRowActionsProps) => {
  // Redux
  const dispatch = useAppDispatch();
  const { name, picture } = useAppSelector((state) => state.auth.user) || {};
  const scorecardTemplatesRedux = useAppSelector((state) => state.scorecardTemplate.templates);

  // Hooks
  const handleApiResponse = useHandleApiResponse();

  // Mutations
  const [duplicateScorecardTemplate, { isLoading: isDuplicating }] = useDuplicateScorecardTemplateMutation();

  // On duplicate success handler
  const onDuplicateSuccess = useCallback(
    (duplicatedTemplate: ScorecardTemplateActionType) => {
      // Scroll to top of the table
      onDuplicateScorecard();

      // Create duplicated scorecard template
      const duplicatedScorecardTemplate: ScorecardTemplate = {
        ...duplicatedTemplate,
        // Current user is the last editor
        lastEditor: {
          name: name || '',
          picture: picture || '',
        },
        // Copy over the tags from the original scorecard
        tags: scorecard.tags || [],
        // Copy over the sections from the original scorecard
        sections: scorecard.sections || [],
      };

      // Update redux state with the duplicated scorecard template added to the front of the array
      dispatch(setScorecardTemplates([duplicatedScorecardTemplate, ...scorecardTemplatesRedux]));
    },
    [onDuplicateScorecard, dispatch, scorecardTemplatesRedux, name, picture, scorecard]
  );

  // Handles Scorecard Duplication
  const handleDuplicateScorecard = useCallback(
    async (scorecard: ScorecardTemplate) => {
      try {
        const response = await duplicateScorecardTemplate(scorecard.id);
        const duplicatedTemplate = response.data?.data.scorecardTemplate;

        if (!duplicatedTemplate) return;

        handleApiResponse({
          response,
          errorMsg: ERROR_MSG,
          successMsg: SUCCESS_MSG,
          onSuccess: () => onDuplicateSuccess(duplicatedTemplate),
        });
      } catch (error) {
        console.error(`${ERROR_MSG}: ${error}`);
      }
    },
    [duplicateScorecardTemplate, handleApiResponse, onDuplicateSuccess]
  );

  const isScorecardArchived = scorecard.status === ScorecardTemplateStatus.ARCHIVED;

  return (
    <div className="flex gap-1">
      <IconButton
        icon={Icon.EDIT}
        color={ButtonColor.SECONDARY}
        size={ComponentSize.MEDIUM}
        onClick={() => onEditScorecard(scorecard)}
        disabled={isScorecardArchived}
        tooltip="Edit"
      />
      <IconButton
        icon={Icon.DUPLICATE}
        color={ButtonColor.SECONDARY}
        size={ComponentSize.MEDIUM}
        onClick={() => handleDuplicateScorecard(scorecard)}
        loading={isDuplicating}
        tooltip="Duplicate"
      />
    </div>
  );
};

export default ScorecardRowActions;
