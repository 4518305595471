import dayjs from 'dayjs';
import { DatePickerRange } from '../components';
import { AverageByPeriod, DateUnit } from '../types';

/**
 * Default date range for analytics as the current month.
 */
export const DEFAULT_ANALYTICS_DATE_RANGE: DatePickerRange = {
  start: dayjs().startOf(DateUnit.MONTH).toDate(),
  end: dayjs().endOf(DateUnit.MONTH).toDate(),
};

export const DEFAULT_ANALYTICS_AVERAGE_BY = AverageByPeriod.DAY;

export const AVERAGE_BY_OPTIONS = [
  { label: 'Day', value: AverageByPeriod.DAY },
  { label: 'Week', value: AverageByPeriod.WEEK },
];
