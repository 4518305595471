import { useCallback, useState } from 'react';
import { TextButton, TextInput, Icon, Badge } from '../../../../shared';
import { ComponentSize } from '../../../../../types';
import { useUpdateOrganizationSettingsMutation } from '../../../../../services';
import { useHandleApiResponse } from '../../../../../hooks';
import OrgTabSectionTitle from './OrgTabSectionTitle';

const ERROR_MSG = 'Failed to update vocabulary';

interface CustomVocabularyProps {
  vocabulary: string[];
}

const CustomVocabulary = ({ vocabulary }: CustomVocabularyProps) => {
  const [newVocab, setNewVocab] = useState('');
  // We are using this state to show a loading state on add button
  // Instead of isLoading from mutation because we don't want to show a loading state in case of word removal
  const [isAddingVocab, setIsAddingVocab] = useState(false);
  const [error, setError] = useState('');

  // API Mutation
  const [updateOrganizationSettings] = useUpdateOrganizationSettingsMutation();

  // Custom Hooks
  const handleApiResponse = useHandleApiResponse(true);

  // Handle updating vocabulary
  const handleUpdateCustomVocabulary = useCallback(
    async (newVocabulary: string[]) => {
      try {
        const response = await updateOrganizationSettings({
          vocabulary: newVocabulary,
        });
        handleApiResponse({
          response,
          errorMsg: ERROR_MSG,
          onError: (error) => setError(error.message),
        });
      } catch (error) {
        console.error(`${ERROR_MSG}: ${error}`);
        setError(ERROR_MSG);
      }
    },
    [updateOrganizationSettings, handleApiResponse]
  );

  // Handle removing existing vocabulary
  const handleRemoveVocab = useCallback(
    (wordToRemove: string) => {
      handleUpdateCustomVocabulary(vocabulary.filter((word) => word !== wordToRemove));
    },
    [vocabulary, handleUpdateCustomVocabulary]
  );

  // Handle adding new vocabulary
  const handleAddVocab = useCallback(async () => {
    // Check if word is empty or only whitespace
    if (!newVocab.trim()) {
      setError('Please enter a word');
      return;
    }

    // Check if word already exists (case-insensitive)
    const isWordAlreadyExists = vocabulary.some((word) => word.toLowerCase() === newVocab.toLowerCase());
    if (isWordAlreadyExists) {
      setError('This word already exists');
      return;
    }

    setIsAddingVocab(true);
    await handleUpdateCustomVocabulary([...vocabulary, newVocab]);
    setNewVocab('');
    setIsAddingVocab(false);
  }, [vocabulary, newVocab, handleUpdateCustomVocabulary]);

  // Handle input change
  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    setNewVocab(e.target.value);
  }, []);

  // Handle key down
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleAddVocab();
      }
    },
    [handleAddVocab]
  );

  return (
    <div className="flex flex-col gap-4">
      <OrgTabSectionTitle
        title="Custom vocabulary"
        iconTooltip="Improve transcription by adding specialized terminology or uncommon proper nouns."
      />
      <div className="flex flex-col gap-2">
        <div className="flex items-start gap-4">
          <TextInput
            placeholder="e.g. Your organization name"
            value={newVocab}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            error={error}
          />
          <TextButton text="Add" onClick={handleAddVocab} disabled={!newVocab} loading={isAddingVocab} />
        </div>
        {!!vocabulary?.length && (
          <div className="flex flex-wrap gap-2">
            {vocabulary.map((word) => (
              <Badge
                size={ComponentSize.MEDIUM}
                key={word}
                label={word}
                endIcon={Icon.CLOSE}
                onClick={() => handleRemoveVocab(word)}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomVocabulary;
