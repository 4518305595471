import clsx from 'clsx';
import { ComponentSize, TextColor } from '../../../types';
import { Tooltip } from '../Tooltip';
import { ICON_COLOR_TO_FILL_COLOR, ICON_COLOR_TO_STROKE_COLOR, ICON_MAP, ICON_SIZE } from './Icons.constants';
import { IconsProps } from './Icons.types';

const Icons = ({
  icon,
  className,
  color = TextColor.PRIMARY,
  fill,
  size = ComponentSize.SMALL,
  tooltip,
  onClick,
  preserveSize,
}: IconsProps) => {
  const IconComponent = ICON_MAP[icon];
  const iconSize = typeof size === 'number' ? size : ICON_SIZE[size];

  if (!IconComponent) return null;

  const getColorClassName = () => {
    const fillOrStrokeColor = fill ? ICON_COLOR_TO_FILL_COLOR[color] : ICON_COLOR_TO_STROKE_COLOR[color];
    return fillOrStrokeColor ?? color;
  };

  return (
    <Tooltip label={tooltip}>
      <IconComponent
        className={clsx(getColorClassName(), onClick && 'cursor-pointer', className)}
        style={preserveSize ? undefined : { width: iconSize, height: iconSize }} // Only apply size if not preserving original
        onClick={onClick}
      />
    </Tooltip>
  );
};

export default Icons;
