import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector, useHandleApiResponse } from '../../../hooks';
import { setScorecardTemplates } from '../../../redux/reducers';
import {
  useArchiveScorecardTemplateMutation,
  useCreateScorecardTemplateMutation,
  useDeleteScorecardTemplateMutation,
  useUpdateScorecardTemplateMutation,
} from '../../../services';
import {
  ScorecardSectionType,
  ScorecardTemplate,
  ScorecardTemplateActionType,
  ScorecardTemplateStatus,
  ScorecardTemplateType,
  TextColor,
} from '../../../types';
import {
  ButtonColor,
  ButtonVariant,
  ConfirmModal,
  Divider,
  TextButton,
  TypographySize,
  Typography,
  Icon,
  Icons,
} from '../../../components';
import ScorecardTemplateInUseModal from './ScorecardTemplateInUseModal';

const SAVE_ERROR_MSG = 'Failed to save scorecard template';
const SAVE_SUCCESS_MSG = 'Scorecard template saved successfully';

const ARCHIVE_ERROR_MSG = 'Failed to archive scorecard';
const ARCHIVE_SUCCESS_MSG = 'Scorecard archived successfully';

const DELETE_ERROR_MSG = 'Failed to delete scorecard';
const DELETE_SUCCESS_MSG = 'Scorecard deleted successfully';

interface ScorecardTemplateActionButtonsProps {
  handleCloseScorecardForm: () => void;
  handleAddSection: () => void;
  scorecardType: ScorecardTemplateType;
  scorecard?: ScorecardTemplate;
  scorecardName?: string;
  sections?: ScorecardSectionType[];
}

const ScorecardTemplateActionButtons = ({
  scorecard,
  scorecardName,
  scorecardType,
  sections,
  handleAddSection,
  handleCloseScorecardForm,
}: ScorecardTemplateActionButtonsProps) => {
  // Mutations
  const [updateScorecardTemplate, { isLoading: isUpdatingScorecard }] = useUpdateScorecardTemplateMutation();
  const [createScorecardTemplate, { isLoading: isCreatingScorecard }] = useCreateScorecardTemplateMutation();

  const [deleteScorecardTemplate, { isLoading: isDeletingScorecard }] = useDeleteScorecardTemplateMutation();
  const [archiveScorecardTemplate, { isLoading: isArchivingScorecard }] = useArchiveScorecardTemplateMutation();

  // State
  const [isInUseModalOpen, setIsInUseModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  // Hooks
  const handleApiResponse = useHandleApiResponse();

  // Redux
  const scorecardTemplatesRedux = useAppSelector((state) => state.scorecardTemplate.templates);
  const { name, picture } = useAppSelector((state) => state.auth.user) || {};
  const dispatch = useAppDispatch();

  // Check if the scorecard is in use
  const isScorecardInUse = scorecard?.isUsedInScorecards;

  // Validate the scorecard data before saving
  // Scorecard name, at least 1 valid section and at least 1 question within each section are required
  const isSaveScorecardValid = () => {
    return (
      scorecardName?.trim() !== '' &&
      !!sections?.length &&
      sections?.every(
        (section) =>
          section.title.trim() !== '' &&
          section.questions.length > 0 &&
          section.questions.every((question) => !!question.text && !!question.type)
      )
    );
  };

  // Update the getSectionsWithOrder function
  const getSectionsWithOrder = useCallback(() => {
    return sections?.map((section, sectionIndex) => ({
      ...section,
      order: sectionIndex,
      questions: section.questions.map((question, questionIndex) => ({
        ...question,
        order: questionIndex,
      })),
    }));
  }, [sections]);

  // On delete scorecard success
  const onDeleteScorecardSuccess = useCallback(() => {
    // Update the scorecard templates in the redux store
    dispatch(setScorecardTemplates(scorecardTemplatesRedux.filter((template) => template.id !== scorecard?.id)));
    // Close the scorecard form
    handleCloseScorecardForm();
  }, [handleCloseScorecardForm, dispatch, scorecardTemplatesRedux, scorecard?.id]);

  // Delete a scorecard template
  const handleDeleteScorecard = useCallback(async () => {
    // If scorecard is not found/created, just close the modal on delete
    if (!scorecard?.id) {
      handleCloseScorecardForm();
      return;
    }

    try {
      const response = await deleteScorecardTemplate(scorecard.id);

      await handleApiResponse({
        response,
        errorMsg: DELETE_ERROR_MSG,
        successMsg: DELETE_SUCCESS_MSG,
        onSuccess: () => onDeleteScorecardSuccess(),
      });
    } catch (error) {
      console.error(`${DELETE_ERROR_MSG}: ${error}`);
    }
  }, [scorecard, deleteScorecardTemplate, handleApiResponse, onDeleteScorecardSuccess]);

  // On archive scorecard success
  const onArchiveScorecardSuccess = useCallback(() => {
    if (!scorecard?.id) return;

    // Update the scorecard status and move it to the end
    const updatedTemplates = scorecardTemplatesRedux.filter((template) => template.id !== scorecard.id);
    updatedTemplates.push({
      ...scorecard,
      status: ScorecardTemplateStatus.ARCHIVED,
    });
    dispatch(setScorecardTemplates(updatedTemplates));

    // Close the scorecard form
    handleCloseScorecardForm();
  }, [handleCloseScorecardForm, dispatch, scorecardTemplatesRedux, scorecard?.id]);

  // Archive a scorecard template
  const handleArchiveScorecard = useCallback(async () => {
    if (!scorecard?.id) return;

    try {
      const response = await archiveScorecardTemplate(scorecard.id);

      await handleApiResponse({
        response,
        errorMsg: ARCHIVE_ERROR_MSG,
        successMsg: ARCHIVE_SUCCESS_MSG,
        onSuccess: () => onArchiveScorecardSuccess(),
      });
    } catch (error) {
      console.error(`${ARCHIVE_ERROR_MSG}: ${error}`);
    }
  }, [scorecard?.id, archiveScorecardTemplate, handleApiResponse, onArchiveScorecardSuccess]);

  const onSaveScorecardSuccess = useCallback(
    (newScorecardTemplate: ScorecardTemplateActionType) => {
      const updatedScorecardTemplates = [
        {
          ...newScorecardTemplate,
          // Set the last editor to the current user
          lastEditor: {
            name: name || '',
            picture: picture || '',
          },
        },
        ...scorecardTemplatesRedux,
      ];
      // Update the scorecard templates in the redux store
      dispatch(setScorecardTemplates(updatedScorecardTemplates));
      // Close the scorecard form
      handleCloseScorecardForm();
    },
    [handleCloseScorecardForm, dispatch, scorecardTemplatesRedux, name, picture]
  );

  const handleCreateScorecard = useCallback(
    async (name: string) => {
      // Get the sections with order
      const sectionsWithOrder = getSectionsWithOrder();
      // Create the template data
      const templateData = { name, sections: sectionsWithOrder, type: scorecardType };

      try {
        const response = await createScorecardTemplate(templateData);
        const newScorecardTemplate = response.data?.data.scorecardTemplate;

        await handleApiResponse({
          response,
          errorMsg: SAVE_ERROR_MSG,
          successMsg: SAVE_SUCCESS_MSG,
          onSuccess: () => {
            if (!newScorecardTemplate) return;
            onSaveScorecardSuccess(newScorecardTemplate);
          },
        });
      } catch (error) {
        console.error(`${SAVE_ERROR_MSG}: ${error}`);
      }
    },
    [createScorecardTemplate, handleApiResponse, onSaveScorecardSuccess, getSectionsWithOrder]
  );

  const onEditScorecardSuccess = useCallback(
    (updatedScorecardTemplate: ScorecardTemplateActionType) => {
      // Filter out existing template
      const filteredTemplates = scorecardTemplatesRedux.filter(
        (template) => template.id !== updatedScorecardTemplate.id
      );

      // Update the scorecard templates in the redux store
      const updatedScorecardTemplates = [
        {
          ...updatedScorecardTemplate,
          lastEditor: {
            name: name || '',
            picture: picture || '',
          },
        },
        ...filteredTemplates, // Put existing templates after the edited one
      ];

      dispatch(setScorecardTemplates(updatedScorecardTemplates));
      // Close the scorecard form
      handleCloseScorecardForm();
    },
    [handleCloseScorecardForm, dispatch, scorecardTemplatesRedux, name, picture]
  );

  const handleEditScorecard = useCallback(async () => {
    if (!scorecard?.id) return;

    const sectionsWithOrder = getSectionsWithOrder();
    const templateData = {
      name: scorecardName,
      sections: sectionsWithOrder,
      tags: scorecard?.tags?.map((tag) => tag.id),
    };

    try {
      const response = await updateScorecardTemplate({ id: scorecard.id, ...templateData });
      const updatedScorecardTemplate = response.data?.data.scorecardTemplate;

      await handleApiResponse({
        response,
        errorMsg: SAVE_ERROR_MSG,
        successMsg: SAVE_SUCCESS_MSG,
        onSuccess: () => {
          if (!updatedScorecardTemplate) return;
          onEditScorecardSuccess(updatedScorecardTemplate);
        },
      });
    } catch (error) {
      console.error(`${SAVE_ERROR_MSG}: ${error}`);
    }
  }, [
    scorecard,
    updateScorecardTemplate,
    handleApiResponse,
    onEditScorecardSuccess,
    getSectionsWithOrder,
    scorecardName,
  ]);

  // Confirm the new scorecard name and save the scorecard
  const handleConfirmNewScorecard = useCallback(
    (newScorecardName: string) => {
      if (!sections?.length) return;

      handleCreateScorecard(newScorecardName || '');
      setIsInUseModalOpen(false);
    },
    [sections?.length, handleCreateScorecard]
  );

  // Handle the save button click
  const handleSaveButtonClick = useCallback(() => {
    // If the scorecard is already created, edit it
    if (scorecard?.id) {
      handleEditScorecard();
      return;
    }
    // If the scorecard is not created, create it
    handleCreateScorecard(scorecardName || '');
  }, [scorecard?.id, handleEditScorecard, handleCreateScorecard, scorecardName]);

  const destructiveAction = isScorecardInUse ? 'Archive' : 'Delete';
  const destructiveButtonOnClick = isScorecardInUse ? handleArchiveScorecard : handleDeleteScorecard;

  const isAIScorecard = scorecardType === ScorecardTemplateType.AI;

  const destructiveButtonConfirmText = (
    <>
      Are you sure you want to {destructiveAction.toLowerCase()}&nbsp;
      <span className="font-medium">{scorecardName}</span>? This action cannot be undone.
    </>
  );

  return (
    <div className="sticky bottom-0 flex flex-col bg-white">
      <Divider />
      <div className="flex justify-between p-8">
        <TextButton text="Add section" onClick={handleAddSection} variant={ButtonVariant.OUTLINE} />

        {isAIScorecard && (
          <div className="flex items-center gap-1">
            <Icons icon={Icon.WAND} color={TextColor.AI} />
            <Typography size={TypographySize.CAPTION} color={TextColor.AI}>
              AI scorecard
            </Typography>
          </div>
        )}

        {!isAIScorecard && <Typography size={TypographySize.CAPTION}>Manual scorecard</Typography>}

        <div className="flex justify-end gap-4">
          <TextButton
            text={destructiveAction}
            onClick={() => setIsConfirmModalOpen(true)}
            variant={ButtonVariant.OUTLINE}
            color={ButtonColor.DESTRUCTIVE}
          />

          {isScorecardInUse && (
            <TextButton
              text="Save as"
              loading={isCreatingScorecard}
              onClick={() => setIsInUseModalOpen(true)}
              disabled={!isSaveScorecardValid()}
            />
          )}

          {!isScorecardInUse && (
            <TextButton
              text="Save"
              loading={isUpdatingScorecard || isCreatingScorecard}
              onClick={handleSaveButtonClick}
              disabled={!isSaveScorecardValid()}
            />
          )}
        </div>
      </div>
      <ScorecardTemplateInUseModal
        isOpen={isInUseModalOpen}
        setIsOpen={setIsInUseModalOpen}
        initialScorecardName={`Copy of ${scorecardName}`}
        onConfirm={handleConfirmNewScorecard}
      />
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        buttonText={destructiveAction}
        setIsOpen={setIsConfirmModalOpen}
        onConfirm={destructiveButtonOnClick}
        isLoading={isDeletingScorecard || isArchivingScorecard}
        title={`${destructiveAction} scorecard`}
        confirmText={destructiveButtonConfirmText}
        destructive
      />
    </div>
  );
};

export default ScorecardTemplateActionButtons;
