import { Getter, PaginationState, Row } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Avatar,
  AvatarGroup,
  ButtonColor,
  ButtonVariant,
  DataTableColumnDef,
  DataTableWithActions,
  Icon,
  IconButton,
  Icons,
  MediaPlayer,
  SortableHeader,
  Typography,
  TypographySize,
  UserBadge,
} from '../../components';
import { DATE_COLUMN_WIDTH, PAGINATION_PAGE_SIZE } from '../../constants';
import { useAppDispatch, useAppSelector, useGetFiltersFromParams } from '../../hooks';
import { closeMediaPlayer, setCalls } from '../../redux/reducers';
import { useGetCallsMutation } from '../../services';
import {
  Call,
  CallSortingFilters,
  ComponentSize,
  CustomSortingState,
  DateFormat,
  PracticeProspect,
  ReviewFilters,
  Roles,
  SortingOrder,
  TextColor,
  TimeFormat,
} from '../../types';
import { conditionalArray, conditionalObject, formatSecondsToDuration, getCallerInfo } from '../../utils';
import CallDetailsDrawer from './CallDetailsDrawer';
import FlaggedIndicator from './FlaggedIndicator';
import ReviewFooter from './ReviewFooter';
import useCallActions from './useCallActions';

const DURATION_COLUMN_WIDTH = 86;
const FLAGGED_COLUMN_WIDTH = 24;
const SCORE_COLUMN_WIDTH = 80;
const COMMENTS_COLUMN_WIDTH = 120;

const COMMENTS_AVATAR_SIZE = 20;

/**
 * Gets the user filter based on the user value.
 * If the user is a phone number, filter by incoming phone number.
 * If the user is a user ID, filter by user ID.
 */
const parseUserFilter = (users?: string[]) => {
  if (!users) return {};

  const phoneNumbers: string[] = [];
  const userIds: string[] = [];

  users.forEach((user) => {
    if (user.startsWith('+')) {
      phoneNumbers.push(user);
    } else {
      userIds.push(user);
    }
  });

  return {
    ...(phoneNumbers.length > 0 && { incomingPhoneNumber: phoneNumbers }),
    ...(userIds.length > 0 && { userId: userIds }),
  };
};

const ReviewPage = () => {
  // State to track the active row.
  const [activeRowIndex, setActiveRowIndex] = useState<number | undefined>(undefined);
  // State to track the clicked row.
  const [clickedRowIndex, setClickedRowIndex] = useState<number | undefined>(undefined);
  // State to track the hovered row.
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | undefined>(undefined);
  // State for pagination settings.
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: PAGINATION_PAGE_SIZE,
  });
  // State for sorting settings.
  const [sorting, setSorting] = useState<CustomSortingState>({
    sortBy: CallSortingFilters.START_TIME,
    sortOrder: SortingOrder.DESC,
  });

  // Redux
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const { calls, currentCallDetails } = useAppSelector((state) => state.review);
  const isSalesRep = useMemo(() => user?.role === Roles.SALES_REP, [user?.role]);

  // Mutations
  // Fetch calls with loading state management.
  const [getCalls, { data, isLoading }] = useGetCallsMutation();
  const totalPages = data?.pagination.totalPages || 0;

  // Ref to the media player element.
  const mediaPlayerRef = useRef<HTMLDivElement | null>(null);

  // Custom hooks
  const filters: ReviewFilters = useGetFiltersFromParams();

  const clickedCall = useMemo(() => {
    if (clickedRowIndex === undefined) return;
    return calls[clickedRowIndex];
  }, [calls, clickedRowIndex]);

  const actions = useCallActions(() => setClickedRowIndex(undefined), clickedCall);

  // Reset to page 1 when filters change.
  useEffect(() => {
    if (pagination.pageIndex !== 1) {
      setPagination((prev) => ({ ...prev, pageIndex: 1 }));
    }
  }, [filters, setPagination]);

  const fetchCalls = useCallback(() => {
    getCalls({
      isFlagged: filters.flagged,
      personaId: filters.prospect,
      startTime: filters.callDate ? [filters.callDate] : undefined,
      tags: filters.tags,
      pagination,
      sorting,
      ...conditionalObject(!isSalesRep, parseUserFilter(filters.user)),
    });
  }, [isSalesRep, filters, pagination, sorting]);

  // Fetch calls whenever filters or pagination settings change.
  useEffect(() => {
    fetchCalls();
  }, [fetchCalls]);

  // This effect sets the list of calls for the Review Page in state.
  // It allows for optimistic updates to the state instead of refetching from the server.
  useEffect(() => {
    if (isLoading) return;
    dispatch(setCalls(data?.calls || []));
    return () => {
      dispatch(setCalls([]));
    };
  }, [data?.calls, isLoading, dispatch, setCalls]);

  // Set the active row index to the call that is currently being played.
  useEffect(() => {
    if (currentCallDetails) {
      const index = calls.findIndex((call) => call.callSid === currentCallDetails.call.callSid);
      if (index === -1) {
        // If the call is not in the list, reset the active row index.
        // Happens when the user filters the calls and the call currently being played is no longer in the list.
        setActiveRowIndex(undefined);
      } else if (index !== activeRowIndex) {
        // If the call is in the list and the active row index changed,
        // update the active row index.
        setActiveRowIndex(index);
      }
    } else if (activeRowIndex !== undefined) {
      // Reset the active row index when the media player closes.
      setActiveRowIndex(undefined);
    }

    // We purposely don't include activeRowIndex in the dependency array
    // because we only want to run this effect when the callSid or filters change.
  }, [currentCallDetails, calls, filters, setActiveRowIndex]);

  // Reset the call details when the user navigates away from the Review page.
  useEffect(() => {
    return () => {
      dispatch(closeMediaPlayer());
    };
  }, [dispatch]);

  if (!user) return null;

  const renderSortableHeader = useCallback(
    (sortingId: CallSortingFilters, title: string) => (
      <SortableHeader title={title} sorting={sorting} setSorting={setSorting} sortingId={sortingId} />
    ),
    [sorting, setSorting]
  );

  // Define columns for the data table.
  const columns: DataTableColumnDef<Call>[] = useMemo(
    () => [
      {
        accessorKey: 'isFlagged',
        header: () => '',
        width: FLAGGED_COLUMN_WIDTH,
        cell: ({ row }) => {
          const { isFlaggedToUser, flaggedUserIds } = row.original;
          const isFlagged = !!isFlaggedToUser || !!flaggedUserIds?.length;
          if (!isFlagged) return null;

          const index = row.index;
          const isActive = hoveredRowIndex === index || activeRowIndex === index || clickedRowIndex === index;
          return <FlaggedIndicator call={row.original} isActive={isActive} />;
        },
      },
      {
        accessorKey: 'callDuration',
        header: () => renderSortableHeader(CallSortingFilters.CALL_DURATION, 'Duration'),
        width: DURATION_COLUMN_WIDTH,
        cell: ({ getValue }: { getValue: Getter<number | undefined> }) => {
          const duration = getValue() || 0;
          return formatSecondsToDuration(duration);
        },
      },
      // Conditionally include the "Caller" column if the user is not a sales representative.
      // This is because a sales rep can only see their own Review.
      ...conditionalArray(!isSalesRep, {
        accessorKey: 'caller',
        header: () => renderSortableHeader(CallSortingFilters.CALLER, 'Caller'),
        cell: ({ row }: { row: Row<Call> }) => {
          const call = row.original;
          const caller = getCallerInfo(call, user);
          if (!caller) return 'N/A';

          const { name, picture } = caller;
          return <UserBadge title={name} imgSrc={picture} hideAvatarIfNoImgSrc />;
        },
      }),
      {
        accessorKey: 'practiceProspect',
        header: () => renderSortableHeader(CallSortingFilters.PROSPECT, 'Prospect'),
        cell: ({ getValue }: { getValue: Getter<PracticeProspect> }) => {
          const prospect = getValue();
          const { firstName, lastName, jobTitle, accountName } = prospect;
          const title = `${firstName} ${lastName}`;
          const subtitle = `${accountName} | ${jobTitle}`;
          return <UserBadge title={title} subtitle={subtitle} />;
        },
      },
      {
        accessorKey: 'numberOfComments',
        header: () => null,
        width: COMMENTS_COLUMN_WIDTH,
        cell: ({ row, getValue }: { row: Row<Call>; getValue: Getter<number> }) => {
          const numberOfComments = getValue();
          if (numberOfComments === 0) return null;
          return (
            <div className="flex items-center gap-1">
              <Icons icon={Icon.COMMENT} />
              <Typography size={TypographySize.CAPTION}>{numberOfComments}</Typography>
              <AvatarGroup className="!-space-x-2.5" size={COMMENTS_AVATAR_SIZE}>
                {row.original.usersCommented?.map((user) => (
                  <Avatar label={user.name} key={user.id} imgSrc={user.picture} />
                )) || []}
              </AvatarGroup>
            </div>
          );
        },
      },
      {
        accessorKey: 'scorecard',
        header: () => renderSortableHeader(CallSortingFilters.POINTS_PERCENTAGE, 'Score'),
        width: SCORE_COLUMN_WIDTH,
        cell: ({ getValue }: { getValue: Getter<{ pointsPercentage: number | null }> }) => {
          const scorecard = getValue();
          if (!scorecard || scorecard?.pointsPercentage === null) return '';
          return `${Math.round(scorecard.pointsPercentage * 100)}%`;
        },
      },
      {
        accessorKey: 'startTime',
        header: () => renderSortableHeader(CallSortingFilters.START_TIME, 'Call date'),
        width: DATE_COLUMN_WIDTH,
        cell: ({ getValue }: { getValue: Getter<Date> }) => {
          const dateStr = getValue();
          const dateObj = new Date(dateStr);
          return (
            <div className="flex flex-col gap-1">
              <Typography color={TextColor.SECONDARY} size={TypographySize.CAPTION}>
                {dayjs(dateObj).format(TimeFormat.SHORT_TIME)}
              </Typography>
              <Typography size={TypographySize.CAPTION}>{dayjs(dateObj).format(DateFormat.MONTH_DAY)}</Typography>
            </div>
          );
        },
      },
    ],
    [activeRowIndex, clickedRowIndex, hoveredRowIndex, isSalesRep, renderSortableHeader]
  );

  return (
    <>
      <div className="flex flex-col items-center gap-8">
        <div className="flex flex-col gap-2">
          <IconButton
            icon={Icon.FAST_FORWARD}
            variant={ButtonVariant.GHOST}
            color={ButtonColor.SECONDARY}
            onClick={fetchCalls}
            tooltip="Refresh"
            size={ComponentSize.X_SMALL}
          />
          <DataTableWithActions
            actions={actions}
            columns={columns}
            isLoading={isLoading}
            data={calls}
            activeRowIndex={activeRowIndex}
            clickedRowIndex={clickedRowIndex}
            setClickedRowIndex={setClickedRowIndex}
            setHoveredRowIndex={setHoveredRowIndex}
            paginationControls={{ pagination, totalPages, setPagination }}
          />
        </div>
        <ReviewFooter />
      </div>

      {/* Call Details Drawer */}
      <CallDetailsDrawer mediaPlayerRef={mediaPlayerRef} />
      {/* MediaPlayer Drawer */}
      <MediaPlayer ref={mediaPlayerRef} />
    </>
  );
};

export default ReviewPage;
