import { findOptionByValue, Select, TextInput } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setManagerNotes, setScorecardTemplateId } from '../../../../redux/reducers';
import { useGetScorecardTemplatesForSelectQuery } from '../../../../services';
import { getAIScorecardTemplateOptions } from '../../../../utils';
import ProspectPageField from '../ProspectPageField';
import TabSection from '../TabSection';

const AI_SCORECARD_SELECT_WIDTH = 340;

const ManagerDetails = () => {
  const dispatch = useAppDispatch();
  const { scorecardTemplateId, managerNotes } = useAppSelector((state) => state.prospect.fields);

  const { data: scorecardTemplates = [], isLoading: isLoadingTemplates } = useGetScorecardTemplatesForSelectQuery();

  // Get AI scorecard template options
  const scorecardTemplateOptions = getAIScorecardTemplateOptions(scorecardTemplates);

  // Find selected scorecard template
  const selectedScorecardTemplate = findOptionByValue(scorecardTemplateOptions, scorecardTemplateId);

  return (
    <TabSection title="Manager details">
      <ProspectPageField
        label="AI scorecard"
        width={AI_SCORECARD_SELECT_WIDTH}
        content={
          <Select
            clearable
            loading={isLoadingTemplates}
            options={scorecardTemplateOptions}
            selected={selectedScorecardTemplate}
            onChange={(newValue) => dispatch(setScorecardTemplateId(newValue))}
          />
        }
      />
      <ProspectPageField
        label="Manager notes"
        tooltip="Only visible to managers and administrators."
        content={<TextInput value={managerNotes} onChange={(e) => dispatch(setManagerNotes(e.target.value))} />}
      />
    </TabSection>
  );
};

export default ManagerDetails;
