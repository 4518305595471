import { PaginationState } from '@tanstack/react-table';
import clsx from 'clsx';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { PaginationType, TextColor } from '../../../types';
import { ButtonColor, ButtonVariant, TextButton } from '../Button';
import { Icon } from '../Icons';
import { Typography, TypographyWeight } from '../Typography';

const ELLIPSIS = '...';
// Total visible pages including ellipses and first/last pages.
const VISIBLE_PAGES = 9;
// How many pages before and after the current page should be displayed.
const PAGE_OFFSET = 2;
// Base classes for page number buttons.
const PAGE_NUMBER_BASE_CLASSES = 'flex h-8 w-8 items-center border justify-center';

const expandEllipsis = (range: (number | string)[], totalPages: number) => {
  while (range.length < VISIBLE_PAGES) {
    const leftEllipseIndex = range.indexOf(ELLIPSIS);
    const rightEllipseIndex = range.lastIndexOf(ELLIPSIS);
    if (range[leftEllipseIndex] !== -1) {
      // Expand left ellipse.
      const nextPage = (range[leftEllipseIndex + 1] as number) - 1;
      if (nextPage === 2) {
        // If nextPage is the page directly after the first page,
        // replace the ellipsis with nextPage.
        range.splice(leftEllipseIndex, 1, nextPage);
      } else {
        // Otherwise, add nextPage directly after the ellipsis.
        range.splice(leftEllipseIndex + 1, 0, nextPage);
      }
    } else if (range[rightEllipseIndex] !== -1) {
      // Expand right ellipse.
      const prevPage = (range[rightEllipseIndex - 1] as number) + 1;
      if (prevPage === totalPages - 1) {
        // If prevPage is the page directly before the last page,
        // replace the ellipsis with prevPage.
        range.splice(rightEllipseIndex, 1, prevPage);
      } else {
        // Otherwise, add prevPage directly before the ellipsis.
        range.splice(rightEllipseIndex, 0, prevPage);
      }
    } else break;
  }
};

const collapseEllipsis = (range: (number | string)[]) => {
  while (range.length > VISIBLE_PAGES) {
    const leftEllipseIndex = range.indexOf(ELLIPSIS);
    const rightEllipseIndex = range.lastIndexOf(ELLIPSIS);
    if (range[leftEllipseIndex] !== -1) {
      // Collapse left ellipse.
      range.splice(leftEllipseIndex + 1, 1);
    } else if (range[rightEllipseIndex] !== -1) {
      // Collapse right ellipse.
      range.splice(rightEllipseIndex - 1, 1);
    } else break;
  }
};

interface PaginationControlsProps {
  pagination: PaginationState;
  totalPages: number;
  onNextClick: () => void;
  onPrevClick: () => void;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  paginationType?: PaginationType;
}

const PaginationControls = ({
  pagination,
  totalPages,
  onNextClick,
  onPrevClick,
  setPagination,
  paginationType = PaginationType.PAGE,
}: PaginationControlsProps) => {
  const currentPage = pagination.pageIndex;

  // Get the range of page numbers to display in the pagination controls.
  const paginationRange = useMemo(() => {
    // If total pages are less than or equal to visible pages, return the full range.
    if (totalPages <= VISIBLE_PAGES) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    const range = [];
    // Calculate the basic range around the current page.
    let start = Math.max(2, currentPage - PAGE_OFFSET);
    let end = Math.min(totalPages - 1, currentPage + PAGE_OFFSET);

    // Adjust the range to ensure a minimum number of pages are visible.
    if (currentPage <= PAGE_OFFSET + 2) {
      end = Math.min(VISIBLE_PAGES - 2, totalPages - 1);
    } else if (currentPage >= totalPages - PAGE_OFFSET - 2) {
      start = Math.max(totalPages - VISIBLE_PAGES + 3, 2);
    }

    // Add the range of pages between start and end.
    for (let i = start; i <= end; i++) {
      range.push(i);
    }

    // Add ellipses if the start or end of the range is not the first or last page.
    if (start > 2) {
      range.unshift(ELLIPSIS);
    }

    if (end < totalPages - 1) {
      range.push(ELLIPSIS);
    }

    // Always include the first and last page.
    range.unshift(1);
    if (totalPages > 1) {
      range.push(totalPages);
    }

    // Ensure the range length is always exactly VISIBLE_PAGES.
    if (range.length < VISIBLE_PAGES) {
      expandEllipsis(range, totalPages);
    }

    if (range.length > VISIBLE_PAGES) {
      collapseEllipsis(range);
    }

    return range;
  }, [currentPage, totalPages]);

  // Render the page number buttons in case of page pagination.
  const renderPagePaginationNumbers = useCallback(
    () =>
      paginationRange.map((page, index) => {
        const isActive = currentPage === page;
        const isEllipsis = typeof page === 'string';

        const btnBgColor = isActive ? 'bg-primary' : '!border-base-300 hover:bg-base-200';
        const textColor = isActive ? TextColor.WHITE : undefined;
        const weight = isActive ? TypographyWeight.MEDIUM : undefined;

        const baseClasses = clsx(PAGE_NUMBER_BASE_CLASSES, 'border-transparent');
        const btnClasses = clsx(baseClasses, 'rounded-lg cursor-pointer', btnBgColor);
        const classes = isEllipsis ? baseClasses : btnClasses;

        // Disable clicking on active buttons to avoid refreshing the table.
        const onClick = isEllipsis || isActive ? undefined : () => setPagination({ ...pagination, pageIndex: page });
        return (
          <div className={classes} onClick={onClick} key={index}>
            <Typography weight={weight} color={textColor}>
              {page}
            </Typography>
          </div>
        );
      }),
    [paginationRange]
  );

  const renderCursorPaginationNumber = () => {
    return (
      <div className={clsx(PAGE_NUMBER_BASE_CLASSES, 'rounded-lg !border-base-300')}>
        <Typography>{currentPage}</Typography>
      </div>
    );
  };

  return (
    <div className="flex items-center space-x-1">
      <TextButton
        onClick={onPrevClick}
        disabled={currentPage === 1}
        startIcon={Icon.CHEVRON_LEFT}
        text="Prev"
        variant={ButtonVariant.OUTLINE}
        color={ButtonColor.SECONDARY}
      />
      {paginationType === PaginationType.PAGE && renderPagePaginationNumbers()}
      {paginationType === PaginationType.CURSOR && renderCursorPaginationNumber()}
      <TextButton
        onClick={onNextClick}
        disabled={currentPage === totalPages}
        endIcon={Icon.CHEVRON_RIGHT}
        text="Next"
        variant={ButtonVariant.OUTLINE}
        color={ButtonColor.SECONDARY}
      />
    </div>
  );
};

export default PaginationControls;
