import { useCallback, useMemo, useState } from 'react';
import { AlertType, Icon, MultiSelect } from '../..';
import { useHandleApiResponse, useToast } from '../../../hooks';
import { useCreateTagMutation, useGetTagsQuery } from '../../../services';
import { TagColor } from '../../../types';
import { ManageTagsProps } from './ManageTags.types';
import ManageTagsTrigger from './ManageTagsTrigger';

const CREATE_TAG_OPTION = 'CREATE_TAG_OPTION';

const ManageTags = ({
  isOpen,
  itemTags,
  setIsOpen,
  onClose,
  isLoading,
  inline,
  selectedTags,
  setSelectedTags,
}: ManageTagsProps) => {
  const [searchValue, setSearchValue] = useState('');

  const { data: tags = [], isLoading: isTagsLoading } = useGetTagsQuery();
  const [createTag, { isLoading: isCreatingTag }] = useCreateTagMutation();

  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse();

  const options = tags.map((tag) => ({ color: tag.color, label: tag.name, value: tag.id }));

  // The selected tags in the dropdown parsed into SelectOption format.
  const selectedOptions = useMemo(
    () => selectedTags.map((tag) => ({ label: tag.name, value: tag.id })),
    [selectedTags]
  );

  const handleCreateTag = useCallback(async () => {
    if (!searchValue.length) return;

    try {
      const randomColor = Object.values(TagColor)[Math.floor(Math.random() * Object.values(TagColor).length)];
      const response = await createTag({ name: searchValue, color: randomColor });
      handleApiResponse({
        response,
        errorMsg: `Failed to create tag "${searchValue}"`,
      });
    } catch (error) {
      showToast({ message: `Failed to create tag "${searchValue}"`, type: AlertType.ERROR });
      console.error(`Failed to create tag "${searchValue}": `, error);
    }
  }, [searchValue, createTag, showToast, handleApiResponse]);

  const onMultiSelectChange = useCallback(
    (newValues?: string[]) => {
      const newTags = tags.filter((tag) => newValues?.includes(tag.id));
      setSelectedTags(newTags);
    },
    [tags]
  );

  const multiSelectProps = inline
    ? { inline }
    : {
        controlledOpenProps: { isOpen, setIsOpen },
        onClose,
        customButton: <ManageTagsTrigger active={isOpen} showBadge={!itemTags.length} />,
      };

  return (
    <MultiSelect
      options={options}
      selected={selectedOptions}
      onChange={onMultiSelectChange}
      disabled={isTagsLoading}
      searchProps={{
        searchValue,
        setSearchValue,
        placeholder: 'Type to filter or create',
        disabled: isLoading || isCreatingTag,
        createOption: {
          icon: Icon.PLUS,
          label: `Create new tag "${searchValue}"`,
          value: CREATE_TAG_OPTION,
          onClick: handleCreateTag,
        },
      }}
      width="w-fit"
      {...multiSelectProps}
    />
  );
};

export default ManageTags;
