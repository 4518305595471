export enum LD_ContextKinds {
  USER = 'user',
}

export enum LD_ContextRoles {
  SUPER_ADMIN = 'super-admin',
  SYSTEM_USER = 'system-user',
}

export enum LD_FeatureFlags {
  UNDER_CONSTRUCTION = 'under-construction',
  RELEASE_GONG = 'release-gong',
  RELEASE_ORG_SETTINGS = 'release-org-settings',
  RELEASE_QUIZ = 'release-quiz',
  RELEASE_SELF_SERVE = 'release-self-serve',
  RELEASE_ANALYTICS_V3 = 'release-analytics-v-3',
}
