import { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler, MouseEventHandler } from 'react';
import { ComponentSize } from '../../../../types';
import { Icon } from '../../Icons';
import { BaseInputProps } from '../Input.types';

export enum TextInputType {
  EMAIL = 'email',
  NUMBER = 'number',
  PASSWORD = 'password',
  TEXT = 'text',
  TEL = 'tel',
  FILE = 'file',
}

export type TextInputProps = BaseInputProps & {
  /* Input end element. */
  endElement?: React.ReactNode;
  /* Input error. */
  error?: boolean | string;
  /* Input size. */
  size?: ComponentSize;
  /* Input start element. */
  startElement?: React.ReactNode;
  /* Input start icon. */
  startIcon?: Icon;
  /* Input onBlur event. */
  onBlur?: FocusEventHandler<HTMLInputElement>;
  /* Input onChange event. */
  onChange?: ChangeEventHandler<HTMLInputElement>;
  /* Input onClick event. */
  onClick?: MouseEventHandler<HTMLInputElement>;
  /* Input onKeyDown event. */
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  /* Input width. */
  width?: string | number;
} & (
  | {  /* Input type. */
      type: TextInputType.NUMBER;
      /* Show number controls for number inputs in Chrome, Safari, Edge, and Opera */
      showNumberControls?: boolean;
    }
  | {
      type?: Exclude<TextInputType, TextInputType.NUMBER>;
      showNumberControls?: never;
    }
);
