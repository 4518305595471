import { TooltipRootProps } from './Tooltip.types';
import TooltipContext from './TooltipContext';
import { useTooltip } from './useTooltip';

/** Tooltip component that provides context for tooltip functionality. */
function TooltipRoot({ children, ...options }: TooltipRootProps) {
  // Use the useTooltip hook to get tooltip state and methods.
  const tooltip = useTooltip(options);

  // Provide the tooltip context to its children.
  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
}

export default TooltipRoot;
