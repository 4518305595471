import { Dispatch, SetStateAction } from 'react';
import { PUBLIC_PROSPECT_TYPE_OPTIONS } from '../../../constants';
import { ProspectType } from '../../../types';
import { findOptionByValue, Select } from '../../shared';

interface ProspectPurposeSelectorProps {
  setNewProspectPurpose: Dispatch<SetStateAction<ProspectType | undefined>>;
  newProspectPurpose?: ProspectType;
}

const ProspectPurposeSelector = ({ newProspectPurpose, setNewProspectPurpose }: ProspectPurposeSelectorProps) => {
  return (
    <Select
      clearable
      placeholder="Select purpose"
      options={PUBLIC_PROSPECT_TYPE_OPTIONS}
      selected={findOptionByValue(PUBLIC_PROSPECT_TYPE_OPTIONS, newProspectPurpose)}
      onChange={(newValue) => setNewProspectPurpose(newValue as ProspectType)}
    />
  );
};

export default ProspectPurposeSelector;
