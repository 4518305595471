import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTableAction, Icon } from '../../components';
import { AppRoutes } from '../../constants';
import { useAppDispatch, useAppSelector, useFeatureFlag, useShareCall } from '../../hooks';
import { openMediaPlayer, openRequestReviewModal, openUnflagConfirmModal } from '../../redux/reducers';
import { Call, CallProcessingStatus, LD_FeatureFlags, Permissions, StatusColor } from '../../types';
import { conditionalArray } from '../../utils';

/**
 * Custom hook to generate actions for call rows in the data table.
 */
const useCallActions = (onClose: () => void, clickedCall?: Call): DataTableAction[] => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const selfServeFF = useFeatureFlag(LD_FeatureFlags.RELEASE_SELF_SERVE);
  const { onShare } = useShareCall(clickedCall?.callSid, undefined, onClose);

  const permissions = useAppSelector((state) => state.auth.user?.permissions || []);
  const canManageProspect = permissions.includes(Permissions.MANAGE_PROSPECT);

  // Check if the user can flag calls.
  const canFlag = permissions.includes(Permissions.FLAG_CALL);

  // Check if the user can unflag the call.
  const canUnflag = !canFlag && permissions.includes(Permissions.UNFLAG_CALL) && !!clickedCall?.isFlaggedToUser;

  // Handles opening the media player to the specified call.
  const onListen = useCallback(
    (call: Call) => {
      dispatch(openMediaPlayer({ call, autoPlay: true }));
    },
    [dispatch]
  );

  // Handles opening the request review modal.
  const onRequestReview = useCallback(
    (call: Call) => {
      dispatch(openRequestReviewModal({ call }));
    },
    [dispatch]
  );

  // Handles opening the call details page of the selected call
  // by navigating to the Review page with the callSid as a parameter
  // while preserving existing search params.
  const onOpen = useCallback(
    (call: Call) =>
      navigate({
        pathname: `${AppRoutes.REVIEW}/${call.callSid}`,
        search: window.location.search,
      }),
    [navigate, dispatch]
  );

  // Handles opening the unflag confirmation modal.
  const onUnflagCall = useCallback(
    (call: Call) => {
      dispatch(openUnflagConfirmModal(call.callSid));
    },
    [dispatch]
  );

  // Handles opening the prospect page for the selected call's prospect.
  const onEditProspect = useCallback(
    (call: Call) => {
      const personaId = call.practiceProspect.personaId;
      navigate(`${AppRoutes.PROSPECT}/${personaId}`);
    },
    [navigate]
  );

  // Handles opening the practice page filtered to the selected prospect.
  const onViewProspect = useCallback(
    (call: Call) => {
      const personaId = call.practiceProspect.personaId;
      const searchParams = new URLSearchParams({ prospect: personaId });
      navigate({ pathname: AppRoutes.PRACTICE, search: searchParams.toString() });
    },
    [navigate]
  );

  // Handles performing the provided call action
  // by calling the action with the clicked call if it exists and then closing the modal.
  const handleCallAction = useCallback(
    (action: (call: Call) => void) => {
      if (!clickedCall) return;
      action(clickedCall);
      onClose();
    },
    [clickedCall, onClose]
  );

  return [
    // Show 'Listen' action only if the call's audioPath exists.
    ...conditionalArray(!!clickedCall?.audioPath, {
      label: 'Listen',
      icon: Icon.PLAY,
      onClick: () => handleCallAction(onListen),
    }),
    // Show 'Processing...' if the call is still undergoing processing.
    ...conditionalArray(clickedCall?.processingStatus === CallProcessingStatus.PROCESSING, {
      label: 'Processing...',
      icon: Icon.OPEN,
    }),
    // Show 'Processing error' if processing has failed.
    ...conditionalArray(clickedCall?.processingStatus === CallProcessingStatus.PROCESSING_FAILED, {
      label: 'Processing error',
      icon: Icon.OPEN,
    }),
    // Show 'Open' action if processing has succeeded.
    ...conditionalArray(clickedCall?.processingStatus === CallProcessingStatus.PROCESSED, {
      label: 'Call details',
      icon: Icon.OPEN,
      onClick: () => handleCallAction(onOpen),
    }),
    ...conditionalArray(selfServeFF && canManageProspect, {
      label: 'Edit prospect',
      icon: Icon.EDIT,
      onClick: () => handleCallAction(onEditProspect),
    }),
    ...conditionalArray(!selfServeFF || !canManageProspect, {
      label: 'View prospect',
      icon: Icon.USER_SEARCH,
      onClick: () => handleCallAction(onViewProspect),
    }),
    // Show 'Request review' action if the user can flag calls.
    ...conditionalArray(canFlag, {
      label: 'Request review',
      icon: Icon.FLAG,
      iconFill: true,
      onClick: () => handleCallAction(onRequestReview),
    }),
    // Show 'Unflag call' action if the user has permission to unflag calls and the call is flagged.
    ...conditionalArray(canUnflag, {
      label: 'Unflag call',
      icon: Icon.FLAG,
      iconColor: StatusColor.WARNING,
      iconFill: true,
      onClick: () => handleCallAction(onUnflagCall),
    }),
    // Show 'Share' action if processing has succeeded.
    ...conditionalArray(clickedCall?.processingStatus === CallProcessingStatus.PROCESSED, {
      label: 'Share',
      icon: Icon.SHARE,
      successMsg: 'Link to call copied to clipboard',
      onClick: () => handleCallAction(onShare),
    }),
  ];
};

export default useCallActions;
