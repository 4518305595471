import clsx from 'clsx';
import { FocusEvent, KeyboardEvent, forwardRef, useCallback } from 'react';
import { ComponentSize } from '../../../../types';
import { Icon, Icons } from '../../Icons';
import '../Input.css';
import { getInputClasses } from '../Input.utils';
import InputError from '../InputError';
import { INPUT_SIZE } from './TextInput.constants';
import { TextInputProps, TextInputType } from './TextInput.types';
import { ButtonColor, ButtonVariant, IconButton } from '../../Button';

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className,
      disabled,
      startElement,
      startIcon,
      error,
      size = ComponentSize.SMALL,
      endElement,
      placeholder,
      type = TextInputType.TEXT,
      value = '',
      width,
      showNumberControls,
      onBlur,
      onChange,
      onClick,
      onKeyDown,
    },
    ref
  ) => {
    // The input is not editable if it has no onChange handler or is disabled.
    const readOnly = !onChange || disabled;
    // The input field is clickable if it has an onClick handler and is not disabled.
    const clickable = onClick && !disabled;

    // Base props for number input controls.
    const numberBaseControlProps = {
      size: ComponentSize.X_SMALL,
      variant: ButtonVariant.GHOST,
      color: ButtonColor.SECONDARY,
      disabled: disabled,
    };

    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLInputElement>) => {
        // If the input is not editable or clickable, do not handle the click event.
        if (readOnly && !clickable) return;

        // Stop event propagation and the default action unless the input is a file input.
        // This allows the file input to open the file explorer.
        if (type !== TextInputType.FILE) {
          e.stopPropagation();
          e.preventDefault();
        }

        if (onClick) {
          onClick(e);
        }
      },
      [readOnly, clickable, type, onClick]
    );

    const handleBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        if (onBlur) {
          event.stopPropagation();
          onBlur(event);
        }
      },
      [onBlur]
    );

    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        if (onKeyDown) {
          event.stopPropagation();
          onKeyDown(event);
        }
      },
      [onKeyDown]
    );

    // Handle number input changes through the number input controls.
    const handleNumberChange = useCallback(
      (number: number) => {
        if (onChange) {
          const newValue = Number(value) + number;
          onChange({ target: { value: newValue.toString() } } as React.ChangeEvent<HTMLInputElement>);
        }
      },
      [onChange, value]
    );

    const handleIncrement = useCallback(() => handleNumberChange(1), [handleNumberChange]);
    const handleDecrement = useCallback(() => handleNumberChange(-1), [handleNumberChange]);

    return (
      <div className={clsx(!width && 'w-full', className)} style={{ width }}>
        <label
          className={clsx(
            'input input-bordered',
            INPUT_SIZE[size],
            disabled && 'input-disabled',
            getInputClasses(disabled, error)
          )}
        >
          {startElement && <div className="min-w-fit">{startElement}</div>}
          {startIcon && <Icons icon={startIcon} />}
          <input
            ref={ref}
            type={type}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={onChange}
            className={clsx('w-full', clickable && 'cursor-pointer')}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            readOnly={readOnly}
            data-1p-ignore={type !== TextInputType.PASSWORD}
            onClick={handleClick}
          />
          {showNumberControls && (
            <div className="right-2 flex flex-col justify-center">
              <IconButton {...numberBaseControlProps} icon={Icon.CHEVRON_UP} onClick={handleIncrement} />
              <IconButton
                {...numberBaseControlProps}
                icon={Icon.CHEVRON_DOWN}
                onClick={handleDecrement}
                className="-mt-3"
              />
            </div>
          )}
          {endElement && endElement}
        </label>
        {typeof error === 'string' && <InputError message={error} />}
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';
export default TextInput;
