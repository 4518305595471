import { ChangeEvent } from 'react';

import { RadioColor } from '../Radio';

export interface RadioOption {
  value: string;
  label: string;
  color?: RadioColor;
}

export interface RadioGroupProps {
  /** Array of radio options */
  options: RadioOption[];
  /** The currently selected value */
  value: string;
  /** Callback function to handle changes in selection */
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  /** Additional class name for the radio group */
  className?: string;
  /** Whether the radio group is disabled */
  disabled?: boolean;
  /** Whether the radio group is view only, show only answered radio option */
  isViewMode?: boolean;
}

/** Representation of a yes or no answer */
export enum YesNoAnswer {
  YES = 'YES',
  NO = 'NO',
}
