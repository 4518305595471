import { useMemo } from 'react';
import { ButtonColor, findOptionByValue, Icon, Select, TextButton, TextInput } from '../../../../components';
import { VOICE_OPTIONS } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { randomizeVoice, setFirstMessage, setPersonaVoiceId } from '../../../../redux/reducers';
import { Permissions } from '../../../../types';
import { conditionalArray } from '../../../../utils';
import ProspectFieldGroup from '../ProspectFieldGroup';
import ProspectPageField from '../ProspectPageField';
import TabSection from '../TabSection';

const VOICE_SELECT_WIDTH = 300;

const Voice = () => {
  const dispatch = useAppDispatch();
  const { personaVoiceId, firstMessage } = useAppSelector((state) => state.prospect.fields);
  const user = useAppSelector((state) => state.auth.user);
  const isSuperAdmin = (user?.permissions || []).includes(Permissions.ADMIN_ACCESS);

  const selectedVoiceId = useMemo(() => {
    let selectedVoice = findOptionByValue(VOICE_OPTIONS, personaVoiceId.value);
    // If the voice is not recognized, add it to the options.
    if (!!personaVoiceId.value && !selectedVoice)
      selectedVoice = { label: 'Unrecognized voice', value: personaVoiceId.value };
    return selectedVoice;
  }, [personaVoiceId.value]);

  const voiceFields = useMemo(
    () => [
      {
        label: 'Voice',
        required: true,
        width: VOICE_SELECT_WIDTH,
        content: (
          <Select
            clearable // Clearable to allow randomizing.
            options={VOICE_OPTIONS}
            selected={selectedVoiceId}
            onChange={(value) => dispatch(setPersonaVoiceId(value))}
          />
        ),
      },
      // TODO: Add preview audio and enable for non-super-admins.
      ...conditionalArray(isSuperAdmin, {
        label: 'Preview audio',
        content: (
          <TextButton
            disabled
            color={ButtonColor.SECONDARY}
            startIcon={Icon.PLAY}
            text="0:00 / 0:00"
            onClick={() => {}}
          />
        ),
      }),
    ],
    [isSuperAdmin, selectedVoiceId]
  );

  return (
    <TabSection title="Voice" onRandomize={() => dispatch(randomizeVoice())}>
      <ProspectFieldGroup fields={voiceFields} />
      <ProspectPageField
        label="First message"
        required
        content={<TextInput value={firstMessage.value} onChange={(e) => dispatch(setFirstMessage(e.target.value))} />}
      />
    </TabSection>
  );
};

export default Voice;
