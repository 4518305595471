export const AppRoutes = {
  ANALYTICS: '/analytics',
  FINISH_SETUP: '/finish-setup',
  GONG_CALLBACK: '/gong-callback',
  HOME: '/',
  NOT_FOUND: '*', // Matches any path that hasn't matched above.
  PRACTICE: '/practice',
  PROMPT_TEMPLATES: '/prompt-templates',
  PROMPT_TEMPLATES_CREATE: '/prompt-templates/create',
  PROSPECT: '/practice/prospect',
  QUIZ: '/quiz',
  REVIEW: '/review',
  SCORECARD_TEMPLATES: '/scorecard-templates',
  SCORECARD_TEMPLATES_CREATE: '/scorecard-templates/create',
};

export const CREATE_ROUTES = [AppRoutes.PROSPECT, AppRoutes.PROMPT_TEMPLATES, AppRoutes.SCORECARD_TEMPLATES];
