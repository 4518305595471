import { useCallback, useMemo } from 'react';
import { DesignerModal, DesignerModalCardProps } from '../../../components';
import { ScorecardTemplateType } from '../../../types';

interface ScorecardDesignerModalProps {
  scorecardType: ScorecardTemplateType;
  isModalOpen: boolean;
  setScorecardType: React.Dispatch<React.SetStateAction<ScorecardTemplateType>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCancel: () => void;
}

const ScorecardDesignerModal = ({
  scorecardType,
  setScorecardType,
  isModalOpen,
  setIsModalOpen,
  onCancel,
}: ScorecardDesignerModalProps) => {
  const options: DesignerModalCardProps[] = useMemo(
    () => [
      {
        id: ScorecardTemplateType.AI,
        isSelected: scorecardType === ScorecardTemplateType.AI,
        title: 'AI scorecard',
        description: 'Automatically scored using AI',
        onSelect: () => setScorecardType(ScorecardTemplateType.AI),
      },
      {
        id: ScorecardTemplateType.MANUAL,
        isSelected: scorecardType === ScorecardTemplateType.MANUAL,
        title: 'Manual scorecard',
        description: 'Completed manually by a user',
        onSelect: () => setScorecardType(ScorecardTemplateType.MANUAL),
      },
    ],
    [scorecardType]
  );

  // Closes the designer modal.
  const onCreate = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <DesignerModal
      options={options}
      isOpen={isModalOpen}
      title="Scorecard designer"
      onCancel={onCancel}
      onNext={onCreate}
      footer="Note: these cannot be changed. You must create a new scorecard to change these properties."
    />
  );
};

export default ScorecardDesignerModal;
