import { useCallback, useMemo } from 'react';
import { Tabs, TabsType } from '../../components';
import { ActivityTab } from './ActivityTab';
import { useAppDispatch, useAppSelector, useFeatureFlag, useUpdateFilterParams } from '../../hooks';
import { AnalyticsTab, AnalyticsFilterKeys, LD_FeatureFlags } from '../../types';
import { setAnalyticsActiveTab } from '../../redux/reducers';
import { ProgressReportTab } from './ProgressReportTab';
import { conditionalArray } from '../../utils';

const AnalyticsPage = () => {
  const dispatch = useAppDispatch();
  const { analyticsActiveTab } = useAppSelector((state) => state.analytics);
  const updateFilterParams = useUpdateFilterParams();

  const analyticsV3FF = useFeatureFlag(LD_FeatureFlags.RELEASE_ANALYTICS_V3);

  const tabs = useMemo(
    () => [
      {
        id: AnalyticsTab.ACTIVITY,
        title: 'Activity',
        content: <ActivityTab />,
      },
      ...conditionalArray(analyticsV3FF, {
        id: AnalyticsTab.PROGRESS_REPORT,
        title: 'Progress report',
        content: <ProgressReportTab />,
      }),
    ],
    [analyticsV3FF]
  );

  const handleTabChange = useCallback(
    (newActiveTab: string) => {
      // Reset all filters except date range when changing tabs
      Object.values(AnalyticsFilterKeys).forEach((key) => {
        if (key !== AnalyticsFilterKeys.DATE_RANGE) {
          updateFilterParams(key, undefined);
        }
      });

      dispatch(setAnalyticsActiveTab(newActiveTab as AnalyticsTab));
    },
    [updateFilterParams, dispatch]
  );

  return (
    <div className="p4 h-full w-full">
      <Tabs tabs={tabs} activeTab={analyticsActiveTab} setActiveTab={handleTabChange} type={TabsType.BORDERED} />
    </div>
  );
};

export default AnalyticsPage;
