import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ButtonColor,
  Icon,
  IconButton,
  Spinner,
  TextButton,
  Typography,
  TypographySize,
  TypographyWeight,
} from '../../../../components';
import { SCORECARDS_WIDTH } from '../../../../constants';
import { useAppSelector } from '../../../../hooks';
import { useGetCallScorecardQuery, useGetOrganizationSettingsQuery } from '../../../../services';
import {
  BaseScorecardTemplate,
  ComponentSize,
  Roles,
  ScorecardAnswer,
  ScorecardTemplate,
  TextColor,
} from '../../../../types';
import CallScorecardSections from './CallScorecardSections';
import NewCallScorecard from './NewCallScorecard';
import ScorecardHeader from './ScorecardHeader';

interface CallScorecardProps {
  isScorecardOpen: boolean;
  setIsScorecardOpen: (isScorecardOpen: boolean) => void;
}

const CallScorecard = ({ isScorecardOpen, setIsScorecardOpen }: CallScorecardProps) => {
  const { callSid } = useParams();

  // Saves scorecards answers
  const [answers, setAnswers] = useState<ScorecardAnswer[] | undefined>(undefined);
  const [isAiScoreable, setIsAiScoreable] = useState(false);

  // State to handle selected scorecard template
  const [scorecardTemplate, setScorecardTemplate] = useState<BaseScorecardTemplate | ScorecardTemplate | undefined>(
    undefined
  );

  const { user } = useAppSelector((state) => state.auth);
  const isSalesRep = user?.role === Roles.SALES_REP;

  // Fetch the scorecard for the current call
  const { data: existingScorecard, isLoading: isScorecardLoading } = useGetCallScorecardQuery(callSid ?? '', {
    skip: !callSid,
  });

  const { data: orgConfigs } = useGetOrganizationSettingsQuery();
  const repCanScoreCalls = orgConfigs?.repCanScoreCalls ?? false;

  // If this is an existing scorecard or not
  const isScored = useMemo(() => existingScorecard?.id, [existingScorecard]);

  // Memoized sections to render
  const sections = useMemo(() => {
    if (isScored) {
      return existingScorecard?.scorecardTemplate?.sections;
    } else if (scorecardTemplate && 'sections' in scorecardTemplate) {
      return scorecardTemplate.sections;
    }
  }, [existingScorecard, scorecardTemplate]);

  // Clear answers if call is not scored
  const handleClearAnswers = useCallback(() => {
    if (!isScored) setAnswers(undefined);
  }, [isScored]);

  // Reset scorecard and clear answers
  const handleResetScorecard = useCallback(() => {
    setScorecardTemplate(undefined);
    handleClearAnswers();
  }, [handleClearAnswers]);

  useEffect(() => {
    if (isScored) {
      // If the scorecard is scored, open the scorecard on initial load
      setIsScorecardOpen(true);
    } else {
      // Update the scorecard open state to false if the scorecard is not scored
      setIsScorecardOpen(false);
    }
    // We listen for changes in callSid in case the call is already fetched and the drawer is not open.
  }, [isScored, callSid]);

  // Initialize answers with existing scorecard data if it exists
  useEffect(() => {
    if (!isScored || !isScorecardOpen) {
      handleResetScorecard();
      return;
    }

    // Flatten the sections and map to answers
    const initialAnswers = existingScorecard?.scorecardTemplate?.sections.flatMap((section) =>
      section.questions
        .map((question) => ({
          id: question?.answers?.[0]?.id,
          questionId: question?.id,
          response: question?.answers?.[0]?.response,
        }))
        // Filter out undefined responses
        .filter((answer) => answer.response !== undefined)
    );
    setAnswers(initialAnswers || []);
  }, [existingScorecard, isScorecardOpen, handleResetScorecard]);

  // Close scorecard and reset scorecard and scorecard template
  const handleCloseScorecard = useCallback(() => {
    setIsScorecardOpen(false);
    handleResetScorecard();
  }, [setIsScorecardOpen, handleResetScorecard]);

  // Check if the scorecard was scored by AI
  const isScoredByAi = existingScorecard?.isScoredByAI ?? false;

  // Check if the scorecard was scored by the current user
  const isScoredByCurrentUser = existingScorecard?.user?.id === user?.id;

  // If the user is a sales rep, then they can score calls if the org setting is enabled
  const canScoreCalls = isSalesRep ? repCanScoreCalls : true;

  // If the user cannot score calls and there is no existing scorecard, then hide the scorecard button
  const hideScorecardButton = !canScoreCalls && !isScored;

  // If call is scored by AI or current user, then anyone with scoring access can edit it
  // If call is not scored, then anyone with scoring access can score it
  const canUserEdit = canScoreCalls && (isScored ? isScoredByAi || isScoredByCurrentUser : true);

  // If the user cannot score calls and there is no existing scorecard, then hide the scorecard button
  if (hideScorecardButton) return null;

  // Show scorecard button if scorecard is not open
  // TODO: calculate postion from the top dynamically
  if (!isScorecardOpen) {
    return (
      <div className="absolute right-4 top-14 flex animate-bounce-horizontal items-start justify-end">
        <TextButton
          text="Scorecard"
          className="!origin-bottom-right !-rotate-90"
          endIcon={isScored ? Icon.CHECK : undefined}
          onClick={() => setIsScorecardOpen(!isScorecardOpen)}
        />
      </div>
    );
  }

  return (
    <div
      className="display-scrollbar-sm flex h-full flex-col overflow-auto rounded-l-lg border-l shadow-md"
      style={{
        minWidth: SCORECARDS_WIDTH,
        maxWidth: SCORECARDS_WIDTH,
      }}
    >
      <div className="flex flex-grow flex-col gap-2 p-6 pb-0">
        <div className="relative flex items-center">
          <div className="absolute -right-3 -top-3">
            <IconButton
              icon={Icon.CLOSE}
              size={ComponentSize.X_SMALL}
              color={ButtonColor.SECONDARY}
              onClick={handleCloseScorecard}
              rounded
            />
          </div>
        </div>
        <div className="flex flex-grow flex-col gap-4">
          <Typography size={TypographySize.H2} weight={TypographyWeight.SEMI_BOLD} color={TextColor.SECONDARY}>
            {isScored ? existingScorecard?.scorecardTemplate?.name : 'Scorecard'}
          </Typography>
          {isScorecardLoading && (
            <div className="flex h-full items-center justify-center">
              <Spinner size={ComponentSize.SMALL} />
            </div>
          )}

          {!isScorecardLoading && (
            <>
              {/* If call is not scored, show scorecard selector and create new scorecard button */}
              {!isScored && (
                <NewCallScorecard
                  scorecardTemplate={scorecardTemplate}
                  setScorecardTemplate={setScorecardTemplate}
                  setIsAiScoreable={setIsAiScoreable}
                  handleClearAnswers={handleClearAnswers}
                />
              )}

              {/* If call is scored, show scored by and points info */}
              {!!existingScorecard?.id && <ScorecardHeader existingScorecard={existingScorecard} />}
              {/* Show scorecard data and actions if a scorecard is selected or call is scored */}
              {sections?.length && (
                <CallScorecardSections
                  scorecardId={existingScorecard?.id}
                  scorecardTemplateId={scorecardTemplate?.id}
                  scorecardName={existingScorecard?.scorecardTemplate?.name || scorecardTemplate?.name}
                  sections={sections}
                  answers={answers}
                  setAnswers={setAnswers}
                  canUserEdit={canUserEdit}
                  isAiScoreable={isAiScoreable}
                  handleClearAnswers={handleClearAnswers}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CallScorecard;
