import clsx from 'clsx';

interface FooterProps {
  children: React.ReactNode;
  className?: string;
}

const Footer = ({ children, className }: FooterProps) => {
  return <div className={clsx('flex w-full border-t border-base-100 p-8', className)}>{children}</div>;
};

export default Footer;
