export interface OrganizationSettings {
  fillerWordsPerMinute?: number | null;
  wordsPerMinute?: number | null;
  talkRatio?: number | null;
  longestMonologue?: number | null;
  vocabulary?: string[];
  repCanScoreCalls?: boolean;
}

export interface OrganizationSettingsResponse {
  message: string;
  data: {
    settings: OrganizationSettings;
  };
}

export enum Benchmarks {
  FILLER_WORDS = 'fillerWordsPerMinute',
  TALK_SPEED = 'wordsPerMinute',
  TALK_RATIO = 'talkRatio',
  LONGEST_MONOLOGUE = 'longestMonologue',
}
