export enum DateFormat {
  WEEKDAY = 'ddd',
  DAY = 'D',
  FULL_DATE = 'MM/DD/YYYY',
  MONTH_DAY = 'MMM D',
  MONTH_DAY_TIME = 'MMM D h:mm A',
  MONTH_YEAR = 'MMMM YYYY',
}

export enum TimeFormat {
  FULL_TIME = 'hh:mm A',
  SHORT_TIME = 'h:mm A',
}

export enum DateUnit {
  MONTH = 'month',
  DAY = 'day',
  WEEK = 'week',
}
