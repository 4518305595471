import { useState } from 'react';
import { ButtonColor, ButtonVariant, Icon, TextButton } from '../../../components';
import { AppRoutes } from '../../../constants';
import { useAppDispatch } from '../../../hooks';
import { startWebCall } from '../../../redux/reducers';
import { PracticeProspect } from '../../../types';
import { getCurrentAppRoute } from '../../../utils';

interface ProspectCallCellProps {
  prospect: PracticeProspect;
}

const ProspectCallCell = ({ prospect }: ProspectCallCellProps) => {
  const dispatch = useAppDispatch();

  const [isHovered, setIsHovered] = useState(false);

  const currRoute = getCurrentAppRoute();
  const isQuiz = currRoute === AppRoutes.QUIZ;

  const runCall = () => {
    dispatch(startWebCall({ prospect }));
  };

  return (
    <TextButton
      startIcon={Icon.PHONE}
      text={isQuiz ? 'Start' : 'Call'}
      color={isHovered ? ButtonColor.PRIMARY : ButtonColor.SECONDARY}
      variant={isHovered ? ButtonVariant.FILLED : ButtonVariant.OUTLINE}
      onClick={runCall}
      onHover={(hover) => setIsHovered(hover)}
    />
  );
};

export default ProspectCallCell;
