import { ColumnDef, PaginationState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import {
  DataTable,
  ManagerNotesModal,
  MAX_TAGS_AND_NOTES_HEIGHT,
  SortableHeader,
  TagsAndNotesCell,
  Typography,
  TypographySize,
  UserBadge,
} from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { startWebCall, updateLastLoginTime } from '../../../redux/reducers';
import { CustomSortingState, DateFormat, PracticeProspect, ProspectSortingFilters, TextColor } from '../../../types';
import ProspectActionsCell from './ProspectActionsCell';
import ProspectCallCell from './ProspectCallCell';

interface ProspectTableProps {
  pagination: PaginationState;
  sorting: CustomSortingState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  setSorting: Dispatch<SetStateAction<CustomSortingState>>;
  isLoading?: boolean;
  totalPages?: number;
}

const ProspectTable = ({
  pagination,
  sorting,
  setPagination,
  setSorting,
  isLoading,
  totalPages = 0,
}: ProspectTableProps) => {
  const [activeRowIndex, setActiveRowIndex] = useState<number>();
  const [managerNotesProspect, setManagerNotesProspect] = useState<PracticeProspect>();

  const dispatch = useAppDispatch();
  const lastLoginTime = useAppSelector((state) => state.auth.lastLoginTime);
  const prospects = useAppSelector((state) => state.activity.prospects);

  const isNewSinceLastLogin = useCallback(
    (date: Date): boolean => {
      // If the last login time is set,
      // check if the date is after the last login time.
      if (lastLoginTime) {
        return dayjs(date).isAfter(dayjs(lastLoginTime));
      }

      // If the last login time is not set, update it with the current time
      // and return false.
      dispatch(updateLastLoginTime());
      return false;
    },
    [dispatch, lastLoginTime]
  );

  // Define columns for the data table.
  const columns: ColumnDef<PracticeProspect>[] = useMemo(
    () => [
      {
        accessorKey: 'call',
        header: '',
        size: 1 / 10,
        cell: ({ row }) => <ProspectCallCell prospect={row.original} />,
      },
      {
        accessorKey: 'prospect',
        header: () => (
          <SortableHeader
            title="Prospect"
            sorting={sorting}
            setSorting={setSorting}
            sortingId={ProspectSortingFilters.FIRST_NAME}
          />
        ),
        cell: ({ row }) => {
          const { firstName, lastName, jobTitle, accountName } = row.original;
          const title = `${firstName} ${lastName}`;
          const subtitle = `${accountName} | ${jobTitle}`;
          return <UserBadge title={title} subtitle={subtitle} />;
        },
      },
      {
        // Column for any additional notes.
        accessorKey: 'tagsAndNotes',
        header: 'Tags & Notes',
        size: 4 / 10,
        cell: ({ row }) => {
          const { personaId, tags, notes } = row.original;
          return <TagsAndNotesCell notes={notes} prospectId={personaId} prospectTags={tags} hideEmptyState />;
        },
      },
      {
        // Column for displaying the date created,
        // highlighting if it was within the last two days.
        accessorKey: 'updatedAt',
        header: () => (
          <SortableHeader
            title="Last updated"
            sorting={sorting}
            setSorting={setSorting}
            sortingId={ProspectSortingFilters.UPDATED_AT}
          />
        ),
        size: 1 / 10,
        cell: ({ row }) => {
          const { updatedAt, createdAt } = row.original;
          const label = dayjs(updatedAt).format(DateFormat.MONTH_DAY);
          // Check if the prospect was created after the user's last login.
          const isNew = isNewSinceLastLogin(createdAt);
          return (
            <div className="flex flex-col gap-1">
              {isNew && (
                <Typography color={TextColor.DESTRUCTIVE} size={TypographySize.CAPTION}>
                  NEW
                </Typography>
              )}
              <Typography size={TypographySize.CAPTION}>{label}</Typography>
            </div>
          );
        },
      },
      {
        accessorKey: 'ellipsis',
        header: '',
        size: 1 / 20,
        cell: ({ row }) => (
          <ProspectActionsCell
            prospect={row.original}
            setIsActive={(open) => setActiveRowIndex(open ? row.index : undefined)}
            setManagerNotesProspect={setManagerNotesProspect}
          />
        ),
      },
    ],
    [sorting, isNewSinceLastLogin]
  );

  return (
    <div className="flex flex-col gap-8">
      <DataTable
        activeRowIndices={[activeRowIndex].filter((index): index is number => index !== undefined)}
        columns={columns}
        data={prospects}
        isLoading={isLoading}
        paginationControls={{ pagination, totalPages, setPagination }}
        contentHeight={MAX_TAGS_AND_NOTES_HEIGHT}
        onRowClick={(_, index) => dispatch(startWebCall({ prospect: prospects[index] }))}
      />
      {managerNotesProspect && (
        <ManagerNotesModal prospect={managerNotesProspect} onClose={() => setManagerNotesProspect(undefined)} />
      )}
    </div>
  );
};

export default ProspectTable;
