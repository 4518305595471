import { v4 as uuidv4 } from 'uuid';
import { BaseScorecardTemplate, QuestionType, ScorecardTemplateType } from '../types';

/** Get a new scorecard question with a default type according to the scorecard type */
export const getNewScorecardQuestion = (isAiScorecard: boolean) => ({
  text: '',
  id: uuidv4(),
  type: isAiScorecard ? QuestionType.YES_NO : undefined,
  points: isAiScorecard ? 1 : undefined,
});

/** Get AI scorecard template options */
export const getAIScorecardTemplateOptions = (scorecardTemplates: BaseScorecardTemplate[]) =>
  scorecardTemplates
    .filter((template) => template.type === ScorecardTemplateType.AI)
    .map((template) => ({
      label: template.name,
      value: template.id,
    }));
