import * as RadixTabs from '@radix-ui/react-tabs';
import clsx from 'clsx';
import React from 'react';
import { TextColor } from '../../../types';
import { Icons } from '../Icons';
import { Typography, TypographySize } from '../Typography';
import { Tab, TabsType } from './Tabs.types';

interface TabsTriggerProps {
  activeTab: string;
  tab: Tab;
  type: TabsType;
  setActiveTab: (newActiveTab: string) => void;
}

const TabsTrigger = ({ activeTab, tab, type, setActiveTab }: TabsTriggerProps) => {
  const { id, title, disabled = false, icon } = tab;

  const isActive = activeTab === id;

  const getTextColor = () => {
    if (isActive) {
      // Active tab text is always primary.
      return TextColor.PRIMARY;
    } else if (type === TabsType.BOXED) {
      // Inactive boxed tabs are always secondary,
      // regardless of disabled state.
      return TextColor.SECONDARY;
    } else if (disabled) {
      // For all other tab types,
      // disabled tabs are always tertiary.
      return TextColor.TERTIARY;
    } else {
      // Default to primary.
      return TextColor.PRIMARY;
    }
  };

  const getBgColor = () => {
    if (!isActive) return 'hover:bg-base-200';
    switch (type) {
      case TabsType.BOXED:
        return 'bg-base-0';
      case TabsType.GHOST:
        return 'bg-base-300';
      case TabsType.LIFTED:
      default:
        return '';
    }
  };

  const renderTrigger = () => {
    const textProps = { size: TypographySize.H5, color: getTextColor(), noWrap: true };
    return (
      <RadixTabs.Trigger
        key={id}
        value={id}
        className={clsx(
          'flex max-h-fit cursor-pointer items-center justify-center gap-2 rounded-lg px-4 py-1',
          disabled && 'pointer-events-none',
          getBgColor()
        )}
        onClick={() => setActiveTab(id)} // We call setActiveTab here and not in onValueChange because we want to always click the tab even if it is already active.
      >
        {icon && <Icons icon={icon} color={getTextColor()} />}
        {typeof title === 'string' && <Typography {...textProps}>{title}</Typography>}
        {typeof title === 'object' && React.cloneElement(title, { ...textProps, ...title.props })}
      </RadixTabs.Trigger>
    );
  };

  if (type === TabsType.LIFTED) {
    return (
      <div
        className={clsx(
          'z-10 flex h-12 items-center border border-transparent',
          !isActive && 'rounded-lg',
          isActive && 'rounded-t-lg border-l-base-100 border-r-base-100 border-t-base-100 bg-base-0'
        )}
      >
        {renderTrigger()}
      </div>
    );
  }

  if (type === TabsType.BORDERED) {
    return (
      <div
        className={clsx(
          'flex h-12 items-center border-b-2',
          !isActive && 'border-b-base-200',
          isActive && 'border-b-neutral-content'
        )}
      >
        {renderTrigger()}
      </div>
    );
  }

  return renderTrigger();
};

export default TabsTrigger;
