import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ScorecardTemplate } from '../../types';

interface ScorecardTemplateState {
  templates: ScorecardTemplate[];
}

const INITIAL_SCORECARD_TEMPLATE_STATE: ScorecardTemplateState = {
  templates: [],
};

const ScorecardTemplateReducer = createSlice({
  name: 'scorecardTemplates',
  initialState: INITIAL_SCORECARD_TEMPLATE_STATE,
  reducers: {
    setScorecardTemplates: (state, action: PayloadAction<ScorecardTemplate[]>) => {
      state.templates = action.payload;
    },
    updateScorecardTemplate: (state, action: PayloadAction<Partial<ScorecardTemplate>>) => {
      const { id, ...rest } = action.payload;
      if (id) {
        state.templates = state.templates.map((template) => (template.id === id ? { ...template, ...rest } : template));
      }
    },
  },
});

export const { setScorecardTemplates, updateScorecardTemplate } = ScorecardTemplateReducer.actions;
export default ScorecardTemplateReducer.reducer;
