import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutes, BANNER_HEIGHT, NAVBAR_HEIGHT } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { openMediaPlayer } from '../../../redux/reducers';
import { useGetCallQuery } from '../../../services';
import CallDetailsDrawerContent from './CallDetailsDrawerContent';

interface CallDetailsDrawerProps {
  mediaPlayerRef?: React.RefObject<HTMLDivElement>;
}

const CallDetailsDrawer = ({ mediaPlayerRef }: CallDetailsDrawerProps) => {
  const navigate = useNavigate();
  const { callSid: urlCallSid } = useParams();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const dispatch = useAppDispatch();
  const banners = useAppSelector((state) => state.modal.banners);
  const reduxCallSid = useAppSelector((state) => state.review.currentCallDetails?.call?.callSid);

  // Only fetch the call if the callSid is present.
  const { data: call, error } = useGetCallQuery(urlCallSid || '', { skip: !urlCallSid });

  // Handles navigating back to the Review page.
  const goToReview = useCallback(
    (replace?: boolean) => navigate({ pathname: AppRoutes.REVIEW, search: window.location.search }, { replace }),
    [navigate]
  );

  useEffect(() => {
    if (urlCallSid && call) {
      // Only open the drawer if the call has been fetched.
      setIsDrawerOpen(true);
      // If the call from URL params differs from Redux state, update Redux with the new call.
      if (urlCallSid !== reduxCallSid) {
        dispatch(openMediaPlayer({ call }));
      }
    } else if (error) {
      // If an error occurred while fetching the call, navigate back to the Review page.
      goToReview(true);
    } else if (!urlCallSid) {
      // If the callSid is not found, close the drawer.
      setIsDrawerOpen(false);
    }
    // We listen for changes in callSid in case the call is already fetched and the drawer is not open.
  }, [urlCallSid, call, error, goToReview, dispatch]);

  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false);
    goToReview();
  }, [goToReview]);

  return (
    <div
      className={clsx(
        'fixed bottom-0 left-0 right-0 translate-y-0 overflow-hidden bg-white transition-all duration-700 ease-in-out',
        isDrawerOpen ? 'translate-y-0' : 'translate-y-full'
      )}
      style={{
        paddingBottom: mediaPlayerRef?.current?.clientHeight,
        top: NAVBAR_HEIGHT + banners.length * BANNER_HEIGHT,
      }}
    >
      {call && <CallDetailsDrawerContent call={call} isOpen={isDrawerOpen} onClose={closeDrawer} />}
    </div>
  );
};

export default CallDetailsDrawer;
