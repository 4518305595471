import { useState } from 'react';
import { ButtonColor, ButtonVariant, Icon, TextButton } from '../../components';
import { useAppDispatch } from '../../hooks';
import { startWebCall } from '../../redux/reducers';
import { PracticeProspect } from '../../types';

interface PracticePageCallCellProps {
  prospect: PracticeProspect;
}

const PracticePageCallCell = ({ prospect }: PracticePageCallCellProps) => {
  const dispatch = useAppDispatch();

  const [isHovered, setIsHovered] = useState(false);

  const runCall = () => {
    dispatch(startWebCall({ prospect }));
  };

  return (
    <TextButton
      startIcon={Icon.PHONE}
      text="Call"
      color={isHovered ? ButtonColor.PRIMARY : ButtonColor.SECONDARY}
      variant={isHovered ? ButtonVariant.FILLED : ButtonVariant.OUTLINE}
      onClick={runCall}
      onHover={(hover) => setIsHovered(hover)}
    />
  );
};

export default PracticePageCallCell;
