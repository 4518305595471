import { useCallback } from 'react';
import { Badge, findOptionByValue, Icon, Select } from '../../components';
import {
  ALL_PROSPECT_TYPE_OPTIONS,
  PROSPECT_TYPE_TO_COLOR,
  PROSPECT_TYPE_TO_LABEL,
  PUBLIC_PROSPECT_TYPE_OPTIONS,
} from '../../constants';
import { useHandleApiResponse } from '../../hooks';
import { useUpdatePromptTemplateMutation } from '../../services';
import { ProspectType } from '../../types';

const UPDATE_PURPOSE_ERROR_MSG = 'Failed to update prompt template purpose';

const PURPOSE_OPTIONS = PUBLIC_PROSPECT_TYPE_OPTIONS.map((option) => ({
  ...option,
  color: PROSPECT_TYPE_TO_COLOR[option.value],
}));

interface ManagePromptTemplatePurposeProps {
  promptTemplateId: string;
  scrollToTop: () => void;
  promptTemplatePurpose?: ProspectType;
}

/**
 * A component that allows the user to manage the prospect purpose of a prompt template.
 * The user can only update the purpose if it is not already set.
 */
const ManagePromptTemplatePurpose = ({
  promptTemplateId,
  scrollToTop,
  promptTemplatePurpose,
}: ManagePromptTemplatePurposeProps) => {
  const handleApiResponse = useHandleApiResponse();
  const [updatePromptTemplate] = useUpdatePromptTemplateMutation();

  // Update the prompt template purpose.
  const handleUpdatePurpose = useCallback(
    async (newPurpose?: string) => {
      try {
        const response = await updatePromptTemplate({
          id: promptTemplateId,
          purpose: newPurpose as ProspectType,
        });
        handleApiResponse({
          response,
          errorMsg: UPDATE_PURPOSE_ERROR_MSG,
          onSuccess: scrollToTop,
        });
      } catch (error) {
        console.error(UPDATE_PURPOSE_ERROR_MSG, error);
      }
    },
    [promptTemplateId, updatePromptTemplate, handleApiResponse, scrollToTop]
  );

  return (
    <Select
      options={PURPOSE_OPTIONS}
      selected={findOptionByValue(ALL_PROSPECT_TYPE_OPTIONS, promptTemplatePurpose)}
      onChange={handleUpdatePurpose}
      disabled={!!promptTemplatePurpose} // Disable the select if the prompt template purpose is already set.
      customButton={
        promptTemplatePurpose ? (
          <Badge
            color={PROSPECT_TYPE_TO_COLOR[promptTemplatePurpose]}
            label={PROSPECT_TYPE_TO_LABEL[promptTemplatePurpose]}
          />
        ) : (
          <Badge outline startIcon={Icon.PLUS} label="Add prospect purpose" />
        )
      }
      width="w-fit"
    />
  );
};

export default ManagePromptTemplatePurpose;
