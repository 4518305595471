import { ComponentSize } from '../../../types';

// Maps badge size to DaisyUI classes.
export const BADGE_SIZE: Record<ComponentSize, string> = {
  [ComponentSize.X_SMALL]: 'badge-xs',
  [ComponentSize.SMALL]: 'badge-sm',
  [ComponentSize.MEDIUM]: 'badge-md',
  [ComponentSize.LARGE]: 'badge-lg',
};

export const BADGE_ICON_SIZE: Record<ComponentSize, ComponentSize> = {
  [ComponentSize.X_SMALL]: ComponentSize.X_SMALL,
  [ComponentSize.SMALL]: ComponentSize.X_SMALL,
  [ComponentSize.MEDIUM]: ComponentSize.SMALL,
  [ComponentSize.LARGE]: ComponentSize.SMALL,
};
