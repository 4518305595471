import { useCallback, useEffect, useRef } from 'react';
import { NO_PACKET_TIMEOUT } from '../constants';
import { TwilioMediaMessage } from '../types';
import { CallMethod } from '../types/call.types';
import { generateRingingDuration } from '../utils';
import usePlayCallAudio from './usePlayCallAudio';

const useWebSocket = ({
  isHangingUpRef,
  resetCallRefs,
  onConnectMessage,
  onStartMessage,
  setIsConnected,
  streamSidRef,
  playBufferedAudio,
  startTimeRef,
  initializeStartTime,
  setIsHangingUp,
}: {
  isHangingUpRef: React.MutableRefObject<boolean>;
  resetCallRefs: () => void;
  onConnectMessage: (message: TwilioMediaMessage) => void;
  onStartMessage: (message: TwilioMediaMessage, startTime: number) => void;
  setIsConnected: (value: boolean) => void;
  streamSidRef: React.MutableRefObject<string>;
  playBufferedAudio: () => void;
  startTimeRef: React.MutableRefObject<number>;
  initializeStartTime: () => void;
  setIsHangingUp: (value: boolean) => void;
}) => {
  const connectWsRef = useRef<WebSocket | null>(null);
  const startWsRef = useRef<WebSocket | null>(null);

  // Ref for the no-packet timeout timer.
  const connectNoPacketTimerRef = useRef<NodeJS.Timeout | null>(null);
  // Ref for the connection initiation timeout timer.
  const initiateConnectionTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { playRingingAudio, pauseRingingAudio, playHangUpAudio } = usePlayCallAudio();

  // Helper function to clear the connection initiation timeout timer.
  const clearInitiateConnectionTimeout = useCallback(() => {
    if (initiateConnectionTimeoutRef.current) {
      clearTimeout(initiateConnectionTimeoutRef.current);
      initiateConnectionTimeoutRef.current = null;
    }
  }, []);

  // Helper function to disconnect the WebSocket connections.
  const handleDisconnect = useCallback(
    async (shouldPlayHangUpAudio?: boolean) => {
      // Clear the connection initiation timeout timer to prevent any further connection attempts
      // if the user has aborted the connection before the connection was established.
      clearInitiateConnectionTimeout();

      // Close /connect WebSocket
      if (connectWsRef.current) {
        connectWsRef.current.close();
        connectWsRef.current = null;
        // Play hang-up audio if the user voluntarily ended the call.
        // We await the hang-up audio to ensure the isConnected state only resets
        // after the hang-up audio has finished playing.
        if (shouldPlayHangUpAudio) {
          await playHangUpAudio();
        }
      }

      // Close /start WebSocket
      if (startWsRef.current) {
        startWsRef.current.close();
        startWsRef.current = null;
      }

      setIsConnected(false);

      // Clear the no-packet timeout timer
      if (connectNoPacketTimerRef.current) {
        clearTimeout(connectNoPacketTimerRef.current);
        connectNoPacketTimerRef.current = null;
      }

      // Pause the ringing sound in case it is still playing.
      pauseRingingAudio();
    },
    [pauseRingingAudio, playHangUpAudio]
  );

  // Helper function to create the 'start' message.
  // TODO: Remove twilioNumber once the backend is updated to support calls using only the personaId.
  const createStartMessage = useCallback(
    (callSid: string, personaId: string, userId: string) => ({
      event: 'start',
      streamSid: streamSidRef.current,
      start: {
        streamSid: streamSidRef.current,
        callSid,
        tracks: ['inbound'],
        mediaFormat: {
          encoding: 'audio/x-mulaw',
          sampleRate: 8000,
          channels: 1,
        },
        customParameters: {
          userId,
          personaId,
          callMethod: CallMethod.WEB_CALL,
        },
      },
    }),
    []
  );

  const initiateConnection = useCallback(
    (
      callSid: string,
      personaId: string,
      userId: string,
      resolve: (value: void | PromiseLike<void>) => void,
      reject: (reason?: unknown) => void
    ) => {
      let connectWsOpen = false;
      let startWsOpen = false;

      // Check if both WebSockets are connected and set the start time.
      const checkBothConnected = () => {
        if (connectWsOpen && startWsOpen) {
          initializeStartTime();
          return resolve();
        }
      };

      // Establish /connect WebSocket.
      connectWsRef.current = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}/twilio/connect/`);
      connectWsRef.current.binaryType = 'arraybuffer';

      connectWsRef.current.onopen = () => {
        console.log('/connect WebSocket connection established');
        pauseRingingAudio();
        setIsConnected(true);
        connectWsOpen = true;
        checkBothConnected();

        // Send 'start' event for /connect
        const startMessage = createStartMessage(callSid, personaId, userId);
        connectWsRef.current?.send(JSON.stringify(startMessage));
      };

      connectWsRef.current.onmessage = (event) => {
        if (typeof event.data === 'string') {
          // Reset the no-packet timer
          if (connectNoPacketTimerRef.current) {
            clearTimeout(connectNoPacketTimerRef.current);
          }
          connectNoPacketTimerRef.current = setTimeout(() => {
            // No packets received for NO_PACKET_TIMEOUT duration
            // Process the remaining audio in the buffer, if any
            playBufferedAudio();
          }, NO_PACKET_TIMEOUT);

          const message = JSON.parse(event.data) as TwilioMediaMessage;
          onStartMessage(message, startTimeRef.current);
          onConnectMessage(message);
        }
      };

      connectWsRef.current.onclose = async () => {
        console.log('/connect WebSocket connection closed');
        connectWsOpen = false;
        // If the socket closed without the user hanging up, then we need to handle the hang up.
        if (!isHangingUpRef.current) {
          setIsHangingUp(true);
          await handleDisconnect(true);
          resetCallRefs();
        }
        reject(new Error('/connect WebSocket connection closed'));
      };

      connectWsRef.current.onerror = (error) => {
        console.error('/connect WebSocket error:', error);
        connectWsOpen = false;
        resetCallRefs();
        handleDisconnect();
        reject(error);
      };

      // Establish /start WebSocket.
      startWsRef.current = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}/twilio/start/`);
      startWsRef.current.binaryType = 'arraybuffer';

      startWsRef.current.onopen = () => {
        console.log('/start WebSocket connection established');
        startWsOpen = true;
        checkBothConnected();

        // Send 'start' event for /start
        const startMessage = createStartMessage(callSid, personaId, userId);
        startWsRef.current?.send(JSON.stringify(startMessage));
      };

      startWsRef.current.onclose = () => {
        console.log('/start WebSocket connection closed');
        reject(new Error('/start WebSocket connection closed'));
      };

      startWsRef.current.onerror = (error) => {
        console.error('/start WebSocket error:', error);
        reject(error);
      };
    },
    [
      onConnectMessage,
      onStartMessage,
      setIsConnected,
      playBufferedAudio,
      createStartMessage,
      initializeStartTime,
      setIsHangingUp,
    ]
  );

  const handleConnect = useCallback(
    (callSid: string, personaId: string, userId: string) => {
      playRingingAudio();

      const ringingDuration = generateRingingDuration();

      return new Promise<void>((resolve, reject) => {
        initiateConnectionTimeoutRef.current = setTimeout(
          () => initiateConnection(callSid, personaId, userId, resolve, reject),
          ringingDuration
        );
      }).finally(() => {
        // Cleanup the connection initiation timeout timer.
        clearInitiateConnectionTimeout();
      });
    },
    [initiateConnection, clearInitiateConnectionTimeout, playRingingAudio]
  );

  const sendConnectMessage = useCallback((message: TwilioMediaMessage) => {
    if (connectWsRef.current?.readyState === WebSocket.OPEN) {
      connectWsRef.current?.send(JSON.stringify(message));
    }
  }, []);

  const sendStartMessage = useCallback((message: TwilioMediaMessage) => {
    if (startWsRef.current?.readyState === WebSocket.OPEN) {
      startWsRef.current?.send(JSON.stringify(message));
    }
  }, []);

  // Cleanup WebSockets on unmount
  useEffect(() => {
    return () => {
      handleDisconnect();
    };
  }, [handleDisconnect]);

  return {
    handleConnect,
    handleDisconnect,
    sendConnectMessage,
    sendStartMessage,
  };
};

export default useWebSocket;
