import { Dispatch, SetStateAction, useCallback } from 'react';
import { ButtonColor, ButtonGroup, TextButton } from '../../../components';
import { useAppDispatch, useAppSelector, useCheckRequiredProspectFields, useHandleApiResponse } from '../../../hooks';
import { setMode } from '../../../redux/reducers';
import { useEditPracticeProspectMutation } from '../../../services';
import { ProspectPageMode } from '../../../types';
import { conditionalObject } from '../../../utils';

const EDIT_PROSPECT_ERROR_MSG = 'Failed to save changes.';

function parseUndefinedValueToNull<T>(value?: T, disabled?: boolean): T | null {
  return disabled || value === undefined ? null : value;
}

interface EditEndActionsProps {
  resetForm: () => void;
  setIsRegeneratePromptOpen: Dispatch<SetStateAction<boolean>>;
}

const EditEndActions = ({ resetForm, setIsRegeneratePromptOpen }: EditEndActionsProps) => {
  const dispatch = useAppDispatch();
  const { personaId, personaPrompt, fields } = useAppSelector((state) => state.prospect);

  // Checks if all required fields are filled.
  const areRequiredFieldsFilled = useCheckRequiredProspectFields();
  const handleApiResponse = useHandleApiResponse();
  const isSaveDisabled = !areRequiredFieldsFilled();

  const [editPracticeProspect, { isLoading: isEditing }] = useEditPracticeProspectMutation();

  // Handles canceling the edit mode by resetting the edited values.
  const handleCancel = useCallback(() => {
    resetForm();
    dispatch(setMode(ProspectPageMode.VIEW));
  }, [resetForm, dispatch]);

  const handleSaveChanges = useCallback(async () => {
    if (!personaId) return;
    try {
      const response = await editPracticeProspect({
        id: personaId,
        ...conditionalObject(!!personaPrompt.isUserModified, {
          // Only save the new prompt if it was edited by the user.
          personaPrompt: personaPrompt.value,
        }),

        // Personal
        firstName: fields.firstName.value,
        lastName: fields.lastName.value,
        age: parseUndefinedValueToNull(fields.age.value),
        pronouns: parseUndefinedValueToNull(fields.pronouns.value),
        talkativenessLevel: fields.talkativenessLevel.value,
        figurativeThinkingLevel: fields.figurativeThinkingLevel.value,
        empathyLevel: fields.empathyLevel.value,
        improvisationLevel: fields.improvisationLevel.value,
        personaVoiceId: fields.personaVoiceId.value,
        firstMessage: fields.firstMessage.value,

        // Lead
        jobTitle: fields.jobTitle.value,
        tenure: parseUndefinedValueToNull(fields.tenure.value),
        roleType: fields.roleType.value,
        department: fields.department.value,
        numOfDirectReports: parseUndefinedValueToNull(fields.numOfDirectReports.value),

        // Account
        accountName: fields.accountName.value,
        industry: fields.industry.value,
        hqLocation: fields.hqLocation.value,
        remoteType: parseUndefinedValueToNull(fields.remoteType.value),
        isMultiSite: fields.isMultiSite.value,
        employeesFrom: parseUndefinedValueToNull(fields.employeesFrom.value),
        employeesTo: parseUndefinedValueToNull(fields.employeesTo, fields.employeesFrom.value === undefined),
        annualRevenueFrom: parseUndefinedValueToNull(fields.annualRevenueFrom.value),
        annualRevenueTo: parseUndefinedValueToNull(
          fields.annualRevenueTo,
          fields.annualRevenueFrom.value === undefined
        ),
        fundingRaisedType: parseUndefinedValueToNull(fields.fundingRaisedType.value),

        // Scenario
        priorities: fields.priorities.map((priority) => priority.value).filter((priority) => !!priority),
        objections: fields.objections.map((objection) => objection.value).filter((objection) => !!objection),
        questions: fields.questions.map((question) => question.value).filter((question) => !!question),
        successResult: fields.successResult.value,
        successDifficultyLevel: fields.successDifficultyLevel.value,
        backgroundAudioPrompt: fields.backgroundAudioPrompt.value,
        backgroundAudioName: fields.backgroundAudioName.value,

        // Configuration
        isHidden: fields.isHidden,
        isRestricted: fields.isRestricted,
        scorecardTemplateId: parseUndefinedValueToNull(fields.scorecardTemplateId),
        managerNotes: parseUndefinedValueToNull(fields.managerNotes),
        numOfInitialShortResponses: parseUndefinedValueToNull(fields.numOfInitialShortResponses),
        objectionRate: parseUndefinedValueToNull(fields.objectionRate),
        questionRate: parseUndefinedValueToNull(fields.questionRate),
        numOfRequiredUncoveredPriorities: parseUndefinedValueToNull(fields.numOfRequiredUncoveredPriorities),
        isHangupFeatureEnabled: fields.isHangupFeatureEnabled,
        hangupPrompt: fields.isHangupFeatureEnabled ? fields.hangupPrompt : null,
        ttsModelId: fields.ttsModelId,
        sttModel: fields.sttModel,
        sttModelId: fields.sttModelId,
        llmModel: fields.llmModel,
        llmModelId: fields.llmModelId,
        llmTemperature: fields.llmTemperature,
        llmMaxTokens: fields.llmMaxTokens,
        promptTemplateId: fields.promptTemplateId,
      });

      handleApiResponse({
        response,
        errorMsg: EDIT_PROSPECT_ERROR_MSG,
        onSuccess: () => {
          dispatch(setMode(ProspectPageMode.VIEW));
          setIsRegeneratePromptOpen(true);
        },
      });
    } catch (error) {
      console.error(EDIT_PROSPECT_ERROR_MSG, error);
    }
  }, [personaId, personaPrompt, fields, dispatch, editPracticeProspect, handleApiResponse]);

  return (
    <ButtonGroup loading={isEditing}>
      <TextButton
        disabled={isSaveDisabled}
        text="Save changes"
        onClick={handleSaveChanges}
        color={ButtonColor.PRIMARY}
        tooltip={isSaveDisabled ? 'Please fill out all required fields before saving.' : undefined}
      />
      <TextButton text="Cancel" onClick={handleCancel} />
    </ButtonGroup>
  );
};

export default EditEndActions;
