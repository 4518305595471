import { useCallback, useState, KeyboardEvent } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { useAppSelector, useHandleApiResponse, useToast } from '../../../hooks';
import { useAddCommentMutation } from '../../../services';
import { ComponentSize, DropdownPlacement } from '../../../types';
import { formatSecondsToDuration } from '../../../utils';
import {
  AlertType,
  ButtonColor,
  Dropdown,
  DropdownContent,
  DropdownTrigger,
  Icon,
  IconButton,
  Icons,
  Spinner,
  TextArea,
  TextButton,
} from '../../shared';
import { useDispatch } from 'react-redux';
import { updateCall } from '../../../redux/reducers';

const ERROR_MSG = 'Failed to add comment';
const COMMENT_PANEL_WIDTH = 300;
const MAX_TEXT_AREA_ROWS = 8;
const MIN_TEXT_AREA_ROWS = 2;

interface CommentButtonProps {
  callSid: string;
  currentTime: number;
  isCompact: boolean;
  waveSurfer: WaveSurfer;
}

const CommentButton = ({ callSid, currentTime, isCompact, waveSurfer }: CommentButtonProps) => {
  // State to keep track of the comment text input.
  const [commentInput, setCommentInput] = useState('');
  // State to control the comment dropdown open/close state.
  const [isCommentDropdownOpen, setIsCommentDropdownOpen] = useState(false);

  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse();

  const dispatch = useDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const currentCall = useAppSelector((state) => state.review.calls.find((call) => call.callSid === callSid));

  const [addComment, { isLoading }] = useAddCommentMutation();

  // Handles toggling the comment dropdown open/close state.
  const toggleCommentDropdown = useCallback(() => {
    if (isCommentDropdownOpen) {
      // Close the dropdown and reset the input state.
      setIsCommentDropdownOpen(false);
      setCommentInput('');
    } else {
      if (waveSurfer.isPlaying()) {
        waveSurfer.pause();
      }
      setIsCommentDropdownOpen(true);
    }
  }, [isCommentDropdownOpen, waveSurfer]);

  const onAddCommentSuccess = useCallback(() => {
    if (!user) return;

    dispatch(
      updateCall({
        callSid,
        numberOfComments: (currentCall?.numberOfComments ?? 0) + 1,
        // Add the user to the list of users who have commented on the call if they are not already in the list
        usersCommented: currentCall?.usersCommented?.find((u) => u.id === user.id)
          ? currentCall.usersCommented
          : [
              ...(currentCall?.usersCommented ?? []),
              { id: user.id, name: user.name ?? '', picture: user.picture ?? '' },
            ],
      })
    );
    // Close the comment dropdown
    toggleCommentDropdown();
  }, [callSid, dispatch, toggleCommentDropdown, user, currentCall]);

  const sendComment = useCallback(async () => {
    const trimmedComment = commentInput.trim();
    if (!trimmedComment.length) return;

    try {
      const response = await addComment({ callSid, text: trimmedComment, timestamp: currentTime });
      handleApiResponse({ response, errorMsg: ERROR_MSG, onSuccess: onAddCommentSuccess });
    } catch (error) {
      console.error(`${ERROR_MSG}: `, error);
      showToast({ message: ERROR_MSG, type: AlertType.ERROR });
    }
  }, [callSid, commentInput, currentTime, handleApiResponse, onAddCommentSuccess]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        sendComment();
      }
    },
    [sendComment]
  );

  const textAreaEndElement = isLoading ? (
    <Spinner size={ComponentSize.X_SMALL} />
  ) : (
    <Icons icon={Icon.SEND} size={ComponentSize.X_SMALL} onClick={sendComment} />
  );

  const action = {
    icon: Icon.COMMENT,
    text: 'Comment',
    onClick: toggleCommentDropdown,
    active: isCommentDropdownOpen,
    color: ButtonColor.SECONDARY,
  };

  return (
    <Dropdown open={isCommentDropdownOpen} onOpenChange={toggleCommentDropdown} placement={DropdownPlacement.TOP_START}>
      <DropdownTrigger>
        {isCompact && (
          <IconButton
            icon={action.icon}
            onClick={action.onClick}
            color={action.color}
            active={action.active}
            tooltip={action.text}
          />
        )}
        {!isCompact && (
          <TextButton
            text={action.text}
            startIcon={action.icon}
            onClick={action.onClick}
            active={action.active}
            color={action.color}
          />
        )}
      </DropdownTrigger>
      <DropdownContent>
        <TextArea
          placeholder={`Leave a comment at ${formatSecondsToDuration(currentTime)}`}
          endIcon={textAreaEndElement}
          width={COMMENT_PANEL_WIDTH}
          maxRows={MAX_TEXT_AREA_ROWS}
          rows={MIN_TEXT_AREA_ROWS}
          value={commentInput}
          size={ComponentSize.X_SMALL}
          onChange={(e) => setCommentInput(e.target.value)}
          onKeyDown={handleKeyDown}
          autoSize
        />
      </DropdownContent>
    </Dropdown>
  );
};

export default CommentButton;
