import { Icon } from '../../components/shared/Icons';

import { Dispatch, SetStateAction, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Badge,
  BadgeColor,
  ButtonColor,
  ButtonVariant,
  Tabs,
  TabsType,
  TextButton,
  Typography,
} from '../../components';
import { useAppDispatch, useAppSelector, useCheckRequiredProspectFields } from '../../hooks';
import { setMode } from '../../redux/reducers';
import { ComponentSize, ProspectPageMode, ProspectPageTabs } from '../../types';
import { camelCaseToLabel } from '../../utils';

interface ProspectPageHeaderProps {
  activeTab: ProspectPageTabs;
  visitedTabs: Set<ProspectPageTabs>;
  setActiveTab: Dispatch<SetStateAction<ProspectPageTabs>>;
  setVisitedTabs: Dispatch<SetStateAction<Set<ProspectPageTabs>>>;
}

const ProspectPageHeader = ({ activeTab, visitedTabs, setActiveTab, setVisitedTabs }: ProspectPageHeaderProps) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { mode, isPromptEdited } = useAppSelector((state) => state.prospect);
  const isViewMode = mode === ProspectPageMode.VIEW;

  const areRequiredFieldsFilled = useCheckRequiredProspectFields();

  // Maps each tab to an object containing its id, title, and disabled state.
  const tabs = useMemo(
    () =>
      Object.values(ProspectPageTabs).map((tab, index) => {
        // Show an asterisk if the tab has unfilled fields.
        const showAsterisk = mode !== ProspectPageMode.VIEW ? !areRequiredFieldsFilled(tab, true) : false;

        // The tab is disabled if it has not been visited before and all required fields in preceding tabs have not been filled out.
        const prevTab = index > 0 ? Object.values(ProspectPageTabs)[index - 1] : null;
        const isDisabled =
          !visitedTabs.has(tab) &&
          (tab === ProspectPageTabs.PROMPT || (!!prevTab && !areRequiredFieldsFilled(prevTab)));
        return {
          id: tab,
          title: (
            <Typography>
              <span className="font-mono">{'\u00A0'}</span>
              {camelCaseToLabel(tab)}
              <span className="font-mono">{showAsterisk ? '*' : '\u00A0'}</span>
            </Typography>
          ),
          disabled: isDisabled,
        };
      }),
    [mode, visitedTabs, areRequiredFieldsFilled]
  );

  // Navigates back to the previous page.
  // Takes into consideration if the previous page was not the Practice page (ie. Review page).
  const handleBack = () => {
    navigate(-1);
  };

  // Changes the active tab and updates the visited tabs.
  const handleChangeTab = (newActiveTab: string) => {
    setActiveTab(newActiveTab as ProspectPageTabs);
    setVisitedTabs((prev) => new Set(prev).add(newActiveTab as ProspectPageTabs));
  };

  // Sets the mode to edit.
  const handleEdit = () => {
    dispatch(setMode(ProspectPageMode.EDIT));
  };

  return (
    <div className="flex w-full flex-none items-center justify-between gap-4 px-8 pt-4">
      <div className="flex items-center gap-4">
        <TextButton onClick={handleBack} text="Back" color={ButtonColor.SECONDARY} startIcon={Icon.CHEVRON_LEFT} />
        <Tabs activeTab={activeTab} setActiveTab={handleChangeTab} type={TabsType.BORDERED} tabs={tabs} />
      </div>
      {isViewMode && (
        <div className="flex items-center gap-4">
          {isPromptEdited && <Badge label="Prompt edited" size={ComponentSize.LARGE} color={BadgeColor.DESTRUCTIVE} />}
          <TextButton text="Edit" variant={ButtonVariant.OUTLINE} color={ButtonColor.SECONDARY} onClick={handleEdit} />
        </div>
      )}
    </div>
  );
};

export default ProspectPageHeader;
